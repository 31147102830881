import { Asset } from '@/app/features/organisation/types';
import ArchiveRestoreMenuItem from '@/components/raytd/archive-restore-menu-item';
import Spinner from '@/components/raytd/spinner';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'; // Shadcn dropdown components
import { AssetEntity, useArchiveAssetMutation, useRetireAssetMutation } from '@app.raytd.com/store';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Archive } from 'lucide-react';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  asset: AssetEntity;
};

export const ActionDropdown: React.FC<Props> = ({ asset }) => {

  const [archiveAsset, { isLoading: isUpdating, isSuccess: isArchiveSuccess, isError: isArchiveError, error: archiveError }] = useArchiveAssetMutation();
  const [retireAsset, { isLoading: isRetiring, isSuccess: isRetireSuccess, isError: isRetireError, error: retireError }] = useRetireAssetMutation();
  const navigate = useNavigate();

  const handleArchive = useCallback(async () => {
    try {
      await archiveAsset(asset.id).unwrap();
    } catch (err) {
      console.error('Error archiving asset', err);
    }
  }, [archiveAsset, asset.id]);

  const handleRetire = useCallback(async () => {
    try {
      await retireAsset(asset.id).unwrap();
    } catch (err) {
      console.error('Error retiring asset', err);
    }
  }, [retireAsset, asset.id]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="h-8 w-8 p-0" disabled={isUpdating}>
          {isUpdating ? <Spinner /> : <DotsHorizontalIcon className="h-4 w-4" />}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {/* <DropdownMenuItem onClick={() => navigate(`/assets/${asset.id}`)} >View</DropdownMenuItem> */}
        <DropdownMenuItem onClick={handleArchive}>
          {/* {asset.status === 'archived' ? 'Restore (Everyone)' : 'Archive (Everyone)'} */}

          <ArchiveRestoreMenuItem
            status={asset.is_globally_archived ? 'archived' : 'active'}
            archiveText='Archive (Everyone)'
            restoreText='Restore (Everyone)'
          />

        </DropdownMenuItem>

        <DropdownMenuItem
          onClick={handleRetire}>
          <Archive className="mr-2 h-4 w-4" />
          {asset.retired ? 'Reinstate' : 'Retire'}
        </DropdownMenuItem>

      </DropdownMenuContent>
    </DropdownMenu>
  );
};
