import { useEffect, useState } from 'react';
import { RatingTypes } from './types';
type RatingWidgetProps = {
    width: number;
    height: number;
    selected?: number;
}


type RatingProps = RatingWidgetProps & {
    type: RatingTypes;
}


const RatingCircle = ({ index, position, diameter, isSelected, svgContent }) => (
    <svg
        key={index}
        x={position.x}
        y={position.y}
        width={diameter}
        height={diameter}
        viewBox="0 0 133 133"
        preserveAspectRatio="xMidYMid meet"
    >

        {
            svgContent !== undefined ? (
                isSelected ? (
                    <g dangerouslySetInnerHTML={{ __html: svgContent }} />
                ) : (
                    <circle cx="66.95" cy="66.95" r="66.95" fill="#d4d4d4" opacity=".9" stroke-width="0" />
                )
            ) : (
                <circle cx="66.95" cy="66.95" r="66.95" fill="#d4d4d4" opacity=".9" stroke-width="0" />
            )
        }
    </svg>
);


type OverlappingCirclesProps = {
    width: number;
    height: number;
    overlapPercentage: number;
    svgUrls: string[];
    selected?: number;
}

const OverlappingCircles = ({ width, height, overlapPercentage, svgUrls, selected }: OverlappingCirclesProps) => {
    const [svgContents, setSvgContents] = useState(Array(5).fill(null));

    useEffect(() => {
        const fetchSvgs = async () => {
            const responses = await Promise.all(svgUrls.map(url => fetch(url)));
            const svgs = await Promise.all(responses.map(res => res.text()));

            //if svgs all null then there is an issues
            //console.info('svgs', svgs);
            if (svgs.every(svg => svg === null)) {
                console.error('Error fetching svgs', svgs);
            }

            setSvgContents(svgs);
        };

        fetchSvgs();
    }, [svgUrls]);

    const calculateDiameter = (width, height, overlapPercentage) => {
        const overlap = overlapPercentage / 100;
        const circleWidth = width / (5 - 4 * overlap);
        return Math.min(circleWidth, height);
    };

    const diameter = calculateDiameter(width, height, overlapPercentage);
    const radius = diameter / 2;
    const horizontalSpacing = diameter * (1 - overlapPercentage / 100);

    const positions = Array.from({ length: 5 }, (_, index) => ({
        x: index * horizontalSpacing,
        y: height / 2 - radius,
    }));

    //console.info('positions', positions, diameter, radius, horizontalSpacing, width, height, overlapPercentage, svgUrls, selected, svgContents);

    const hasSelection = selected !== undefined;

    if (isNaN(selected)) {
        console.info('selected is not a number', selected);
        return <div>{selected}</div>;
    }

    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            {positions.map((position, index) => {
                const isSelected = selected === index;


                if (!hasSelection) {
                    return <RatingCircle key={index} index={index} position={position} diameter={diameter} isSelected={true} svgContent={svgContents[index]} />
                }

                return <RatingCircle key={index} index={index} position={position} diameter={diameter} isSelected={isSelected} svgContent={svgContents[index]} />

            })}

            {
                hasSelection && (
                    <svg
                        x={positions[selected].x}
                        y={positions[selected].y}
                        width={diameter}
                        height={diameter}
                        viewBox="0 0 133 133"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        {svgContents[selected] && (
                            <g dangerouslySetInnerHTML={{ __html: svgContents[selected] }} />
                        )}
                    </svg>
                )
            }

        </svg>
    );
};


export const ComplianceRating = ({ width, height, selected }: RatingWidgetProps) => {

    const svgUrls = [
        'reports/ratings/Compliance-A_1.svg',
        'reports/ratings/Compliance-A_2.svg',
        'reports/ratings/Compliance-A_3.svg',
        'reports/ratings/Compliance-A_4.svg',
        'reports/ratings/Compliance-A_5.svg'
    ];

    if (selected && svgUrls[selected] === undefined) {
        return (
            <div>Invalid rating ({selected})</div>
        )
    }

    return (

        <OverlappingCircles width={width} height={height} overlapPercentage={15} svgUrls={svgUrls} selected={selected} />
    )
}

//convert select = 4 tot 4, < 4 to 1 or undefined = undefined
const convertSelected = (selected) => {

    if (selected === undefined) {
        return undefined;
    }

    if (selected === 4) {
        return 4;
    } else {
        return 0;
    }
}

const ComplianceBRating = ({ width, height, selected }: RatingWidgetProps) => {



    const converted = convertSelected(selected);

    const svgUrls = [
        'reports/ratings/Compliance-A_1.svg',
        undefined,
        undefined,
        undefined,
        'reports/ratings/Compliance-A_5.svg'
    ];

    if (converted && svgUrls[converted] === undefined) {
        console.info('Invalid rating compliance_b', converted);

        return (
            <div>Invalid rating</div>
        )
    }


    return (

        <OverlappingCircles width={width} height={height} overlapPercentage={15} svgUrls={svgUrls} selected={converted} />

    )
}

const ConditionRating = ({ width, height, selected }: RatingWidgetProps) => {
    const svgUrls = [
        'reports/ratings/Condition_1.svg',
        'reports/ratings/Condition_2.svg',
        'reports/ratings/Condition_3.svg',
        'reports/ratings/Condition_4.svg',
        'reports/ratings/Condition_5.svg'
    ];

    return (
        <OverlappingCircles width={width} height={height} overlapPercentage={15} svgUrls={svgUrls} selected={selected} />
    )
}

const MixedRating = ({ width, height, selected }: RatingWidgetProps) => {
    const svgUrls = [
        'reports/ratings/Mixed_1.svg',
        'reports/ratings/Mixed_2.svg',
        'reports/ratings/Mixed_3.svg',
        'reports/ratings/Mixed_4.svg',
        'reports/ratings/Mixed_5.svg'
    ];

    return (
        <OverlappingCircles width={width} height={height} overlapPercentage={15} svgUrls={svgUrls} selected={selected} />
    )
}


export const Rating = ({ type, width, height, selected }: RatingProps) => {

    return <RatingPNG type={type} width={width} height={height} selected={selected} />

    const RatingComponent = (props) => {
        switch (type) {
            case 'compliance':
            case 'compliance_a':
                return <ComplianceRating {...props} />;
            case 'compliance_b':
                return <ComplianceBRating {...props} />;
            case 'condition':
                return <ConditionRating {...props} />;
            case 'mixed':
            case 'frankenstein':
                return <MixedRating {...props} />;
            case 'no_rating':
                return <div>No rating</div>;
            default:
                return <div>Invalid rating scale: {type}</div>;
        }
    }

    console.info('selected rating: ', type);

    const selectedIndex = isNaN(selected) ? undefined : selected < 0 ? 0 : selected > 4 ? 4 : selected;

    if (selectedIndex !== undefined && (!Number.isInteger(selectedIndex) || selectedIndex < 0 || selectedIndex > 4)) {
        throw new Error(`Rating: Selected must be an integer between 0 and 4 or undefined: ${selectedIndex}`);
    }

    return <RatingComponent width={width} height={height} selected={selectedIndex} />

}

export const RatingPNG = ({ type, width, height, selected }: RatingProps) => {

    let rating_type_file = 'Mixed_';

    switch (type) {
        case 'compliance':
        case 'compliance_a':
            rating_type_file = 'Compliance-A';
            break;
        case 'compliance_b':
            rating_type_file = 'Compliance-B';
            break;
        case 'condition':
            rating_type_file = 'Condition';
            break;
        case 'mixed':
            rating_type_file = 'Mixed1';
            break;
        case 'frankenstein':
            rating_type_file = 'Mixed2';
            break;
        case 'no_rating':
        case 'generic':
            rating_type_file = 'No_Rating';
            break;
        default:
            return <div>Invalid rating scale: {type}</div>;
    }

    if (isNaN(selected) || selected === undefined || type === 'generic') {
        return <img
            className="object-contain max-w-full"
            src={`reports/ratings/PNG__No_Rating.png`}
            alt={`No Rating`}
            width={width} height={height}
            title={`Not rated`}
            style={{ width: `${width}px`, height: `${height}px` }}
        />

    }

    let selectedIndex = isNaN(selected) ? undefined : selected < 0 ? 0 : selected > 5 ? 5 : selected;
    selectedIndex += 1;

    if (selectedIndex !== undefined && (!Number.isInteger(selectedIndex) || selectedIndex < 0 || selectedIndex > 5)) {
        throw new Error(`Rating: Selected must be an integer between 0 and 4 or undefined: ${selectedIndex}`);
    }

    return <img
        className="object-contain max-w-full"
        src={`reports/ratings/PNG__${rating_type_file}_${selectedIndex}.png`}
        alt={`Rating: ${selectedIndex}`}
        width={width} height={height}
        title={`${type} / Result ${selectedIndex}`}
        style={{ width: `${width}px`, height: `${height}px` }}
    />

}

export default Rating;