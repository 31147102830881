import { MainPage } from "@/app/layout2/AppLayout"
import SubPageLayout from "@/app/features/organisation/SubPageLayout"
import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom"
import { useCallback, useState } from "react"
import Filters from '../assets/Filters';
import { InspectionList } from './InspectionList';
import { useGetInspectionsQuery } from "@app.raytd.com/store"

export default function () {
  const navigate = useNavigate()
  const [filter, setFilter] = useState('active')
  const [searchTerm, setSearchTerm] = useState('')

  const { isFetching } = useGetInspectionsQuery();

  const handleAdd = useCallback(() => {
    navigate('/inspections/new')
  }, []);

  return (
    <>
      <div className="flex flex-row justify-end items-center space-x-4">
        <Filters selected={filter} setFilter={setFilter} setSearchTerm={setSearchTerm} />
        <Button onClick={handleAdd}>
          + Create Inspection
        </Button>
      </div>

      <MainPage>
        <SubPageLayout
          title="Inspections"
          subtitle="Create, update, manage and share your inspections."
          showSpinner={isFetching}
        >
          <InspectionList
            filter={filter}
            searchTerm={searchTerm}
            handleAdd={handleAdd}

          />
        </SubPageLayout>
      </MainPage>
    </>
  )
}

