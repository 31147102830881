import React from 'react';
import { CircleUser } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Link } from 'react-router-dom';
import { selectLoggedInUser, useAppSelector } from '@app.raytd.com/store';

const UserMenu: React.FC = () => {

  const loggedInUser = useAppSelector(selectLoggedInUser);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
      <div className="flex items-center">
    <Button variant="secondary" size="icon" className="rounded-full mr-2">
      <CircleUser className="h-5 w-5" />
      <span className="sr-only">Toggle user menu</span>
    </Button>
    <span className="text-base text-zinc-950">{loggedInUser?.name}</span>
  </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem><Link to="/profile">Profile</Link></DropdownMenuItem>
        <DropdownMenuItem>Support</DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem><Link to="/logout">Logout</Link></DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserMenu;
