import { api } from '../../api/fetch';
import { ClassificationTree } from '../types';

const getClassificationTrees = async () => {
    return await api.get(`api/v1/classification-trees`);
}

const getClassificationTreeById = async (treeId: number) => {
    return await api.get(`api/v1/classification-trees/${treeId}`);
}

export const getClassificationTreesByOrganisation = async (organisationId: number) => {
    return await api.get(`api/v1/classification-trees/organisation/${organisationId}`);
}

const getActiveClassificationTreesByOrganisation = async (organisationId: number) => {
    return await api.get(`api/v1/classification-trees/organisation/${organisationId}/active`);
}

const getElementsByTreeId = async (treeId: number) => {
    return await api.get(`api/v1/classification-trees/tree/${treeId}`);
}

const addClassificationTree = async (tree: Partial<ClassificationTree>) => {
    return await api.post(`api/v1/classification-trees`, tree);
}
 const updateClassificationTree = async (tree: Partial<ClassificationTree>) => {
    return await api.put(`api/v1/classification-trees/${tree.id}`, tree);
}
const deleteClassificationTree = async (treeId: number) => {
    return await api.delete(`api/v1/classification-trees/${treeId}`);
}

export default {
    getClassificationTrees,
    getClassificationTreeById,
    getClassificationTreesByOrganisation,
    getActiveClassificationTreesByOrganisation,
    getElementsByTreeId,
    addClassificationTree,
    updateClassificationTree,
    deleteClassificationTree
}