import React, { useEffect } from "react";
import { useAppSelector, selectAllTestsuites, saveTestSuite, selectTestSuiteSavingStatus, archiveTestSuite, deleteTestSuite, duplicateTestSuite, alertsActions } from '@app.raytd.com/store';
import { fetchTestsuites, selectTestSuiteLoadingStatus, useAppDispatch } from '@app.raytd.com/store';
import { Card, Button, TableListItem, TableActionCell, Text, SkeletonGroup, SkeletonLine, SectionHeader, Notification, Box, Heading, Badge, TableActionCellSecondaryAction, Avatar, Tabs } from "@wix/design-system";
import { Link, useNavigate } from "react-router-dom";
import { ArchiveBoxXMarkIcon, CameraIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { ArchiveBoxArrowDownIcon, ChevronRightIcon, DocumentDuplicateIcon, PlusIcon } from "@heroicons/react/24/outline";
import TestSuiteEditModal from "./testSuiteEditModal";
import { TestSuite } from "store/src/lib/tests/entity";
import { unwrapResult } from "@reduxjs/toolkit";
import { TestSuiteDeleteModal } from "./testSuiteDeleteModal";

const testSuiteTypeToFriendly = {
    'compliance': 'Compliance',
    'condition': 'Condition Assessment',
    'generic': 'General',
}

const TestSuiteList = () => {
    const testSuites = useAppSelector(selectAllTestsuites);
    const loadingStatus = useAppSelector(selectTestSuiteLoadingStatus);
    const savingStatus = useAppSelector(selectTestSuiteSavingStatus);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showEditModel, setShowEditModel] = React.useState(false);
    const [selectedTestSuite, setSelectedTestSuite] = React.useState(null);
    const [showNotification, setShowNotification] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState(1);

    useEffect(() => {

        console.info('loading status', loadingStatus);

        if (loadingStatus === 'not loaded') {
            console.info('fetching test suites');
            dispatch(fetchTestsuites());
        }

    }
        , [loadingStatus, dispatch]);


    console.info('test suites', testSuites);

    const handleSave = async (testCase: TestSuite) => {
        console.log('save test suite', testCase);
        const result = await dispatch(saveTestSuite(testCase));
        try {
            const response = unwrapResult(result);
            setShowNotification(true);
            setShowEditModel(false);
            console.info(response);
            navigate(`/test/suites/${response.id}`);
            return response;
        } catch (err) {
            console.info('caught error', err);
            throw err;
        }
    }

    const handleDelete = async (id: number) => {
        const testSuite = testSuites.find((testSuite) => testSuite.id === id);
        if (!testSuite) {
            console.info('test suite not found', id);
            return;
        }
        console.info('delete test suite', testSuite);

        try {
            let response;
            if (testSuite.total_assessments > 0) {
                console.info('cannot delete test suite with assessments');
                response = await dispatch(archiveTestSuite(testSuite));
                return;
            } else {
                response = await dispatch(deleteTestSuite(testSuite));
            }
            const result = unwrapResult(response);
            setShowNotification(true);
            setShowDeleteModal(false);
            console.info(result);
        } catch (err) {
            console.info('caught error', err);
            throw err;
        }
    }

    const handleArchive = async (id: number) => {
        const testSuite = testSuites.find((testSuite) => testSuite.id === id);
        if (!testSuite) {
            console.info('test suite not found', id);
            return;
        }
        console.info('archive test suite', testSuite);

        try {
            const response = await dispatch(archiveTestSuite(testSuite));
            const result = unwrapResult(response);
            setShowNotification(true);
            setShowDeleteModal(false);
            console.info(result);
        } catch (err) {
            console.info('caught error', err);
            throw err;
        }
    }

    const handleCreate = () => {
        console.log('create test suite');

        setSelectedTestSuite({
            id: 0,
            title: '',
            description: '',
            type: '',
            ratingScale: 1,
            rating_type: 'single'
        });

        setShowEditModel(true);
    }

    const _handleDuplicate = async (testSuite: TestSuite) => {
        console.info('duplicate test suite', testSuite);
        const response = await dispatch(duplicateTestSuite(testSuite));
        try {
            const result = unwrapResult(response);
            setShowNotification(true);
            navigate(`/test/suites/${result.id}`)
        } catch (err) {
            console.info('caught error', err);
            dispatch(alertsActions.error({ message: 'Failed to duplicate test suite', timeout: 5000 }));
            throw err;
        }

    }

    if (loadingStatus === 'error') {
        return <div>Error loading test suites</div>;
    }

    if (loadingStatus === 'loading') {

        return (
            <SkeletonGroup>
                <TableListItem
                    showDivider={true}
                    options={[
                        { value: <SkeletonLine />, width: '25%' },
                        { value: <SkeletonLine />, width: '50%' },
                        { value: <SkeletonLine />, width: '25%' },
                    ]}
                />
            </SkeletonGroup>
        );
    }

    const testSuiteTypes = [...new Set(testSuites.map(testSuite => testSuite.type))];

    return (
        <>
            <Notification
                theme="success"
                type="sticky"
                autoHideTimeout={1000}
                show={showNotification}
                onClose={() => setShowNotification(false)}
            >
                <Notification.TextLabel ellipsis={false}>
                    <Text weight="bold">
                        {savingStatus === 'saved' ? 'Test Specification saved' : 'Test Specification updated'}
                    </Text>
                </Notification.TextLabel>
                <Notification.CloseButton />
            </Notification>
            <Card>
                <Card.Header
                    title='Test Suites'
                    subtitle="Create, update, manage and share your test suites"
                    suffix={
                        <Button
                            priority="secondary"
                            size="small"
                            prefixIcon={<PlusIcon width={20} height={20} />}
                            onClick={handleCreate}
                        >
                            Create Test Suite
                        </Button>
                    }
                />
                <Card.Content >

                    <Tabs
                        size="medium"
                        activeId={selectedTab}
                        alignment="start"
                        onClick={(value) => setSelectedTab(value.id as unknown as number)}
                        items={[
                            { id: 1, title: 'Current', },
                            { id: 2, title: 'Archived', },
                        ]}
                        />

                    {
                        testSuiteTypes
                        .map((type, index) => {

                            const byType = testSuites.filter((testSuite) => testSuite.type === type)
                            .filter((testSuite) => selectedTab === 1 ? testSuite.status !== 'archived' : testSuite.status === 'archived')
                            .map((row) => {
                                const isArchived = row.status === 'archived';
                                return (
                                    <TestSuiteTile
                                        key={`type-${type}-${row.id}`}
                                        row={row}
                                        actions={!isArchived ? [
                                            {
                                                text: 'Configure',
                                                skin: 'dark',
                                                icon: <ChevronRightIcon width={20} height={20} />,
                                                onClick: () => { setSelectedTestSuite(row); setShowEditModel(true); },
                                            },
                                            {
                                                text: 'Duplicate',
                                                icon: <DocumentDuplicateIcon width={20} height={20} />,
                                                onClick: () =>  { _handleDuplicate(row); },
                                            },
                                            {
                                                // text: `${row.total_assessments > 0 ? 'Archive' : 'Delete'}`,
                                                text: 'Archive',
                                                skin: 'destructive',
                                                icon: (<ArchiveBoxArrowDownIcon width={20} height={20} />),
                                                onClick: () => { setSelectedTestSuite(row); setShowDeleteModal(true); },
                                            },
                                        ] : [
                                            {
                                                text: 'Restore',
                                                icon: <ArchiveBoxXMarkIcon width={20} height={20} />,
                                                onClick: () => { setSelectedTestSuite(row); handleArchive(row.id); },
                                            },
                                            {
                                                text: 'Delete',
                                                skin: 'destructive',
                                                icon: <XCircleIcon width={20} height={20} />,
                                                disabled: row.total_assessments > 0,
                                                onClick: () => { setSelectedTestSuite(row); setShowDeleteModal(true); },
                                            }
                                        
                                        ]}
                                    />
                                );
                            })

                            return (
                                <React.Fragment key={type}>
                                    <SectionHeader 
                                        title={testSuiteTypeToFriendly[type]} 
                               
                                        skin="neutral" 
                                    />
                                    {byType}
                                </React.Fragment>
                            );
                        })

                    }

                </Card.Content>
            </Card>
            <TestSuiteEditModal
                isOpen={showEditModel}
                onClose={() => setShowEditModel(false)}
                handleSave={handleSave}
                testSuite={selectedTestSuite}
            />
            <TestSuiteDeleteModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onDelete={handleDelete}
                onArchive={handleArchive}
                testSuite={selectedTestSuite}
            />
       

        </>
    );
}

const TestSuiteLink = ({ testSuite }: { testSuite: TestSuite }) => {
    return (
        <Box gap={2} align="left" verticalAlign="middle">
            <Avatar name={testSuite.title} size="size30" 
                imgProps={{ src: testSuite.owner?.logo_url ?? '/public/raytd_logo_square.png' }} indication={<CameraIcon width={12} height={12} />}
                text={testSuite.owner?.name ?? 'Unknown'}
                />
            <Link className="text-raytd-600 hover:underline" to={`/test/suites/${testSuite.id}`}>{testSuite.title}</Link>
        </Box>
    )
}

const TestSuiteTile = ({ row, actions }: { row: TestSuite, actions: TableActionCellSecondaryAction[] }) => {

    const isArchived = row.status === 'archived';
    const badge = isArchived ? <Badge size="tiny" prefixIcon={<ArchiveBoxArrowDownIcon width={12} height={12} />}>Archived</Badge> : '';

    return (

        <TableListItem
            showDivider={true}
            verticalPadding="tiny"
            horizontalPadding="xxTiny"
            options={[
                {
                    value: <TestSuiteLink testSuite={row} />,
                    width: '180px'
                },
                {
                    value: <Box direction="vertical"><Text size="small">{row.description} </Text><Box>{badge}</Box></Box>,
                    width: '1fr'
                },
                {
                    value: (
                        <TestSuiteInformationPanel testSuite={row} />
                    ),
                    width: '250px'
                },
                {
                    value: (
                        <TableActionCell
                            size="small"
                            numOfVisibleSecondaryActions={isArchived ? 0 : 1}
                            // primaryAction={{
                            //     text: 'View',
                            //     skin: 'standard',
                            //     onClick: () => { //go to tesdt suite details page
                            //         console.info('view test suite', row);
                            //         redirect(`/test/suites/${row.id}`);
                            //     }
                            // }}
                            secondaryActions={actions}

                        />
                    ),
                    width: '90px'
                },

            ]}
        />

    )
}

const InfoPill = ({ label, value }: { label: string, value: string }) => {
    return (
        <Box direction="vertical" gap={0} align="right" >
            <Text size="tiny">{value}</Text>
            <Heading size="extraTiny">{label}</Heading>
        </Box>
    )
}

const TestSuiteInformationPanel = ({ testSuite }: { testSuite: TestSuite }) => {

    if (testSuite.type === 'compliance') {

        return (
            <Box gap={4} align="right">
                <InfoPill label="Tests" value={testSuite.total_specifications?.toString()} />
                <Box width={"70px"}></Box>
                <Box direction="vertical" gap={0} align="right" width={"90px"}>
                    <InfoPill label="Categories" value={testSuite.total_categories?.toString()} />
                    <InfoPill label="Sub-Categories" value={"tba"} />
                </Box>
                <InfoPill label="Inspections" value={testSuite.total_assessments?.toString()} />
            </Box>
        )
    }

    if (testSuite.type === 'condition') {

        return (
            <Box gap={4} align="right">
                <InfoPill label="Tests" value={testSuite.total_specifications?.toString()} />
                <Box direction="vertical" gap={0} align="right" width={"70px"}>
                    <InfoPill label="Types" value={"tba"} />
                    <InfoPill label="Sub-Types" value={"tba"} />
                </Box>
                <Box direction="vertical" gap={0} align="right" width={"90px"}>
                    <InfoPill label="Categories" value={testSuite.total_categories?.toString() ?? "0"} />
                    <InfoPill label="Sub-Categories" value={"tba"} />
                </Box>
                <InfoPill label="Inspections" value={testSuite.total_assessments?.toString() ?? "0"} />
            </Box>
        )
    }

    if (testSuite.type === 'generic') {

        return (
            <Box gap={4} align="right">
                <InfoPill label="Tests" value={"N/A"} />
                <Box direction="vertical" gap={0} align="right" width={"70px"}>
                    <InfoPill label="Types" value={"tba"} />
                    <InfoPill label="Sub-Types" value={"tba"} />
                </Box>
                <Box direction="vertical" gap={0} align="right" width={"90px"}>
                    <InfoPill label="Categories" value={testSuite.total_categories?.toString() ?? "0"} />
                    <InfoPill label="Sub-Categories" value={"tba"} />
                </Box>
                <InfoPill label="Inspections" value={testSuite.total_assessments?.toString() ?? "0"} />
            </Box>
        )
    }
}

export default TestSuiteList;
