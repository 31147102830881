import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { ScrollArea } from "@/components/ui/scroll-area"

export type FastFillItem = {
  uuid: string
  id: number
  content: string
  delete?: boolean
}

type Props = {
  title: string
  itemName?: string
  items: FastFillItem[]
}

function EmptyList({ itemName }: { itemName?: string }) {
  return (
    <div className="flex flex-col items-start justify-center">
      <p className="text-sm text-gray-500 mb-2">No {itemName?.toLowerCase() ?? 'items'}</p>
    </div>
  )
}

export default function FastFillViewer({ title, itemName = 'Item', items = [] }: Props) {
  return (
    <Card className="w-full p-0 border-0 rounded-none">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 p-2">
        <CardTitle className="text-sm font-medium p-0">
          Fast Fill {title}
        </CardTitle>
      </CardHeader>
      <CardContent className='px-2 py-0'>
        <ScrollArea className="max-h-52">
          {items.length === 0 ? (
            <EmptyList itemName={itemName} />
          ) : (
            items.map(item => (
              <div
                key={item.uuid}
                className="py-1"
              >
                <div
                  className={`bg-zinc-100 text-zinc-950 rounded-md px-3 py-2 text-sm ${
                    item.delete ? 'line-through text-zinc-600' : ''
                  }`}
                >
                  {item.content}
                </div>
              </div>
            ))
          )}
        </ScrollArea>
      </CardContent>
    </Card>
  )
}