import { cn } from "@/lib/utils";

interface SegmentedControlProps {
    value: string
    onValueChange: (value: string) => void
    options: { value: string; label: string }[]
}

const SegmentedControl = ({ value, onValueChange, options }: SegmentedControlProps) => {
    return (
        <div>
            <div className="inline-flex h-10 items-center justify-center rounded-md bg-muted py-1 px-2 text-muted-foreground gap-2">
                {options.map((option) => (
                    <button
                        key={option.value}
                        onClick={() => onValueChange(option.value)}
                        className={cn(
                            "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                            value === option.value && "bg-white text-foreground shadow-sm border border-background"
                        )}
                    >
                        {option.label}
                    </button>
                ))}
            </div>
        </div>
    )
}

export default SegmentedControl