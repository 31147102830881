import { api } from '../../api/fetch';

export const updateLevelOrder = async (ids: number[]) => {

  return await api.post(
    `api/v1/levels/order`,
    { ids: ids }
  );
};

export default updateLevelOrder;
