import { Skeleton } from "@/components/ui/skeleton"
import React from "react";

const NestedListSkeleton = () => {
    return (
        <div className="space-y-4 w-full p-4">
            <MainCategory />
            <MainCategory />
        </div>
    )
};

function MainCategory() {
    return (
        <div className="space-y-6 py-2">
            <div className="flex items-center space-x-2 h-8">
                <Skeleton className="h-4 w-4" />
                <Skeleton className="h-5 w-full" />
                <Skeleton className="h-5 w-24 ml-auto" />
            </div>
            <div className="pl-6 space-y-2">
                <SubCategory />
                <SubCategory />
                <SubCategory />
            </div>
        </div>
    )
}

function SubCategory() {
    const randomNumber = Math.floor(Math.random() * 3) + 1;

    return (
        <div className="pl-6 space-y-6 py-2">
            <div className="flex items-center space-x-2 h-8">
                <Skeleton className="h-4 w-4" />
                <Skeleton className="h-5 w-full" />
                <Skeleton className="h-5 w-20 ml-auto" />
            </div>
            <div className="space-y-2">
                {Array.from({ length: randomNumber }).map((_, index) => (
                    <ListItem key={index} />
                ))}
            </div>
        </div>
    )
}

function ListItem() {
    return (
        <div className="flex items-center space-x-6">
            <Skeleton className="h-4 w-4" />
            <Skeleton className="h-5 w-1/2" />
            <div className="w-full" />
            <div className="flex items-center space-x-2 ml-auto">
                <Skeleton className="h-5 w-8" />
                <Skeleton className="h-5 w-8" />
                <Skeleton className="h-5 w-5" />
            </div>
        </div>
    )
}


export default React.memo(NestedListSkeleton);