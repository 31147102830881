import { api } from '../../api/fetch';
import { Building } from '../types';

export const addBuilding = async (building: Building) => {
  return await api.post('/api/v1/buildings', building);
};

export const updateBuilding = async (building: Building) => {
  return await api.put(`/api/v1/buildings/${building.id}`, building);
}
