import React, { useState, useRef, useEffect } from 'react'

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { ChevronLeft } from "lucide-react"
import { toast } from "sonner"
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import BlockEditor from '@/app/features/blocks/BlockEditor'
import { TDescendant, TElement } from '@udecode/plate-common'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Textarea } from "@/components/ui/textarea"
import { Checkbox } from "@/components/ui/checkbox"
import { useNav } from "@/app/features/profile/NavContext"
import { useNavigate, useParams } from "react-router-dom"
import { useCallback } from 'react';
import { useUnsavedChanges } from "@/hooks/useUnsavedChanges"
import BusyButton from "@/components/raytd/busy-button"
import { useGetReportBlockQuery, useUpdateReportBlockMutation } from '@app.raytd.com/store'
import AutoSizer from 'react-virtualized-auto-sizer';


// First, create the base text node schema
const textSchema = z.object({
    text: z.string(),
}).passthrough(); // Use passthrough for UnknownObject properties

// Create the element schema (recursive)
// @ts-expect-error
const elementSchema: z.ZodType<TElement> = z.lazy(() =>
    z.object({
        type: z.string(),
        children: z.array(descendantSchema),
    }).passthrough() // For UnknownObject properties
);

// Create the descendant schema (union of element and text)
// @ts-expect-error
const descendantSchema: z.ZodType<TDescendant> = z.union([
    elementSchema,
    textSchema
]);

// Create the final body schema
const bodySchema = z.array(elementSchema).min(1, "Content cannot be empty");


const formScheme = z.object({
    title: z.string().min(1, "Title is required"),
    body: bodySchema
})

const EditBlockPage: React.FC<{ blockId: string }> = ({ blockId }) => {

    const navigate = useNavigate();
    const [formDisabled, setFormDisabled] = useState(false);

    const editorRef = useRef<typeof BlockEditor>(null);

    const { data: block, isLoading } = useGetReportBlockQuery(blockId);
    const [updateBlock, { isLoading: isUpdatingBlock }] = useUpdateReportBlockMutation();

    const form = useForm<z.infer<typeof formScheme>>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            title: "",
            body: [],
        },
        disabled: formDisabled,
    });

    const onSubmit = useCallback(async (values: z.infer<typeof formScheme>) => {

        //setContent(editor.children);
        try {
            // // body[] . children[] . text cannot be null
            // const newBody = editorContentRef.current.map((item) => {
            //     if (item.type === 'text') {
            //         return item;
            //     }

            //     const children = item.children.filter((child) => {
            //         console.info({ child });
            //         return child.text !== null;
            //     });

            //     return {
            //         ...item,
            //         children
            //     }
            // });

            // console.debug({ newBody });

            const formData = {
                ...values,
                // body: newBody
            };

            await updateBlock({ id: blockId, ...formData }).unwrap();

            console.debug('save block', formData);

            toast.success('Content saved successfully');
        } catch (error) {
            console.error(error);
            toast.error('Failed to save content');
        }
    }, [form]);

    const { formState } = form;
    const { isDirty } = formState;

    const isBusy = false;

    const { AlertDialogComponent } = useUnsavedChanges(isDirty, form.reset, formDisabled);

    const { setNavButtons } = useNav();

    useEffect(() => {
        setNavButtons([
            <BusyButton
                onClick={form.handleSubmit(onSubmit)}
                disabled={!isDirty}
                isBusy={isBusy}
                busyText="Saving..."
            >
                Save
            </BusyButton>

        ])

        return () => {
            setNavButtons([])
        }
    }, [setNavButtons, block, form.handleSubmit, onSubmit, isDirty, isBusy]);


    useEffect(() => {
        console.debug(formState.errors);
    }, [formState.errors]);

    useEffect(() => {
        if (block) {

            form.reset(block);

            editorContentRef.current = block.body;

            const isAdmin = true; // Implement role logic here
            setFormDisabled(!isAdmin);
        }
    }, [block, form.reset]);


    const editorContentRef = useRef<TElement[]>([]);


    const handleEditorChange = useCallback((newValue: TElement[]) => {
        console.info('editor content', newValue);
        editorContentRef.current = newValue;
        form.setValue('body', newValue, {
            shouldDirty: true,
            shouldValidate: false,
            shouldTouch: false
        });
        // editorRef.current?.focus();

    }, [form]);

    const handleEditorBlur = useCallback(() => {
        console.info('editor blur', editorContentRef.current);
        form.setValue('body', editorContentRef.current, {
            shouldDirty: true,
            shouldValidate: true
        });
    }, [form]);

    //console.debug('editorRef', editorRef);


    return (
        <div className="w-full p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Block</h1>
                {/* Buttons */}
            </div>

            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                    <FormField
                        control={form.control}
                        name="title"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Title*</FormLabel>
                                <FormControl>
                                    <Input {...field} placeholder="Title" />
                                </FormControl>
                                <FormMessage>{form.formState.errors.title?.message}</FormMessage>
                            </FormItem>
                        )}
                    />
                    <FormItem>
                        <FormLabel>Body</FormLabel>
                        <FormControl>
                            <FormField
                                control={form.control}
                                name="body"
                                render={({ field }) => (
                                    <div 
                                    className="border border-zinc-200 rounded-lg"
                                    >
                                        <BlockEditor
                                            //ref={editorRef}
                                            onChange={field.onChange}
                                            initialValue={editorContentRef.current}
                                            onBlur={handleEditorBlur}
                                        />
                                    </div>
                                )}
                            // <BlockEditor
                            //     ref={editorRef}
                            //     onChange={handleEditorChange}
                            //     initialValue={editorContentRef.current}
                            //     onBlur={handleEditorBlur}
                            // />
                            />
                        </FormControl>
                        <FormMessage>{form.formState.errors.body?.message}</FormMessage>
                    </FormItem>
                </form>
            </Form>
            <AlertDialogComponent />
        </div>
    );
}

export default EditBlockPage;