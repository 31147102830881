'use client'

import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Organisation } from './types';
import AvatarImage from "@/app/features/profile/AvatarImage";
import SubPageLayout from './SubPageLayout';
import Offices from "@/app/features/organisation/Offices";
import { useGetOrganisationQuery, useUpdateOrganisationMutation } from "@app.raytd.com/store";
import BusyButton from "@/components/raytd/busy-button";
import { useNav } from "@/app/features/profile/NavContext";
import { toast } from "sonner";
import { LinkedInInput } from "@/app/features/profile/LinkedIn";
import { PrefixedInput } from "@/components/raytd/prefixed-input";
import { Badge } from "@/components/ui/badge";
import { useUnsavedChanges } from "@/hooks/useUnsavedChanges";
import { ImageUpload } from "@/components/raytd/image-upload";
import { useFileUpload } from "@/hooks/use-file-upload";

const formSchema = z.object({
  name: z.string().min(1, "Organisation name is required"),
  phone: z.string().optional(),
  website: z.string().url("Invalid URL").optional().or(z.literal('')),
  linkedin: z.string().url("Invalid URL").optional().or(z.literal('')),
  facebook: z.string().url("Invalid URL").optional().or(z.literal('')),
  instagram: z.string().url("Invalid URL").optional().or(z.literal('')),
  photo: z.union([z.null(), z.instanceof(File)]).optional(),
});

type APIError = {
  status: number;
  data: {
    message: string;
    errors: Record<string, string[]>;
  };
}

type UpdateResponse = {
  organisation: Organisation;
}

export const OrganisationForm: React.FC<{ organisationId: number }> = ({ organisationId }) => {
  const { data: organisation, isLoading, error:loadingError } = useGetOrganisationQuery(organisationId);
  const [updateOrganisation, { isLoading: isUpdating, isError, error }] = useUpdateOrganisationMutation();

  const { upload: uploadPhoto, isLoading: isImageUploading, isError: isImageError, isSuccess: isImageSuccess, error: imageError, reset } = useFileUpload('/api/v1/organisations/:id/avatar', 'organisation_image');

  const [formDisabled, setFormDisabled] = React.useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      phone: '',
      website: '',
      linkedin: '',
      facebook: '',
      instagram: '',
      photo: null
    },
    disabled: formDisabled,
  });

  const { isDirty, dirtyFields } = form.formState;

  console.debug({ isDirty, dirtyFields });

  const { AlertDialogComponent } = useUnsavedChanges(isDirty, form.reset);

  const [retainedImage, setRetainedImage] = React.useState<string | null>(null);

  const onSubmit = useCallback(async (values: z.infer<typeof formSchema>) => {
    try {

      const { name, photo, ...data } = values;

      const promises = [];

      if (photo instanceof File) {
        const uploadPhotoPromise = uploadPhoto(photo, { id: organisationId.toString() });
        promises.push(uploadPhotoPromise);
      }
  
      const updateOrganisationPromise = updateOrganisation({ id: organisationId, title: name, ...data }).unwrap();
      promises.push(updateOrganisationPromise);
  
      const [photoResponse, payload] = await Promise.all(promises);
  
      if (photoResponse) {
        console.debug('photo', photoResponse);
        setRetainedImage(photoResponse.url);
      }
  
      console.info(payload);

//      form.reset((payload as UpdateResponse).organisation); 

    } catch (err) {
      console.error(err as APIError);
      if (err?.status === 403) {
        form.setError("name", {
          type: "manual",
          message: "You are not authorised to update this organisation",
        });
        toast.error("You dont' have permission to update this organisation");
      }
    }
  }, [updateOrganisation, organisationId]);


  //   const onSubmit = useCallback(async (values: z.infer<typeof formSchema>) => {
  //     try {
  //         const { photo, address, ...data } = values;
  //         data.physical_address = address;
  //         const payload = await updateAsset({ id: asset.id, ...data }).unwrap();
  //         console.info(payload);

  //         if (photo instanceof File) {
  //             const photoResponse = await uploadPhoto(photo, { id: asset.id.toString() });
  //             console.debug('photo', photoResponse);
  //         }

  //         form.reset(values);

  //     } catch (err) {
  //         console.error(err);
  //     }
  // }, [updateAsset, uploadPhoto, asset.id, form]);

  const { setNavButtons } = useNav();

  useEffect(() => {
    setNavButtons(
      <BusyButton
        onClick={form.handleSubmit(onSubmit)}
        disabled={!isDirty}
        isBusy={isUpdating || isImageUploading}
        busyText="Saving..."
      >
        Save
      </BusyButton>
    );

    return () => setNavButtons(null);
  }, [setNavButtons, form.handleSubmit, onSubmit, isDirty, isUpdating, isImageUploading]);

  useEffect(() => {
    if (organisation) {
      console.debug('Organisation reset', organisation);
      form.reset({
        ...organisation,
        photo: null
      });
      const isAdmin = organisation?.role === 'admin';
      setFormDisabled(!isAdmin);
      //setRetainedImage(organisation.avatar);
    }
  }, [organisation, form.reset, setFormDisabled]);

  if (loadingError) {
    console.error(error);
    return <div>Error loading organisation</div>;
  }

  return (
    <SubPageLayout title="Organisation Profile"
      showLoading={isLoading}
      badge={<Badge>{organisation?.role}</Badge>}
    >
      <AlertDialogComponent />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="col-span-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name*</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Organisation Name" required />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Phone</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Phone Number" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="website"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Website</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Website URL" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="linkedin"
                render={({ field }) => (
                  <PrefixedInput
                    prefix="https://linkedin.com/"
                    label="LinkedIn Profile"
                    description="Your LinkedIn profile URL (optional)"
                    placeholder="company/yourcompany"
                    {...field}
                    value={field.value || ""}
                    onChange={(value) => field.onChange(value || null)}
                  />
                )}
              />


              <FormField
                control={form.control}
                name="facebook"
                render={({ field }) => (
                  <PrefixedInput
                    prefix="https://facebook.com/"
                    label="Facebook Profile"
                    description="Your facebook page (optional)"
                    placeholder="page"
                    {...field}
                    value={field.value || ""}
                    onChange={(value) => field.onChange(value || null)}
                  />
                )}
              />

              <FormField
                control={form.control}
                name="instagram"
                render={({ field }) => (
                  <PrefixedInput
                    prefix="https://instagram.com/"
                    label="Instagram"
                    description="Your instagram page"
                    placeholder="username"
                    {...field}
                    value={field.value || ""}
                    onChange={(value) => field.onChange(value || null)}
                  />
                )}
              />

            </div>

            <div>
              {/* <AvatarImage
                model={organisation}
                disabled={formDisabled}
                updateEndpoint={{
                  endpoint: 'api/v1/organisations/:id/avatar',
                  key: 'organisation_image'
                }}
              /> */}

              <FormField
                control={form.control}
                name="photo"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Photo</FormLabel>
                    <FormControl>

                      <ImageUpload
                        onChange={(file) => field.onChange(file)}
                        onBlur={field.onBlur}
                        value={field.value}
                        name={field.name}
                        error={form.formState.errors.photo?.message}
                        initialImage={retainedImage ?? organisation?.avatar}
                        shape="circle"
                        isLoading={isImageUploading}
                      />

                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

            </div>
          </div>
        </form>
      </Form>

      <div className="mt-6">
        <Offices disabled={formDisabled} />
      </div>
    </SubPageLayout>
  );
};