import { api } from "../../api/fetch";
import { TestCase } from '../entity';

export const saveTestSpecification = async (testCase: TestCase) => {
    return  await api.post(`/api/v1/test-suites/${testCase.test_suite_id}/tests`, testCase);
}

export const deleteTestSpecification = async (testCase: TestCase) => {
    return  await api.delete(`/api/v1/test-suites/${testCase.test_suite_id}/tests/${testCase.id}`);
}

export default saveTestSpecification;
