import classNames from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNav } from './NavContext';

type NavLinkProps = {
    to: string;
    children: React.ReactNode;
    active?: boolean;
};

export const NavLink: React.FC<NavLinkProps> = ({ to, children }) => {
    const location = useLocation();
    //const isActive = location.pathname === to; // Check if the current route matches
    //const isActive = location.pathname.endsWith(to);
    const isActive = location.pathname.includes(to) && location.pathname.slice(-to.length) === to;

    return (
        <Link
            to={to}
            className={classNames(" hover:text-black", {
                "text-black font-semibold": isActive,
                "text-zinc-500": !isActive,
            })}
        >
            {children}
        </Link>
    );
};

export const TopNavBar = ({children}) => {
    const { navButtons } = useNav();

    return (
        <nav className="flex items-center bg-white border-b h-10">
            {/* Left side - Logo or Navigation Links */}
            <div className="flex items-center space-x-5 px-4 flex-1">
                {children}
            </div>
            <div className="flex space-x-2 justify-center items-center">
                {navButtons}
            </div>

        </nav>
    );
};
