import { Address, formatAddress } from "@app.raytd.com/store";
import { Pill } from "@/components/raytd/pill";
import AddressAutoComplete, { AddressType } from '@/components/ui/address-autocomplete';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import AddressLine from "@/app/features/assets/AddressLine";

export const convertAddressTypeToAddress = (address: AddressType): Address => {
    return {
        street: address.address1,
        suburb: address.city,
        city: '',
        state: address.region,
        postcode: address.postalCode,
        country: address.country
    }
}

export const convertAddressToAddressType = (address: Address): AddressType => {
    return {
        address1: address.street,
        address2: '',
        formattedAddress: formatAddress(address),
        city: address.suburb,
        region: address.state,
        postalCode: address.postcode,
        country: address.country,
        lat: 0,
        lng: 0
    }
}

type AddressInputProps = {
  value: Address;
  onChange: (value: Address) => void;
  onBlur: () => void;
};

const AddressInput = forwardRef<HTMLInputElement, AddressInputProps>(({ value, onChange, onBlur }, ref) => {
  const [isEditing, setIsEditing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (value) {
      console.debug('AddressInput setValue effect', {value});
      setSearchInput(formatAddress(value));
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        onBlur();
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onBlur]);

  const handleAddressChange = (newAddress: AddressType) => {
    const convertedAddress = convertAddressTypeToAddress(newAddress);
    console.info('AddressInput handleAddressChange', { newAddress, convertedAddress });
    onChange(convertedAddress);
    setIsEditing(false);
    onBlur();
  };

  useEffect(() => {
    console.info('AddressInput isEditing', isEditing);
  }, [isEditing])

  // if (!isEditing) {
  //   return (
  //     <div onClick={() => setIsEditing(true)}>
  //       <Pill>
  //         <AddressLine address={value} emptyText="Select Address" />
  //       </Pill>
  //     </div>
  //   )
  // }

  const convertedAddress = useMemo(() => convertAddressToAddressType(value), [value]);

  return (
    <div ref={containerRef}>
      <AddressAutoComplete
        ref={ref}
        // @ts-ignore
        address={convertedAddress}
        setAddress={handleAddressChange}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        dialogTitle="Enter Address"
      />
    </div>
  )
});

AddressInput.displayName = "AddressInput";

export default React.memo(AddressInput);
