import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"

const paginationToolbarVariants = cva(
  "flex items-center justify-between px-2",
  {
    variants: {
      size: {
        default: "py-4",
        sm: "py-2",
        lg: "py-6",
      },
    },
    defaultVariants: {
      size: "default",
    },
  }
)

export interface PaginationToolbarProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof paginationToolbarVariants> {
  currentPage: number
  totalPages: number
  pageSize?: number
  pageSizeOptions?: number[]
  onPageChange: (page: number) => void
  onPageSizeChange?: (pageSize: number) => void
  totalItems?: number
  selectedItems?: number
  showPageSize?: boolean
  showFirstLastButtons?: boolean
  itemsText?: string
  pageSizeText?: string
}

const PaginationToolbar = React.forwardRef<HTMLDivElement, PaginationToolbarProps>(
  ({ 
    className,
    size,
    currentPage,
    totalPages,
    pageSize,
    pageSizeOptions = [10, 20, 30, 40, 50],
    onPageChange,
    onPageSizeChange,
    totalItems,
    selectedItems,
    showPageSize = false,
    showFirstLastButtons = false,
    itemsText = "item(s)",
    pageSizeText = "Rows per page",
    ...props
  }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(paginationToolbarVariants({ size }), className)}
        {...props}
      >
        <div className="flex-1 text-sm text-muted-foreground font-medium">
          {selectedItems !== undefined && totalItems !== undefined && (
            <>
              {selectedItems} of {totalItems} {itemsText} selected.
            </>
          )}
          {selectedItems === undefined && totalItems !== undefined && (
            <>
              {totalItems} {itemsText}
            </>
          )}
        </div>
        <div className="flex items-center space-x-6 lg:space-x-8">
          {showPageSize && onPageSizeChange && (
            <div className="flex items-center space-x-2">
              <p className="text-sm font-medium">{pageSizeText}</p>
              <Select
                value={`${pageSize}`}
                onValueChange={(value) => onPageSizeChange(Number(value))}
              >
                <SelectTrigger className="h-8 w-[70px]">
                  <SelectValue placeholder={pageSize} />
                </SelectTrigger>
                <SelectContent side="top">
                  {pageSizeOptions.map((size) => (
                    <SelectItem key={size} value={`${size}`}>
                      {size}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {currentPage} of {totalPages}
          </div>
          <div className="flex items-center space-x-2">
            {showFirstLastButtons && (
              <Button
                variant="outline"
                size="icon"
                className="hidden h-8 w-8 p-0 lg:flex"
                onClick={() => onPageChange(1)}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Go to first page</span>
                <ChevronLeftIcon className="h-4 w-4" />
                <ChevronLeftIcon className="h-4 w-4 -ml-2" />
              </Button>
            )}
            <Button
              variant="outline"
              size="icon"
              className="h-8 w-8 p-0"
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              size="icon"
              className="h-8 w-8 p-0"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            {showFirstLastButtons && (
              <Button
                variant="outline"
                size="icon"
                className="hidden h-8 w-8 p-0 lg:flex"
                onClick={() => onPageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                <span className="sr-only">Go to last page</span>
                <ChevronRightIcon className="h-4 w-4" />
                <ChevronRightIcon className="h-4 w-4 -ml-2" />
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }
)
PaginationToolbar.displayName = "PaginationToolbar"

export { PaginationToolbar, paginationToolbarVariants }