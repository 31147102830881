import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Point } from '@app.raytd.com/entities';

export const SITEMAP_FEATURE_KEY = 'sitemap';

/*
 * Update these interfaces according to your requirements.
 */
export interface SitemapEntity {
  id: number;
}

export interface SitemapState extends EntityState<SitemapEntity, number> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  error?: string | null;
  points: Point[];
}

export const sitemapAdapter = createEntityAdapter<SitemapEntity>();

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchSitemap())
 * }, [dispatch]);
 * ```
 */
export const fetchSitemap = createAsyncThunk<SitemapEntity[]>(
  'sitemap/fetchStatus',
  async (_, thunkAPI) => {
    /**
     * Replace this with your custom fetch call.
     * For example, `return myApi.getSitemaps()`;
     * Right now we just return an empty array.
     */
    return Promise.resolve([]);
  }
);

export const initialSitemapState: SitemapState = sitemapAdapter.getInitialState(
  {
    loadingStatus: 'not loaded',
    error: null,
    points: [] as Point[],
  }
);

export const sitemapSlice = createSlice({
  name: SITEMAP_FEATURE_KEY,
  initialState: initialSitemapState,
  reducers: {
    add: sitemapAdapter.addOne,
    remove: sitemapAdapter.removeOne,
    addPoint(state: SitemapState, action: PayloadAction<Point>) {
      state.points.push(action.payload);
    },
    setPoints(state: SitemapState, action: PayloadAction<Point[]>) {
      state.points = action.payload;
    },
    removePointAt(state: SitemapState, action: PayloadAction<number>) {

      state.points = state.points.filter((point, index) => {
        return index !== action.payload;
      });
    },
    removePoint(state: SitemapState, action: PayloadAction<Point>) {

      state.points = state.points.filter((point) => {
        return point.x !== action.payload.x && point.y !== action.payload.y;
      });
    },
    updatePoint: (state: SitemapState, action: PayloadAction<Point>) => {
      const index = state.points.findIndex(
        (point) => point.x === action.payload.x && point.y === action.payload.y
      );

      if (index !== -1) {
        const newPoints = [...state.points];
        newPoints[index] = { ...newPoints[index], comment: action.payload.comment };
        state.points = newPoints;
      }

    }  
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSitemap.pending, (state: SitemapState) => {
        state.loadingStatus = 'loading';
      })
      .addCase(
        fetchSitemap.fulfilled,
        (state: SitemapState, action: PayloadAction<SitemapEntity[]>) => {
          sitemapAdapter.setAll(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(fetchSitemap.rejected, (state: SitemapState, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const sitemapReducer = sitemapSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(sitemapActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const sitemapActions = sitemapSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllSitemap);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = sitemapAdapter.getSelectors();

export const getSitemapState = (rootState: {
  [SITEMAP_FEATURE_KEY]: SitemapState;
}): SitemapState => rootState[SITEMAP_FEATURE_KEY];

export const selectAllSitemap = createSelector(getSitemapState, selectAll);

export const selectSitemapEntities = createSelector(
  getSitemapState,
  selectEntities
);
