import { TestSuite } from '../entity';
import { api } from '../../api/fetch';

export const updateTestSuite = async (testSuite: Partial<TestSuite>) => {
    return await api.put(`api/v1/test-suites/${testSuite.id}`, testSuite);
}

export const duplicateTestSuite = async (testSuite: TestSuite) => {
    return await api.post(`api/v1/test-suites/${testSuite.id}/duplicate`);
}
