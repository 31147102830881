
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { TestSuite } from "store/src/lib/tests/entity"
import { useEffect } from "react"
import BusyButton from "@/components/raytd/busy-button"
import { useUnsavedChanges } from "@/hooks/useUnsavedChanges"
import { formatDistance } from 'date-fns';
import { useNav } from "@/app/features/profile/NavContext"


const createSchema = z.object({
    name: z.string().min(1, "Name is required"),
    description: z.string().min(1, "Description is required"),
})

type CreateFormValues = z.infer<typeof createSchema>

interface TestSuiteDetailsFormProps {
    suite?: TestSuite;
    onSubmit: (data: any) => void;
    saveButtonText?: string;
    cancelButtonText?: string;
    onCancel?: () => void;
    isBusy?: boolean;
    setNavButtons?: (buttons: React.ReactNode) => void;
}

const TestSuiteDetailsForm: React.FC<TestSuiteDetailsFormProps> = ({ 
    suite, 
    onSubmit, 
    onCancel, 
    saveButtonText, 
    cancelButtonText,
    isBusy = false,
    setNavButtons
 }) => {

    const createForm = useForm<CreateFormValues>({
        resolver: zodResolver(createSchema),
    });

    const { formState } = createForm;
    const { isDirty } = formState;
    const formDisabled = false;

    const { AlertDialogComponent } = useUnsavedChanges(isDirty, createForm.reset, formDisabled);   

    useEffect(() => {
        if (setNavButtons) {
          setNavButtons(
            <BusyButton
              onClick={createForm.handleSubmit(onSubmit)}
              disabled={!isDirty}
              isBusy={isBusy}
              busyText="Saving..."
            >
              Save
            </BusyButton>
          );
    
          // Clean up function to reset buttons when component unmounts
          return () => setNavButtons(null);
        }
      }, [setNavButtons, createForm.handleSubmit, onSubmit, isDirty, isBusy]);
    

    useEffect(() => {
        if (suite) {
            createForm.reset({
                name: suite.title,
                description: suite.description,
            });
        }
    }, [suite]);

    return (
        <Form {...createForm}>
            <form onSubmit={createForm.handleSubmit(onSubmit)} className="space-y-4">
                <FormField
                    control={createForm.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Name*</FormLabel>
                            <FormDescription>Used to identify your test suite on the Raytd web app, mobile app and reports.</FormDescription>
                            <FormControl>
                                <Input placeholder="Test Suite Name..." {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={createForm.control}
                    name="description"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Description*</FormLabel>
                            <FormControl>
                                <Textarea placeholder="Test Suite Description..." {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className="flex justify-between">
                    {cancelButtonText && (
                        <Button variant="outline" type="button" onClick={() => onCancel?.()}>
                            {cancelButtonText}
                        </Button>
                    )}
                    <div className="flex-1"/>
                    {saveButtonText && (
                        <BusyButton isBusy={isBusy} type="submit">{saveButtonText}</BusyButton>
                )}
                </div>
            </form>
            <AlertDialogComponent />
        </Form>
    );
};

export default TestSuiteDetailsForm;