import { Provider } from 'react-redux';
import { rootStore } from '@app.raytd.com/store';
import * as ReactDOM from 'react-dom/client';
import App from './app/app';

import { ThemeProvider, WixDesignSystemProvider } from '@wix/design-system';
import '@wix/design-system/styles.global.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={rootStore}>
    <WixDesignSystemProvider
      features={{
        newColorsBranding: true,
      }}

    >
      <ThemeProvider
        theme={{
          buttonBorderRadius: '1px',
        }}
      >

        <App />

      </ThemeProvider>
    </WixDesignSystemProvider>
  </Provider>
);
