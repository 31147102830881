
import { useAuth } from "@/app/_components";
import { RoleTypes, TeamMember } from "@/app/features/organisation/types";
import ErrorAlert from "@/components/raytd/form-error";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader } from "@/components/ui/alert-dialog";
import { DataTable } from "@/components/ui/data-table";
import { Input } from "@/components/ui/input";
import { CompanyEntity, useDeleteAdminUserMutation, useGetAdminOrganisationsQuery, useGetAdminUsersQuery, useGetOrganisationQuery, useGetTeamUsersQuery, useLeaveOrganisationMutation, useUpdateUserRoleMutation } from "@app.raytd.com/store";
import { AlertDialogTitle } from "@radix-ui/react-alert-dialog";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Badge } from "@/components/ui/badge";
import SubPageLayout from '../organisation/SubPageLayout';
import { createColumns } from './users/columns';
import { Users } from 'lucide-react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select"
import { set } from "react-hook-form";
  

const UsersAdminPage = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [selectedOrg, setSelectedOrg] = React.useState<CompanyEntity>();

    useEffect(() => {
       console.info('selectorg', selectedOrg);
    }, [selectedOrg]);

    return (
        <SubPageLayout title="Users Administration">

            <OrganisationSelector organisation={selectedOrg} setOrganisation={setSelectedOrg} />
            <UsersTable organisation={selectedOrg} />

        </SubPageLayout>
    );
}

const OrganisationSelector = ({organisation, setOrganisation}) => {
  const { data: organizations, isLoading, error } = useGetAdminOrganisationsQuery({})

  if (isLoading) {
    return <div>Loading organizations...</div>
  }

  if (error) {
    return <div>Error loading organizations</div>
  }

  return (
    <Select value={organisation?.id} onValueChange={(id) => setOrganisation(organizations?.find((org) => org.id === id))}>
      <SelectTrigger className="w-[200px]">
        <SelectValue placeholder="Select an organization" />
      </SelectTrigger>
      <SelectContent>
        {organizations?.map((org) => (
          <SelectItem key={org.id} value={org.id}>
            {org.title}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

interface TeamPageProps {
    organisation: CompanyEntity; // Define the prop type
}

const UsersTable: React.FC<TeamPageProps> = ({ organisation }) => {
    const [globalFilter, setGlobalFilter] = useState("")
    const { data: teamMembers, isLoading, isFetching, error } = useGetAdminUsersQuery(organisation?.id);
    const [updateUserRole, { isLoading: isUpdatingRole, isError: isUpdateRoleError, error: updateUserRoleError }] = useUpdateUserRoleMutation();
    const { user, refreshUser } = useAuth();
    const [userToRemove, setUserToRemove] = useState<TeamMember | null>(null);
    const [leaveOrganisation, { isLoading: isLeavingOrganisation, isError: isLeavingOrgError, error: leaveOrgError }] = useLeaveOrganisationMutation();
    const navigate = useNavigate();
    const [deleteUser, { isLoading: isDeletingUser, isError: isDeleteUserError, error: deleteUserError }] = useDeleteAdminUserMutation();
    const [opereration, setOperation] = useState<string | null>(null);

    const handleChangeRole = useCallback(async (member: TeamMember, newRole: RoleTypes) => {
        try {
            console.log("Change role", { userId: member.id, organisation_id: organisation.id, role: newRole })
            const response = await updateUserRole({ user_id: member.id, organisation_id: organisation.id, role: newRole });
            console.log("Update role response", response);
            if (response.error) {
                //@ts-ignore
                throw new Error(response.error?.message ?? "Error updating user role");
            }
            toast.success("User role updated successfully");
        } catch (error) {
            console.error("Error updating user role", { error, isUpdateRoleError, updateUserRoleError });
            toast.error("Error updating user role");
        }
    }, []);

    console.info('Team members', teamMembers);

    const handleRemoveMemberConfirmation = useCallback((member: TeamMember) => {

        // Implement your logic here
        setUserToRemove(member);
        setOperation('remove');

    }, []);

    const handleDelete = useCallback(async (member: TeamMember) => {
        console.log(`Delete ${member.name}`);
        setOperation('delete');
        setUserToRemove(member);
        // Implement your logic here
    }, []);

    const handleRemoveMember = useCallback(async () => {
        console.log(`Remove ${userToRemove.name}`);

        try {
            if (opereration === 'delete') {
                const response = await deleteUser({ userId: userToRemove.id });
                await refreshUser();
                console.log("Delete user response", response);
                toast.success("User deleted successfully");
            } else if (opereration === 'remove') {
                const response = await leaveOrganisation({ id: organisation.id, user_id: userToRemove.id });
                await refreshUser();
                console.log("Leave organisation response", response);
                toast.success("User removed successfully");
            }

        } catch (error) {
            console.error("Error removing user", { error, isLeavingOrgError, leaveOrgError });
            toast.error("Error removing user");
        } finally {
            setUserToRemove(null);
        }

    }, [userToRemove]);

    const columns = createColumns({
        userId: user.id,
        onChangeRole: handleChangeRole,
        onRemoveMember: handleRemoveMemberConfirmation,
        onDeleteMember: handleDelete,
    });

    // if (!organisation) {
    //     return <ErrorAlert description="Please select an organisation" />
    // }

    if (error) {
        //@ts-ignore
        return <ErrorAlert description={error.message} />
    }

    return (
        <>
            <div className="space-y-4 flex flex-col">
                <div className="flex justify-between items-end">
                    <Input
                        placeholder="Search team members..."
                        value={globalFilter ?? ""}
                        onChange={(event) => setGlobalFilter(event.target.value)}
                        className="max-w-sm"
                    />
                    {/* <AddTeamMemberDialog organisation={organisation} /> */}
                </div>
                <DataTable
                    columns={columns}
                    showHeaders={false}
                    isLoading={isLoading}
                    data={teamMembers}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    emptyMessage="No team members available"
                    showPagination={true}
                    showPageSize={false}
                    showFirstLastButtons={false}
                    itemsText="users"
                    pageSizeText="Users per page"
                    pageSizeOptions={[50]}
                />
            </div>
            <AlertDialog open={userToRemove !== null} onOpenChange={(open) => !open && setUserToRemove(null)}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Confirm?</AlertDialogTitle>
                        <AlertDialogDescription>
                            Are you sure you want to {opereration === 'remove' ? 'remove from organisation' : 'delete user forever'} {userToRemove?.name}?
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleRemoveMember}>Confirm</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
</>
    );

};


export default UsersAdminPage;

function useDeleteUserMutation(): [any, { isLoading: any; isError: any; error: any; }] {
    throw new Error("Function not implemented.");
}

