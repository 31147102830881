import { Loader2Icon } from "lucide-react";
import Spinner from "../../../components/raytd/spinner";

type Props = {
    title: string;
    subtitle?: string;
    badge?: React.ReactNode;
    showSpinner?: boolean;
    showLoading?:boolean;
    children: React.ReactNode;
}

const Loading = () => {
    return (
        <div className="flex items-center justify-center h-48">
            <Loader2Icon className="h-8 w-8 animate-spin" />
        </div>
    );
}

const SubPageLayout: React.FC<Props> = ({ 
    title, 
    subtitle, 
    badge,
    showSpinner = false, showLoading = false,
    children }) => {


    return (
        <div className="">
            <div className='mb-6'>
                <div className="flex flex-row items-center space-x-2">
                    <h1 className="text-2xl font-semibold">{title}</h1>
                    {badge}
                    {showSpinner && <Spinner />}
                </div>
                {subtitle && <p className="text-sm text-muted-foreground">{subtitle}</p>}
            </div>
            <div>
                {showLoading ? <Loading /> : children}
            </div>
        </div>
    );

}

export default SubPageLayout;