import { api } from '@app.raytd.com/store';
import { useState, useCallback } from 'react';

interface UploadHookResult {
  upload: (
    file: File,
    params?: Record<string, string>
  ) => Promise<PhotoUploadResponse>;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: Error | null;
  reset: () => void;
}

type PhotoUploadResponse = {
  ok: boolean;
  url: string;
};

export const useFileUpload = (
  endpointTemplate: string,
  key = 'file'
): UploadHookResult => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const reset = useCallback(() => {
    setIsLoading(false);
    setIsError(false);
    setIsSuccess(false);
    setError(null);
  }, []);

  const upload = useCallback(
    async (file: File, params?: Record<string, string>) => {
      reset();
      setIsLoading(true);

      try {
        let endpoint = endpointTemplate;

        // Replace placeholders in the endpoint with actual values
        if (params) {
          Object.entries(params).forEach(([key, value]) => {
            console.debug('image key', { key, value });
            endpoint = endpoint.replace(`:${key}`, value);
          });
        }

        const formData = new FormData();
        formData.append(key, file);

        const response = await api.post(endpoint, formData);

        console.debug('upload response', response);

        if (response.ok) {
          setIsSuccess(true);
        } else {
          throw new Error('Upload failed');
        }

        return {
          ...response.body,
          ok: true,
        } as PhotoUploadResponse;
      } catch (err) {
        console.error('Error uploading file', err);
        setIsError(true);
        setError(
          err instanceof Error ? err : new Error('Unknown error occurred')
        );

        return {
          url: null,
          ok: false,
        } as PhotoUploadResponse;
      } finally {
        setIsLoading(false);
      }
    },
    [endpointTemplate, reset, key]
  );

  return {
    upload,
    isLoading,
    isError,
    isSuccess,
    error,
    reset,
  };
};
