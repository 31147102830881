import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./root-state.reducer"; 
import { rAPI } from "../services";

declare const process: any;

const isDevelopment = process.env.NODE_ENV === 'development';

const rootStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rAPI.middleware),
    devTools: isDevelopment,
    //preloadedState: initialRootState,
});

export type AppDispatch = typeof rootStore.dispatch;

export { rootStore };

export type RootState = ReturnType<typeof rootStore.getState>;

export default rootStore;