// src/googlePlacesAPI.ts

import { AddressType } from "@/components/ui/address-autocomplete";

const API_KEY = import.meta.env.VITE_GOOGLE_PLACES_API_KEY;

export async function getPlaceDetails(placeId: string): Promise<{ data: { address: AddressType, adrAddress: string } | null, error: string | null }> {
  if (!API_KEY) {
    return { error: "Missing API Key", data: null };
  }

  const url = `https://places.googleapis.com/v1/${placeId}`;

  try {
    const response = await fetch(url, {
      headers: {
        "X-Goog-Api-Key": API_KEY,
        "X-Goog-FieldMask":
          "adrFormatAddress,shortFormattedAddress,formattedAddress,location,addressComponents",
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    const dataFinderRegx = (c: string) => {
      const regx = new RegExp(`<span class="${c}">([^<]+)<\/span>`);
      const match = data.adrFormatAddress.match(regx);
      return match ? match[1] : "";
    };

    const address1 = dataFinderRegx("street-address");
    const address2 = "";
    const city = dataFinderRegx("locality"); //suburb
    const region = dataFinderRegx("region"); //state
    const postalCode = dataFinderRegx("postal-code");
    const country = dataFinderRegx("country-name");
    const lat = data.location.latitude;
    const lng = data.location.longitude;

    const formattedAddress = data.formattedAddress;

    const formattedData: AddressType = {
      address1,
      address2,
      formattedAddress,
      city,
      region,
      postalCode,
      country,
      lat,
      lng,
    };

    return {
      data: {
        address: formattedData,
        adrAddress: data.adrFormatAddress,
      },
      error: null,
    };
  } catch (err) {
    console.error("Error fetching place details:", err);
    return { error: err instanceof Error ? err.message : String(err), data: null };
  }
}
