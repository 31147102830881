import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Plus } from "lucide-react"
import { memo } from "react"
import { fieldTypeConfiguration } from './types';
import { CustomFieldType } from "store/src/lib/tests/entity";
import { cn } from "@/lib/utils";

interface FieldTypeConfig {
  type: CustomFieldType
  title: string
  icon?: React.ReactNode
  description?: string
}

const FIELD_TYPES: FieldTypeConfig[] = [
  { type: "text" as const, title: fieldTypeConfiguration.text.title },
  { type: "textarea" as const, title: fieldTypeConfiguration.textarea.title },
  { type: "number" as const, title: fieldTypeConfiguration.number.title },
  { type: "date" as const, title: fieldTypeConfiguration.date.title },
  { type: "select" as const, title: fieldTypeConfiguration.select.title },
  { type: "switch" as const, title: fieldTypeConfiguration.switch.title },
  { type: "checkbox" as const, title: fieldTypeConfiguration.checkbox.title },
  { type: "radio" as const, title: fieldTypeConfiguration.radio.title },
].map(field => ({
  ...field,
  description: fieldTypeConfiguration[field.type].information
}))

interface CustomFieldDropdownProps {
  addCustomField: (type: CustomFieldType) => void
  disabled?: boolean
  className?: string
}

function CustomFieldDropdownComponent({ 
  addCustomField, 
  disabled = false,
  className 
}: CustomFieldDropdownProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="ghost" 
          size="sm"
          disabled={disabled}
          className={cn(
            "text-zinc-600 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-zinc-100",
            "focus-visible:ring-1 focus-visible:ring-zinc-950",
            className
          )}
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Custom Field
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent 
        align="end" 
        className="w-80"
        sideOffset={4}
      >
        <DropdownMenuLabel>Field Types</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {FIELD_TYPES.map(({ type, title, icon, description }) => (
          <DropdownMenuItem
            key={type}
            onClick={() => addCustomField(type)}
            className="flex items-center cursor-pointer"
          >
            {icon && <span className="mr-2">{icon}</span>}
            <div className="flex flex-col">
              <span className="text-sm font-medium">{title}</span>
              {description && (
                <span className="text-xs text-zinc-500">{description}</span>
              )}
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const CustomFieldDropdown = memo(CustomFieldDropdownComponent)