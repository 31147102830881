import addAsset, { updateStatus } from './addAsset';
import fetchUserAssets from './fetchAssets';
import getAsset from './getAsset';
import { addBuilding, updateBuilding } from './building';
import {addFloor, updateFloor} from './addFloor';
import fetchLevels from './fetchLevels';
import updateLevelOrder from './updateLevelOrder';
import { addRoom, getRoomsByAssetId, getRoomsByFloor, updateRoom } from './room';

export const AssetAPI = {
    fetchUserAssets: fetchUserAssets,
    add: addAsset,
    get: getAsset,
    updateStatus: updateStatus,
    addBuilding: addBuilding,
    updateBuilding: updateBuilding,
    addFloor: addFloor,
    updateFloor: updateFloor,
    fetchLevels: fetchLevels,
    fetchRoomsByFloorId: getRoomsByFloor,
    fetchRoomsByAssetId: getRoomsByAssetId,
    updateLevelOrder: updateLevelOrder,
    addRoom: addRoom,
    updateRoom: updateRoom
}

export default AssetAPI;