import { useCreateTestSpecificationMutation, useDeleteTestSpecificationMutation, useGetTestSpecificationsQuery, useUpdateTestSpecificationMutation } from '@app.raytd.com/store'
import React, { createContext, useState, useContext, useMemo, useEffect, useCallback } from 'react'
import { TestCase } from 'store/src/lib/tests/entity'
import { on } from 'events';

type TestSpecificationsContextType = {
    data: TestCase[]
    setData: React.Dispatch<React.SetStateAction<TestCase[]>>
    selectedCategory: string | null
    setSelectedCategory: (category: string | null) => void
    selectedSubCategory: string | null
    setSelectedSubCategory: (subCategory: string | null) => void
    groupedData: Record<string, Record<string, TestCase[]>>
    categories: string[]
    subcategories: { category: string; subcategory: string }[]
    addTestSpecification: () => void;
    isLoading: boolean;
    testSuiteId: string;
    onDelete: (id: number) => void;
    onUpdate: (data: Partial<TestCase>) => void;
    onAdd: (data: Partial<TestCase>) => Promise<void>;
    onDirtyChange: (isDirty: boolean, formId: string) => void;
    dirtyForms: Set<string>;
}

const TestSpecificationsContext = createContext<TestSpecificationsContextType | undefined>(undefined)

export function TestSpecificationsProvider({ children, testSuiteId }: { children: React.ReactNode, testSuiteId: string; }) {

    const { data: specifications, isLoading } = useGetTestSpecificationsQuery(testSuiteId);

    const [data, setData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
    const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null)

    const [deleteTestSpecification] = useDeleteTestSpecificationMutation();

    const [createTestSpecification, { isLoading: isCreating }] = useCreateTestSpecificationMutation();
    const [updateTestSpecification, { isLoading: isUpdating }] = useUpdateTestSpecificationMutation();

    // const isBusy = isLoading || isCreating || isUpdating;

    // const handleSubmit = async (form) => {
    //     try {

    //         const data = {
    //             testSuiteId: testSuiteId,
    //             ...form
    //         }

    //         console.log('data', data);
    //         let payload;
    //         if (data.id) {
    //             payload = await updateTestSpecification(data);
    //         } else {
    //             payload = await createTestSpecification({
    //                 testSuiteId: parseInt(testSuiteId),
    //                 test: data
    //             });
    //         }

    //         console.log('payload', payload);

    //     } catch (error) {
    //         console.error('error', error);
    //     }
    // }

    const groupedData = useMemo(() => groupByCategory(data), [data])
    const categories = useMemo(() => Object.keys(groupedData), [groupedData])

    const subcategories = useMemo(() => {

        const uniquePairs = new Set<string>();

        Object.keys(groupedData).forEach(category => {
            Object.keys(groupedData[category]).forEach(subcategory => {
                uniquePairs.add(`${category},${subcategory}`);
            });
        });

        return Array.from(uniquePairs).map(pair => {
            const [category, subcategory] = pair.split(',');
            return { category, subcategory };
        });
    }, [groupedData]);

    const addTestSpecification = () => {
        // Logic to add a new test specification
    }

    const handleDelete = useCallback(async (id: number) => {
        // Logic to delete a test specification
        await deleteTestSpecification({
            testSuiteId: parseInt(testSuiteId),
            testSpecificationId: id
        });
    }, [testSuiteId, deleteTestSpecification]);

    const handleAdd = useCallback(async (data: Partial<TestCase>) => {
        try {

            console.log('handleAdd data', data);
            const payload = await createTestSpecification({
                testSuiteId: parseInt(testSuiteId),
                test: data
            });

            console.log('payload', payload);

        } catch (error) {
            console.error('error', error);
        }
    }, [createTestSpecification, testSuiteId]);

    const handleUpdate = useCallback(async (data: Partial<TestCase>) => {
        try {

            console.log('handleUpdate data', data);

            const payload = await updateTestSpecification({
                testSuiteId: parseInt(testSuiteId),
                testSpecificationId: data.id,
                test: data
            });

            console.log('payload', payload);

        } catch (error) {
            console.error('error', error);
        }
    }, [updateTestSpecification, testSuiteId]);

    const [dirtyForms, setDirtyForms] = useState<Set<string>>(new Set());

    const handleDirtyChange = (isDirty: boolean, formId: string) => {
      setDirtyForms(prev => {
        const next = new Set(prev);
        if (isDirty) {
          next.add(formId);
        } else {
          next.delete(formId);
        }
        return next;
      });
    };  

    useEffect(() => {
        if (!isLoading) {
            setData(specifications)
        }
    }, [specifications, isLoading]);

    const value = {
        data,
        setData,
        selectedCategory,
        setSelectedCategory,
        selectedSubCategory,
        setSelectedSubCategory,
        groupedData,
        categories,
        subcategories,
        addTestSpecification,
        isLoading,
        testSuiteId,
        onDelete: handleDelete,
        onUpdate: handleUpdate,
        onAdd: handleAdd,
        onDirtyChange: handleDirtyChange,
        dirtyForms
    }

    return (
        <TestSpecificationsContext.Provider value={value}>
            {children}
        </TestSpecificationsContext.Provider>
    )
}

export function useTestSpecifications() {
    const context = useContext(TestSpecificationsContext)
    if (context === undefined) {
        throw new Error('useNestedList must be used within a NestedListProvider')
    }
    return context
}

function groupByCategory(data: TestCase[]) {
    return data.reduce((acc, item) => {
        if (!acc[item.category]) {
            acc[item.category] = {}
        }
        if (!acc[item.category][item.subcategory]) {
            acc[item.category][item.subcategory] = []
        }
        acc[item.category][item.subcategory].push(item)
        return acc
    }, {} as Record<string, Record<string, TestCase[]>>)
}