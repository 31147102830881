import { normalize } from "normalizr";
import { Room, roomsActions } from './rooms.slice';
import { AssetEntity, Building, Floor } from "./types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildingsActions } from './buildings.slice';
import { levelsActions } from './levels.slice';

interface AssetDataNormalisedData {
    entities: {
      assets: { [id: number]: AssetEntity };
      buildings: { [id: number]: Building };
      levels: { [id: number]: Floor };
      rooms: { [id: number]: Room };
    };
  }
  
  export interface EntityUpdatePayload {
    assets?: ReturnType<typeof normalize> & AssetDataNormalisedData;
    buildings?: ReturnType<typeof normalize> & AssetDataNormalisedData;
    levels?: ReturnType<typeof normalize> & AssetDataNormalisedData;
    rooms?: ReturnType<typeof normalize> & AssetDataNormalisedData;
  }


  export const updateEntities = createAsyncThunk(
    'asset/entities',
    async (payload: EntityUpdatePayload, thunkAPI) => {
      const { assets, buildings, levels, rooms } = payload;
      console.info('updateEntities', payload);
      if (assets) {
        //thunkAPI.dispatch(assetActions.updateAssets(assets));
      }
      if (buildings) {
        thunkAPI.dispatch(buildingsActions.updateBuildings(buildings));
      }
      if (levels) {
        thunkAPI.dispatch(levelsActions.updateLevels(levels));
      }
      if (rooms) {
        thunkAPI.dispatch(roomsActions.updateRooms(rooms));
      }
  
      return assets as unknown as AssetEntity[];
    }
  );