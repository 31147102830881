import AddressLine from '@/app/features/assets/AddressLine';
import DataTablePagination from '@/app/features/assets/TablePagination';
import OrganisationAvatar from '@/components/raytd/organisation-avatar';
import {
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@/components/ui/table'; // Assuming you're using Shadcn or custom table components
import { AssetEntity } from '@app.raytd.com/store';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import {
  ColumnDef,
  ColumnMeta,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { Clipboard, FileStack, Layers3 } from 'lucide-react';
import React, { useCallback, useMemo, useState } from 'react';
import { ActionDropdown } from './ActionDropdown'; // Reuse the action dropdown
import { SkeletonLoader } from './Skeleton';
import ImageWithFallback from '@/components/raytd/image-with-fallback';
import { useNavigate } from 'react-router-dom';
import SpacesPill from '@/components/raytd/asset-spaces-pill';
import { ValuePill } from '@/components/raytd/value-pill';
import RetiredBadge from '@/components/raytd/retired-badge';

type AssetListProps = {
  assets: AssetEntity[];
  isLoading?: boolean;
};
interface ExtendedColumnMeta extends ColumnMeta<AssetEntity, unknown> {
  cellClassName?: string;
}

export const AssetList: React.FC<AssetListProps> = ({ assets, isLoading }) => {

  const navigate = useNavigate();

  const handleAssetClick = useCallback((assetId) => {
    console.log('Asset clicked');
    navigate(`/assets/${assetId}`);
  }, []);

  // Column definitions for the asset table
  const columns = useMemo<ColumnDef<AssetEntity>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => (
          <div className="flex space-x-4 items-center cursor-pointer group" onClick={() => handleAssetClick(row.original.id)}>
            {/* <img src={row.original.imageUrl} alt={row.original.name} className="h-12 w-12 rounded-lg object-cover" /> */}
            <ImageWithFallback src={row.original.imageUrl} alt={row.original.name} className="h-12 w-12 rounded-lg object-cover" fallbackSrc='/no_building.svg' />
            <div>
              <p className="font-semibold group-hover:underline">{row.original.name}</p>
              {/* <p className="text-sm text-zinc-500">{row.original.address}</p> */}
              <AddressLine address={row.original.address} />
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'spaces',
        header: 'Spaces',
        size: 90,
        cell: ({ row }) => {
          return (
            <div className="flex justify-end gap-x-1 items-center">
              <RetiredBadge retired={row.original.retired} />
              <SpacesPill asset={row.original} onClick={(asset) => navigate(`/assets/${asset.id}/spaces`)} />
              <ValuePill label="Inspections" value={row.original.numberOfAssessments} iconOnly icon={<Clipboard className="text-zinc-500 h-4 w-4" />} onClick={() => navigate(`/assets/${row.original.id}/inspections`)} />
              <ValuePill label="Reports" value={row.original.numberOfReports ?? 0} iconOnly icon={<FileStack className="text-zinc-500 w-4 h-4" />} onClick={() => navigate(`/assets/${row.original.id}/reports`)} />
            </div>
          )
        },
      },
      {
        accessorKey: 'organisation',
        header: '',
        size: 10,
        meta: {
          cellClassName: 'items-center justify-center',
        } as ExtendedColumnMeta,
        cell: ({ row }) =>
          <OrganisationAvatar organisation={row.original.organisation} size='sm' />
      },
      {
        id: 'actions',
        size: 15,
        header: '',
        cell: ({ row }) => <ActionDropdown asset={row.original} />, // Actions dropdown for each row
      },
    ],
    []
  );

  const table = useReactTable({
    data: assets,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 20,
        pageIndex: 0,
      },
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ]

    },

  });

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <div className="space-y-4">
      <TooltipProvider>
        <div className="rounded-md border">
          <Table className="table-fixed">

            <TableBody>
              {table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <MemoizedTableRow key={row.id} row={row} />
                ))
              ) : (
                <EmptyTable colSpan={columns.length} />
              )}
            </TableBody>
          </Table>
        </div>

        <DataTablePagination table={table} />
      </TooltipProvider>
    </div>

  );
};

const EmptyTable = ({ colSpan }) => (
  <TableRow>
    <TableCell colSpan={colSpan} className="text-center">
      No assets found.
    </TableCell>
  </TableRow>
);

const MemoizedTableRow = React.memo(({ row }: { row: Row<AssetEntity> }) => {
  const { id, original, getVisibleCells } = row;
  const opacity = original.status === 'archived' ? 0.5 : 1;

  return (
    <TableRow key={id} className="" style={{ opacity }}>
      {getVisibleCells().map((cell) => {

        return (
          <TableCell
            key={cell.id}
            className={(cell.column.columnDef.meta as ExtendedColumnMeta)?.cellClassName}
            style={{
              width: cell.column.columnDef.size, // Apply the size directly
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        )
      })}
    </TableRow>
  );
});