import React, { useState, useCallback } from 'react';
import { toast } from 'sonner';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";

interface UseConfirmationDialogProps<T> {
    onConfirm: (params: T) => Promise<any>;
    onSuccess?: () => void;
    onError?: (error: any) => void;
    successMessage: string;
    dialogTitle: string;
    dialogDescription: string | ((item: T | null) => string);
    confirmButtonText?: string;
    cancelButtonText?: string;
}

export function useConfirmationActionDialog<T>({
    onConfirm,
    onSuccess,
    onError,
    successMessage,
    dialogTitle,
    dialogDescription,
    confirmButtonText = "Confirm",
    cancelButtonText = "Cancel"
}: UseConfirmationDialogProps<T>) {
    const [itemToConfirm, setItemToConfirm] = useState<T | null>(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const openConfirmationDialog = useCallback((item: T) => {
        setItemToConfirm(item);
    }, []);

    const handleConfirm = useCallback(async () => {
        if (!itemToConfirm) return;

        setIsProcessing(true);
        try {
            await onConfirm(itemToConfirm);
            toast.success(successMessage);
            onSuccess?.();
        } catch (error) {
            console.error("Error during confirmation", error);
            toast.error("An error occurred");
            onError?.(error);
        } finally {
            setIsProcessing(false);
            setItemToConfirm(null);
        }
    }, [itemToConfirm, onConfirm, successMessage, onSuccess, onError]);

    const closeConfirmationDialog = useCallback(() => {
        setItemToConfirm(null);
    }, []);

    const ConfirmationDialog = useCallback(() => (
        <AlertDialog open={itemToConfirm !== null} onOpenChange={(open) => !open && closeConfirmationDialog()}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{dialogTitle}</AlertDialogTitle>
                    <AlertDialogDescription>
                        {typeof dialogDescription === 'function' ? dialogDescription(itemToConfirm) : dialogDescription}
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>{cancelButtonText}</AlertDialogCancel>
                    <AlertDialogAction onClick={handleConfirm}>{confirmButtonText}</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    ), [itemToConfirm, dialogTitle, dialogDescription, cancelButtonText, confirmButtonText, closeConfirmationDialog, handleConfirm]);

    return {
        isProcessing,
        openConfirmationDialog,
        ConfirmationDialog
    };
}
