import React, { createContext, useState, ReactNode } from 'react';

type NavContextType = {
  navButtons: ReactNode;
  setNavButtons: React.Dispatch<React.SetStateAction<ReactNode>>;
  busy: boolean;
  setIsBusy: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NavContext = createContext<NavContextType | undefined>(undefined);

export const NavProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [navButtons, setNavButtons] = useState<ReactNode>(null);
  const [busy, setIsBusy] = useState(false);

  return (
    <NavContext.Provider value={{ navButtons, setNavButtons, busy, setIsBusy }}>
      {children}
    </NavContext.Provider>
  );
};

export const useNav = () => {
  const context = React.useContext(NavContext);
  if (context === undefined) {
    throw new Error('useNav must be used within a NavProvider');
  }
  return context;
};