import SubPageLayout from "@/app/features/organisation/SubPageLayout";
import { MainPage } from "@/app/layout2/AppLayout";
import { Navigate, Route, Routes } from "react-router-dom";
import PageWithTopBarNavigation from './PageWithTopBarNavigation';
import { ProfileForm } from "./ProfileForm";
import { NavLink, TopNavBar } from "./TopNavBar";

export const ProfilePage = () => {

    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    <NavLink to="/profile">
                        Profile
                    </NavLink>
                    <NavLink to="/profile/subscriptions">
                        Subscriptions
                    </NavLink>
                    <NavLink to="/profile/preferences">
                        Preferences
                    </NavLink>
                </TopNavBar>
                <div className="container mx-auto py-6 px-4">
                    <Routes>
                        <Route path="/" element={<ProfileForm />} />
                        <Route path="/subscriptions" element={<SubPageLayout title='Subscriptions'><span>Not completed</span></SubPageLayout>} />
                        <Route path="/preferences" element={<SubPageLayout title='Preferences'><span>Not completed</span></SubPageLayout>} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
            </PageWithTopBarNavigation>
        </MainPage>
    );
};


export default ProfilePage;
