import { DefaultFieldProperties, TestSuiteType } from "store/src/lib/tests/entity";
import { ChevronRightIcon } from '@heroicons/react/24/solid';

export type ToolActionContext = {
    setActiveTab: (tab: number) => void;
}

export const locationFields: DefaultFieldProperties[] = [
    {
        header: 'Location Fields',
        field_name: 'asset',
        label: 'Asset Name',
        description: 'Select from your available Assets',
        type: 'raytd',
        required: true,
        active: true,
    }, {
        header: 'Location Fields',
        field_name: 'location',
        field_id: 'location',
        label: 'Location',
        description: 'Select from your available Locations',
        type: 'raytd',
        required: true,
        required_configurable: true,
        active: true,
        active_configurable: true,
    }, {
        header: 'Location Fields',
        field_name: 'element',
        field_id: 'element',
        label: 'Element',
        description: 'Select from your element classification list',
        type: 'raytd',
        required: true,
        required_configurable: true,
        active: true,
        active_configurable: true,
    },
    {
        header: 'Location Fields',
        field_name: 'element_name',
        label: 'Element Name',
        description: 'Enter Element Name',
        required: false,
        required_configurable: true,
        active: true,
        active_configurable: true,
        configurable: true,
    },
    {
        header: 'Location Fields',
        field_name: 'element_id',
        label: 'Element Identifier',
        description: 'Enter Element Identifier',
        required: false,
        required_configurable: true,
        active: true,
        active_configurable: true,
        configurable: true,
    }
]

const observationFieldBuilder = (testSuiteType: TestSuiteType): DefaultFieldProperties => {
    return {
        header: testSuiteType.charAt(0).toUpperCase() + testSuiteType.slice(1) + ' Fields',
        field_name: 'observations',
        label: 'Observations',
        description: 'Enter Observations',
        required: true,
        required_configurable: true,
        active: true,
        active_configurable: testSuiteType !== 'compliance',
        configurable: true,
    }
}

const ratingFieldBuilder = (testSuiteType: TestSuiteType) => {
    return {
        header: 'Location Fields',
        field_name: 'rating',
        label: 'Rating',
        description: 'Enter Rating',
        required: true,
        required_configurable: true,
        active: testSuiteType !== 'generic',
        configurable: false,
        testSuiteType
    }
}

const recommendationFieldBuilder = (testSuiteType: TestSuiteType) => {
    return {
        header: testSuiteType.charAt(0).toUpperCase() + testSuiteType.slice(1) + ' Fields',
        field_name: 'recommendations',
        label: 'Recommendations',
        description: 'Enter Recommendations',
        required: true,
        required_configurable: true,
        active: true,
        active_configurable: testSuiteType !== 'compliance',
        configurable: true,
        testSuiteType
    }
}

const photographFieldBuilder = (testSuiteType: TestSuiteType) => {
    return {
        header: testSuiteType.charAt(0).toUpperCase() + testSuiteType.slice(1) + ' Fields',
        field_name: 'photographs',
        label: 'Images',
        description: 'Add Images',
        required: true,
        required_configurable: true,
        active: true,
        active_configurable: testSuiteType !== 'compliance',
        configurable: true,
        testSuiteType
    }
}

export const testSuiteTypeSpecificFields: Record<TestSuiteType, DefaultFieldProperties[]> = {
    compliance: [
        {
            header: 'Compliance Fields',
            field_name: 'test',
            label: 'Test',
            description: 'Select Test',
            required: true,
            active: true,
            configurable: true,
            toolAction: {
                text: 'Configure Tests',
                icon: <ChevronRightIcon width={18} height={18} />,
                action: (context: ToolActionContext) => { context.setActiveTab(4) }
            }
        },
        observationFieldBuilder('compliance'),
        recommendationFieldBuilder('compliance'),
        photographFieldBuilder('compliance')
    ],
    condition: [
        ratingFieldBuilder('condition'),
        observationFieldBuilder('condition'),
        recommendationFieldBuilder('condition'),
        photographFieldBuilder('condition')
    ],
    generic: [
        photographFieldBuilder('condition')
    ]
}

