import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';

const UpgradeCard: React.FC = () => {
  return (
    <Card className="shadow-none border-none bg-zinc-100">
      <CardHeader className="p-2 pt-0 md:p-4">
        <CardDescription>
          You are enrolled in our Raytd.Beta program
        </CardDescription>
      </CardHeader>
      <CardContent className="p-2 pt-0 md:p-4 md:pt-0">
        <Button size="sm" className="w-full bg-zinc-200 text-zinc-950 border shadow-none border-zinc-300">
          Send Feedback
        </Button>
      </CardContent>
    </Card>
  );
};

export default UpgradeCard;
