import { api } from '../../api/fetch';
import { FloorLocation } from '../types';

export const getRoomsByFloor = async (floorId: number) => {
    return await api.get(`api/v1/levels/${floorId}/rooms`);
}

export const getRoomsByAssetId = async (assetId: number) => {
    return await api.get(`api/v1/assets/${assetId}/rooms`);
}

export const addRoom = async(room: Partial<FloorLocation>) => {
    return await api.post(`api/v1/rooms`, room);
}

export const updateRoom = async(room: Partial<FloorLocation>) => {
    return await api.put(`api/v1/rooms/${room.id}`, room);
}