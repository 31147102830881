import HeaderLogo from "@/app/layout2/HeaderLogo";
import { api, authActions, useAppDispatch } from '@app.raytd.com/store';
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from '../../../_components/privateRoute';

const AcceptInvite: React.FC = () => {

    const { token } = useParams<{ token: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState<string | null>(null);
    const [confirmInvite, setConfirmInvite] = useState<boolean>(false);
    const [invite, setInvite] = useState<any>(null);

    const { isAuthenticated, user } = useAuth();

    const dispatch = useAppDispatch();

    //check the iniviation token status;

    useEffect(() => {
        if (token) {
            if (isAuthenticated) {
                console.debug('User is authenticated');
                handleInvitation();
            } else {

                console.info('Checking invitation');
                checkInvitation();
                // need to check if it's a new user and we need to create the user

                // Redirect to login page, but save the current location
                //navigate('/login', { state: { from: location } });
            }
        } else {
            console.error('No token found');
            navigate('/');
        }
    }, [token, isAuthenticated]);

    const checkInvitation = async () => {

        try {
            const response = await api.get(`/api/v1/organisations/invite/${token}`);
            console.debug('check invitation', { response });
            navigate('/login', { state: { from: location } });
        } catch (error) {

            if (error.response) {
                console.info(error.response);
                const statusCode = error.response.status;
                const statusText = error.response.statusText;
                const message = error.response?.body?.message ?? statusText;
                console.info({ statusCode, statusText });
                if (statusCode === 404) {
                    setError('Invitation not found');
                } else if (statusCode === 403) {
                    // user needs to sign up
                    console.info('User needs to sign up', error.response?.body?.user);
                    navigate('/signup', {
                        state: {
                            from: location, invite: {
                                token: token,
                                user: error.response?.body?.user
                            }
                        }
                    });
                } else {
                    setError(message);
                }

            } else {
                setError('Unknown error invitation');
            }
        }

    }

    const acceptInvitation = async () => {
        try {
            console.info('Accepting invitation');
            const response = await api.post(`/api/v1/organisations/invite/${token}/accept`);
            console.info('Invitation accepted', response);
            //update user token;
            await dispatch(authActions.getUser()).unwrap(); //pull in the company created.

            
            navigate('/');
        } catch (error) {
            console.debug('Error accepting invitation', error);
            setError((error as APIError).message ?? 'Failed to accept invitation');
        }
    }

    const handleInvitation = async () => {

        try {
            const response = await api.get(`/api/v1/organisations/invite/${token}`);
            console.debug({ response });

            // const changing_organisation = response.body.changing_organisation ?? true;
            const changing_organisation = true;
            
            if (changing_organisation) {
                // user is changing organisation
                console.info('Changing organisation');
                setInvite(response.body.invite);
                setConfirmInvite(true);

            } else {
                console.info('Invitation accepted');
                await acceptInvitation();
                navigate('/');
            }

        } catch (error) {

            if (error.response) {
                console.info(error.response);
                const statusCode = error.response.status;
                const statusText = error.response.statusText;
                const message = error.response?.body?.message ?? statusText;
                console.info({ statusCode, statusText });
                if (statusCode === 404) {
                    setError('Invitation not found');
                } else if (statusCode === 403) {
                    setError(`You don't have permission to accept this invitation`);
                } else {
                    setError(message);
                }

            } else {
                setError('Unknown error invitation');
            }

        }

        return true;

    };

    return (
        <Card className="w-96 border-none shadow-none">

            {error ? (
                <div className="mt-10">
                    <InvitationErrors error={error} />
                </div>
            ) : (confirmInvite) ? (
                <ConfirmAcceptInvite user={user} invitation={invite} setError={setError} acceptInvitation={acceptInvitation} />
            ) : (
                <div className="flex flex-col justify-center items-center">
                    <div className="my-4"><Loader2 className="animate-spin w-10 h-10 text-zinc-400" /></div>
                    <div className="text-zinc-500 text-sm animate-pulse">Processing...</div>
                </div>
            )}

        </Card>
    )

}

export default AcceptInvite;

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "@/components/ui/card";
import SignupCardTitle from "@/components/raytd/sign-up-card-title";
import APIError from "store/src/lib/api/apiError";

const InvitationErrors = ({ error }: { error: string }) => {
    console.info('error', error);
    const title = "Join Organisation";
    const description = error;

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/');
    }

    return (
        <Alert variant="default"
            className="border-none items-center justify-center">
            <AlertTitle className="text-zinc-800 dark:text-zinc-200 text-xl">{title}</AlertTitle>
            <AlertDescription className="text-base text-zinc-700 dark:text-zinc-300">
                {description}
            </AlertDescription>
            <div className="flex items-center justify-center my-6">
                <Button variant="default" onClick={handleClick}>Proceed to Dashboard</Button>
            </div>
        </Alert>
    )
}

const ConfirmAcceptInvite = ({ user, invitation, setError, acceptInvitation }) => {

    const navigate = useNavigate();
    
    const currentOrganisation = user.company.title;
    const newOrganisation = invitation.organisation.title;
    const token = invitation.token;

    const rejectInvitation = async () => {
        try {
            console.info('Rejecting invitation');
            const response = await api.post(`/api/v1/organisations/invite/${token}/reject`);
            console.info('Invitation rejected', response);
            navigate('/');
        } catch (error) {
            console.debug('Error rejecting invitation', error);
            setError((error as APIError).message ?? 'Failed to reject invitation');
        }

    }

    return (

        <Card className="w-96 border-none shadow-none">
            <CardHeader className="mb-4">
                <SignupCardTitle className="text-center text-2xl">Change Organisation</SignupCardTitle>
            </CardHeader>

            <CardContent className="space-y-8">
                <div className="bg-zinc-100 p-4 rounded-lg text-sm text-zinc-900 space-y-4">
                    <p className="">You are currently a member of <span className="font-medium">{currentOrganisation}</span></p>
                    <p>If you accept the invitation to join <span className="font-medium">{newOrganisation}</span> you will no longer be a member of {currentOrganisation}</p>
                    <Button variant="default" className='w-full' onClick={acceptInvitation}>Accept Invitation</Button>
                </div>
            </CardContent>

            <CardFooter className="flex flex-col justify-center">
                <Button variant="link" className='w-full' onClick={rejectInvitation}>Cancel</Button>
            </CardFooter>
        </Card>
    )
}


