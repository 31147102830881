import { useState, useCallback } from "react";

export const useSubmitWithSaving = (onSubmit: (data: any) => Promise<boolean>) => {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = useCallback(
        async (data: any) => {
            setSaving(true);
            setError(null);
            try {
                const result = await onSubmit(data);
                return result;
            } catch (error) {
                console.error(error);
                setError(error?.message ?? 'An error occurred');
                return false;
            } finally {
                setSaving(false);
            }
        }, [onSubmit]
    );
    return { handleSubmit, saving, error };
}

export default useSubmitWithSaving;