import BlockEditor from "@/app/features/blocks/BlockEditor";
import ElementCollaboratorsPage from "@/app/features/elements/Collaborators";
import PageWithTopBarNavigation from "@/app/features/profile/PageWithTopBarNavigation";
import { NavLink, TopNavBar } from "@/app/features/profile/TopNavBar";
import { MainPage } from "@/app/layout2/AppLayout";
import { useBreadcrumb } from "@/app/layout2/BreadcrumbContext";
import { useGetReportBlockQuery } from "@app.raytd.com/store";
import { useEffect } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import Details from './Details';
import EditBlockPage from "@/app/features/blocks/EditBlockPage";

const ReportBlockPageLayout1 = () => {
    const { setBreadcrumbName } = useBreadcrumb();
    const { blockId } = useParams<{ blockId: string }>();
    const { data: block } = useGetReportBlockQuery(blockId);

    useEffect(() => {
        if (block) {
            setBreadcrumbName(`/report-blocks/${blockId}`, block?.name);
        }
    }, [block]);

    console.debug({ blockId: blockId });

    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    <NavLink to="details">
                        Details
                    </NavLink>
                    <NavLink to="edit">
                        Block
                    </NavLink>
                    <NavLink to="collaborators">
                        Collaborators
                    </NavLink>
                </TopNavBar>
                <div className="container mx-auto py-6 px-4">
                    <Routes>
                        <Route path="details" element={<Details />} />
                        <Route path="edit" element={<EditBlockPage blockId={blockId} />} />
                        <Route path="collaborators" element={<ElementCollaboratorsPage assetId={blockId} />} />
                        <Route path="*" element={<Navigate to="details" />} />
                    </Routes>
                </div>
            </PageWithTopBarNavigation>
        </MainPage>
    );

}

export default ReportBlockPageLayout1;

