import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils"

const testCountPillVariants = cva(
  "inline-flex items-center justify-center rounded-md px-2.5 py-1.5 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        archived: "bg-white text-gray-700 border border-gray-200",
        draft: "bg-orange-50 text-orange-700 border border-orange-100",
        qa: "bg-orange-200 text-orange-800",
        published: "bg-orange-500 text-white",
      },
    },
    defaultVariants: {
      variant: "archived",
    },
  }
)

export interface TestCountPillProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof testCountPillVariants> {
  value: number
}

const TestCountPill = React.forwardRef<HTMLDivElement, TestCountPillProps>(
  ({ className, variant, value, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(testCountPillVariants({ variant }), "h-7 min-w-[2.5rem]", className)}
        {...props}
      >
        {value}
      </div>
    )
  }
)

TestCountPill.displayName = "TestCountPill"

export { TestCountPill, testCountPillVariants }
