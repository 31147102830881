import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils"

const rolePillVariants = cva(
    "inline-block px-3 py-1 rounded-md text-sm font-medium w-20 text-center",
    {
        variants: {
            variant: {
                admin: "bg-primary text-primary-foreground",
                member: "bg-secondary text-secondary-foreground",
                guest: "bg-muted text-muted-foreground",
                pending: "bg-warning border border-bg-warning text-warning",
            },
        },
        defaultVariants: {
            variant: "guest",
        },
    }
)

export interface RolePillProps
    extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof rolePillVariants> {
    role: "admin" | "member" | "guest" | "pending"
}

const RolePill = React.forwardRef<HTMLDivElement, RolePillProps>(
    ({ role, className, ...props }, ref) => {
        if (!role) return null

        const convertedName = (role === "admin" ? "owner" : role ) as string;
        const formattedName = convertedName.charAt(0).toUpperCase() + convertedName.slice(1)

        return (
            <div
                ref={ref}
                className={cn(rolePillVariants({ variant: role }), className)}
                {...props}
            >
                {formattedName}
            </div>
        )
    }
)
RolePill.displayName = "RolePill"

export { RolePill, rolePillVariants }
export default RolePill;