import ArchiveRestoreMenuItem from '@/components/raytd/archive-restore-menu-item';
import Spinner from '@/components/raytd/spinner';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'; // Shadcn dropdown components
import { Assessment, useArchiveInspectionForUserMutation, useArchiveInspectionMutation, useUpdateInspectionMutation } from '@app.raytd.com/store';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Archive } from 'lucide-react';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

type Props = {
    inspection: Assessment;
    setIsBusy: (isBusy: boolean) => void;
};

export const ListActionDropdown: React.FC<Props> = ({ inspection, setIsBusy }) => {

    const [archive, { isLoading: isArchiving, isSuccess: isArchiveSuccess, isError: isArchiveError, error: archiveError }] = useArchiveInspectionMutation();
    const [archiveUser, { isLoading: isArchivingForUser, isSuccess: isArchiveUserSuccess, isError: isArchiveUserError, error: archiveUserError }] = useArchiveInspectionForUserMutation();
    const [updateInspection, {isLoading: isUpdating}] = useUpdateInspectionMutation();

    const isBusy = isArchiving || isArchivingForUser || isUpdating;

    useEffect(() => {
        setIsBusy?.(isBusy);
    }, [isBusy, setIsBusy]);

    const navigate = useNavigate();

    const handleArchive = useCallback(async () => {
        try {
            await archive(inspection.id).unwrap();
        } catch (err) {
            console.error('Error archiving asset', err);
        }
    }, [archive, inspection.id]);

    const handleArchiveForUser = useCallback(async () => {
        try {
            await archiveUser(inspection.id).unwrap();
            const archivedOrRestored = inspection.status === 'archived' ? 'restored' : 'archived';
            toast.success(`Inspection ${archivedOrRestored} successfully`);
        } catch (err) {
            console.error('Error', err);
            toast.error('Error archiving inspection');
        }
    }, [archiveUser, inspection.id]);

    const handleRetire = useCallback(async (testSuite) => {
        console.log('Retire', testSuite.id);
        try {
            await updateInspection({
                id: inspection.id,
                retired: !inspection.retired
            }).unwrap();

            const text = inspection.retired ? 'Reinstated' : 'Retired';

            toast.success(`Inspection ${text} successfully`);
        } catch (error) {
            console.error('Error retiring inspection', error);
            toast.error(`Inspection was not ${inspection.retired ? 'reinstated' : 'retired'}`);
        }
    }, [updateInspection, inspection]);

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="outline" className="h-8 w-8 p-0" disabled={isBusy}>
                    {isBusy ? <Spinner /> : <DotsHorizontalIcon className="h-4 w-4" />}
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => navigate(`/assets/${inspection.id}`)} >View</DropdownMenuItem>

                <DropdownMenuItem onClick={handleArchiveForUser}>
                    <ArchiveRestoreMenuItem status={inspection.status} />
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleArchive}>
                    <ArchiveRestoreMenuItem
                        status={inspection.is_globally_archived ? 'archived' : 'active'}
                        archiveText='Archive (Everyone)'
                        restoreText='Restore (Everyone)'
                    />

                </DropdownMenuItem>
                <DropdownMenuItem
                    onClick={handleRetire}>
                    <Archive className="mr-2 h-4 w-4" />
                    {inspection.retired ? 'Reinstate' : 'Retire'}
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};
