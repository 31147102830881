import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../root';
import fetchUserOrganisations from './api/fetchOrganisations';
import { UsersEntity, usersActions } from '../users';
import acceptInvitation from './api/acceptInvitation';
import { authActions } from '../auth';

export const ORGANISATION_FEATURE_KEY = 'organisation';

/*
 * Update these interfaces according to your requirements.
 */
export interface Organisation {
  title: string;
  id: number;
  role: string;
  joined: 'joined' | 'pending' | 'unjoined';
}

export interface OrganisationJoinRequest {
  id: number;
  title: string;
  organisation: Organisation;
  user: UsersEntity;
  token: string;
}

export interface UserOrganisationState {
  organisations: Organisation[];
  invites: OrganisationJoinRequest[];
  requests: OrganisationJoinRequest[];
  status: 'unjoined' | 'joined' | 'pending' | 'error';
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  error: string | null;
}

export const initialOrganisationState: UserOrganisationState = {
  organisations: [],
  invites: [],
  requests: [],
  status: 'unjoined',
  loadingStatus: 'not loaded',
  error: null
};

export const fetchOrganisations = createAsyncThunk(
  'organizations/fetch',
  async () => {
    console.info('fetch user organisations');
    const response = await fetchUserOrganisations();
    return response;
  }
);

export const requestToJoin = createAsyncThunk(
  'organizations/joinRequest',
  async (orgId: string) => {
    // API call to request joining organization
  }
);

export const acceptInvitationAction = createAsyncThunk(
  'organizations/acceptInvite',
  async (token: string, {dispatch}) => {
    // API call to accept invite with token
    const response = await acceptInvitation(token);

    console.debug('acceptInvitationAction', response, response.ok);

    if (response.ok) {
      await dispatch(authActions.getUser());
    }

    return response;
  }
);

export const organisationSlice = createSlice({
  name: ORGANISATION_FEATURE_KEY,
  initialState: initialOrganisationState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganisations.fulfilled, (state, action) => {
        state.organisations = action.payload.body.organisations;
        state.requests = action.payload.body.requests;
        state.invites = action.payload.body.invites;

        state.loadingStatus = 'loaded';
        console.info(state);
      })

      .addCase(fetchOrganisations.pending, (state, action) => {
        state.loadingStatus = 'loading';
      })

      .addCase(fetchOrganisations.rejected, (state, action) => {
        state.loadingStatus = 'error';
        console.error(action.error);
        state.error = action.error as string;
      })


      .addCase(requestToJoin.fulfilled, (state, action) => {
        // add request to pendingRequests
      })
      .addCase(acceptInvitationAction.fulfilled, (state, action) => {
        // add organization, update status
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const organisationReducer = organisationSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(organisationActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const organisationActions = {
  ...organisationSlice.actions,
  fetchOrganisations,
  requestToJoin,
  acceptInvitation: acceptInvitationAction,
};

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
import { selectLoadingStatus } from '../asset/asset.slice';
import { usersActions } from '../users/users.slice';
 *
 * // ...
 *
 * const entities = useSelector(selectAllOrganisation);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */

export const getOrganisationState = (rootState: {
  [ORGANISATION_FEATURE_KEY]: UserOrganisationState;
}): UserOrganisationState => rootState[ORGANISATION_FEATURE_KEY];

export const selectOrganisations = createSelector(
  getOrganisationState,
  (state) => state.organisations
);

export const selectPendingOrganisations = createSelector(
  getOrganisationState,
  (state) => state.requests
);

export const selectPendingInvites = createSelector(
  getOrganisationState,
  (state) => state.invites
  );

export const selectOrganisationLoadingStatus = createSelector(
  getOrganisationState,
  (state) => state.loadingStatus
);
