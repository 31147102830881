import * as React from "react";
import { X, Undo2, Plus } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { DataRecord } from './types';
import { useCallback } from "react";
import { Textarea } from "@/components/ui/textarea";
import { is } from "date-fns/locale";


interface PillProps {
  record: DataRecord;
  isEditing: boolean;
  inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
  multiline?: boolean;
  onEdit: () => void;
  onUndo: () => void;
  onRemove: () => void;
  onBlur: (event: React.FocusEvent) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  onChange: (value: string) => void;
}

const Pill = React.forwardRef<HTMLDivElement, PillProps>(({
  record,
  isEditing,
  inputRef,
  multiline = false,
  onEdit,
  onUndo,
  onRemove,
  onBlur,
  onKeyDown,
  onChange
}, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "group inline-flex items-center gap-1 rounded-md bg-zinc-100 text-sm",
        "transition-all duration-200",
        record.ghost && "opacity-70",
        isEditing ? "pr-1" : "pr-2",
        {
          'w-full': isEditing && multiline,
        }
      )}
    >
      {isEditing ? (
        <div className="flex flex-1 items-center flex-row w-full">
          {
            multiline ? (
              <div className="flex flex-1 w-full">
              <Textarea
                ref={inputRef as React.RefObject<HTMLTextAreaElement>}
                value={record.displayName}
                onChange={e => onChange(e.target.value)}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                className={cn(
                  "rounded-md border-0 bg-transparent px-3 py-1",
                  "focus:outline-none focus:ring-0 focus:ring-offset-0",
                  "placeholder:text-zinc-400"
                )}
                placeholder="Enter text..."
              />
              </div>
            ) : (


              <Input
                ref={inputRef as React.RefObject<HTMLInputElement>}
                value={record.displayName}
                onChange={e => onChange(e.target.value)}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                className={cn(
                  "h-7 w-auto min-w-[180px] rounded-md border-0 bg-transparent px-3 py-1",
                  "focus:outline-none focus:ring-0 focus:ring-offset-0",
                  "placeholder:text-zinc-400"
                )}
                placeholder="Enter text..."
              />
            )}
          <div className="flex w-12 items-center gap-0.5">
            <Button
              variant="ghost"
              size="sm"
              className={cn(
                "h-5 w-5 p-0 opacity-0 transition-opacity",
                "hover:bg-zinc-200",
                "group-hover:opacity-100"
              )}
              data-action="undo"
              onClick={onUndo}
            >
              <Undo2 className="h-3 w-3" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              className={cn(
                "h-5 w-5 p-0 opacity-0 transition-opacity",
                "hover:bg-zinc-200",
                "group-hover:opacity-100"
              )}
              onClick={onRemove}
            >
              <X className="h-3 w-3" />
            </Button>
          </div>
        </div>
      ) : (
        <>
          <span
            className="cursor-pointer px-2 py-1"
            onClick={onEdit}
          >
            {record.displayName}
          </span>
          <Button
            variant="ghost"
            size="sm"
            className={cn(
              "h-5 w-5 p-0 opacity-0 transition-opacity",
              "hover:bg-zinc-200",
              "group-hover:opacity-100"
            )}
            onClick={onRemove}
          >
            <X className="h-3 w-3" />
          </Button>
        </>
      )}
    </div>
  );
});

Pill.displayName = "Pill";

interface ListEditorProps {
  value: DataRecord[];
  multiline?: boolean;
  addItemText?: string;
  onChange: (value: DataRecord[]) => void;
  onBlur?: () => void;
}
export function ListEditor({
    value,
    multiline = false,
    addItemText = "Add item",
    onChange, onBlur
}: ListEditorProps) {

  const [editingId, setEditingId] = React.useState<string | null>(null);
  const [editingValue, setEditingValue] = React.useState<string>("");
  const [originalValue, setOriginalValue] = React.useState<string>("");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const ignoreBlurRef = React.useRef(false);

  const records: DataRecord[] = value || [];

  const addNewItem = React.useCallback(() => {
    const newRecord: DataRecord = {
      id: uuidv4(),
      displayName: "",
      ghost: true
    };
    onChange([...records, newRecord]);
    setEditingId(newRecord.id);
    setEditingValue("");
    setTimeout(() => inputRef.current?.focus(), 0);
  }, [records, onChange]);

  const startEditing = (record: DataRecord) => {
    setEditingId(record.id);
    setEditingValue(record.displayName);
    setOriginalValue(record.displayName);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const removeItem = (id: string) => {
    onChange(records.filter(r => r.id !== id));
    onBlur?.();
  };

  const handleBlur = useCallback((event: React.FocusEvent, record: DataRecord) => {
    console.info('blur', { event, record });
    if (ignoreBlurRef.current) {
      ignoreBlurRef.current = false;
      return;
    }

    const relatedTarget = event.relatedTarget as HTMLElement;
    if (relatedTarget?.dataset.action === 'undo') {
      return;
    }

    if (!editingValue.trim()) {
      removeItem(record.id);
    } else {
      console.info('blur', { editingValue, record });
      const updatedRecords = records.map(r =>
        r.id === record.id ? { ...r, displayName: editingValue, ghost: false } : r
      );

      // Sort the updated records alphabetically by displayName
      updatedRecords.sort((a, b) => a.displayName.localeCompare(b.displayName));

      onChange(updatedRecords);
    }
    setEditingId(null);
    setEditingValue("");
    onBlur?.();
  }, [editingValue, records, onChange, onBlur]);

  const handleUndo = (record: DataRecord) => {
    ignoreBlurRef.current = true;
    onChange(records.map(r =>
      r.id === record.id ? { ...r, displayName: originalValue } : r
    ));
    setEditingId(null);
    setEditingValue("");
    onBlur?.();
  };

  const handleKeyDown = useCallback((e: React.KeyboardEvent, record: DataRecord) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleBlur(e as any, record);
    } else if (e.key === "Escape") {
      handleUndo(record);
    } else if (e.key === "Tab") {
      e.preventDefault();
      handleBlur(e as any, record);
      // setTimeout(() => addNewItem(), 100);
    }
  }, [addNewItem, handleBlur, handleUndo]);

  // Get the current record being edited
  const getDisplayValue = (record: DataRecord) => {
    if (editingId === record.id) {
      return editingValue;
    }
    return record.displayName;
  };

  return (
    <div className="space-y-2">
      <div className="flex flex-col items-start gap-2">
        {records.map(record => (
          <Pill
            key={record.id}
            record={{
              ...record,
              displayName: getDisplayValue(record)
            }}
            isEditing={editingId === record.id}
            inputRef={inputRef}
            multiline={multiline}
            onEdit={() => startEditing(record)}
            onUndo={() => handleUndo(record)}
            onRemove={() => removeItem(record.id)}
            onBlur={(e) => handleBlur(e, record)}
            onKeyDown={(e) => handleKeyDown(e, record)}
            onChange={(value) => setEditingValue(value)}
          />
        ))}
      </div>

      <Button
        variant="link"
        size="default"
        className={cn(
          "h-7 px-2 text-xs",
          "hover:bg-zinc-50 hover:text-zinc-900"
        )}
        onClick={addNewItem}
      >
        <Plus className="mr-1 h-3 w-3" />
        {addItemText}
      </Button>
    </div>
  );
}