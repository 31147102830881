import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { api } from '../api/fetch';
import { RootState } from '../root';
import { CompanyEntity } from '../company/company.slice';
import APIError from '../api/apiError';
import { UsersEntity } from './types/userEntity';

export const USERS_FEATURE_KEY = 'users';

/*
 * Update these interfaces according to your requirements.
 */

export * from './types/userEntity';

export interface UserSignup {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  //role: string;
}

export interface UsersState extends EntityState<UsersEntity, number> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  error?: string | null;
  users: UsersEntity[];
}

export const usersAdapter = createEntityAdapter<UsersEntity>();

export const fetchUsers = createAsyncThunk<UsersEntity[]>(
  `${USERS_FEATURE_KEY}}/fetchStatus`,
  async (_, thunkAPI) => {
    //const response = await fetch('/api/users');
    //return (await response.json()) as UsersEntity[];

    const response = await api.get('/api/v1/users');
    return response.body.users as UsersEntity[];
  }
);

export const getUser = createAsyncThunk(
  `${USERS_FEATURE_KEY}}/getUser`,
  async () => {
    const response = await api.get('/api/v1/user');
    return response.body as UsersEntity;
  }
);

export const signupUser = createAsyncThunk(
  `${USERS_FEATURE_KEY}}/signupUser`,
  async (user: UserSignup, { rejectWithValue }) => {
    try {
      console.info('user signup ', user);
      const response = await api.post('/api/v1/register', user);
      return response.body as UsersEntity;
    } catch (err) {
      return rejectWithValue(
        err instanceof Error ? err.message : 'Unknown error'
      );
    }
  }
);

export const updateUser = createAsyncThunk(
  `${USERS_FEATURE_KEY}}/updateUser`,
  async (user: UsersEntity, { rejectWithValue }) => {
    const response = await api.put(`api/v1/users/${user.id}`, user);``

    if (!response.ok) {
      return rejectWithValue(response.statusText);
    }

    console.info(response.body);

    return response.body;
  }
);

export const inviteUser = createAsyncThunk(
  `${USERS_FEATURE_KEY}}/inviteUser`,
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/v1/organisations/invite', {
        organisation_id: 6, //hardcoded for now
        email: email,
        role: 'member',
      });

      if (!response.ok) {
        return rejectWithValue(response);
      }

      return response.body;
    } catch (err: any) {
      console.error('Error inviting user', err);

      if (err instanceof APIError) {
        let message = err.message;
        if (err.response.status == 500) {
          message = 'Unexpected error';
        }
        return rejectWithValue({
          ok: false,
          message: message,
          response: err.response,
        });
      } else {
        return rejectWithValue({ ok: false, message: 'Unknown error' });
      }
    }
  }
);

export const initialUsersState: UsersState = usersAdapter.getInitialState({
  loadingStatus: 'not loaded',
  error: null,
  users: [] as UsersEntity[],
});

export const usersSlice = createSlice({
  name: USERS_FEATURE_KEY,
  initialState: initialUsersState,
  reducers: {
    add: usersAdapter.addOne,
    remove: usersAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state: UsersState) => {
        state.loadingStatus = 'loading';
      })
      .addCase(
        fetchUsers.fulfilled,
        (state: UsersState, action: PayloadAction<UsersEntity[]>) => {
          usersAdapter.setAll(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(fetchUsers.rejected, (state: UsersState, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(
        signupUser.fulfilled,
        (state: UsersState, action: PayloadAction<UsersEntity>) => {
          console.info('add user fulfilled: ', action.payload);
          //usersAdapter.addOne(state, action.payload);
        }
      )
      .addCase(signupUser.rejected, (state: UsersState, action) => {
        console.info('add user rejected: ', action, action.payload);
        //usersAdapter.addOne(state, action.payload);
      })
      .addCase(
        updateUser.fulfilled,
        (state: UsersState, action: PayloadAction<UsersEntity>) => {
          console.info('update user fulfilled: ', action.payload);
          const { id, ...changes } = action.payload;
          usersAdapter.updateOne(state, { id: id, changes: changes });
        }
      )
      .addCase(
        inviteUser.fulfilled,
        (state: UsersState, action: PayloadAction<string>) => {
          console.info('invite user fulfilled: ', action.payload);
        }
      );
  },
});

/*
 * Export reducer for store configuration.
 */
export const usersReducer = usersSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(usersActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const usersActions = {
  ...usersSlice.actions,
  getUser,
  signupUser,
  inviteUser,
};

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
import { UsersEntity } from '../../../../dist/store/src/lib/users/users.slice';
 *
 * // ...
 *
 * const entities = useSelector(selectAllUsers);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities, selectById } = usersAdapter.getSelectors();

export const getUsersState = (rootState: {
  [USERS_FEATURE_KEY]: UsersState;
}): UsersState => rootState[USERS_FEATURE_KEY];

export const selectAllUsers = createSelector(getUsersState, selectAll);

export const selectUsersLoadingState = createSelector(
  getUsersState,
  (state) => state.loadingStatus
);

export const selectUserLoadingStatus = createSelector(
  getUsersState,
  (state) => state.loadingStatus
);

export const selectUserById = (id: number) => {
  return createSelector(getUsersState, (state) => selectById(state, id));
};

export const selectUsersEntities = createSelector(
  getUsersState,
  selectEntities
);
