
import { useState, useEffect } from 'react';
import { AddressType } from './index';
import { getPlaceDetails } from '@/components/ui/address-autocomplete/google-api';

interface PlaceDetailsData {
  address: AddressType;
  adrAddress: string;
}

export function usePlaceDetails(placeId: string | null) {
  const [data, setData] = useState<PlaceDetailsData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlaceDetails = async () => {
      if (!placeId) {
        setData(null);
        setError(null);
        return;
      }

      setIsLoading(true);
      try {
        const result = await getPlaceDetails(placeId);
        if (result.error) {
          setError(result.error);
          setData(null);
        } else if (result.data) {
          setData(result.data);
          setError(null);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
        setData(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlaceDetails();
  }, [placeId]);

  return { data, isLoading, error };
}