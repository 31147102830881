import { formatAddress } from '@app.raytd.com/store';
const OfficeTileContent = ({ office }) => {

    if (!office) {
        return <div className="text-sm">No office selected.</div>;
    }

    return (
        <div>
            <p className="font-medium">{office.name}</p>
            <p className="text-sm text-zinc-600">{formatAddress(office.address)}</p>
            <p className="text-sm text-zinc-600">{office.phone}</p>
            <p className="text-sm text-zinc-600">{office.email}</p>
        </div>
    )
}

export default OfficeTileContent;