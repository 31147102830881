import React, { forwardRef, useRef, useState, useEffect } from 'react'
import { Input } from "@/components/ui/input"
import { ImagePlus, Loader2 } from 'lucide-react'
import { ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'
import { cn } from '@/lib/utils'

interface ImageUploadProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<ControllerRenderProps<TFieldValues, TName>, 'ref'> {
  error?: string;
  initialImage?: string;
  isLoading?: boolean;
  shape?: 'circle' | 'square';
}

export const ImageUpload = forwardRef<HTMLInputElement, ImageUploadProps>(
  ({ onChange, onBlur, value, name, error, initialImage, shape='square', isLoading=false, ...rest }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [isHovering, setIsHovering] = useState(false)
    const [currentImage, setCurrentImage] = useState<string | File | null>(initialImage || null)

    useEffect(() => {
      console.info('image upload', { initialImage, value })
      if (initialImage && !value) {
        setCurrentImage(initialImage)
      } else if (value instanceof File) {
        setCurrentImage(value)
      } else if (typeof value === 'string') {
        setCurrentImage(value)
      } else {
        setCurrentImage(null)
      }
    }, [initialImage, value]);


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0]
      if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png') && selectedFile.size <= 8 * 1024 * 1024) {
        setCurrentImage(selectedFile)
        onChange(selectedFile)
      } else {
        alert('Please select a JPEG or PNG file under 8MB.')
        if (inputRef.current) {
          inputRef.current.value = ''
        }
      }
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      const droppedFile = event.dataTransfer.files[0]
      if (droppedFile && (droppedFile.type === 'image/jpeg' || droppedFile.type === 'image/png') && droppedFile.size <= 8 * 1024 * 1024) {
        setCurrentImage(droppedFile)
        onChange(droppedFile)
      } else {
        alert('Please drop a JPEG or PNG file under 8MB.')
      }
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
    }

    const renderContent = () => {
      if (currentImage) {
        const imageUrl = currentImage instanceof File ? URL.createObjectURL(currentImage) : currentImage
return (
        <div 
          className={cn("relative w-full h-full")}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <RetryableImage src={imageUrl} alt="Image preview" shape={shape} />
          {isHovering && (
            <div className={cn("absolute inset-0 bg-black/20 flex flex-col items-center justify-center",
              shape === 'circle' ? 'rounded-full' : 'rounded-lg'
            )}>
              <ImagePlus className="w-8 h-8 text-white mb-2" />
              <p className="text-white text-sm">Change photo</p>
            </div>
          )}
          {isLoading && (
            <div className="absolute inset-0 bg-black/20 flex items-center justify-center">
              <div className="w-8 h-8">
                <Loader2 className="size-10 animate-spin text-white" />
              </div>
            </div>
          )}
        </div>
      );
      }

      return (
        <>
          <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mb-4">
            <ImagePlus className="w-8 h-8 text-gray-400" />
          </div>
          <h3 className="text-lg font-semibold mb-1">
            Drop your image or <span className="text-blue-600">browse</span>
          </h3>
          <p className="text-sm text-gray-500">JPEG or PNGs only • 8MB max</p>
        </>
      )
    }

    return (
      <div 
        className={cn("w-64 h-64 border-2 border-dashed border-zinc-300 flex flex-col items-center justify-center text-center cursor-pointer hover:border-zinc-400 hover:border-dashed transition-colors overflow-hidden",
          error ? 'border-red-500' : 'border-zinc-300',
          currentImage ? 'border-none' : 'border-dashed',
          shape === 'circle' ? 'rounded-full' : 'rounded-lg'

        )}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={() => inputRef.current?.click()}
      >
        {renderContent()}
        <Input 
          type="file" 
          ref={(e) => {
            inputRef.current = e
            if (typeof ref === 'function') {
              ref(e)
            } else if (ref) {
              ref.current = e
            }
          }}
          className="hidden" 
          onChange={handleFileChange}
          onBlur={onBlur}
          name={name}
          accept=".jpg,.jpeg,.png"
          {...rest}
        />
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </div>
    )
  }
)

ImageUpload.displayName = 'ImageUpload';

const RetryableImage = ({ src, alt, shape, maxRetries = 3 }) => {
  const [retryCount, setRetryCount] = useState(0);
  const [currentSrc, setCurrentSrc] = useState(src);

  useEffect(() => {
    setRetryCount(0);
    setCurrentSrc(src);
  }, [src]);

  const handleImageError = () => {
    if (retryCount < maxRetries) {
      setTimeout(() => {
        setRetryCount(retryCount + 1);
        setCurrentSrc(`${src}?retry=${retryCount + 1}`);
      }, 1000); // Retry after 1000ms
    } else {
      console.info('Max retries reached. Failed to load image.');
    }
  };

  return (
    <img
      src={currentSrc}
      alt={alt}
      onError={handleImageError}
      className={cn("w-full h-full object-contain",
        shape === 'circle' ? 'rounded-full' : 'rounded-lg'
      )}
    />
  );
};
