import * as yup from 'yup';

const addressSchema = yup.object().shape({
  street: yup.string().required('Street Name is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  postcode: yup.string().required('Postal Code is required'),
  country: yup.string().required('Country is required'),
});

const assetSchema = yup.object().shape({
  name: yup.string().required('Title is required'),
  // type: yup.string().oneOf(['residential', 'skyrise', 'commercial', 'hotel', 'hospital', 'other']).required('Type is required'),
  owner: yup.number().required('Owner is required'),
  organisation: yup
    .number()
    .required(
      'Organisation is required, you may not have an organisation with sufficient permissions to create an asset'
    ),
  address: addressSchema,
  description: yup.string().nullable(),
  // numberOfFloors: yup.number().nullable().positive('Number of floors must be positive'),
  // yearConstructed: yup.number().nullable().positive('Year constructed must be positive'),
  // amenities: yup.array().of(yup.string()).nullable(),
  // imageUrl: yup.string().url('Invalid image URL').nullable(),
  // collaborators: yup.array().of(
  //   yup.object().shape({
  //     // Define validation for collaborators (UsersEntity) if needed
  //   })
  // ),
  // project: yup.object().shape({
  //   title: yup.string().required('Project title is required'),
  //   assets: yup.array().of(
  //     yup.object().shape({
  //       // Define validation for assets (AssetEntity) if needed
  //     })
  //   ),
  // }).nullable(),
  // portfolio: yup.object().shape({
  //   title: yup.string().required('Portfolio title is required'),
  //   assets: yup.array().of(
  //     yup.object().shape({
  //       // Define validation for assets (AssetEntity) if needed
  //     })
  //   ),
  // }).nullable(),
});

export default assetSchema;
