import updateTestSpecification from "./updateTestSpecification";
import { fetchTestSpecifications } from "./fetchTestSpecifications";
import { fetchTestSuites, fetchTestSuiteFields } from "./fetchTestSuites";
import saveTestSpecification, { deleteTestSpecification } from './saveTestSpecification';
import { saveTestSuite, saveTestSuiteFields } from './saveTestSuite';
import { updateTestSuite, duplicateTestSuite } from './updateTestSuite';
import archiveTestSuite from './archiveTestSuite';
import deleteTestSuite from './deleteTestSuite';
import { fetchRatingScale, fetchRatingScales } from './fetchRatingScale';
import { saveCustomField, updateCustomField, deleteCustomField } from "./customFields";

export const TestAPI = {
    fetchTestSuites: fetchTestSuites,
    fetchTestSpecifications: fetchTestSpecifications,
    saveTestSpecification: saveTestSpecification,
    updateTestSpecification: updateTestSpecification,
    deleteTestSpecification: deleteTestSpecification,
    saveTestSuite: saveTestSuite,
    saveTestSuiteFields: saveTestSuiteFields,
    duplicateTestSuite: duplicateTestSuite,
    fetchTestSuiteFields: fetchTestSuiteFields,
    updateTestSuite: updateTestSuite,
    archiveTestSuite: archiveTestSuite,
    deleteTestSuite: deleteTestSuite,
    fetchRatingScale: fetchRatingScale,
    fetchRatingScales: fetchRatingScales,
    saveCustomField: saveCustomField,
    updateCustomField: updateCustomField,
    deleteCustomField: deleteCustomField
}

export default TestAPI;