import React, { useState } from "react";
import { OrganisationForm } from "./OrganisationForm";
import { Organisation } from './types';
import PageWithTopBarNavigation from '../profile/PageWithTopBarNavigation';
import { NavLink, TopNavBar } from '../profile/TopNavBar';
import { Navigate, Route, Routes } from "react-router-dom";
import TeamPage from "./TeamPage";
import { MainPage } from "@/app/layout2/AppLayout";
import { selectLoggedInUser, useAppSelector } from "@app.raytd.com/store";

export const OrganisationPage = () => {
   const loggedInUser = useAppSelector(selectLoggedInUser);

   console.info(loggedInUser);  

    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    <NavLink to="/organisation">
                        Profile
                    </NavLink>
                    <NavLink to="/organisation/team">
                        Team
                    </NavLink>
                </TopNavBar>
                <div className="container mx-auto py-6 px-4">
                    <Routes>
                        <Route path="/" element={<OrganisationForm organisationId={loggedInUser?.company?.id} />} />
                        <Route path="/team" element={<TeamPage organisation={loggedInUser?.company} />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
            </PageWithTopBarNavigation>
        </MainPage>
    );
};

export default OrganisationPage;