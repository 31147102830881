import UserMenu from '@/app/layout2/UserMenu';
import { Button } from '@/components/ui/button';
import { PowerIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';


const AdminSidebar: React.FC = () => {

    const navigate = useNavigate();

    const handleLogout = () => {
        // Perform any logout logic here, if necessary
        navigate('/logout'); // Redirect to the login page or any other route
    };

    return (
        <div className="hidden border-none md:block">
            <div className="flex h-full max-h-screen w-[220px] lg:w-[280px] flex-col gap-2 fixed">
                <div className="flex h-14 items-center border-none px-4 lg:h-[60px] lg:px-6">
                    {/* <Link to="/" className="flex items-center gap-2 font-semibold">
            <span className="">Raytd</span>
          </Link>
          <Button variant="outline" size="icon" className="ml-auto h-8 w-8">
            <Bell className="h-4 w-4" />
            <span className="sr-only">Toggle notifications</span>
          </Button> */}
                    <UserMenu />

                </div>
                <div className="flex-1">
                    <SidebarNavigation />
                </div>
                <div className="px-4 pb-4">
                    <Button
                        variant="link"
                        size="default"
                        className="w-full justify-start flex text-zinc-500 font-normal"
                        onClick={handleLogout}
                    >
                        <PowerIcon className='w-4 h-4 mr-4' />Logout</Button>
                </div>
            </div>
        </div>
    );
};

export default AdminSidebar;


import { Badge } from '@/components/ui/badge';
import classNames from 'classnames';
import { BadgeHelp, Building2, Clipboard, FileStack, Home, Layers3, Share2, Users } from 'lucide-react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const isActiveRoute = (path: string) => {
  if (path === '/') {
    // For home route, it should only be active if it's exactly '/'
    return location.pathname === '/';
  }
  // For other routes, check if the pathname starts with the given path
  return location.pathname.startsWith(path);
};

const SidebarNavigation: React.FC = () => {
  const location = useLocation();



  return (
    <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
      <SideBarItem icon={<Home className="h-4 w-4" />} title="Dashboard" to="/" />
      <SideBarSeparator />
      <SideBarItem icon={<Users className="h-4 w-4" />} title="Users" to="/admin/users" />
      <SideBarSeparator />
    </nav>
  );
};

type SidebarItemProps = {
  icon: React.ReactNode;
  title: string;
  to: string;
  badge?: React.ReactNode;
};

const SideBarItem: React.FC<SidebarItemProps> = ({ icon, title, to, badge }) => {

  const isActivePath = isActiveRoute(to);

  return (
    <Link
      to={to}
      className={classNames("flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary", {
        "text-primary bg-zinc-100": isActivePath,

      })}
    >
      {icon}
      {title}
      {badge && (
        <Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
          {badge}
        </Badge>
      )}
    </Link>
  );
}

const SideBarSeparator: React.FC = () => {
  return <div className="border-t border-muted-background my-4 mx-6" />;
}
