import { CustomField } from '../entity';
import { api } from './../../api/fetch';

export const saveCustomField = async (testsuiteId: number, customField: Partial<CustomField>) => {
    return await api.post(`api/v1/test-suites/${testsuiteId}/fields`, customField);
}
export const fetchCustomFields = async () => {
    return await api.get('/api/v1/custom-fields');
}
export const fetchCustomField = async (customFieldId: number) => {
    return await api.get(`/api/v1/custom-fields/${customFieldId}`);
}
export const updateCustomField = async (testsuiteId: number, customField: Partial<CustomField>) => {
    return await saveCustomField(testsuiteId, customField);
}
export const deleteCustomField = async (testsuiteId: number, customField: Partial<CustomField>) => {
    return await api.delete(`api/v1/test-suites/${testsuiteId}/fields`, {field_id: customField.field_id});
}

