import { MainPage } from "@/app/layout2/AppLayout"
import SubPageLayout from "@/app/features/organisation/SubPageLayout"
import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom"
import { useCallback, useState } from "react"
import Filters from '../assets/Filters';
import { useGetInspectionsQuery } from "@app.raytd.com/store"
import InspectionList from '../inspections/InspectionList';

export default function ({ assetId }) {
    const navigate = useNavigate()
    const [filter, setFilter] = useState('active')
    const [searchTerm, setSearchTerm] = useState('')

    const { isFetching } = useGetInspectionsQuery();

    const handleAdd = useCallback(() => {
        navigate('/inspections/new?assetId=' + assetId)
    }, []);

    return (
        <SubPageLayout
            title="Inspections"
            subtitle="Create, update, manage and share your inspections."
            showSpinner={isFetching}
        >
            <div className="flex flex-row justify-end items-center space-x-4">
                <Filters selected={filter} setFilter={setFilter} setSearchTerm={setSearchTerm} />
                <Button onClick={() => navigate('/inspections/new')}>
                    + Create Inspection
                </Button>
            </div>
            <InspectionList
                filter={filter}
                searchTerm={searchTerm}
                filters={{ assetId }}
                handleAdd={handleAdd}
            />
        </SubPageLayout>
    )
}

