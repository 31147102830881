import { useTestSpecifications } from "@/app/features/testsuites/specifications/Context"
import { FastFillEditor, FastFillItem } from "@/components/raytd/fast-fill-editor"
import { BorderlessCard } from "@/components/ui/borderless-card"
import { Button } from "@/components/ui/button"
import { CardContent } from "@/components/ui/card"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { TestCase } from "store/src/lib/tests/entity"
import { v4 as uuid } from 'uuid'
import * as z from "zod"
import CategorySelect from './category-select';

const testSchema = z.object({
  label: z.string().min(1, "Test Name is required"),
  category: z.string().min(1, "Category is required"),
  subcategory: z.string().min(1, "Sub Category is required"),
  description: z.string().min(1, "Test Specification is required"),
})

type TestFormValues = z.infer<typeof testSchema>

interface TestFormProps {
  onSubmit: (data: TestFormValues) => Promise<void>;
  onDiscard: () => void
  specification: Partial<TestCase>;
  onDirtyChange?: (isDirty: boolean, formId: string) => void
  formId?: string // Unique identifier for this form instance
}

const TestSpecificationForm: React.FC<TestFormProps> = ({
  specification,
  onSubmit,
  onDiscard,
  onDirtyChange,
  formId = uuid() // Default to generated ID if not provided
}) => {

  const editorRef = React.useRef<HTMLDivElement>(null);

  const { categories: initialCategories, subcategories: initialSubCategories, } = useTestSpecifications();

  const [categories, setCategories] = useState<string[]>(null);
  const [subcategories, setSubcategories] = useState<{ category: string; subcategory: string; }[]>(null);

  // Track dirty state for FastFill editors
  const [observationsDirty, setObservationsDirty] = useState(false);
  const [recommendationsDirty, setRecommendationsDirty] = useState(false);

  const form = useForm<TestFormValues>({
    resolver: zodResolver(testSchema),
    defaultValues: {
      label: "",
      category: "",
      subcategory: "",
      description: "",
    },
  });

  const [recommendations, setRecommendations] = useState<FastFillItem[]>([]);
  const [observations, setObservations] = useState<FastFillItem[]>([]);

  const discardChanges = useCallback(() => {
    form.reset(specification);

    // Reset recommendations to empty array if none exist
    setRecommendations(specification.recommendations?.length
      ? specification.recommendations.map((recommendation) => ({
        id: recommendation.id,
        content: recommendation.description,
        delete: false,
        uuid: uuid()
      }))
      : []
    );

    // Reset observations to empty array if none exist
    setObservations(specification.observations?.length
      ? specification.observations.map((observation) => ({
        id: observation.id,
        content: observation.description,
        delete: false,
        uuid: uuid()
      }))
      : []
    );

    setCategories(initialCategories);
    setSubcategories(initialSubCategories);

    setObservationsDirty(false);
    setRecommendationsDirty(false);

    onDirtyChange?.(false, formId);
    onDiscard?.();

  }, [form.reset, specification, initialCategories, initialSubCategories, onDiscard]);

  useEffect(() => {
    form.reset(specification);
    setRecommendations(specification.recommendations.map(
      (recommendation) => ({
        id: recommendation.id, content: recommendation.description, delete: false, uuid: uuid()
      })
    ));

    setObservations(specification.observations.map(
      (observation) => ({
        id: observation.id, content: observation.description, delete: false, uuid: uuid()
      })
    ));

    setCategories(initialCategories);
    setSubcategories(initialSubCategories);

  }, [specification, initialCategories, initialSubCategories, form.reset]);

  const isDirty = useMemo(() => {
    return form.formState.isDirty || observationsDirty || recommendationsDirty;
  }, [form.formState.isDirty, observationsDirty, recommendationsDirty]);

  // Watch for form changes
  useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      onDirtyChange?.(isDirty, formId);
    });

    return () => subscription.unsubscribe();
  }, [form, formId, onDirtyChange, isDirty]);

  const handleUpdateObservations = useCallback((items: FastFillItem[]) => {
    setObservations(items);
    const isDirty = !items.every(item =>
      specification.observations.some(orig =>
        orig.id === item.id && orig.description === item.content
      )
    );
    setObservationsDirty(isDirty);
  }, [specification.observations]);

  const handleUpdateRecommendations = useCallback((items: FastFillItem[]) => {
    setRecommendations(items);
    const isDirty = !items.every(item =>
      specification.recommendations.some(orig =>
        orig.id === item.id && orig.description === item.content
      )
    );
    setRecommendationsDirty(isDirty);
  }, [specification.recommendations]);


  const prepareSubmit = useCallback(async (values: TestFormValues) => {
    const data: Partial<TestCase> = {
      ...values,
      observations: observations.map((item) => ({ id: item.id, description: item.content })),
      recommendations: recommendations.map((item) => ({ id: item.id, description: item.content })),
    }
    await onSubmit(data);
    setObservationsDirty(false);
    setRecommendationsDirty(false);
    onDirtyChange?.(false, formId);
  }, [observations, recommendations, onSubmit, formId, onDirtyChange]);


  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (editorRef.current && !editorRef.current.contains(event.target as Node)) {
        form.clearErrors();
      } else {
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [editorRef, form]);

  const selectedCategory = form.watch('category');

  return (
    <BorderlessCard className="w-full px-2 py-4" ref={editorRef}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(prepareSubmit)}>
          <CardContent className="space-y-4 px-2">

            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="label"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Test Name*</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter test name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex flex-row items-start justify-end gap-4 w-full">
                <Button variant="outline" type="button" onClick={discardChanges} disabled={!isDirty}>
                  Discard
                </Button>
                <Button type="submit" disabled={!isDirty}>Save</Button>
              </div>

            </div>

            <div className="flex space-x-4">

              <CategorySelect
                form={form}
                name="category"
                label="Category"
                items={categories?.map((category) => ({ value: category, label: category }))}
                onAddItem={(item) => {
                  if (item.delete) {
                    setCategories(prev => prev.filter(cat => cat !== item.value));
                  } else {
                    setCategories(prev => [...prev, item.value]);
                    form.setValue('category', item.value);
                  }
                }}
              />

              <CategorySelect
                form={form}
                name="subcategory"
                label="Sub Category"
                items={subcategories?.filter(sub => sub.category === selectedCategory).map((item) => ({
                  value: item.subcategory,
                  label: item.subcategory
                }))}
                onAddItem={(item) => {
                  if (item.delete) {
                    setSubcategories(prev => prev.filter(sub => sub.subcategory !== item.value));
                  } else {
                    setSubcategories(prev => [...prev, {
                      category: selectedCategory,
                      subcategory: item.value
                    }]);
                    form.setValue('subcategory', item.value);
                  }
                }}
              />

            </div>

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Test Specification*</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Enter test specification"
                      className="min-h-[100px]"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
        </form>
        <div className="w-full grid grid-cols-2">
          <FastFillEditor
            title="Observations"
            initialItems={observations}
            onChange={handleUpdateObservations}
          />
          <FastFillEditor
            title="Recommendations"
            initialItems={recommendations}
            onChange={handleUpdateRecommendations}
          />

        </div>
      </Form>
    </BorderlessCard>
  )
}

export default TestSpecificationForm;
