import React, { useState } from 'react';

type ImageWithFallbackProps = {
    src: string;
    alt: string;
    fallbackSrc?: string; // Optional fallback image
    className?: string; // Optionally pass class names for styling
};

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({ src, alt, fallbackSrc, className }) => {

    fallbackSrc = fallbackSrc || '/missing.png'; // Default fallback if none provided

    const [imageSrc, setImageSrc] = useState<string>(src ?? fallbackSrc); // Start with the provided image URL
    // Handle error by setting fallback image
    const handleError = () => {
        setImageSrc(fallbackSrc); // If a fallback is provided, use it on error
    };

    return (
        <img
            src={imageSrc}
            alt={alt}
            onError={handleError} // When image fails to load, trigger fallback
            className={className}
        />
    );
};

export default ImageWithFallback;