import AddIndividualCollaboratorDialog from "@/app/features/assets/AddIndividualCollaboratorDialog"
import SubPageLayout from "@/app/features/organisation/SubPageLayout"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import RolePill from "@/components/ui/role-pill"
import { Separator } from "@/components/ui/separator"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { useChangeUserRoleMutation, useGetAssetQuery, useRemoveCollaboratorMutation, UsersEntity } from "@app.raytd.com/store"
import { MoreHorizontal, Search, SearchIcon } from "lucide-react"
import React, { useCallback, useEffect, useState } from "react"
import { useConfirmationActionDialog } from "@/components/raytd/use-confirmation-action-dialog"
import { useNav } from "@/app/features/profile/NavContext"
import SearchInput from "@/components/raytd/search-input"
import { RoleTypes } from "@/app/features/organisation/types"
import { isRoleGreaterThanOrEqual, isRoleLessThan } from "@/app/utils/roles"
import { toast } from "sonner"
import { OrganisationsTable } from '../assets/AssetCollaboratorPage';


const ElementCollaboratorsPage: React.FC<{ assetId: string }> = ({ assetId }) => {
    const [searchTerm, setSearchTerm] = useState("")
  
    const { data: asset, isLoading } = useGetAssetQuery(assetId);
  
    console.info('asset', asset);
  
    const individuals = asset?.collaborators || [];
    const organisations = asset?.organisation ? [asset.organisation] : [];
  
    const { busy, setIsBusy, setNavButtons } = useNav();
  
    useEffect(() => {
      setNavButtons([
        <SearchInput key="search" searchTerm={searchTerm} setSearchTerm={setSearchTerm} />,
      ])
      return () => {
        setNavButtons([]);
      }
    }, [setNavButtons, searchTerm, setSearchTerm]);
  
    return (
      <SubPageLayout title="Collaborators" showLoading={isLoading || busy}>
  
        {/* <div className="mb-6">
          <div className="relative">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search collaborators..."
              className="pl-8"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div> */}
  
        <div className="space-y-8">
          <OrganisationsTable organisations={organisations} searchTerm={searchTerm} />
        </div>
      </SubPageLayout>
    )
  }
  
  export default ElementCollaboratorsPage;