import React, { useState, useMemo } from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '@/components/ui/command';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover';

interface AssetType {
    id: string | number;
    type: string;
    category: string;
}

interface AssetSelectorProps {
    assetTypes: AssetType[];
    onChange: (value: string) => void;
    value: string;
}

const AssetSelector = React.forwardRef<HTMLButtonElement, AssetSelectorProps>(
    ({ assetTypes = [], onChange, value }, ref) => {
        const [open, setOpen] = useState(false);
        const [selectedCategory, setSelectedCategory] = useState('');

        const categories = useMemo(() => {
            return [...new Set(assetTypes.map(asset => asset.category))];
        }, [assetTypes]);

        const selectedAsset = useMemo(() => {
            const a= assetTypes.find(asset => asset.type?.toLocaleLowerCase() === value?.toLocaleLowerCase());
            
            console.debug('selectedAsset', {value, a, assetTypes});
            return a;
        }, [assetTypes, value]);

        const handleAssetSelect = (assetId: string) => {
            onChange(assetId);
            //onChange(assetId === value ? '' : assetId);
            setOpen(false);
        };

        const renderAssetItems = () => {
            const filteredAssets = assetTypes.filter(asset => asset.category === selectedCategory);
            return filteredAssets.length > 0 ? (
                filteredAssets.map((asset) => (
                    <CommandItem
                        key={asset.id}
                        value={asset.type.toString()}
                        onSelect={() => handleAssetSelect(asset.type.toString())}
                    >
                        <Check
                            className={cn(
                                "mr-2 h-4 w-4",
                                value === asset.id.toString() ? "opacity-100" : "opacity-0"
                            )}
                        />
                        {asset.type}
                    </CommandItem>
                ))
            ) : (
                <CommandItem disabled>No assets in this category</CommandItem>
            );
        };

        const renderCategories = () => {
            return categories.length > 0 ? (
                categories.map((category) => (
                    <CommandItem
                        key={category}
                        onSelect={() => setSelectedCategory(category)}
                    >
                        {category}
                    </CommandItem>
                ))
            ) : (
                <CommandItem disabled>No categories available</CommandItem>
            );
        };

        if (!Array.isArray(assetTypes) || assetTypes.length === 0) {
            return <div>No asset types available.</div>;
        }

        return (
            <div>
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        ref={ref}
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className="w-[300px] justify-between"
                    >
                        {selectedAsset ? selectedAsset.type : "Select an asset..."}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[300px] p-0">
                    <Command>
                        <CommandList>
                            <CommandInput placeholder="Search asset..." />
                            <CommandEmpty>No asset found.</CommandEmpty>
                            {selectedCategory ? (
                                <CommandGroup heading={selectedCategory}>
                                    <CommandItem onSelect={() => setSelectedCategory('')}>
                                        ← Back to categories
                                    </CommandItem>
                                    {renderAssetItems()}
                                </CommandGroup>
                            ) : (
                                <CommandGroup heading="Categories">
                                    {renderCategories()}
                                </CommandGroup>
                            )}
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
            </div>
        );
    }
);

AssetSelector.displayName = 'AssetSelector';

export default AssetSelector;
