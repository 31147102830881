import { Separator } from '@/components/ui/separator';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { AssetEntity } from '@app.raytd.com/store';
import { Layers3 } from 'lucide-react';
import React from 'react';

type SpacesPillProps = {
    asset: AssetEntity;
    onClick?: (asset: AssetEntity) => void;
  }
  
  const SpacesPill: React.FC<SpacesPillProps> = ({ asset, onClick }) => {
  
    return (
      <Tooltip>
        <TooltipTrigger>
          <div className="flex flex-row space-x-1.5 items-center text-xs bg-zinc-100 px-2 py-2 rounded-md h-full" onClick={() => onClick?.(asset)}>
            <Layers3 className="h-4 w-4 text-zinc-500" />
            <span>{asset.numberOfBuildings}</span>
            <Separator orientation="vertical" />
            <span>{asset.numberOfLevels}</span>
            <Separator orientation="vertical" />
            <span>{asset.numberOfRooms}</span>
          </div>
        </TooltipTrigger>
        <TooltipContent side='bottom' align='start'>
          <div className="flex flex-col space-y-1 text-sm">
            <div className="font-normal">Spaces</div>
            <div><span className="mr-2 font-semibold">{asset.numberOfBuildings}</span>Buildings</div>
            <div><span className="mr-2 font-semibold">{asset.numberOfLevels}</span>Levels</div>
            <div><span className="mr-2 font-semibold">{asset.numberOfRooms}</span>Areas</div>
          </div>
        </TooltipContent>
      </Tooltip>
  
    )
  
  }

  export default SpacesPill;