import React, { useState, useEffect } from 'react';

const API_URL = import.meta.env.VITE_API_URL;

console.log(API_URL);

export const HealthCheckBanner = ({ showOverlay = true }: { showOverlay?: boolean }) => {
  const [backendHealthy, setBackendHealthy] = useState(true);

  useEffect(() => {
    const checkHealth = async () => {
      try {
        const response = await fetch(`${API_URL}/health-check`,{
          headers: {
            'Accept': 'application/json'
          }
        });
        if (response.status === 200) {
          const x = await response.json();
          if (x.status === 'up') {
            setBackendHealthy(true);
            return;
          }
        }

        setBackendHealthy(false);

      } catch (error) {
        setBackendHealthy(false);
      }
    };

    checkHealth(); // check immediately on app open

    const interval = setInterval(() => {
      checkHealth(); // check every 30 seconds
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  if (!backendHealthy) {

    if (showOverlay) {
      return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-90 z-50 flex justify-center items-center">
          <div className="bg-raytd-500 w-full p-4">
            <p className="text-white text-xl text-center">Raytd is currently unavailable. Please try again later.</p>
          </div>
        </div>

      )
    }

    return (
      <div className="bg-red-500 text-white py-2 px-4 text-center">
        The service is currently unavailable. Please try again later.
      </div>
    );
  }

  return null;
};

export default HealthCheckBanner;