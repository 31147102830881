import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import { AssetEntity } from './types';

import { updateEntities } from './updateEntitiesAction';
import {addAsset, fetchAsset, getAsset, updateAssetStatus} from './actions/actions';
import { WritableDraft } from 'immer';

export * from './actions/actions';

export const ASSET_FEATURE_KEY = 'asset';

export interface AssetState extends EntityState<AssetEntity, number> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  assetLoadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  buildingLoadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  buildingIdLoading?: number;
  error?: string | null;
}

export const assetAdapter = createEntityAdapter<AssetEntity>();

export const initialAssetState: AssetState = assetAdapter.getInitialState({
  loadingStatus: 'not loaded',
  assetLoadingStatus: 'not loaded',
  buildingLoadingStatus: 'not loaded',
  buildingIdLoading: undefined,
  error: null,
});

export const assetSlice = createSlice({
  name: ASSET_FEATURE_KEY,
  initialState: initialAssetState,
  reducers: {
    add: assetAdapter.addOne,
    remove: assetAdapter.removeOne,
    // updateAssets(state, action) {
    //   console.info('assets received', action.payload);
    //   assetAdapter.upsertMany(state, action.payload);
    //   state.loadingStatus = 'loaded';
    // },
    },
  
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsset.pending, (state: WritableDraft<AssetState>) => {
        state.loadingStatus = 'loading';
      })
      .addCase(
        fetchAsset.fulfilled,
        (state: AssetState, action: PayloadAction<AssetEntity[]>) => {
          assetAdapter.setAll(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(fetchAsset.rejected, (state: AssetState, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(getAsset.pending, (state: AssetState) => {
        state.assetLoadingStatus = 'loading';
      })
      .addCase(
        getAsset.fulfilled,
        (state: AssetState, action: PayloadAction<AssetEntity>) => {
          assetAdapter.addOne(state, action.payload);
          state.assetLoadingStatus = 'loaded';
        }
      )
      .addCase(getAsset.rejected, (state: AssetState, action: any) => {
        console.info('get asset rejected', action);
        if (action.payload.response.status === 403) {
          state.assetLoadingStatus = 'error';
          state.error = 'You do not have permission to view this asset';
          return;
        }
        state.assetLoadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(updateAssetStatus.fulfilled, (state: AssetState, action) => {
        console.info('update status fulfilled', action);
        assetAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });

      })
      builder.addCase(updateEntities.fulfilled, (state, action) => {
        // Handle the fetch result by inserting the articles here
        assetAdapter.upsertMany(state, action.payload);
      })
      
      ;

  },
});

/*
 * Export reducer for store configuration.
 */
export const assetReducer = assetSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(assetActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const assetActions = {
  get: getAsset,
  create: addAsset,
  list: fetchAsset,
  ...assetSlice.actions,
};

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
import Address from './types/address';
import updateLevelOrder from './api/updateLevelOrder';
import { EntityUpdatePayload } from './normalised';
 *
 * // ...
 *
 * const entities = useSelector(selectAllAsset);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = assetAdapter.getSelectors();

export const getAssetState = (rootState: {
  [ASSET_FEATURE_KEY]: AssetState;
}): AssetState => rootState[ASSET_FEATURE_KEY];

export const selectAllAsset = createSelector(getAssetState, selectAll);

export const selectAssetEntities = createSelector(
  getAssetState,
  selectEntities
);

export const selectLoadingStatus = createSelector(
  getAssetState,
  (state: AssetState) => state.loadingStatus
);

export const selectAssetErrorMessage = createSelector(
  getAssetState,
  (state: AssetState) => state.error
);

export const selectAssetLoadingStatus = createSelector(
  getAssetState,
  (state: AssetState) => state.assetLoadingStatus
);
