import { MoreVertical } from "lucide-react"
import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { formatAddress } from '@app.raytd.com/store'
import { Office } from './types'
import OfficeTileContent from "@/app/features/organisation/OfficeTileContent"

type OfficeTileProps = {
  office: Office;
  editable?: boolean;
  onRemove?: (office: Office) => void;
  onEdit?: (office: Office) => void;
}

const OfficeTile = ({ office, editable = true, onRemove, onEdit }: OfficeTileProps) => (
  <div className="flex items-center justify-between p-4 bg-gray-100 rounded-lg">
    <OfficeTileContent office={office} />

    {editable && (

      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <MoreVertical className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onSelect={() => onEdit?.(office)}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => onRemove?.(office)}>
            Remove
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

    )}

  </div>
);

export default OfficeTile;