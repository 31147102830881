import { Badge } from '@/components/ui/badge';
import classNames from 'classnames';
import { BadgeHelp, Building2, Clipboard, FileStack, Home, Layers3, Share2, Users } from 'lucide-react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const isActiveRoute = (path: string) => {
  if (path === '/') {
    // For home route, it should only be active if it's exactly '/'
    return location.pathname === '/';
  }
  // For other routes, check if the pathname starts with the given path
  return location.pathname.startsWith(path);
};

const SidebarNavigation: React.FC = () => {
  const location = useLocation();



  return (
    <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
      <SideBarItem icon={<Home className="h-4 w-4" />} title="Dashboard" to="/" />
      <SideBarItem icon={<Building2 className="h-4 w-4" />} title="Assets" to="/assets" />
      {/* <SideBarItem icon={<Building2 className="h-4 w-4" />} title="Assets" to="/assets" badge={2} /> */}
      <SideBarItem icon={<Clipboard className="h-4 w-4" />} title="Inspections" to="/inspections" />
      <SideBarItem icon={<FileStack className="h-4 w-4" />} title="Reports" to="/reports" />
      <SideBarSeparator />
      <SideBarItem icon={<Users className="h-4 w-4" />} title="Organisation" to="/organisation" />
      <SideBarItem icon={<Share2 className="h-4 w-4" />} title="Test Suites" to="/test-suites" />
      <SideBarItem icon={<Share2 className="h-4 w-4" />} title="Test Suites (old)" to="/test-suites-old" />
      <SideBarItem icon={<Layers3 className="h-4 w-4" />} title="Element Groups" to="/elements" />
      <SideBarItem icon={<Users className="h-4 w-4" />} title="Report Blocks" to="/report-blocks" />
      <SideBarSeparator />
      <SideBarItem icon={<BadgeHelp className="h-4 w-4" />} title="Support" to="/support" />
    </nav>
  );
};

export default SidebarNavigation;

type SidebarItemProps = {
  icon: React.ReactNode;
  title: string;
  to: string;
  badge?: React.ReactNode;
};

const SideBarItem: React.FC<SidebarItemProps> = ({ icon, title, to, badge }) => {

  const isActivePath = isActiveRoute(to);

  return (
    <Link
      to={to}
      className={classNames("flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary", {
        "text-primary bg-zinc-100": isActivePath,

      })}
    >
      {icon}
      {title}
      {badge && (
        <Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
          {badge}
        </Badge>
      )}
    </Link>
  );
}

const SideBarSeparator: React.FC = () => {
  return <div className="border-t border-muted-background my-4 mx-6" />;
}
