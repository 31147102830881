import Header from "@/app/layout2/Header";
import { useAuth } from "../../_components";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Toaster } from "@/components/ui/sonner";
import AdminSidebar from './AdminSidebar';

const AdminLayout = () => {

    const { user, refreshUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        refreshUser();
    }, []);

    useEffect(() => {
        if (!user?.email?.includes('raytd.com')) {
            navigate('/');
        }
    }, [user, navigate]);

    if (!user) {
        return null;
    }

    return (
        <div className="grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]  bg-muted/50">
        <AdminSidebar />
        <div className="flex flex-col space-y-4">
          <Header />
          <Toaster />
          <Outlet />
        </div>
      </div>
    )
};


export default AdminLayout;

