import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { UsersEntity, api, authActions, useAppDispatch } from '@app.raytd.com/store';
import BusyButton from '@/components/raytd/busy-button';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocation, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import SignupCardTitle from '@/components/raytd/sign-up-card-title';
import ErrorAlert from '@/components/raytd/form-error';
import APIError from 'store/src/lib/api/apiError';
import { Loader2 } from 'lucide-react';

const LoginError = ({ error }: { error: string }) => {

    if (!error) {
      return null;
    }
  
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <span className="block sm:inline" data-testid="login-error-message">{error}</span>
      </div>
    );
  }

const Step1Schema = z.object({
    first_name: z.string().min(2, { message: 'Name must be at least 2 characters' }),
    last_name: z.string().min(2, { message: 'Name must be at least 2 characters' }),
    email: z.string().email({ message: 'Please enter a valid email address' }),
});

const Step2Schema = z.object({
    password: z.string().min(8, { message: 'Password must have at least 8 characters' }),
    password_confirmation: z.string(),
});

const SignupSchema = Step1Schema.merge(Step2Schema).refine(
    (data) => data.password === data.password_confirmation,
    {
        message: 'Your passwords do not match',
        path: ['password_confirmation'],
    }
);

// Type for the form data
type SignupFormData = z.infer<typeof SignupSchema>;
type Step1Data = z.infer<typeof Step1Schema>;
type Step2Data = z.infer<typeof Step2Schema>;

// Define the three steps of the form
const Step1 = ({ register, errors }: any) => (
    <>
        <div className="flex space-x-4 mb-4">
            <div className="flex-1">
                <Label htmlFor="first_name" className="sr-only">First Name</Label>
                <Input
                    id="first_name"
                    type="text"
                    placeholder="First Name"
                    {...register('first_name', { required: true })}
                />
                {errors.firstName && <p className="text-red-600 text-sm">First name is required</p>}
            </div>
            <div className="flex-1">
                <Label htmlFor="last_name" className="sr-only">Last Name</Label>
                <Input
                    id="last_name"
                    type="text"
                    placeholder="Last Name"
                    {...register('last_name', { required: true })}
                />
                {errors.lastName && <p className="text-red-600 text-sm">Last name is required</p>}
            </div>
        </div>
        <div className="mb-4">
            <Label htmlFor="email" className="sr-only">Email address</Label>
            <Input
                id="email"
                type="email"
                placeholder="Email address"
                {...register('email', { required: true })}
            />
            {errors.email && <p className="text-red-600 text-sm">Email is required</p>}
        </div>
    </>
);

const Step1Form = ({ onComplete, prefill }: { onComplete: (data: Step1Data) => void, prefill: SignupFormData }) => {
    const { register, handleSubmit, formState: { errors } } = useForm<Step1Data>({
        resolver: zodResolver(Step1Schema),
        defaultValues: {
            first_name: prefill?.first_name || '',
            last_name: prefill?.last_name || '',
            email: prefill?.email || '',
        },
        mode: 'onBlur'
    });

    const onSubmit = (data: Step1Data) => {
        onComplete(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Step1 register={register} errors={errors} />
            <Button type="submit" className="w-full">Next</Button>
        </form>
    );
}

const Step2 = ({ register, errors, watch }: any) => {

    const password = watch('password');

    return (
        <>
            <div className="mb-4">
                <Label htmlFor="password" className="sr-only">Password</Label>
                <Input
                    id="password"
                    type="password"
                    placeholder="Password"
                    autoComplete='new-apssword'
                    {...register('password', { required: true })}
                />
                {errors.password && <p className="text-red-600 text-sm">{errors.password.message ?? 'Password is required'}</p>}
            </div>
            <div className="mb-4">
                <Label htmlFor="password_confirmation" className="sr-only">Confirm Password</Label>
                <Input
                    id="password_confirmation"
                    type="password" autoComplete='new-apssword'

                    placeholder="Confirm Password"
                    {...register('password_confirmation', {
                        required: true,
                        validate: (value) => value === password || 'Passwords do not match', // Validate that the confirmation matches the password
                    })}
                />
                {errors.password_confirmation && <p className="text-red-600 text-sm">{errors.password_confirmation.message ?? 'Confirmation is required'}</p>}
            </div>
        </>
    )
};

const Step3 = ({ register, errors, completeSignUp }: any) => {

    const [showMessage, setShowMessage] = useState(false);
    const navigate = useNavigate();

    // we need to create a new organisation in the name of the user;

    const handleClick = async (e) => {
        e.preventDefault();
        await completeSignUp();
    }

    return (
        <>
            <div className="mb-4">
                {!showMessage && (
                    <Button variant='default' className="w-full" onClick={() => setShowMessage(true)}>
                        Join an existing Organisation
                    </Button>
                )}

                {showMessage && (
                    <div className="bg-gray-100 p-4 rounded-lg text-sm text-gray-600">
                        <p className="mb-4">To join an existing Organisation you need to  finish setting up your account and be invited.</p>
                        <p className="mb-4 font-medium">Ask a colleague to send you an invitation from their Raytd account.</p>
                        <Button variant='outline' className="w-full" onClick={handleClick}>Finsh account setup</Button>
                    </div>
                )}

            </div>

        </>
    )
};

const createOrganisation = z.object({
    title: z.string().nonempty('Organisation name is required'),
});

type createOrgData = z.infer<typeof createOrganisation>;

const Step4 = ({ step1Data }: { step1Data: Step1Data }) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const { register, handleSubmit, formState: { errors } } = useForm<createOrgData>({
        resolver: zodResolver(createOrganisation),
        defaultValues: {
            title: '',
        },
        mode: 'onBlur'
    });

    const onSubmit = async (data: createOrgData) => {
        console.log('Create new organisation', data);
        setBusy(true);
        setError(null);
        try {
            const orgResponse = await api.post('/api/v1/organisations/join', {
                title: data.title
            });
            console.log('Organisation created', orgResponse);
            await dispatch(authActions.getUser()).unwrap(); //pull in the company created.
            navigate('/');

        } catch (error) {
            console.error('Failed to create organisation', error);
            setError((error as APIError)?.message);
        } finally {
            setBusy(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {error && <LoginError error={error} />}
            <div className="mb-4">
                <Label htmlFor="organisation" className="sr-only">Organisation Name</Label>
                <Input
                    id="organisation"
                    type="text"
                    placeholder="Organisation Name"
                    {...register('title', { required: true })}
                />
                {errors.title && <p className="text-red-600 text-sm">Organisation name is required</p>}
            </div>
            <BusyButton isBusy={busy} type="submit" className="w-full">Create</BusyButton>
        </form>
    )
}



const Step5 = () => {

    const navigate = useNavigate();

    return (
        <div className="flex items-center justify-center">
            <Loader2 className="animate-spin w-10 h-10 text-zinc-400" />
        </div>
    )
}

type CreateUserResponse = {
    body: {
        user: UsersEntity;
        token: string;
    }
}

// Main Signup component that handles the steps
export function SignupPage() {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const prefill = location.state?.invite || {};
    const [step, setStep] = useState(location.state?.invite?.token ? 2 : 1);
    const hasToken = Boolean(prefill?.token);
    const [isBusy, setBusy] = useState(false);
    const [step1Data, setStep1Data] = useState<Step1Data | null>(null);
    const [error, setError] = useState<string | null>(null);

    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm({
        resolver: zodResolver(SignupSchema),
        defaultValues: {
            first_name: prefill?.user?.first_name || '',
            last_name: prefill?.user?.last_name || '',
            email: prefill?.user?.email || '',
            password: '',
            password_confirmation: '',
        },
        mode: 'onBlur'
    });

    const onStep1Complete = (data: Step1Data) => {
        setStep1Data(data);
        reset({
            ...data
        });
        setStep(2);
    };

    const handleGoBack = () => {
        setError(null);
        setStep(1);
    }

    const handleInviteSignup = async (data: SignupFormData) => {
        console.info('Invite signup', data);

        setBusy(true);
        try {
            const response: CreateUserResponse = await api.post('/api/v1/signup/invite', {
                token: prefill.token,
                password: data.password,
                password_confirmation: data.password_confirmation,
                email: data.email,
            });

            console.info('User created', response);
            dispatch(authActions.loginUser({ user: response.body.user, token: response.body.token }));
            await dispatch(authActions.getUser()).unwrap();
            navigate('/profile');
        } catch (error) {
            console.error('Failed to create user', error);
        } finally {
            setBusy(false);
        }

    }

    const handleRegisterUser = async (data: SignupFormData) => {
        try {
            setError(null);
            setBusy(true);
            const response = await api.post('/api/v1/register', {
                ...data
            });

            console.info('register', response);

            if (response.status === 201) {
                console.debug('User created, logging in...');
                dispatch(authActions.loginUser({ user: response.body.user, token: response.body.token }));
                await dispatch(authActions.getUser()).unwrap();
            }

            setStep(3);

        } catch (error) {
            console.error(error);

            setError((error as APIError)?.message);
        } finally {
            setBusy(false);
        }
    }

    const onSubmit = async (data: SignupFormData) => {
        if (step < 3) {

            if (step === 2) {
                // Validate password and confirm password match
                // and attempt to create a user.
                if (hasToken) {
                    await handleInviteSignup(data);
                } else {
                    await handleRegisterUser(data);
                }

            }

        } else {
            // Submit final form data here
            setStep((step) => step + 1);
        }
    };

    const handleSkipOrganisation = useCallback(async () => {
        setStep(5);
        console.log('Creating personal organisation');
        setError(null);
        setBusy(true);
    
        // Create org name from user name
        //const orgName = `${step1Data.first_name} ${step1Data.last_name}`;
    
        try {
          const orgResponse = await api.post('/api/v1/organisations/join', {
            //title: orgName,
          });
    
          console.log('Organisation created', orgResponse);
          await dispatch(authActions.getUser()).unwrap(); // Pull in the company created.
    
          navigate('/');
        } catch (error) {
          console.error('Failed to create organisation', error);
          setError((error as APIError)?.message);
        } finally {
          setBusy(false);
        }
      }, [step1Data, dispatch, navigate]);


    const renderStep = () => {
        switch (step) {
            case 1:
                return <Step1Form onComplete={onStep1Complete} prefill={step1Data} />;
            case 2:
                return (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Step2 register={register} errors={errors} watch={watch} />
                        <BusyButton type="submit" className="w-full" isBusy={isBusy}>
                            Next
                        </BusyButton>
                    </form>
                );
            case 3:
                return (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Step3 register={register} errors={errors} completeSignUp={handleSkipOrganisation} />
                        <BusyButton type="submit" className="w-full" isBusy={isBusy}>
                            Create a new Organisation
                        </BusyButton>
                    </form>
                );
            case 4:
                return <Step4 step1Data={step1Data} />;
            case 5:
                return <Step5 />;
            default:
                return null;
        }
    };

    return (
        <Card className="w-96 border-none shadow-none">
            <CardHeader className="mb-4">
                {step === 1 && <SignupCardTitle className="text-center text-2xl">Create your free account</SignupCardTitle>}
                {step === 2 && <SignupCardTitle className="text-center text-2xl">Choose your password</SignupCardTitle>}
                {step === 3 && <SignupCardTitle className="text-center text-2xl">Your Organisation</SignupCardTitle>}
                {step === 4 && <SignupCardTitle className="text-center text-2xl">Create a new Organisation</SignupCardTitle>}
                {step === 5 && <SignupCardTitle className="text-center text-2xl">Creating your profile...</SignupCardTitle>}
            </CardHeader>
            <CardContent>
                {error && (<ErrorAlert description={error} />)}
                {renderStep()}
            </CardContent>

            <CardFooter className="flex justify-center mt-4">
                {step === 2 && error && (
                    <Button variant='ghost' className="w-full" onClick={handleGoBack} >
                        Go Back
                    </Button>
                )}

                {step === 3 && (
                    <Button variant="ghost" className="w-full" onClick={handleSkipOrganisation}>
                        Skip
                    </Button>
                )}
            </CardFooter>
        </Card>
    );
}

export default SignupPage;