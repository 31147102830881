import React, { useState, useRef } from 'react'

import { cn, withProps } from '@udecode/cn';
import { createPlateEditor, Plate, ParagraphPlugin, PlateLeaf } from '@udecode/plate-common/react';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { HorizontalRulePlugin } from '@udecode/plate-horizontal-rule/react';
import { LinkPlugin } from '@udecode/plate-link/react';
import { TogglePlugin } from '@udecode/plate-toggle/react';
import { ColumnPlugin, ColumnItemPlugin } from '@udecode/plate-layout/react';
import { HeadingPlugin } from '@udecode/plate-heading/react';
import { TablePlugin, TableRowPlugin, TableCellPlugin, TableCellHeaderPlugin } from '@udecode/plate-table/react';
import { DatePlugin } from '@udecode/plate-date/react';
import { CaptionPlugin } from '@udecode/plate-caption/react';
import { BoldPlugin, CodePlugin, ItalicPlugin, StrikethroughPlugin, UnderlinePlugin } from '@udecode/plate-basic-marks/react';

import { BaseFontColorPlugin } from '@udecode/plate-font';
import { HighlightPlugin } from '@udecode/plate-highlight/react';
import { KbdPlugin } from '@udecode/plate-kbd/react';
import { BaseAlignPlugin } from '@udecode/plate-alignment';
import { IndentPlugin } from '@udecode/plate-indent/react';
import { IndentListPlugin } from '@udecode/plate-indent-list/react';
import { BaseLineHeightPlugin } from '@udecode/plate-line-height';
import { BlockSelectionPlugin, BlockMenuPlugin } from '@udecode/plate-selection/react';
import { DndPlugin } from '@udecode/plate-dnd';
import { ExitBreakPlugin, SoftBreakPlugin } from '@udecode/plate-break/react';
import { NodeIdPlugin } from '@udecode/plate-node-id';
import { ResetNodePlugin } from '@udecode/plate-reset-node/react';
import { DeletePlugin } from '@udecode/plate-select';
import { TabbablePlugin } from '@udecode/plate-tabbable/react';
import { TrailingBlockPlugin } from '@udecode/plate-trailing-block';
import { BaseSlashPlugin } from '@udecode/plate-slash-command';
import { DocxPlugin } from '@udecode/plate-docx';
import { CsvPlugin } from '@udecode/plate-csv';
import { MarkdownPlugin } from '@udecode/plate-markdown';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ImagePlugin, MediaEmbedPlugin } from '@udecode/plate-media/react';
import { SelectOnBackspacePlugin } from '@udecode/plate-select';

import { BlockquoteElement } from '@/components/plate-ui/blockquote-element';
import { HrElement } from '@/components/plate-ui/hr-element';
import { LinkElement } from '@/components/plate-ui/link-element';
import { LinkFloatingToolbar } from '@/components/plate-ui/link-floating-toolbar';
import { ToggleElement } from '@/components/plate-ui/toggle-element';
import { ColumnGroupElement } from '@/components/plate-ui/column-group-element';
import { ColumnElement } from '@/components/plate-ui/column-element';
import { HeadingElement } from '@/components/plate-ui/heading-element';
import { ParagraphElement } from '@/components/plate-ui/paragraph-element';
import { TableElement } from '@/components/plate-ui/table-element';
import { TableRowElement } from '@/components/plate-ui/table-row-element';
import { TableCellElement, TableCellHeaderElement } from '@/components/plate-ui/table-cell-element';
import { DateElement } from '@/components/plate-ui/date-element';
import { HighlightLeaf } from '@/components/plate-ui/highlight-leaf';
import { KbdLeaf } from '@/components/plate-ui/kbd-leaf';
import { Editor } from '@/components/plate-ui/editor';
import { FixedToolbar } from '@/components/plate-ui/fixed-toolbar';
import { FixedToolbarButtons } from '@/components/plate-ui/fixed-toolbar-buttons';
import { FloatingToolbar } from '@/components/plate-ui/floating-toolbar';
import { FloatingToolbarButtons } from '@/components/plate-ui/floating-toolbar-buttons';
import { withPlaceholders } from '@/components/plate-ui/placeholder';
import { withDraggables } from '@/components/plate-ui/with-draggables';
import { TooltipProvider } from '@/components/plate-ui/tooltip';
import { ImageElement } from '@/components/plate-ui/image-element';
import { ImagePreview } from '@/components/plate-ui/image-preview';
import { TElement } from '@udecode/plate-common';
import { useCallback } from 'react';

import {
  CodeBlockPlugin,
  CodeLinePlugin,
  CodeSyntaxPlugin,
} from '@udecode/plate-code-block/react';
import Prism from 'prismjs';
import { CodeBlockElement } from '@/components/plate-ui/code-block-element';
import { CodeLineElement } from '@/components/plate-ui/code-line-element';
import { CodeLeaf } from '@/components/plate-ui/code-leaf';
import { NumberedListPlugin } from '@udecode/plate-list/react';
import { ListElement } from '@/components/plate-ui/list-element';

type BlockEditorProps = {
  onChange?: (value: TElement[]) => void;
  initialValue: TElement[];
  onBlur?: () => void;
};

const BlockEditor= React.forwardRef<HTMLDivElement, BlockEditorProps>(({ onChange, initialValue, onBlur }, ref) => {

  const handleChange = useCallback(({
    editor,
    value
  }) => {
    if (onChange) {
      onChange(value);
    }
  }, [onChange]);

  const handleOnBlur = useCallback(() => {
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const editor = createPlateEditor({
    plugins: [
      BlockquotePlugin,
      HorizontalRulePlugin,
      LinkPlugin.configure({
        render: { afterEditable: () => <LinkFloatingToolbar /> },
      }),
      TogglePlugin,
      ColumnPlugin,
      ParagraphPlugin,
      HeadingPlugin,
      TablePlugin,
      DatePlugin,
      CodeBlockPlugin.configure({ options: { prism: Prism } }),
      CaptionPlugin.configure({
        options: {
          plugins: [
            ImagePlugin, MediaEmbedPlugin
          ]
        },
      }),
      BoldPlugin,
      ItalicPlugin,
      StrikethroughPlugin,
      UnderlinePlugin,
      CodePlugin,
      CodeLinePlugin,
      BaseFontColorPlugin,
      HighlightPlugin,
      KbdPlugin,
      BaseAlignPlugin.configure({
        inject: { targetPlugins: ['p', 'h1', 'h2', 'h3', ImagePlugin.key,] },
      }),
      IndentPlugin.configure({
        inject: { targetPlugins: ['p', 'h1', 'h2', 'h3'] },
      }),
      IndentListPlugin.configure({
        inject: { targetPlugins: ['p', 'h1', 'h2', 'h3'] },
      }),
      BaseLineHeightPlugin.configure({
        inject: {
          nodeProps: {
            defaultNodeValue: 1.5,
            validNodeValues: [1, 1.2, 1.5, 2, 3],
          },
          targetPlugins: ['p', 'h1', 'h2', 'h3'],
        },
      }),
      BlockSelectionPlugin,
      BlockMenuPlugin,
      DndPlugin.configure({
        options: { enableScroller: true },
      }),
      ExitBreakPlugin.configure({
        options: {
          rules: [
            {
              hotkey: 'mod+enter',
            },
            {
              before: true,
              hotkey: 'mod+shift+enter',
            },
            {
              hotkey: 'enter',
              level: 1,
              query: {
                allow: ['h1', 'h2', 'h3'],
                end: true,
                start: true,
              },
              relative: true,
            },
          ],
        },
      }),
      NodeIdPlugin,
      ResetNodePlugin.configure({
        options: {
          rules: [
            // Usage: https://platejs.org/docs/reset-node
          ],
        },
      }),
      DeletePlugin,
      SoftBreakPlugin.configure({
        options: {
          rules: [
            { hotkey: 'shift+enter' },
            {
              hotkey: 'enter',
              query: {
                allow: [CodeBlockPlugin.key, CodePlugin.key, CodeLinePlugin.key, 'blockquote', 'td', 'th'],
              },
            },
          ],
        },
      }),
      TabbablePlugin,
      TrailingBlockPlugin.configure({
        options: { type: 'p' },
      }),
      BaseSlashPlugin,
      DocxPlugin,
      CsvPlugin,
      MarkdownPlugin,
      SelectOnBackspacePlugin.configure({
        options: {
          query: {
            allow: [ImagePlugin.key, MediaEmbedPlugin.key],
          },
        },
      }),
      ImagePlugin.extend({
        render: { afterEditable: ImagePreview },
        //uploadImage: undefined
      }),

    ],


    override: {
      components: withDraggables(withPlaceholders(({
        [HorizontalRulePlugin.key]: HrElement,
        [LinkPlugin.key]: LinkElement,
        [TogglePlugin.key]: ToggleElement,
        [ColumnPlugin.key]: ColumnGroupElement,
        [ColumnItemPlugin.key]: ColumnElement,
        [HEADING_KEYS.h1]: withProps(HeadingElement, { variant: 'h1' }),
        [HEADING_KEYS.h2]: withProps(HeadingElement, { variant: 'h2' }),
        [HEADING_KEYS.h3]: withProps(HeadingElement, { variant: 'h3' }),
        [HEADING_KEYS.h4]: withProps(HeadingElement, { variant: 'h4' }),
        [HEADING_KEYS.h5]: withProps(HeadingElement, { variant: 'h5' }),
        [HEADING_KEYS.h6]: withProps(HeadingElement, { variant: 'h6' }),
        [ParagraphPlugin.key]: ParagraphElement,
        [TablePlugin.key]: TableElement,
        [TableRowPlugin.key]: TableRowElement,
        [TableCellPlugin.key]: TableCellElement,
        [TableCellHeaderPlugin.key]: TableCellHeaderElement,
        [DatePlugin.key]: DateElement,
        [BoldPlugin.key]: withProps(PlateLeaf, { as: 'strong' }),
        [HighlightPlugin.key]: HighlightLeaf,
        [ItalicPlugin.key]: withProps(PlateLeaf, { as: 'em' }),
        [KbdPlugin.key]: KbdLeaf,
        [UnderlinePlugin.key]: withProps(PlateLeaf, { as: 'u' }),
        [StrikethroughPlugin.key]: withProps(PlateLeaf, { as: 's' }),
        [CodeBlockPlugin.key]: CodeBlockElement,
        [CodeLinePlugin.key]: CodeLineElement,
        [CodePlugin.key]: CodeLeaf,
        [BlockquotePlugin.key]: BlockquoteElement,
        [NumberedListPlugin.key]: ListElement
      }))),
    },
    value: initialValue
  });

  return (

    <DndProvider backend={HTML5Backend}>
      <Plate
        editor={editor}
        onValueChange={handleChange}
      >
          <FixedToolbar>
            <FixedToolbarButtons />
          </FixedToolbar>

          <Editor
            focusRing={false}
            ref={ref}
            variant="ghost"
           // onBlur={handleOnBlur}
            className={cn(
              'relative',
              'w-full',
              'rounded-b-2xl border-none border-zinc-200',
              'bg-white',
              'overflow-y-scroll',
              'min-h-[calc(100vh-500px)]',
              'max-h-[calc(100vh-500px)]',
              //'max-h-full'
            )}  

          //className="min-h-[50vh]"
          />

          <FloatingToolbar>
            <FloatingToolbarButtons />
          </FloatingToolbar>
      </Plate>
    </DndProvider>

  )
});

export default React.memo(BlockEditor);
