
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { BookOpenCheck } from "lucide-react";
import React from "react";

type ListItem = {
    observations_count: number;
    recommendations_count: number;
}

type FastFillProps = {
    item: ListItem;
    onClick?: (item: ListItem) => void;
}

const FastFillCountPill: React.FC<FastFillProps> = ({ item, onClick }) => {

    if (!item) return null;

    return (
        <Tooltip>
            <TooltipTrigger>
                <div className="flex flex-row space-x-1.5 items-center text-xs bg-zinc-100 px-2 py-2 rounded-md h-full" onClick={() => onClick?.(item)}>
                    <BookOpenCheck className="h-4 w-4 text-zinc-500" />
                    <div>{item.observations_count}</div>
                    <span className="px-0.5 opacity-50">|</span>
                    <div>{item.recommendations_count}</div>
                </div>
            </TooltipTrigger>
            <TooltipContent side='bottom' align='start'>
                <div className="flex flex-col space-y-1 text-sm">
                    <div className="font-normal">Fast Fills</div>
                    <div><span className="mr-2 font-semibold">{item.observations_count}</span>Observations</div>
                    <div><span className="mr-2 font-semibold">{item.recommendations_count}</span>Recommendations</div>
                </div>
            </TooltipContent>
        </Tooltip>

    )

}

export default FastFillCountPill;