import React, { createContext, useContext, useState, ReactNode } from 'react';

type BreadcrumbContextType = {
  setBreadcrumbName: (path: string, name: string) => void;
  getBreadcrumbName: (path: string) => string | undefined;
  getMatchingPaths: (paths: string[]) => { [key: string]: string };
};

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

export const BreadcrumbProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [breadcrumbNames, setBreadcrumbNames] = useState<Record<string, string>>({});

  const setBreadcrumbName = (path: string, name: string) => {
    setBreadcrumbNames(prev => ({ ...prev, [path]: name }));
  };

  const getBreadcrumbName = (path: string) => {
    const matchingPath = Object.keys(breadcrumbNames).find(key => path.startsWith(key));
    return matchingPath ? breadcrumbNames[matchingPath] : undefined;
  };

  const getMatchingPaths = (paths: string[]) => {
    const matches: { [key: string]: string } = {};
    paths.forEach(path => {
      const matchingPath = Object.keys(breadcrumbNames).find(key => path.startsWith(key));
      if (matchingPath && !matches[matchingPath]) {
        matches[matchingPath] = breadcrumbNames[matchingPath];
      }
    });
    return matches;
  };

  return (
    <BreadcrumbContext.Provider value={{ setBreadcrumbName, getBreadcrumbName, getMatchingPaths }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumb = () => {
  const context = useContext(BreadcrumbContext);
  if (context === undefined) {
    throw new Error('useBreadcrumb must be used within a BreadcrumbProvider');
  }
  return context;
};