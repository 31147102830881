import { Badge } from "@/components/ui/badge";

type RetiredBadgeProps = {
    retired: boolean
}

const RetiredBadge: React.FC<RetiredBadgeProps> = ({ retired }) => {
    if (!retired) return null;

    return <Badge variant="retired" className="h-6">Retired</Badge>;
};

export default RetiredBadge;