import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ClassificationTree } from './types/index';
import ClassificationTreeAPI from './api/ClassificationTreeAPI';

export const CLASSIFICATION_TREES_FEATURE_KEY = 'classificationTrees';

const classificationTreesAdapter = createEntityAdapter<ClassificationTree>();

export interface ClassificationTreesState
  extends EntityState<ClassificationTree, number> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  error?: string | null;
}

export const initialClassificationTreesState: ClassificationTreesState =
  classificationTreesAdapter.getInitialState({
    loadingStatus: 'not loaded',
    error: null,
  });

export const fetchClassificationTreesByOrganisation = createAsyncThunk<
  ClassificationTree[],
  number
>(
  'classificationTree/fetchClassificationTrees',
  async (organisationId: number, thunkAPI) => {
    console.info('fetch classification trees', organisationId);
    try {
      const response =
        await ClassificationTreeAPI.getClassificationTreesByOrganisation(
          organisationId
        );
      console.info('fetch classification trees response', response.body);
      return response.body as ClassificationTree[];
    } catch (error) {
      console.info('fetch classification trees error', error);
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const fetchClassificationTrees = createAsyncThunk<
  ClassificationTree[],
  void
>(
  'classificationTree/fetchClassificationTrees',
  async (_, thunkAPI) => {
    console.info('fetch classification trees');
    try {
      const response =
        await ClassificationTreeAPI.getClassificationTrees();
      console.info('fetch classification trees response', response.body);
      return response.body as ClassificationTree[];
    } catch (error) {
      console.info('fetch classification trees error', error);
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const storeClassificationTree = createAsyncThunk<
  ClassificationTree,
  Partial<ClassificationTree>
>(
  'classificationTree/storeClassificationTree',
  async (tree: Partial<ClassificationTree>, thunkAPI) => {
    try {
      const response = await ClassificationTreeAPI.addClassificationTree(tree);
      return response.body as ClassificationTree;
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const updateClassificationTree = createAsyncThunk<
  ClassificationTree,
  Partial<ClassificationTree>
>(
  'classificationTree/updateClassificationTree',
  async (tree: Partial<ClassificationTree>, thunkAPI) => {
    try {
      const response = await ClassificationTreeAPI.updateClassificationTree(
        tree
      );
      return response.body.data as ClassificationTree;
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const deleteClassificationTree = createAsyncThunk<number, number>(
  'classificationTree/deleteClassificationTree',
  async (treeId: number, thunkAPI) => {
    try {
      await ClassificationTreeAPI.deleteClassificationTree(treeId);
      return treeId;
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const classificationTreeSlice = createSlice({
  name: CLASSIFICATION_TREES_FEATURE_KEY,
  initialState: initialClassificationTreesState,
  reducers: {
    add: classificationTreesAdapter.addOne,
    remove: classificationTreesAdapter.removeOne,
    updateClassificationTree(state, action) {
      classificationTreesAdapter.upsertOne(state, action.payload);
    },
    updateClassificationTrees(state, action) {
      console.info('classification trees received', action.payload);
      classificationTreesAdapter.upsertMany(state, action.payload);
      state.loadingStatus = 'loaded';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchClassificationTreesByOrganisation.pending,
        (state: ClassificationTreesState) => {
          state.loadingStatus = 'loading';
        }
      )
      .addCase(
        fetchClassificationTreesByOrganisation.fulfilled,
        (
          state: ClassificationTreesState,
          action: PayloadAction<ClassificationTree[]>
        ) => {
          console.info('debug', action.payload);
          classificationTreesAdapter.upsertMany(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(
        fetchClassificationTreesByOrganisation.rejected,
        (state: ClassificationTreesState, action) => {
          state.loadingStatus = 'error';
          state.error = action.error.message;
        }
      )
      .addCase(
        storeClassificationTree.fulfilled,
        (state, action: PayloadAction<ClassificationTree>) => {
          classificationTreesAdapter.addOne(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(storeClassificationTree.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(
        updateClassificationTree.fulfilled,
        (state, action: PayloadAction<ClassificationTree>) => {
          classificationTreesAdapter.upsertOne(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(updateClassificationTree.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(
        deleteClassificationTree.fulfilled,
        (state, action: PayloadAction<number>) => {
          classificationTreesAdapter.removeOne(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(deleteClassificationTree.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const classificationTreesReducer = classificationTreeSlice.reducer;
export const classificationTreesActions = {
  ...classificationTreeSlice.actions,
};

const { selectAll, selectEntities, selectById } =
  classificationTreesAdapter.getSelectors();

export const getClassificationTreeState = (rootState: {
  [CLASSIFICATION_TREES_FEATURE_KEY]: ClassificationTreesState;
}): ClassificationTreesState => {
  return rootState[CLASSIFICATION_TREES_FEATURE_KEY];
};

export const selectAllClassificationTrees = createSelector(
  getClassificationTreeState,
  selectAll
);

export const selectClassificationTreeEntities = createSelector(
  getClassificationTreeState,
  selectEntities
);

export const selectClassificationTreeLoadingStatus = createSelector(
  getClassificationTreeState,
  (state: ClassificationTreesState) => state.loadingStatus
);

export const selectClassificationTreeById = (classificationTreeId: number) =>
  createSelector(getClassificationTreeState, (state) => {
    return state.entities[classificationTreeId];
  });

export const selectClassificationTreesByOrganisationId = createSelector(
  selectAllClassificationTrees, // selector that selects all classification trees from the state
  (_, organisationId: number) => organisationId, // selector that selects the organisationId from the props
  (classificationTrees: ClassificationTree[], organisationId: number) =>
    classificationTrees.filter(
      (tree) => tree.organisation_id === organisationId
    )
);
