import { RootState } from '../root';
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const name = 'alerts';
export const ALERTS_FEATURE_KEY = name;

export type AlertType =  {
    type: 'alert-success' | 'alert-danger',
    message: string,
    showAfterRedirect?: boolean,
    dismissable?: boolean,
    timeout?: number
}

export type AlertState = {
    value: AlertType | null
}

export const initialAlertState: Readonly<AlertState> = {
    value: null
}

export const alertsSlice = createSlice({
    name, 
    initialState: initialAlertState,
    reducers: {
        success(state:AlertState, action:PayloadAction<Partial<AlertType>>) {
            state.value = {
                type: 'alert-success',
                message: action.payload.message || 'Success!',
                showAfterRedirect: action.payload?.showAfterRedirect,
                timeout: action.payload?.timeout
            }
        },
            error(state:AlertState, action:PayloadAction<Partial<AlertType>>) {
            state.value = {
                type: 'alert-danger',
                message: action.payload?.message || 'Error!',
                showAfterRedirect: action.payload?.showAfterRedirect
            };
        },
        clear(state:AlertState) {
            if (state.value?.showAfterRedirect) {
                state.value.showAfterRedirect = false;
            } else {
                state.value = null;
            }
        }
    }
});

export const {success, error, clear} = alertsSlice.actions;
export const alertsActions = { ...alertsSlice.actions };

export const selectAlert = (state:RootState) => state.alerts.value;

export default alertsSlice.reducer;