import React, { useState, useEffect, useCallback, useMemo, createContext, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type Tab = {
  title: string;
  slug: string;
};

type Tabs = { [key: number]: Tab };

interface TabContextProps {
  activeTab: number;
  setActiveTab: (tabId: number) => void;
}

const TabContext = createContext<TabContextProps | undefined>(undefined);

export const useTabContext = (): TabContextProps => {
  const context = useContext(TabContext);
  if (!context) {
    throw new Error('useTabContext must be used within a TabProvider');
  }
  return context;
};

interface TabProviderProps {
  tabs: Tabs;
  defaultTabId: number;
  path: string;
  children: React.ReactNode;
}

export const TabProvider: React.FC<TabProviderProps> = ({ tabs, defaultTabId, path, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const slug = location.pathname.split('/').pop() ?? '';
  const currentTab = Object.keys(tabs).find((key) => tabs[Number(key)].slug === slug) ?? '';
  const currentTabId = isNaN(parseInt(currentTab)) ? defaultTabId : parseInt(currentTab);

  const [activeTab, setActiveTabState] = useState<number>(currentTabId);

  const setActiveTab = useCallback(
    (tabId: number) => {
      const newPath = `${path}/${tabs[tabId].slug}`;
      navigate(newPath);
      setActiveTabState(tabId);
    },
    [navigate, tabs]
  );

  useEffect(() => {
    setActiveTabState(currentTabId);
  }, [currentTabId]);

  const value = useMemo(() => ({ activeTab, setActiveTab }), [activeTab, setActiveTab]);

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};

export default TabProvider;