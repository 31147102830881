import PageWithTopBarNavigation from "@/app/features/profile/PageWithTopBarNavigation";
import { NavLink, TopNavBar } from "@/app/features/profile/TopNavBar";
import { MainPage } from "@/app/layout2/AppLayout";
import { useBreadcrumb } from "@/app/layout2/BreadcrumbContext";
import EmptyState from "@/components/raytd/empty-state";
import { useEffect } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import { useGetInspectionQuery } from "@app.raytd.com/store";
import InspectionReports from "@/app/features/inspections/InspectionReports";
import InspectionDetails from "@/app/features/inspections/InspectionDetail";
import InspectionsCollaborators from "@/app/features/inspections/InspectionCollaborators";

export default function () {

    const { setBreadcrumbName } = useBreadcrumb();
    const { inspectionId } = useParams<{ inspectionId: string }>();
    const { data: inspection } = useGetInspectionQuery(inspectionId);

    useEffect(() => {
        if (inspection) {
            setBreadcrumbName(`/inspections/${inspectionId}`, inspection?.title);
        }
    }, [inspection]);

    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    <NavLink to="details">
                        Details
                    </NavLink>
                    <NavLink to="results">
                        Results
                    </NavLink>
                    <NavLink to="collaborators">
                        Collaborators
                    </NavLink>
                </TopNavBar>
                <div className="container mx-auto py-6 px-4">
                    <Routes>
                        <Route path="details" element={<InspectionDetails inspectionId={inspectionId} />} />
                        <Route path="results" element={<InspectionReports />} />
                        <Route path="collaborators" element={<InspectionsCollaborators id={inspectionId} />} />
                        <Route path="*" element={<Navigate to="details" />} />
                    </Routes>
                </div>
            </PageWithTopBarNavigation>
        </MainPage>
    )
}

