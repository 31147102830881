import React from "react"
import { useFormField, FormItem, FormLabel, FormControl, FormDescription, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { cn } from "@/lib/utils"

interface LinkedInInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  label?: string
  description?: string
  value?: string
  onChange?: (value: string) => void
}

const LinkedInInput = React.forwardRef<HTMLInputElement, LinkedInInputProps>(
  ({ className, label, description, value, onChange, ...props }, ref) => {
    const { id, name } = props
    const { error, formItemId } = useFormField()

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value
      const fullValue = inputValue ? `https://linkedin.com/in/${inputValue}` : ""
      onChange?.(fullValue)
    }

    const displayValue = value ? value.replace(/^https:\/\/linkedin\.com\/in\//, "") : ""

    return (
      <FormItem className="space-y-2">
        <FormLabel htmlFor={formItemId}>{label}</FormLabel>
        <FormControl>
          <div className="flex items-center">
            <span className="bg-muted px-3 py-2 rounded-l-md border border-r-0 border-input text-muted-foreground">
              https://linkedin.com/in/
            </span>
            <Input
              {...props}
              ref={ref}
              id={formItemId}
              name={name}
              value={displayValue}
              onChange={handleInputChange}
              className={cn("rounded-l-none", className)}
            />
          </div>
        </FormControl>
        {description && <FormDescription>{description}</FormDescription>}
        <FormMessage />
      </FormItem>
    )
  }
)

LinkedInInput.displayName = "LinkedInInput"

export { LinkedInInput }