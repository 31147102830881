import { Button } from "@/components/ui/button"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import { Check, ChevronsUpDown, Trash } from "lucide-react"
import React from "react"
import { TestCase } from "store/src/lib/tests/entity"

interface CategorySelectProps {
    form: any;
    name: string;
    label: string;
    items: { value: string; label: string; }[];
    onAddItem: (item: { value: string; label: string; delete?: boolean; }) => void;
    initialValues?: TestCase[];
}

export function CategorySelect({ form, name, label, items, onAddItem }: CategorySelectProps) {
    const [open, setOpen] = React.useState(false)
    const [inputValue, setInputValue] = React.useState("")
    const [isEditing, setIsEditing] = React.useState(false)
    const [initialCategories] = React.useState(new Set(items?.map(item => item.value) || []))


    const normalizedInput = inputValue.trim();
    const capitalizedInput = normalizedInput
        ? normalizedInput.charAt(0).toUpperCase() + normalizedInput.slice(1)
        : "";

    const existingItem = items?.find(item =>
        item.value.toLowerCase() === capitalizedInput.toLowerCase()
    );

    const handleNewCategory = (value: string) => {
        const normalizedInput = value.trim();
        if (!normalizedInput) return;

        const capitalizedValue = normalizedInput.charAt(0).toUpperCase() + normalizedInput.slice(1);
        onAddItem({ value: capitalizedValue, label: capitalizedValue });
        setInputValue("");
        setOpen(false);
    }

    const filteredItems = items?.filter(item =>
        item.value.toLowerCase().includes(normalizedInput.toLowerCase())
    ) || [];

    const canDelete = (categoryValue: string) => {
        return !initialCategories.has(categoryValue);
    }

    const handleDeleteCategory = (categoryValue: string) => {
        if (canDelete(categoryValue)) {
            const field = form.getValues(name);
            if (field === categoryValue) {
                form.setValue(name, '');
            }
            onAddItem({ value: categoryValue, label: categoryValue, delete: true });
        }
    }

    return (
        <FormField
            control={form.control}
            name={name}
            render={({ field }) => (
                <FormItem className="flex flex-col flex-1">
                    <FormLabel>{label}</FormLabel>
                    <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>
                            <FormControl>
                                <Button
                                    variant="outline"
                                    role="combobox"
                                    aria-expanded={open}
                                    className="w-full justify-between"
                                >
                                    {field.value
                                        ? items?.find((item) => item.value === field.value)?.label
                                        : `Select ${label}`}
                                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </FormControl>
                        </PopoverTrigger>

                        <PopoverContent className="p-0 w-[var(--radix-popover-trigger-width)]" align="center">
                            <Command>
                                <CommandInput
                                    placeholder={`Search ${label}...`}
                                    value={inputValue}
                                    onValueChange={setInputValue}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && inputValue.trim()) {
                                            handleNewCategory(inputValue);
                                        }
                                    }}
                                />
                                <CommandEmpty>
                                    {inputValue.trim() && (
                                        <Button
                                            variant="ghost"
                                            className="w-full justify-start"
                                            onClick={() => handleNewCategory(inputValue)}
                                        >
                                            Add "{inputValue.trim()}"
                                        </Button>
                                    )}
                                </CommandEmpty>
                                <CommandList>
                                    <CommandGroup>
                                        {filteredItems.map((item) => (
                                            <CommandItem
                                                key={item.value}
                                                onSelect={() => {
                                                    if (field.value === item.value && !isEditing) {
                                                        setIsEditing(true);
                                                    } else {
                                                        field.onChange(item.value === field.value ? "" : item.value);
                                                        setOpen(false);
                                                    }
                                                }}
                                            >
                                                <div className="flex justify-between w-full items-center">
                                                    <div className="flex items-center">
                                                        <Check
                                                            className={cn(
                                                                "mr-2 h-4 w-4",
                                                                field.value === item.value ? "opacity-100" : "opacity-0"
                                                            )}
                                                        />
                                                        {isEditing && field.value === item.value ? (
                                                            <Input
                                                                autoFocus
                                                                defaultValue={item.label}
                                                                onBlur={(e) => {
                                                                    const newValue = e.target.value.trim();
                                                                    if (newValue && newValue !== item.label) {
                                                                        handleNewCategory(newValue);
                                                                    }
                                                                    setIsEditing(false);
                                                                }}
                                                                onClick={(e) => e.stopPropagation()}
                                                            />
                                                        ) : (
                                                            item.label
                                                        )}
                                                    </div>
                                                    {canDelete(item.value) && (
                                                        <Button
                                                            size="sm"
                                                            variant="ghost"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDeleteCategory(item.value);
                                                            }}
                                                        >
                                                            <Trash className="h-4 w-4" />
                                                        </Button>
                                                    )}
                                                </div>
                                            </CommandItem>
                                        ))}
                                    </CommandGroup>
                                    <CommandGroup>
                                        {normalizedInput && !existingItem && (
                                            <CommandItem
                                                onSelect={() => handleNewCategory(inputValue)}
                                            >
                                                <div className="flex items-center text-sm font-medium px-2">
                                                    Add "{capitalizedInput}"
                                                </div>
                                            </CommandItem>
                                        )}
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </PopoverContent>
                    </Popover>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}

export default CategorySelect;