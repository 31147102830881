import classNames from "classnames";

type Props = {
  value: number;
  label: string;
  light?: boolean;
  dark?: boolean;
  inverted?: boolean;
  icon?: React.ReactNode;
  iconOnly?: boolean; // New prop for icon only mode
  onClick?: () => void;
};

const ValuePill: React.FC<Props> = ({
  value,
  label,
  light = true,
  dark,
  inverted,
  icon,
  iconOnly = false, // Default to false
  onClick
}) => {
  return (
    <div className="flex" onClick={() => onClick?.()}>
      <div
        className={classNames(
          "flex flex-row items-center px-2 py-1.5 rounded-md justify-center flex-wrap",
          { "bg-zinc-500 text-white": dark },
          { "bg-zinc-100 text-zinc-950": light },
          { "bg-white text-zinc-950 border border-zinc-500": inverted }
        )}
      >
        {!iconOnly && (
          <div className={classNames("", {
              "hidden lg:block": icon,
              "": !icon
            })}
          >
            <span className="text-xs truncate">
              <span className="font-semibold mr-1">{value}</span>
              {label}
            </span>
          </div>
        )}

        {icon && (
          <div className={classNames("flex flex-row", {
              "lg:hidden": !iconOnly, // Hide icon on large screens when not in iconOnly mode
              "": iconOnly // Always show icon in iconOnly mode
            })}
            title={`${value} ${label}`}
          >
            {icon}
            <span className="font-semibold ml-1 text-xs">{value}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ValuePill;
