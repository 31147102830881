import SubPageLayout from "@/app/features/organisation/SubPageLayout";
import pluralize from 'pluralize';

function sortByKey(key: string) {
    return (a: any, b: any) => {
        return a[key].localeCompare(b[key], undefined, { numeric: true, sensitivity: 'base' });
    };
}

const AssetSpacesPage = () => {

    const { assetId } = useParams<{ assetId: string }>();

    const { data: buildings, isLoading: isBuildingLoading } = useGetAssetBuildingsQuery(assetId);
    const { data: levels, isLoading: isLevelsLoading } = useGetAssetLevelsQuery(assetId);
    const { data: areas, isLoading: isRoomsLoading } = useGetAssetAreasQuery(assetId);

    const isLoading = isBuildingLoading || isLevelsLoading || isRoomsLoading;

    console.debug({ buildings, levels, areas, isLoading });

    const nestedData = convertToNestedStructure(buildings || [], levels || [], areas || []);

    console.debug({ nestedData });

    return (
        <SubPageLayout title='Spaces'>
            {
                isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <Spaces assetId={assetId} data={nestedData} />
                )
            }

        </SubPageLayout>
    )

}

function convertToNestedStructure(
    buildings: Building[],
    floors: Floor[],
    locations: FloorLocation[]
) {
    return [...buildings].sort(sortByKey('description'))
        .map((building) => ({
            id: building.id.toString(),
            parentId: building.id,
            original: building,
            title: building.description,
            isOpen: true,
            children: floors
                .filter((floor) => floor.building_id === building.id)
                .sort(sortByKey('description'))
                .map((floor) => ({
                    id: `${building.id}-${floor.id}`,
                    parentId: floor.id,
                    original: floor,
                    title: floor.description,
                    isOpen: true,
                    children: locations
                        .filter(
                            (location) =>
                                location.level_id === floor.id
                        )
                        .sort(sortByKey('description'))
                        .map((location) => ({
                            id: `${building.id}-${floor.id}-${location.id}`,
                            parentId: floor.id,
                            original: location,
                            title: location.description,
                            isOpen: false,
                        })),
                })),
        }));
}

function searchNestedStructure(items: any[], searchTerm: string) {

    if (searchTerm.trim() === '') {
        return items;
    }

    const searchTermLower = searchTerm.toLowerCase();

    function search(items: any[]): any[] {
        let results = [];

        for (const item of items) {
            const match = item.title.toLowerCase().includes(searchTermLower);
            const childResults = item.children ? search(item.children) : [];
            const actualChildren = item.children?.length || 0;

            if (match || childResults.length > 0) {
                results.push({
                    ...item,
                    children: childResults,
                    actualChildren,
                });
            }
        }

        return results;
    }

    return search(items);

}


import { useNav } from "@/app/features/profile/NavContext";
import OptionToggle from "@/components/raytd/option-toggle";
import { Pill } from "@/components/raytd/pill";
import SearchInput, { SearchInputDebounced, SearchInputWithButton } from "@/components/raytd/search-input";
import { Button } from "@/components/ui/button";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Building, Floor, FloorLocation, useAddAssetAreaMutation, useAddAssetLevelMutation, useAddBuildingMutation, useGetAssetAreasQuery, useGetAssetBuildingsQuery, useGetAssetLevelsQuery, useUpdateAreaMutation, useUpdateBuildingMutation, useUpdateLevelMutation } from "@app.raytd.com/store";
import { ReloadIcon } from "@radix-ui/react-icons";
import { ChevronDown, ChevronRight, Plus } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import EmptyState from "@/components/raytd/empty-state";
import { debounce } from 'lodash';

interface ListItem {
    id: string
    parentId?: number;
    original: any;
    title: string
    children?: ListItem[]
    actualChildren?: number;
    isOpen: boolean;
}

type AddNewLeadProps = {
    title: string;
    initialValue?: string;
    onSubmit: (title: string) => Promise<void>;
    depth: number;
    isEditing?: boolean;
    id?: number;
}

const AddNewLeaf = ({ title = 'Add new item', initialValue, onSubmit, depth, id }: AddNewLeadProps) => {
    const [isActive, setIsActive] = useState(false)
    const [busy, setBusy] = useState(false)
    const [itemTitle, setItemTitle] = useState(initialValue || "")
    const inputRef = useRef<HTMLInputElement>(null);
    const componentRef = useRef<HTMLDivElement>(null);
    const isEditing = id !== undefined;

    const handleSubmit = async () => {

        if (!itemTitle.trim()) {
            return;
        }
        setBusy(true);

        await onSubmit(itemTitle.trim());
        setBusy(false);
        setItemTitle(itemTitle.trim());
        setIsActive(false)
    }

    useEffect(() => {
        if (inputRef.current && isActive) {
            inputRef.current.focus();
            if (!isEditing) {
                setItemTitle("");
            }
        }
    }, [isActive]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
                setIsActive(false)
                setItemTitle(initialValue)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [initialValue])

    if (busy) {
        return (
            <div className="flex items-center">
                <div className="w-6" />
                <ReloadIcon className="h-4 w-4 animate-spin mr-2" />
                <Label className="mr-2">{isEditing ? 'Updating...' : 'Adding...'}</Label>
            </div>
        )
    }

    if (isActive) {
        return (
            <div className="flex items-center"
                ref={componentRef}>
                <div className="w-6" />
                <Input
                    type="text"
                    ref={inputRef}
                    value={itemTitle}
                    onChange={(e) => setItemTitle(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                    placeholder="Start typing..."
                    className="flex-grow mr-2"
                />
                <Button onClick={handleSubmit} size="sm" disabled={!itemTitle.trim()}>
                    {isEditing ? 'Update' : 'Add'}
                </Button>
                <Button onClick={() => {
                    setIsActive(false)
                    setItemTitle(initialValue || "")
                }} variant="ghost" size="sm">
                    Cancel
                </Button>
            </div>
        )
    }

    return (
        <div className="flex items-center h-12">
            <div className="w-6" />
            {/* <Button
                variant="ghost"
                asChild
                size="sm"
                onClick={() => setIsActive(true)}
                className="hover:text-primary-muted/50"
            > */}
            <Pill
                variant={isEditing ? "enabled" : "disabled"}
                bold={depth === 0}
                className="cursor-pointer"
                onClick={() => setIsActive(true)}
            >
                {!isEditing && (<Plus className="h-4 w-4 mr-2" />)}
                {title}
            </Pill>
            {/* </Button> */}
        </div>
    )
}

const NestedListItem = ({
    item,
    depth = 0,
    onAddChild,
    onEditItem,
    onToggle,
    isEditMode,
    maxDepth = 2
}: {
    item: ListItem;
    depth?: number;
    onAddChild: (parentId: number, title: string, depth: number) => Promise<void>;
    onEditItem: (parentId: number, title: string, depth: number, original: any) => Promise<void>;
    onToggle: (id: string) => void;
    isEditMode: boolean;
    maxDepth?: number;
}) => {

    const isMaxDepth = depth >= maxDepth;
    const canEdit = !isMaxDepth && isEditMode;
    const count = item.actualChildren ?? (item.children ? item.children.length : 0);
    const type = (depth === 0 ? "Floor" : "Room");
    const countPillDescription = pluralize(type, count);
    const parentId = item.parentId;

    const canExpand = (!canEdit && item.children && item.children.length > 0) || (canEdit && !isMaxDepth);

    return (
        <Collapsible open={item.isOpen} onOpenChange={() => onToggle(item.id)}>
            <div className="flex items-center border-b border-zinc-200 h-12">

                {canExpand ? (
                    <CollapsibleTrigger asChild>
                        <Button variant="ghost" size="sm" className="p-0 h-6 w-6">
                            {item.isOpen ?
                                <ChevronDown className="h-4 w-4" /> :
                                <ChevronRight className="h-4 w-4" />}
                        </Button>
                    </CollapsibleTrigger>
                ) : (
                    <div className="w-6" />
                )}

                {/* <span className="ml-2 py-2">{item.title}</span> */}
                {/* <span className="ml-2 py-2"><Pill variant="enabled" bold={depth === 0}>{item.title}</Pill></span> */}
                {/* <span className="ml-10 text-xs text-muted-foreground">{count}</span> */}

                {isEditMode ? (
                    <AddNewLeaf
                        title={item.title}
                        initialValue={item.title}
                        onSubmit={(title) => onEditItem(parentId, title, depth, item.original)}
                        depth={depth} id={item.parentId}
                    />
                ) : (
                    <Pill variant="enabled"
                        bold={depth === 0}
                        className="cursor-pointer">
                        {item.title}
                    </Pill>
                )}

                {!isMaxDepth && (
                    <Pill variant="disabled"
                        size="sm"
                        className="ml-2">{`${count} ${countPillDescription}`}
                    </Pill>
                )}
            </div>
            <CollapsibleContent>
                <div className={`ml-8 mt-1 border-l`}>
                    {item.children && item.children.map((child) => (
                        <NestedListItem
                            key={`${item.id}-${child.id}`}
                            item={child}
                            depth={depth + 1}
                            onAddChild={onAddChild}
                            onEditItem={onEditItem}
                            onToggle={onToggle}
                            isEditMode={isEditMode}
                        />
                    ))}
                    {canEdit && (
                        <div className={`ml-6`}>
                            <AddNewLeaf
                                title={`Add ${type}`}
                                onSubmit={(title) => onAddChild(parentId, title, depth + 1)}
                                depth={depth + 1}
                            />
                        </div>
                    )
                    }
                </div>
            </CollapsibleContent>
        </Collapsible>
    )
}

type ExpandOrCollapse = 'expand' | 'collapse';

const Spaces = ({ assetId, data }) => {
    const [listData, setListData] = useState<ListItem[]>([])
    const [isEditMode, setIsEditMode] = useState(true);
    const [expandedState, setExpandedState] = useState<Record<string, boolean>>({});
    const [isExpanded, setIsExpanded] = useState<ExpandOrCollapse>('collapse');
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);

    const { setNavButtons } = useNav();

    //  const [archiveAsset, { isLoading: isArchiving, isSuccess: isArchiveSuccess, isError: isArchiveError, error: archiveError }] = useArchiveAssetMutation();

    const [addLevel, { isLoading: isAddingLevel, isSuccess: isLevelSuccess, isError: isLevelError }] = useAddAssetLevelMutation();
    const [updateLevel, { isLoading: isUpdatingLevel, isSuccess: isLevelUpdateSuccess, isError: isLevelUpdaetError }] = useUpdateLevelMutation();
    const [addArea, { isLoading: isAddingArea, isSuccess: isAreaSuccess, isError: isAreaError }] = useAddAssetAreaMutation();
    const [addBuilding, { isLoading: isAddingBuilding, isSuccess: isBuildingSuccess, isError: isBuildingError }] = useAddBuildingMutation();
    const [updateArea, { isLoading: isUpdatingArea, isSuccess: isAreaUpdateSuccess, isError: isAreaUpdateError }] = useUpdateAreaMutation();
    const [updateBuilding, { isLoading: isUpdatingBuilding, isSuccess: isBuildingUpdateSuccess, isError: isBuildingUpdateError }] = useUpdateBuildingMutation();

    const memoizedSearchNestedStructure = useMemo(() => {
        return (items: any[], term: string) => searchNestedStructure(items, term);
    }, []);

    // Debounce the search handler
    const debouncedSearch = useCallback(
        (term: string) => {
            console.info('searching', term);
            if (term.trim() === '') {
                setSearchResults(listData);
                setIsFiltered(false);
            } else {
                const results = memoizedSearchNestedStructure(listData, term);
                setSearchResults(results);
                setIsFiltered(true);
            }
            setIsSearching(false);
        }, [listData, memoizedSearchNestedStructure]);

    const [searchTerm, setSearchTerm] = useState('');

    const debouncedSearchTerm = useMemo(
        () => debounce(debouncedSearch, 100),
        [debouncedSearch]
    );

    useEffect(() => {
        setIsExpanded('expand');
        setIsSearching(true);
        debouncedSearchTerm(searchTerm);
        // Cleanup function to cancel debounce on unmount
        return () => {
            debouncedSearchTerm.cancel();
        };
    }, [searchTerm, debouncedSearchTerm]);

    // useEffect(() => {
    //     setIsSearching(false);
    // }, [searchResults]);

    useEffect(() => {
        // Initialize the list data with isOpen set to true for all items
        const initializeListData = (items: ListItem[]): ListItem[] => {
            return items.map(item => ({
                ...item,
                isOpen: expandedState[item.id] ?? isExpanded === 'expand',
                children: item.children ? initializeListData(item.children) : undefined
            }))
        }

        setListData(initializeListData(data))
    }, [data, expandedState, isExpanded]);


    useEffect(() => {
        setNavButtons([
            // <SearchInputWithButton key="search" onSearch={debouncedSearch} />,
            <SearchInputDebounced key="search" setSearchTerm={setSearchTerm} searchTerm={searchTerm} />,
        ])
        return () => {
            setNavButtons([]);
        }
    }, [setNavButtons, debouncedSearch, setSearchTerm, searchTerm]);


    const editItem = async (parentId: number, title: string, depth: number, original: any) => {
        console.debug('Editing', { parentId, title, depth, original });

        if (depth === 0) {
            const response = await updateBuilding({
                building_id: original.id,
                description: title,
            });
        } else if (depth === 1) {
            const response = await updateLevel({
                level_id: original.id,
                building_id: original.building_id,
                description: title,
            });
        } else {
            const response = await updateArea({
                room_id: original.id,
                description: title
            });
        }

        const editItemInList = (items: ListItem[]): ListItem[] => {
            return items.map((item) => {
                if (item.id === parentId.toString()) {
                    return { ...item, title }
                }
                if (item.children) {
                    return {
                        ...item,
                        children: editItemInList(item.children),
                    }
                }
                return item
            })
        }

        setListData(editItemInList(listData));
    }

    const addChild = async (parentId: number, title: string, depth: number) => {

        const type = (depth === 0 ? "building" : depth === 1 ? "floor" : "room");
        console.debug('Adding new', { parentId, title, depth, type });

        if (type === 'floor') {

            const response = await addLevel({
                asset_id: assetId,
                building_id: parentId,
                description: title
            });
            console.info('response', response);


        } else if (type === 'room') {
            const response = await addArea({
                asset_id: assetId,
                level_id: parentId,
                description: title
            });
            console.info('response', response);
        } else if (type === 'building') {

            const response = await addBuilding({
                asset_id: assetId,
                description: title
            })

        }

        const newId = Date.now().toString();

        const addChildToItem = (items: ListItem[]): ListItem[] => {
            return items.map((item) => {
                if (item.id === parentId.toString()) {
                    return {
                        ...item,
                        original: { id: newId },
                        children: [...(item.children || []), {
                            id: newId,
                            original: { id: newId },
                            title,
                            isOpen: true
                        }],
                    }
                }
                if (item.children) {
                    return {
                        ...item,
                        original: { id: newId },
                        children: addChildToItem(item.children),
                    }
                }
                return item
            })
        }

        setListData(addChildToItem(listData));

    }

    const toggleItem = (id: string) => {
        console.debug('toggle', id, !expandedState[id]);

        setExpandedState((prevState) => ({
            ...prevState,
            [id]: prevState[id] !== undefined ? !prevState[id] : isExpanded === 'expand' ? false : true,

        }));

        // const toggleItemInList = (items: ListItem[]): ListItem[] => {
        //     return items.map((item) => {
        //         if (item.id === id) {
        //             return { ...item, isOpen: !item.isOpen }
        //         }
        //         if (item.children) {
        //             return {
        //                 ...item,
        //                 children: toggleItemInList(item.children),
        //             }
        //         }
        //         return item
        //     })
        // }
        // setListData(toggleItemInList(listData))
    }

    const expandAll = () => {
        setExpandedState({});
        setIsExpanded('expand');
        // const expandAllItems = (items: ListItem[]): ListItem[] => {
        //     return items.map((item) => ({
        //         ...item,
        //         isOpen: true,
        //         children: item.children ? expandAllItems(item.children) : undefined,
        //     }))
        // }
        // setListData(expandAllItems(listData))
    }

    const collapseAll = () => {
        console.info('collapsing');
        setExpandedState({});
        setIsExpanded('collapse');
        // const collapseAllItems = (items: ListItem[]): ListItem[] => {
        //     return items.map((item) => ({
        //         ...item,
        //         isOpen: false,
        //         children: item.children ? collapseAllItems(item.children) : undefined,
        //     }))
        // }
        // setListData(collapseAllItems(listData))
    }

    const [isListStabilized, setIsListStabilized] = useState(false);

    useEffect(() => {
        if (listData && listData.length > 0) {
            setIsListStabilized(true);
            debouncedSearch('');
        }
    }, [listData]);


    useEffect(() => {
        if (!isListStabilized) return;
        if (isExpanded === 'expand') {
            expandAll()
        } else {
            collapseAll()
        }
    }, [isExpanded]);

    return (
        <div className="w-full mx-auto p-4 bg-white rounded-lg shadow">
            <div className="flex justify-end items-center mb-4">
                <div className="space-x-2">
                    <OptionToggle options={[
                        { description: 'Expand All', value: 'expand' as ExpandOrCollapse },
                        { description: 'Collapse All', value: 'collapse' as ExpandOrCollapse }]}
                        value={isExpanded} onChange={(value) => value === 'expand' ? expandAll() : collapseAll()} />
                    <OptionToggle options={[{ description: 'View', value: 'view' }, { description: 'Edit', value: 'edit' }]} value={isEditMode ? 'edit' : 'view'} onChange={(value) => setIsEditMode(value === 'edit')} />
                </div>
            </div>

            {isSearching ? (
                <div className="flex items-center justify-center h-48">
                    <ReloadIcon className="h-8 w-8 animate-spin mr-2" />
                    <Label>Searching...</Label>
                </div>
            ) : (
                <>
                    {searchResults.map((item) => (
                        <NestedListItem
                            key={item.id}
                            item={item}
                            onAddChild={addChild}
                            onEditItem={editItem}
                            onToggle={toggleItem}
                            isEditMode={isEditMode}
                        />
                    ))}

                    {
                        isFiltered && searchResults.length === 0 && (
                            <div className="flex flex-col space-y-6 items-center justify-center h-24">
                                <Label>No spaces found</Label>
                                {/* <Button variant="outline" size="sm" onClick={() => debouncedSearch('')}>Clear Search</Button> */}
                            </div>
                        )
                    }

                    {isEditMode && !isFiltered && searchResults.length !== 0 && (
                        <div className="ml-6">
                            <AddNewLeaf title={`Add Building`} onSubmit={(title) => addChild(0, title, 0)} depth={0} />
                        </div>
                    )}

                    {!isFiltered && searchResults.length === 0 && (
                        <EmptyState

                            title="No spaces"
                            description="Create your first building to get started."
                            customComponent={
                                <div className="pl-10 relative">
                                    <AddNewLeaf title={`Add Building`} onSubmit={(title) => addChild(0, title, 0)} depth={0} />
                                </div>
                            }
                        />
                    )}
                </>
            )}
        </div>
    )
}



export default AssetSpacesPage;