
//import { getGeolocation } from "./utils/get-geolocation";

const API_KEY = import.meta.env.VITE_GOOGLE_PLACES_API_KEY;

export async function getAutocompleteSuggestions(input: string) {
  if (!API_KEY) {
    throw new Error("Missing API Key");
  }

  const url = "https://places.googleapis.com/v1/places:autocomplete";

  const primaryTypes = [
    "street_address",
    "subpremise",
    "route",
    "street_number",
    "landmark",
  ];

  try {
    // Check if your hosting provider gives you the country code
    //const country = await getGeolocation();
    const country = "AU";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Goog-Api-Key": API_KEY,
      },
      body: JSON.stringify({
        input: input,
        includedPrimaryTypes: primaryTypes,
        // Location biased towards the user's country
        includedRegionCodes: [country || "AU"],
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return { data: data.suggestions, error: null };
  } catch (error) {
    console.error("Error fetching autocomplete suggestions:", error);
    return { error: error instanceof Error ? error.message : String(error), data: null };
  }
}