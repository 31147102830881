import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import React from 'react'

type ActiveSelectProps = {
    value: boolean;
    onValueChange: (value: boolean) => void;
    configurable?: boolean;
};

const ActiveSelect: React.FC<ActiveSelectProps> = ({ value, onValueChange, configurable }) => {

    return (
        <Select
            value={value ? 'yes' : 'no'}
            onValueChange={(value) => onValueChange(value === 'yes')}
            disabled={configurable === false ? true : false}
        >
            <SelectTrigger className="w-[70px]">
                <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
                <SelectItem value="yes">Yes</SelectItem>
                <SelectItem value="no">No</SelectItem>
            </SelectContent>
        </Select>
    );
};

export default ActiveSelect;