import { authActions } from './../auth/auth.slice';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import organisationAPI from './api';
import { Addressable } from '../asset';

export const COMPANY_FEATURE_KEY = 'company';

type RoleTypes = 'admin' | 'member' | 'guest';

/*
 * Update these interfaces according to your requirements.
 */
export type CompanyEntity = {
  id: number;
  name: string;
  title: string;
  email?: string;
  role: RoleTypes;
  address?: Addressable;
  phone1?: string;
  phone2?: string;
  website?: string;
  capabilities?: string[];
  instagram?: string;
  facebook?: string;
  twitter?: string;
  linkedin?: string;
  logo_url?: string;
};

export interface CompanyState extends EntityState<CompanyEntity, number> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  error?: string | null;
}

export const companyAdapter = createEntityAdapter<CompanyEntity>();

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchCompany())
 * }, [dispatch]);
 * ```
 */
export const fetchCompany = createAsyncThunk<CompanyEntity[]>(
  'company/fetchStatus',
  async (_, thunkAPI) => {
    /**
     * Replace this with your custom fetch call.
     * For example, `return myApi.getCompanys()`;
     * Right now we just return an empty array.
     */
    return Promise.resolve([]);
  }
);

export const createAndJoinOrganisation = createAsyncThunk<any, any>(
  'company/createAndJoin',
  async (organizationData, { dispatch, rejectWithValue }) => {
    try {
      const createAction = await dispatch(createOrganisation(organizationData));
      if (createOrganisation.fulfilled.match(createAction)) {
        const getUserAction = await dispatch(authActions.getUser());
        if (authActions.getUser.fulfilled.match(getUserAction)) {
          const user = getUserAction.payload;
          console.info(user);
        }
      }
    } catch (err: any) {
      return rejectWithValue(
        err instanceof Error ? err.message : 'Login failed'
      );
    }

    return true;
  }
);

export const createOrganisation = createAsyncThunk<
  CompanyEntity,
  CompanyEntity
>('company/create', async (organizationData, { dispatch, rejectWithValue }) => {
  try {
    const response = await organisationAPI.create(organizationData);
    // Organization created successfully, now you might want to refresh the user profile
    return response.body; // The response should be handled by the reducer
    

  } catch (error: any) {
    if (error?.response?.status === 422) {
      // Validation error
      const data = await error.response.body;
      console.info('validation error', data);
      return rejectWithValue(data);
    }

    return rejectWithValue(error?.message ?? 'Unknown error');
  }
});

export const requestToJoinOrganisation = createAsyncThunk<
  any,
  { email?: string; organisation?: number }
>(
  'company/requestToJoin',
  async ({ email, organisation }, { dispatch, rejectWithValue }) => {
    try {
      const response = await organisationAPI.join({
        email: email,
        organisation_id: organisation,
      });

      if (response.ok) {
        return response.body;
      }

      const errors = await response.body;
      return rejectWithValue(errors);

    } catch (error: any) {
      console.info('error', error);
      return rejectWithValue(error?.response ?? 'Unknown error');
    }
  }
);

export const initialCompanyState: CompanyState = companyAdapter.getInitialState(
  {
    loadingStatus: 'not loaded',
    error: null,
  }
);

export const companySlice = createSlice({
  name: COMPANY_FEATURE_KEY,
  initialState: initialCompanyState,
  reducers: {
    add: companyAdapter.addOne,
    remove: companyAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompany.pending, (state: CompanyState) => {
        state.loadingStatus = 'loading';
      })
      .addCase(
        fetchCompany.fulfilled,
        (state: CompanyState, action: PayloadAction<CompanyEntity[]>) => {
          companyAdapter.setAll(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(fetchCompany.rejected, (state: CompanyState, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(requestToJoinOrganisation.pending, (state) => {
        // Update state to indicate request is in progress
      })
      .addCase(requestToJoinOrganisation.fulfilled, (state, action) => {
        // Update state based on successful response
      })
      .addCase(requestToJoinOrganisation.rejected, (state, action) => {
        // Update state to handle errors
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const companyReducer = companySlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(companyActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const companyActions = companySlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
import APIError from '../api/apiError';
import requestToJoinOrganisation from './api/joinOrganisation';
import { getUser } from '../users/users.slice';
 *
 * // ...
 *
 * const entities = useSelector(selectAllCompany);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = companyAdapter.getSelectors();

export const getCompanyState = (rootState: {
  [COMPANY_FEATURE_KEY]: CompanyState;
}): CompanyState => rootState[COMPANY_FEATURE_KEY];

export const selectAllCompany = createSelector(getCompanyState, selectAll);

export const selectCompanyEntities = createSelector(
  getCompanyState,
  selectEntities
);
