import { MainPage } from "@/app/layout2/AppLayout"
import PageWithTopBarNavigation from "@/app/features/profile/PageWithTopBarNavigation"
import { CreateTestSuiteModal } from "./v2/CreateTestSuiteModal"

const CreateTestSuitePage = () => {
  return (
    <MainPage>
      <PageWithTopBarNavigation>
        <CreateTestSuiteModal />
      </PageWithTopBarNavigation>
    </MainPage>
  )
}

export default CreateTestSuitePage