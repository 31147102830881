import { cn } from "@/lib/utils"
import { TestCountPill } from "./test-count-pill"


interface TestCountsProps {
  testCounts: {
    archived?: number
    draft?: number
    qa?: number
    published?: number
  }
  className?: string
}

export function TestCountPills({ testCounts, className }: TestCountsProps) {
  const counts = [
    { value: testCounts.archived, variant: "archived" as const },
    { value: testCounts.draft, variant: "draft" as const },
    { value: testCounts.qa, variant: "qa" as const },
    { value: testCounts.published, variant: "published" as const },
  ]

  return (
    <div className={cn("flex gap-2", className)}>
      {counts.map((count, index) => (
        <TestCountPill
          key={index}
          variant={count.variant}
          value={count.value}
          className="w-10"
        />
      ))}
    </div>
  )
}