import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "@/components/ui/button"
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import AddressInput from "@/app/features/assets/components/address"
import { Assessment, useCreateInspectionMutation, useGetInspectionQuery, useUpdateInspectionMutation } from "@app.raytd.com/store"
import TestSuiteSelector from "@/components/raytd/test-suite-selector"
import { useCallback, useEffect, useState } from "react"
import { useAuth } from "@/app/_components"
import { useUnsavedChanges } from "@/hooks/useUnsavedChanges"
import { useNav } from "@/app/features/profile/NavContext"
import BusyButton from "@/components/raytd/busy-button"
import { toast } from "sonner"
import SubPageLayout from "@/app/features/organisation/SubPageLayout"
import ElementTreeSelector from "@/components/raytd/element-group-selector"
import { AssetSelector } from "@/components/raytd/asset-selector"
import { id } from "date-fns/locale"
import { useNavigate } from "react-router-dom"

const formSchema = z.object({
    title: z.string().min(2, "Name must be at least 2 characters"),
    testSuiteId: z.array(z.number()).min(1, "Test Suite is required"),
    elementGroupId: z.array(z.number()).optional().nullable(),
    asset: z.object({
        id: z.number({ invalid_type_error: 'Asset is required' }).min(1, "Asset is required"),
    }),
})

type InspectionFormValues = z.infer<typeof formSchema>

interface InspectionFormProps {
    //onSubmit: (data: InspectionFormValues) => void
    inspection?: Partial<Assessment>
}

export function InspectionForm({ inspection }: InspectionFormProps) {

    const navigate = useNavigate();


    const form = useForm<InspectionFormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            title: "",
            testSuiteId: [],
            elementGroupId: [],
            asset: {
                id: undefined,
            },
        },
    });

    const [updateInspection, { isLoading: isUpdating }] = useUpdateInspectionMutation();
    const [createInspection, { isLoading: isCreating }] = useCreateInspectionMutation();

    const [formDisabled, setFormDisabled] = useState(false);
    const { user } = useAuth();

    const isBusy = isUpdating || isCreating;
    const { formState } = form;
    const { isDirty, errors } = formState;

    console.debug({ errors });

    const { AlertDialogComponent } = useUnsavedChanges(isDirty, form.reset, formDisabled);
    const { setNavButtons } = useNav();

    useEffect(() => {

        console.debug('Inspection id', { inspection });
        form.reset({
            title: inspection?.title ?? "",
            testSuiteId: inspection?.testSuiteId ?? [],
            elementGroupId: inspection?.elementGroupId ?? [],
            asset: {
                //@ts-ignore
                id: inspection?.asset?.id ?? undefined,
            },

        });

        //const isAdmin = assessment?.role === 'admin';
        const isAdmin = true;
        setFormDisabled(!isAdmin);

    }, [inspection, form.reset, setFormDisabled]);

    const values = form.getValues();
    console.debug({ values });

    const onSubmit = useCallback(async (data: InspectionFormValues) => {
        try {
            console.log('submit', { data });

            if (inspection.id !== undefined) {

                await updateInspection({
                    id: inspection.id,
                    ...data,
                });

                toast.success('Inspection saved successfully');

            } else {
                const payload = await createInspection({
                    ...data,
                    asset: data.asset.id,
                    organisationId: user.company.id,
                });

                toast.success('Inspection created successfully');

                setFormDisabled(true);

                setTimeout(() => navigate(`/inspections/${payload.data.id}`), 300);

            }

        } catch (error) {
            console.error(error);
            toast.error('Failed to save inspection');
        }

    }, [inspection, form.reset, setFormDisabled]);

    useEffect(() => {
        setNavButtons(
            <BusyButton
                onClick={form.handleSubmit(onSubmit)}
                disabled={!isDirty}
                isBusy={isBusy}
                busyText="Saving..."
            >
                Save
            </BusyButton>
        );

        // Clean up function to reset buttons when component unmounts
        return () => setNavButtons(null);
    }, [
        setNavButtons,
        form.handleSubmit,
        onSubmit,
        isDirty,
        isBusy

    ]);

    return (
        <Form {...form}>
            <form className="space-y-6 max-w-xl">
                <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Name*</FormLabel>
                            <FormControl>
                                <Input placeholder="Enter inspection name..." {...field} />
                            </FormControl>
                        </FormItem>
                    )}
                />

                <AssetSelector name="asset.id" disabled={inspection.id !== undefined} />
                <TestSuiteSelector name="testSuiteId" />
                <ElementTreeSelector name="elementGroupId" />
            </form>
            <AlertDialogComponent />
        </Form>
    )
}

const InspectionDetails = ({ inspectionId }) => {
    const { data: inspection, isLoading } = useGetInspectionQuery(inspectionId);

    console.debug({ inspection });

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <SubPageLayout title="Inspection Details">
            <InspectionForm inspection={inspection} />
        </SubPageLayout>
    )
}

export default InspectionDetails;