"use client"

import BusyButton from "@/components/raytd/busy-button"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { useCreateUserQualificationMutation, useDeleteUserQualificationMutation, useGetUserQualificationsQuery } from "@app.raytd.com/store"
import { zodResolver } from "@hookform/resolvers/zod"
import { PlusIcon, X } from "lucide-react"
import { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "sonner"
import * as z from "zod"

type Qualification = {
    id: number;
    title: string;
    issued_by: string;
}

const qualificationSchema = z.object({
    title: z.string().min(2, {
        message: "Title must be at least 2 characters.",
    }),
    issued_by: z.string().min(1, {
        message: "Issuer cannot be empty",
    }),
})

type QualificationFormValues = z.infer<typeof qualificationSchema>

const EmptyQualifications = () => (
    <div className="p-4 text-center text-gray-500">
        <p>No qualifications added</p>
    </div>
)

type QualificationTileProps = {
    qualification: Qualification
    onRemove: (qualification: Qualification) => void
}

const QualificationTile = ({ qualification, onRemove }: QualificationTileProps) => (
    <div className="flex items-center justify-between p-4 bg-gray-100 rounded-lg">
        <div>
            <p className="font-medium">{qualification.title}</p>
            <p className="text-sm text-gray-600">{qualification.issued_by}</p>
        </div>
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button variant="ghost" size="icon">
                    <X className="h-4 w-4" />
                    <span className="sr-only">Remove qualification</span>
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete the qualification.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={() => onRemove(qualification)}>Remove</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    </div>
)

const AddQualificationDialog = ({ onAdd }: { onAdd: (qualification: Omit<Qualification, "id">) => void }) => {
    const [open, setOpen] = useState(false)

    const form = useForm<QualificationFormValues>({
        resolver: zodResolver(qualificationSchema),
        defaultValues: {
            title: "",
            issued_by: "",
        },
    })

    function onSubmit(values: QualificationFormValues) {
        // @ts-ignore
        onAdd(values)
        form.reset()
        setOpen(false)
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="ghost">
                    <PlusIcon className="mr-2 h-4 w-4" />
                    Add Qualification
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Add Qualification</DialogTitle>
                    <DialogDescription>
                        Enter the details of your new professional qualification here.
                    </DialogDescription>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <FormField
                            control={form.control}
                            name="title"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Title</FormLabel>
                                    <FormControl>
                                        <Input placeholder="e.g. Registered Architect #1111" {...field} />
                                    </FormControl>
                                    <FormDescription>
                                        The title of your qualification.
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="issued_by"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Issued By</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Board of Architects Queensland" {...field} />
                                    </FormControl>
                                    <FormDescription>
                                        The organisation that issued this qualification.
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter>
                            <Button type="button" variant="ghost" onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <BusyButton type="submit" isBusy={form.formState.isSubmitting}>
                                Add Qualification
                            </BusyButton>

                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default function Qualifications({}: { }) {
    //const [qualifications, setQualifications] = useState<Qualification[]>([])

    const { data: qualifications, isLoading, isFetching } = useGetUserQualificationsQuery({});
    const [removeQualication, {}] = useDeleteUserQualificationMutation();
    const [createQualification, {}] = useCreateUserQualificationMutation();

    console.debug("Qualifications", qualifications);


    const handleRemove = useCallback(async (qualification: Qualification) => {
        try {
            const response = await removeQualication(qualification.id).unwrap();
            toast.success("Qualification removed");
        } catch (error) {
            console.error(error);
            toast.error("Failed to remove qualification");
        }

    }, []);

    const handleAdd = useCallback(async (newQualification: Omit<Qualification, "id">) => {
        try {
            const response = await createQualification(newQualification).unwrap();
            toast.success("Qualification added");
        } catch (error) {
            console.error(error);
            toast.error("Failed to add qualification");
        }
    }, []);

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h3 className="text-lg font-medium">Professional Qualifications</h3>
                <AddQualificationDialog onAdd={handleAdd} />
            </div>
            <Card>
                <div className="space-y-1">

                    {isLoading && <div>Loading...</div>}

                    {!isLoading && qualifications?.length > 0 ? (
                        qualifications.map((q) => (
                            <QualificationTile key={q.id} qualification={q} onRemove={handleRemove} />
                        ))
                    ) : (
                        <EmptyQualifications />
                    )}
                </div>
            </Card>
        </div>
    )
}