import React from 'react';
import { Button, ButtonProps } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { ChevronDown, ChevronRight, ChevronsDown, ChevronsRight, Ellipsis } from 'lucide-react';

type ListButtonProps = {
    variant: 'ghost' | 'ellipsis' | 'expand-item-right' | 'expand-item-down' | 'expand-right' | 'expand-down';
    border?: boolean;
    className?: string;
    onClick?: () => void;
    size?: ButtonProps['size'];
}

const ListButton: React.FC<ListButtonProps> = ({ variant, className, border = true, size = 'sm', onClick }) => {
    return (
        <Button
            variant="ghost"
            className={cn("p-2", 
                    { 
                        'border border-zinc-300': border ,
                        'bg-zinc-200': variant.includes('down')

                    }, 
                    className)}
            onClick={onClick}
            size={size}
        >
            {variant === 'ellipsis' && <Ellipsis className="h-4 w-4 text-zinc-500" />}
            {variant === 'expand-item-right' && <ChevronsRight className="h-4 w-4 text-zinc-500" />}
            {variant === 'expand-item-down' && <ChevronsDown className="h-4 w-4 text-zinc-500" />}
            {variant === 'expand-right' && <ChevronRight className="h-4 w-4 text-zinc-500" />}
            {variant === 'expand-down' && <ChevronDown className="h-4 w-4 text-zinc-500" />}
        </Button>
    );
};

export default ListButton;