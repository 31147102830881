import { Floor } from '../types';
import { api } from '../../api/fetch';

export const addFloor = async (floor: Partial<Floor>) => {

  return await api.post(
    `api/v1/levels`,
    floor
  );
};

export const updateFloor = async (floor: Partial<Floor>) => {
  return await api.put(
    `api/v1/levels/${floor.id}`,
    floor
  );
};

export default addFloor;