"use client"

import * as React from "react"
import { X } from 'lucide-react'
import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { ScrollArea } from "@/components/ui/scroll-area"
import { cn } from "@/lib/utils"
import { Badge } from "@/components/ui/badge"
import { useFormContext, Controller } from "react-hook-form"
import { useGetTestSuitesQuery } from "@app.raytd.com/store"
import { FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Skeleton } from "@/components/ui/skeleton"

interface TestSuite {
    id: string
    title: string
    type: 'compliance' | 'condition' | 'generic'
}

interface TestSuiteSelectorProps {
    name: string
    label?: string
}

export function TestSuiteSelector({ name, label = "Test Suites" }: TestSuiteSelectorProps) {
    const [open, setOpen] = React.useState(false)
    const [search, setSearch] = React.useState("")
    const { data: testSuites = [], isLoading } = useGetTestSuitesQuery({})
    const form = useFormContext()

    const filteredSuites = React.useMemo(() => {
        return testSuites.filter((suite) =>
            suite.title.toLowerCase().includes(search.toLowerCase()) && suite.retired === false
        );
    }, [testSuites, search]);
    
    const getTypeStyles = (type: TestSuite['type']) => {
        switch (type) {
            case 'compliance':
                return 'bg-green-500 text-white hover:text-green-500 hover:border-green-500 hover:bg-white'
            case 'condition':
                return 'bg-sky-500 text-white border-transparent hover:bg-white hover:text-sky-500 hover:border-sky-500 '
            default:
                return 'text-white bg-zinc-500 border-zinc-500 hover:text-zinc-500 hover:bg-white hover:border-zinc-500'
        }
    }

    return (
        <FormField

            name={name}
            control={form.control}
            defaultValue={[]}
            render={({ field }) => (
                <FormItem>
                    <div className="space-y-2">
                        <div className="flex justify-between items-center">
                            <FormLabel>{label}</FormLabel>
                            <Button
                                type="button"
                                variant="link"
                                size="default"
                                onClick={() => setOpen(true)}
                            >
                                + Add Test Suite
                            </Button>
                        </div>

                        {
                            isLoading && (
                                <div className="h-20 flex flex-col w-full gap-4">
                                    <Skeleton className="h-8 w-24" />
                                    <Skeleton className="h-8 w-40" />
                                    <Skeleton className="h-8 w-32" />
                                </div>
                            )

                        }


                        {
                            !isLoading && (

                                <div className="flex flex-col items-start gap-2 bg-zinc-100 p-2 rounded-lg">
                                    {field.value.map((id: string) => {
                                        const suite = testSuites.find((s) => s.id === id)
                                        if (!suite) return null

                                        return (
                                            <Badge
                                                key={suite.id}
                                                variant="secondary"
                                                className={cn(
                                                    "px-3 py-1 text-sm",
                                                    getTypeStyles(suite.type)
                                                )}
                                            >
                                                {suite.title}
                                                <button
                                                    type="button"
                                                    className="ml-2 hover:text-foreground/80"
                                                    onClick={() => {
                                                        field.onChange(field.value.filter((i: string) => i !== suite.id))
                                                    }}
                                                >
                                                    <X className="h-3 w-3" />
                                                </button>
                                            </Badge>
                                        )

                                    })}

                                    {
                                        field.value.length === 0 && (
                                            <span className="text-muted-foreground text-sm">No test suites assigned</span>
                                        )
                                    }

                                </div>
                            )}
                        <FormMessage />

                        <Dialog open={open} onOpenChange={setOpen}>
                            <DialogContent className="max-w-2xl">
                                <DialogHeader>
                                    <DialogTitle>Select Test Suites</DialogTitle>
                                </DialogHeader>
                                <div className="space-y-4 py-4">
                                    <Input
                                        placeholder="Search test suites..."
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        className="w-full"
                                    />
                                    <ScrollArea className="h-[300px] rounded-md border p-4">
                                        <div className="space-y-2 flex flex-col">
                                            {filteredSuites.map((suite) => (
                                                <div
                                                    key={suite.id}
                                                    className={cn(
                                                        "flex flex-wrap items-center justify-between rounded-lg p-2 cursor-pointer transition-colors",
                                                        getTypeStyles(suite.type),
                                                        field.value.includes(suite.id) && "opacity-50"
                                                    )}
                                                    onClick={() => {
                                                        if (!field.value.includes(suite.id)) {
                                                            field.onChange([...field.value, suite.id])
                                                        }
                                                    }}
                                                >
                                                    <span>{suite.title}</span>
                                                    {field.value.includes(suite.id) && (
                                                        <X className="h-4 w-4" />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </ScrollArea>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                </FormItem>
            )}
        />

    )
}

export default TestSuiteSelector;