import { TestSpecificationsProvider } from './specifications/Context';
import NestedList from "./specifications/NestedList";
import { useParams } from 'react-router-dom';


const TestSpecificationsPage = () => {


    const { testSuiteId } = useParams<{ testSuiteId: string }>();

    return (
        <TestSpecificationsProvider testSuiteId={testSuiteId}>
            <NestedList />
        </TestSpecificationsProvider>
    )

}

export default TestSpecificationsPage;