import { Fragment, useState } from 'react'
import { ChevronRight, ChevronDown } from 'lucide-react'
import { Test } from './Test'
import { TestSpecificationsEntity } from '@app.raytd.com/store'
import { Pill } from '@/components/raytd/pill'
import { ValuePill } from '@/components/raytd/value-pill'

type SubCategoryProps = {
  subCategory: string
  tests: TestSpecificationsEntity[]
}

export function SubCategory({ subCategory, tests }: SubCategoryProps) {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <div className="mb-4">
      <div
        className="flex items-center cursor-pointer space-x-2 border-b pb-2"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <ChevronDown className="w-4 h-4 mr-2" /> : <ChevronRight className="w-4 h-4 mr-2" />}
        <Pill bold>{subCategory}</Pill>
        <ValuePill value={tests.length} label={"Tests"} variant='inverted' />
          
        
      </div>
      {isExpanded && (
        <div className="ml-6 mt-2">
          {tests.map((test, index) => (
        <Fragment key={index}>
        <Test test={test} />
        {index < tests.length - 1 && <hr className="my-2 border-t border-gray-300" />}
      </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}