import { api } from '../../api/fetch';

export const addAsset = async (asset: any) => {
  //rename the key address to physical_address
  asset.physical_address = asset.address;
  delete asset.address;

  return await api.post(
    `/api/v1/organisations/${asset.organisation}/assets`,
    asset
  );
};

export const updateStatus = async (id: number, status) => {
  return await api.patch(
    `/api/v1/asset/${id}/archive`,
    { status }
  );
}

export default addAsset;
