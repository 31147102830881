import { MainPagewithColumns } from "@/app/layout2/AppLayout"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Card, CardContent } from "@/components/ui/card";
import { authActions, useAppDispatch } from "@app.raytd.com/store";
import { X } from "lucide-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageColumn = ({ children }) => {
    return (
        <div className="flex flex-col gap-4 md:gap-6">
            {children}
        </div>
    )
}

const DashboardPage = () => {

    const dispatch = useAppDispatch();
    const location = useLocation();
    // whne users go to dashboard reload the getUsers query
    useEffect(() => {
        if (location.pathname === '/') {
          dispatch(authActions.getUser());
        }
      }, [dispatch, location.pathname]);

    return (
        <MainPagewithColumns>
            <PageColumn>
                <Card className="p-6 h-48">
                    <h1 className="text-2xl font-bold text-zinc-800">Inspections</h1>
                    <p className="text-zinc-600">Welcome to the dashboard</p>
                </Card>

                <Card className="p-6 h-56">
                    <h1 className="text-2xl font-bold text-zinc-800">Reports</h1>
                    <p className="text-zinc-600">Reports to go here</p>
                </Card>

            </PageColumn>
            <PageColumn>
                <Card className="p-6 h-96">
                    <h1 className="text-2xl font-bold text-zinc-800 mb-6">Notifications</h1>

                    <div className="flex-col gap-4">

                        <div className="border border-zinc-200 flex flex-col p-4 rounded-md">

                            <div className='flex flex-row items-center flex-1'>

                                <div className="w-14">
                                    <Avatar>
                                        <AvatarImage src="https://randomuser.me/api/portraits" alt="avatar" />
                                        <AvatarFallback>U</AvatarFallback>
                                    </Avatar>
                                </div>

                                <div className="flex-1 text-sm font-medium">
                                    Raytd
                                </div>

                                <div className="text-sm text-zinc-500 mr-2">
                                    2 days ago
                                </div>

                                <div>
                                    <X className="text-zinc-400" />
                                </div>

                            </div>



                            <div className="flex-1 ml-14 text-sm pr-8">

                                Important actions to finish setting up your account.
                                <p>1. Complete your profile.</p>
                                <p>2. Add a profile photo.</p>
                                <p>✋ High five!</p>

                            </div>


                        </div>

                    </div>

                </Card>
            </PageColumn>

        </MainPagewithColumns>
    )

}

export default DashboardPage;