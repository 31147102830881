// Define the role hierarchy
const roleHierarchy = ['guest', 'member', 'admin'] as const;

type Role = typeof roleHierarchy[number];

/**
 * Determines if the userRole is greater than or equal to the given role.
 * @param userRole - The role of the user.
 * @param role - The role to compare against.
 * @returns True if userRole is greater than or equal to the given role, false otherwise.
 */
export function isRoleGreaterThanOrEqual(userRole: Role, role: Role): boolean {
  const userRoleIndex = roleHierarchy.indexOf(userRole.toLocaleLowerCase() as Role);
  const roleIndex = roleHierarchy.indexOf(role.toLocaleLowerCase() as Role);
  return userRoleIndex >= roleIndex;
}

/**
 * Determines if the userRole is less than the given role.
 * @param userRole - The role of the user.
 * @param role - The role to compare against.
 * @returns True if userRole is less than the given role, false otherwise.
 */
export function isRoleLessThan(userRole: Role, role: Role): boolean {
    const userRoleIndex = roleHierarchy.indexOf(userRole.toLocaleLowerCase() as Role);
    const roleIndex = roleHierarchy.indexOf(role.toLocaleLowerCase() as Role);
    return userRoleIndex < roleIndex;
  }