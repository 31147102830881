
type AssetType = {
    id: number
    category: string
    type: string
}

const assetTypes: AssetType[] = [
    { "id": 1, "category": "Residential", "type": "House" },
    { "id": 2, "category": "Residential", "type": "Duplex" },
    { "id": 3, "category": "Residential", "type": "Townhouse" },
    { "id": 4, "category": "Residential", "type": "Apartment" },
    { "id": 5, "category": "Residential", "type": "Apartment Building" },
    { "id": 6, "category": "Residential", "type": "Mobile Home" },
    { "id": 7, "category": "Residential", "type": "Modular House" },
    { "id": 8, "category": "Residential", "type": "Dormitory" },
    { "id": 9, "category": "Residential", "type": "Housing Estate" },
    { "id": 10, "category": "Residential", "type": "Retirement Home" },
    { "id": 11, "category": "Residential", "type": "Student Accommodation" },
    { "id": 12, "category": "Residential", "type": "Other" },
    { "id": 13, "category": "Commercial", "type": "Office Building" },
    { "id": 14, "category": "Commercial", "type": "Office Tenancy" },
    { "id": 15, "category": "Commercial", "type": "Retail Building" },
    { "id": 16, "category": "Commercial", "type": "Retail Tenancy" },
    { "id": 17, "category": "Commercial", "type": "Hotel" },
    { "id": 18, "category": "Commercial", "type": "Motel" },
    { "id": 19, "category": "Commercial", "type": "Resort" },
    { "id": 20, "category": "Commercial", "type": "Bed & Breakfast" },
    { "id": 21, "category": "Commercial", "type": "Restaurant" },
    { "id": 22, "category": "Commercial", "type": "Warehouse" },
    { "id": 23, "category": "Commercial", "type": "Office Tower" },
    { "id": 24, "category": "Commercial", "type": "Co-Working Space" },
    { "id": 25, "category": "Commercial", "type": "Business Park" },
    { "id": 26, "category": "Commercial", "type": "Shopping Centre" },
    { "id": 27, "category": "Commercial", "type": "Mall" },
    { "id": 28, "category": "Commercial", "type": "Conference Centre" },
    { "id": 29, "category": "Commercial", "type": "Exhibition Centre" },
    { "id": 30, "category": "Commercial", "type": "Food Court" },
    { "id": 31, "category": "Commercial", "type": "Pub" },
    { "id": 32, "category": "Commercial", "type": "Nightclub" },
    { "id": 33, "category": "Commercial", "type": "Bar" },
    { "id": 34, "category": "Commercial", "type": "Bank" },
    { "id": 35, "category": "Commercial", "type": "Credit Union" },
    { "id": 36, "category": "Commercial", "type": "Car Yard" },
    { "id": 37, "category": "Commercial", "type": "Data Centre" },
    { "id": 38, "category": "Commercial", "type": "Other" },
    { "id": 39, "category": "Industrial", "type": "Factory" },
    { "id": 40, "category": "Industrial", "type": "Distribution Centre" },
    { "id": 41, "category": "Industrial", "type": "Refineries" },
    { "id": 42, "category": "Industrial", "type": "Storage Facility" },
    { "id": 43, "category": "Industrial", "type": "Food Processing Facility" },
    { "id": 44, "category": "Industrial", "type": "Cold Storage Warehouse" },
    { "id": 45, "category": "Industrial", "type": "Warehouse" },
    { "id": 46, "category": "Industrial", "type": "Fulfilment Centre" },
    { "id": 47, "category": "Industrial", "type": "Freight Terminal" },
    { "id": 48, "category": "Industrial", "type": "Depot" },
    { "id": 49, "category": "Industrial", "type": "Laboratory" },
    { "id": 50, "category": "Industrial", "type": "Timber Mill" },
    { "id": 51, "category": "Industrial", "type": "Steel Mill" },
    { "id": 52, "category": "Industrial", "type": "Light Industrial" },
    { "id": 53, "category": "Industrial", "type": "Printer" },
    { "id": 54, "category": "Industrial", "type": "Recycling Centre" },
    { "id": 55, "category": "Industrial", "type": "Waste Processing Centre" },
    { "id": 56, "category": "Industrial", "type": "Landfill" },
    { "id": 57, "category": "Industrial", "type": "Hardstand" },
    { "id": 58, "category": "Industrial", "type": "Other" },
    { "id": 59, "category": "Institutional", "type": "Primary School" },
    { "id": 60, "category": "Institutional", "type": "Middle School" },
    { "id": 61, "category": "Institutional", "type": "High School" },
    { "id": 62, "category": "Institutional", "type": "School" },
    { "id": 63, "category": "Institutional", "type": "University" },
    { "id": 64, "category": "Institutional", "type": "Technical College" },
    { "id": 65, "category": "Institutional", "type": "Library" },
    { "id": 66, "category": "Institutional", "type": "Correctional Facility" },
    { "id": 67, "category": "Institutional", "type": "Secondary School" },
    { "id": 68, "category": "Institutional", "type": "Community College" },
    { "id": 69, "category": "Institutional", "type": "Vocational College" },
    { "id": 70, "category": "Institutional", "type": "Technical School" },
    { "id": 71, "category": "Institutional", "type": "Vocational School" },
    { "id": 72, "category": "Institutional", "type": "Research Institute" },
    { "id": 73, "category": "Institutional", "type": "Other" },
    { "id": 74, "category": "Community", "type": "Museum" },
    { "id": 75, "category": "Community", "type": "Art Gallery" },
    { "id": 76, "category": "Community", "type": "Theatre" },
    { "id": 77, "category": "Community", "type": "Amusement Park" },
    { "id": 78, "category": "Community", "type": "Community Centre" },
    { "id": 79, "category": "Community", "type": "Public Toilets" },
    { "id": 80, "category": "Community", "type": "Movie Theatre" },
    { "id": 81, "category": "Community", "type": "Casino" },
    { "id": 82, "category": "Community", "type": "Seniors Centre" },
    { "id": 83, "category": "Community", "type": "Youth Centre" },
    { "id": 84, "category": "Community", "type": "Cultural Centre" },
    { "id": 85, "category": "Community", "type": "Skating Rink" },
    { "id": 86, "category": "Community", "type": "Mobile Library" },
    { "id": 87, "category": "Community", "type": "Community Library" },
    { "id": 88, "category": "Community", "type": "Public Park" },
    { "id": 89, "category": "Community", "type": "Parking" },
    { "id": 90, "category": "Community", "type": "Other" },
    { "id": 91, "category": "Sports", "type": "Arena" },
    { "id": 92, "category": "Sports", "type": "Golf Course" },
    { "id": 93, "category": "Sports", "type": "Driving Range" },
    { "id": 94, "category": "Sports", "type": "Playing Courts" },
    { "id": 95, "category": "Sports", "type": "Playing Fields" },
    { "id": 96, "category": "Sports", "type": "Bowling Alley" },
    { "id": 97, "category": "Sports", "type": "Stadium" },
    { "id": 98, "category": "Sports", "type": "Fitness Centre" },
    { "id": 99, "category": "Sports", "type": "Gymnasium" },
    { "id": 100, "category": "Sports", "type": "Swimming Pool" },
    { "id": 101, "category": "Sports", "type": "Sports Complex" },
    { "id": 102, "category": "Sports", "type": "Running Track" },
    { "id": 103, "category": "Sports", "type": "Indoor Running Track" },
    { "id": 104, "category": "Sports", "type": "Athletics Field" },
    { "id": 105, "category": "Sports", "type": "Ice Skating Rink" },
    { "id": 106, "category": "Sports", "type": "Equestrian Facilities" },
    { "id": 107, "category": "Sports", "type": "Velodrome" },
    { "id": 108, "category": "Sports", "type": "Cycling Track" },
    { "id": 109, "category": "Sports", "type": "Mountain Bike Trail" },
    { "id": 110, "category": "Sports", "type": "Shooting Range" },
    { "id": 111, "category": "Sports", "type": "Archery Range" },
    { "id": 112, "category": "Sports", "type": "Canoe Course" },
    { "id": 113, "category": "Sports", "type": "Rock Climbing Facility" },
    { "id": 114, "category": "Sports", "type": "Ski Resort" },
    { "id": 115, "category": "Sports", "type": "Motorsport Facility" },
    { "id": 116, "category": "Sports", "type": "Sports Field" },
    { "id": 117, "category": "Sports", "type": "Other" },
    { "id": 118, "category": "Religious", "type": "Church" },
    { "id": 119, "category": "Religious", "type": "Temple" },
    { "id": 120, "category": "Religious", "type": "Mosque" },
    { "id": 121, "category": "Religious", "type": "Synagogue" },
    { "id": 122, "category": "Religious", "type": "Place of worship" },
    { "id": 123, "category": "Religious", "type": "Chapel" },
    { "id": 124, "category": "Religious", "type": "Monastery" },
    { "id": 125, "category": "Religious", "type": "Convent" },
    { "id": 126, "category": "Religious", "type": "Other" },
    { "id": 127, "category": "Agricultural", "type": "Barns" },
    { "id": 128, "category": "Agricultural", "type": "Greenhouses" },
    { "id": 129, "category": "Agricultural", "type": "Silos" },
    { "id": 130, "category": "Agricultural", "type": "Stables" },
    { "id": 131, "category": "Agricultural", "type": "Tank" },
    { "id": 132, "category": "Agricultural", "type": "Sheds" },
    { "id": 133, "category": "Agricultural", "type": "Hardstand" },
    { "id": 134, "category": "Agricultural", "type": "Other" },
    { "id": 135, "category": "Mixed-Use", "type": "Mixed-Use Building" },
    { "id": 136, "category": "Mixed-Use", "type": "Other" },
    { "id": 137, "category": "Transportation", "type": "Airport" },
    { "id": 138, "category": "Transportation", "type": "Train Station" },
    { "id": 139, "category": "Transportation", "type": "Bus Station" },
    { "id": 140, "category": "Transportation", "type": "Bus Stop" },
    { "id": 141, "category": "Transportation", "type": "Ferry Terminal" },
    { "id": 142, "category": "Transportation", "type": "Hangar" },
    { "id": 143, "category": "Transportation", "type": "Rail Yards" },
    { "id": 144, "category": "Transportation", "type": "Aviation Facility" },
    { "id": 145, "category": "Transportation", "type": "Port" },
    { "id": 146, "category": "Transportation", "type": "Dockyards" },
    { "id": 147, "category": "Transportation", "type": "Shipping Container Yards" },
    { "id": 148, "category": "Transportation", "type": "Other" },
    { "id": 149, "category": "Utilities", "type": "Renewable Energy Plant" },
    { "id": 150, "category": "Utilities", "type": "Water Treatment Plant" },
    { "id": 151, "category": "Utilities", "type": "Sewage Treatment Facility" },
    { "id": 152, "category": "Utilities", "type": "Electrical Substation" },
    { "id": 153, "category": "Utilities", "type": "Communication Tower" },
    { "id": 154, "category": "Utilities", "type": "Power Plant" },
    { "id": 155, "category": "Utilities", "type": "Telecommunications Exchange" },
    { "id": 156, "category": "Utilities", "type": "Other" },
    { "id": 157, "category": "Civic", "type": "City Hall" },
    { "id": 158, "category": "Civic", "type": "Community Hall" },
    { "id": 159, "category": "Civic", "type": "Law Courts" },
    { "id": 160, "category": "Civic", "type": "Fire Station" },
    { "id": 161, "category": "Civic", "type": "Police Station" },
    { "id": 162, "category": "Civic", "type": "Post Office" },
    { "id": 163, "category": "Civic", "type": "Correctional Facility" },
    { "id": 164, "category": "Civic", "type": "Ambulance Station" },
    { "id": 165, "category": "Civic", "type": "Legislative Buildings" },
    { "id": 166, "category": "Civic", "type": "Embassy" },
    { "id": 167, "category": "Civic", "type": "Consulate" },
    { "id": 168, "category": "Civic", "type": "Prison" },
    { "id": 169, "category": "Civic", "type": "Jail" },
    { "id": 170, "category": "Civic", "type": "Youth Detention Centre" },
    { "id": 171, "category": "Civic", "type": "Juvenile Detention Centre" },
    { "id": 172, "category": "Civic", "type": "Training Facility" },
    { "id": 173, "category": "Civic", "type": "Other" },
    { "id": 174, "category": "Health", "type": "Medical Centre" },
    { "id": 175, "category": "Health", "type": "Hospital" },
    { "id": 176, "category": "Health", "type": "Mental Health Facility" },
    { "id": 177, "category": "Health", "type": "Nursing Home" },
    { "id": 178, "category": "Health", "type": "Assisted Living Facility" },
    { "id": 179, "category": "Health", "type": "Rehabilitation Facility" },
    { "id": 180, "category": "Health", "type": "Hospice" },
    { "id": 181, "category": "Health", "type": "Medical Research Facility" },
    { "id": 182, "category": "Health", "type": "Day Hospital" },
    { "id": 183, "category": "Health", "type": "Operating Theatre" },
    { "id": 184, "category": "Health", "type": "Independent Living Facility" },
    { "id": 185, "category": "Health", "type": "Retirement Community" },
    { "id": 186, "category": "Health", "type": "Other" },
    { "id": 187, "category": "Mining", "type": "Open Cut Mine" },
    { "id": 188, "category": "Mining", "type": "Underground Mine" },
    { "id": 189, "category": "Mining", "type": "Quarry" },
    { "id": 190, "category": "Mining", "type": "Oil Extraction Site" },
    { "id": 191, "category": "Mining", "type": "Gas Extraction Site" },
    { "id": 192, "category": "Mining", "type": "Processing Facility" },
    { "id": 193, "category": "Mining", "type": "Other" },
    { "id": 194, "category": "Aerospace and Defence", "type": "Base" },
    { "id": 195, "category": "Aerospace and Defence", "type": "Training Facility" },
    { "id": 196, "category": "Aerospace and Defence", "type": "Testing Centre" },
    { "id": 197, "category": "Aerospace and Defence", "type": "Military Industrial Complex" },
    { "id": 198, "category": "Aerospace and Defence", "type": "Storage Yard" },
    { "id": 199, "category": "Aerospace and Defence", "type": "Range" },
    { "id": 200, "category": "Aerospace and Defence", "type": "Launch Pad" },
    { "id": 201, "category": "Aerospace and Defence", "type": "Mission Control Centre" },
    { "id": 202, "category": "Aerospace and Defence", "type": "Other" }
];

export default assetTypes;