import React from 'react';
import { cn } from "@/lib/utils";
import { CardTitle } from "@/components/ui/card";

const SignupCardTitle = React.forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...props }, ref) => {
      return (
        <CardTitle
          ref={ref}
          className={cn("text-2xl text-center", className)}
          {...props}
        />
      );
    }
  );
  
  SignupCardTitle.displayName = "SignupCardTitle";
  
  export default SignupCardTitle;