import { CompanyEntity } from '../company.slice';
import { api } from '../../api/fetch';

export const createOrganisation = async (company: CompanyEntity) => {
  return await api.post('/api/v1/organisations/join', {
    title: company.name,
    ...company
  });

};

export default createOrganisation;
