import {
  EntityState,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { Building } from './types';

export const BUILDINGS_FEATURE_KEY = 'buildings';

const buildingsAdapter = createEntityAdapter<Building>();

export interface BuildingsState extends EntityState<Building, number> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  error?: string | null;
}

export const initialBuildingsState: BuildingsState =
  buildingsAdapter.getInitialState({
    loadingStatus: 'not loaded',
    error: null,
  });

export const buildingsSlice = createSlice({
  name: BUILDINGS_FEATURE_KEY,
  initialState: initialBuildingsState,
  reducers: {
    add: buildingsAdapter.addOne,
    remove: buildingsAdapter.removeOne,
    updateBuilding(state, action) {
      buildingsAdapter.upsertOne(state, action.payload);
    },
    updateBuildings(state, action) {
      console.info('buildings received', action.payload);
      buildingsAdapter.upsertMany(state, action.payload);
      state.loadingStatus = 'loaded';
    },
  },
  extraReducers: (builder) => {
    // builders
  },
  // Reducers for CRUD operations on buildings
});

export const buildingsReducer = buildingsSlice.reducer;
export const buildingsActions = {
  ...buildingsSlice.actions,
};

export const { selectAll, selectEntities, selectById } =
  buildingsAdapter.getSelectors();

export const getBuildingState = (rootState: {
  [BUILDINGS_FEATURE_KEY]: BuildingsState;
}): BuildingsState => {
  console.log('Buildings state', rootState); // Add this line
  return rootState[BUILDINGS_FEATURE_KEY];
};

export const selectAllBuildings = createSelector(getBuildingState, selectAll);

export const selectBuildingEntities = createSelector(
  getBuildingState,
  selectEntities
);

export const selectBuildings = (state: any) => state.buildings;

export const selectBuildingLoadingStatus = createSelector(
  getBuildingState,
  (state: BuildingsState) => state.loadingStatus
);

export const selectBuildingsByAssetId = createSelector(
  [selectAllBuildings, (_, assetId: number) => assetId],
  (buildings, assetId) =>
    buildings.filter((building) => building.asset_id === assetId)
);
