import { useState, useEffect } from 'react'
import { Check, ChevronsUpDown } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { allCountries } from 'country-telephone-data'

interface CountryOption {
  value: string
  label: string
}

interface CountryDropdownProps {
  onSelect: (country: CountryOption) => void
  defaultValue?: string
}

export default function CountryDropdown({ onSelect, defaultValue }: CountryDropdownProps) {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(defaultValue || "")

  const countries: CountryOption[] = allCountries.map(country => ({
    value: country.name,
    label: country.name
  }))

  useEffect(() => {
    if (defaultValue) {
      const defaultCountry = countries.find(country => country.value === defaultValue)
      if (defaultCountry) {
        setValue(defaultCountry.value)
        onSelect(defaultCountry)
      }
    }
  }, [defaultValue, onSelect])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {value
            ? countries.find((country) => country.value === value)?.label
            : "Select country..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput placeholder="Search country..." />
          <CommandList>
            <CommandEmpty>No country found.</CommandEmpty>
            <CommandGroup className="max-h-64 overflow-y-auto">
              {countries.map((country) => (
                <CommandItem
                  key={country.value}
                  onSelect={() => {
                    setValue(country.value)
                    setOpen(false)
                    onSelect(country)
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === country.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {country.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}