import { Button } from "@/components/ui/button"

interface EmptyStateProps {
    title: string
    description: string
    actionLabel?: string
    onAction?: () => void
    customComponent?: React.ReactNode;
}
const EmptyState: React.FC<EmptyStateProps> = ({
    title = "You have no items",
    description = "Create your first item to get started.",
    actionLabel = "Create Item",
    onAction,
    customComponent
}) => {
    return (
        <div className="w-full min-h-[500px] border-2 border-dashed border-gray-200 dark:border-gray-800 rounded-lg flex flex-col items-center justify-center p-6">
            <div className="max-w-md text-center space-y-4">
                <h2 className="text-2xl font-semibold">{title}</h2>
                <p className="text-muted-foreground">{description}</p>
                {
                    onAction && (
                        <Button onClick={onAction}>
                            {actionLabel}
                        </Button>

                    )
                }
                {
                    customComponent && customComponent
                }
            </div>
        </div>
    )
}

export default EmptyState;