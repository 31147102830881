import { Badge } from "@wix/design-system";
import { memo } from "react";

const UnsavedChangesBadge = memo(({ isDirty }: { isDirty: boolean }) => {
    if (!isDirty) {
        return null;
    }
    return (
        <Badge size="small" skin="warning">
            Unsaved changes
        </Badge>
    );
});

export default UnsavedChangesBadge;