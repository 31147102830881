import AssetSpacesPage from "@/app/features/assets/AssetSpacesPage";
import SubPageLayout from "@/app/features/organisation/SubPageLayout";
import PageWithTopBarNavigation from "@/app/features/profile/PageWithTopBarNavigation";
import { TopNavBar, NavLink } from "@/app/features/profile/TopNavBar";
import { MainPage } from "@/app/layout2/AppLayout";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import Details from './Details';
import { useGetAssetQuery, useGetElementGroupQuery } from "@app.raytd.com/store";
import { useEffect } from "react";
import { useBreadcrumb } from "@/app/layout2/BreadcrumbContext";
import AssetCollaboratorsPage from "@/app/features/assets/AssetCollaboratorPage";
import ElementCollaboratorsPage from "@/app/features/elements/Collaborators";
import ElementEditorPage from "@/app/features/elements/ElementEditor";

const ElementPageLayout = () => {
    const { setBreadcrumbName } = useBreadcrumb();
    const { elementId } = useParams<{ elementId: string }>();
    const { data: element } = useGetElementGroupQuery(elementId);

    useEffect(() => {
        if (element) {
            setBreadcrumbName(`/elements/${elementId}`, element?.name);
        }
    }, [element]);

    console.debug({assetId: elementId});

    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    <NavLink to="details">
                        Details
                    </NavLink>
                    <NavLink to="elements">
                        Elements
                    </NavLink>
                    <NavLink to="collaborators">
                        Collaborators
                    </NavLink>
                </TopNavBar>
                <div className="container mx-auto py-6 px-4">
                    <Routes>
                        <Route path="details" element={<Details  />} />
                        <Route path="elements" element={<ElementEditorPage treeId={elementId}/>} />
                        <Route path="collaborators" element={<ElementCollaboratorsPage assetId={elementId} />} />
                        <Route path="*" element={<Navigate to="details" />} />
                    </Routes>
                </div>
            </PageWithTopBarNavigation>
        </MainPage>
    );

}

export default ElementPageLayout;

