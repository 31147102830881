import { useAppDispatch, authActions } from "@app.raytd.com/store";
import { useEffect } from "react";

export const Logout = () => {

    const dispatch = useAppDispatch();
  
    useEffect(() => {
      dispatch(authActions.logout())
        }, [dispatch]);
  
    return  <div>Logging out</div>;

}