import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { X } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';

interface SearchInputProps {
  searchTerm: string
  setSearchTerm: (term: string) => void
  placeholder?: string
}

export function SearchInput({ searchTerm, setSearchTerm, placeholder = 'Search...' }: SearchInputProps) {
  const handleClear = () => {
    setSearchTerm('')
  }

  return (
    <div className="relative">
      <Input
        type="text"
        placeholder={placeholder}
        className="pr-8"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        aria-label="Search input"
      />
      {searchTerm && (
        <button
          className="absolute right-2 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
          onClick={handleClear}
          aria-label="Clear search"
        >
          <X className="h-4 w-4" />
        </button>
      )}
    </div>
  )
}

export function SearchInputDebounced({ searchTerm, setSearchTerm, placeholder = 'Search...' }: SearchInputProps) {

  const inputRef = useRef<HTMLInputElement>(null);
  // Memoize the debounced function
  const debouncedSetSearchTerm = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
    }, 500),
    [setSearchTerm]
  );

  // Handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchTerm(e.target.value);
  };

  // Cleanup function to cancel debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSetSearchTerm.cancel();
      setSearchTerm('');
    };
  }, [debouncedSetSearchTerm]);

  const handleClear = () => {
    setSearchTerm('');
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.focus();

    }
  };

  return (
    <div className="relative">
      <Input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        className="pr-8"
        defaultValue={searchTerm}
        onChange={handleChange}
        aria-label="Search input"
      />
      {searchTerm && (
        <button
          className="absolute right-2 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
          onClick={handleClear}
          aria-label="Clear search"
        >
          <X className="h-4 w-4" />
        </button>
      )}
    </div>
  );
}

import { debounce } from 'lodash';

export function SearchInputWithButton({ onSearch, placeholder = 'Search...' }: { onSearch: (term: string) => void, placeholder?: string }) {

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedOnSearch = useCallback(debounce(onSearch, 500), [onSearch]);

  const handleClear = useCallback(() => {
    setSearchTerm('');
    debouncedOnSearch(''); // clear search
  }, [debouncedOnSearch]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedOnSearch(value);
  }, [debouncedOnSearch]);

  return (
    <div className="flex flex-row">
      <div className="relative">
        <Input
          type="text"
          placeholder={placeholder}
          className="pr-8"
          value={searchTerm}
          onChange={handleChange}
          aria-label="Search input"
        />
        {searchTerm && (
          <button
            className="absolute right-2 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
            onClick={handleClear}
            aria-label="Clear search"
          >
            <X className="h-4 w-4" />
          </button>
        )}
      </div>
      {/* <Button onClick={() => onSearch(searchTerm)} className="ml-2" disabled={searchTerm === ''} hidden>
      Search
    </Button> */}
    </div>
  )
}

export default SearchInput;
