import { useTestSpecifications } from '@/app/features/testsuites/specifications/Context'
import NestedListSkeleton from '@/components/raytd/nested-list-loading-state'
import { Button } from "@/components/ui/button"
import { TooltipProvider } from '@/components/ui/tooltip'
import { Category } from './Category'
import { useState, useMemo, useCallback } from 'react'
import TestSpecificationForm from '@/app/features/testsuites/specifications/TestSpecificationForm'
import EmptyState from '@/components/raytd/empty-state'
import { cn } from '@/lib/utils'
import { useCreateTestSpecificationMutation, useUpdateTestSpecificationMutation } from '@app.raytd.com/store'
import { useUnsavedChanges } from '@/hooks/useUnsavedChanges'
import { toast } from 'sonner'

export default function NestedList() {

    const { groupedData, isLoading, testSuiteId, onAdd, onUpdate, dirtyForms, onDirtyChange } = useTestSpecifications();
    const [showCreateNewForm, setshowCreateNewForm] = useState(false);

    const isDirty = useMemo(() => dirtyForms.size > 0, [dirtyForms]);

    const reset = useCallback(() => {
        return ;
    }, []);
    
    const { AlertDialogComponent } = useUnsavedChanges(isDirty,reset, false);

    const defaultSpecification = useMemo(() => ({
        category: undefined,
        subcategory: undefined,
        description: '',
        observations: [],
        recommendations: []
    }), []);

    const addTestSpecification = () => {
        setshowCreateNewForm(true);
    }

    const isEmpty = useMemo(() => Object.entries(groupedData).length === 0, [groupedData]);

    const handleSubmit = useCallback(async (data) => {
        try {
            await onAdd(data);
            setshowCreateNewForm(false);
            toast.success('Test specification created successfully');
        } catch (error) {
            console.error(error);
            toast.error('Failed to create test specification');
        }
    }, [onAdd]);

    if (isLoading) {
        return <NestedListSkeleton />
    }

    return (
        <TooltipProvider>
            <div className="w-full p-4">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-2xl font-bold">Tests</h1>
                    <Button
                        onClick={addTestSpecification}
                        disabled={showCreateNewForm}
                        className={
                            cn(
                                { 'hidden': isEmpty },
                            )
                        }
                    >+ Add Test Specification</Button>
                </div>
                <div>You have {dirtyForms.size} unsaved forms</div>
                {
                    showCreateNewForm && (
                        <div className="border border-zinc-200 rounded-md p-4">
                            <h2 className="font-medium text-lg">New Specification</h2>
                            {/* Form to create a new test specification */}

                            <TestSpecificationForm
                                specification={defaultSpecification}
                                onSubmit={handleSubmit}
                                onDiscard={() => {
                                    setshowCreateNewForm(false);
                                }}
                                formId='new-specification'
                                onDirtyChange={onDirtyChange}
                            />
                        </div>
                    )
                }
                {Object.entries(groupedData)?.map(([category, subCategories]) => (
                    <Category
                        key={category}
                        category={category}
                        subCategories={subCategories}
                    />
                ))}

                {
                    isEmpty && !showCreateNewForm && (
                        <EmptyState
                            title="No Test Specifications"
                            description="Create a new test specification to get started"
                            actionLabel='Create Test Specification'
                            onAction={addTestSpecification}
                        />
                    )
                }
            </div>
            <AlertDialogComponent />
        </TooltipProvider>
    )
}
