import UserMenu from '@/app/layout2/UserMenu';
import { Button } from '@/components/ui/button';
import { PowerIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarNavigation from './SidebarNavigation';
import UpgradeCard from './UpgradeCard';


const Sidebar: React.FC = () => {

    const navigate = useNavigate();

    const handleLogout = () => {
        // Perform any logout logic here, if necessary
        navigate('/logout'); // Redirect to the login page or any other route
    };

    return (
        <div className="hidden border-none md:block">
            <div className="flex h-full max-h-screen w-[220px] lg:w-[280px] flex-col gap-2 fixed">
                <div className="flex h-14 items-center border-none px-4 lg:h-[60px] lg:px-6">
                    {/* <Link to="/" className="flex items-center gap-2 font-semibold">
            <span className="">Raytd</span>
          </Link>
          <Button variant="outline" size="icon" className="ml-auto h-8 w-8">
            <Bell className="h-4 w-4" />
            <span className="sr-only">Toggle notifications</span>
          </Button> */}
                    <UserMenu />

                </div>
                <div className="flex-1">
                    <SidebarNavigation />
                </div>
                <div className="mt-auto p-4">
                    <UpgradeCard />
                </div>
                <div className="px-4 pb-4">
                    <Button
                        variant="link"
                        size="default"
                        className="w-full justify-start flex text-zinc-500 font-normal"
                        onClick={handleLogout}
                    >
                        <PowerIcon className='w-4 h-4 mr-4' />Logout</Button>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
