import { useState, useCallback } from 'react';

export const useTableStatusFilter = () => {
  const [filter, setFilter] = useState('active');
  const [searchTerm, setSearchTerm] = useState('');

  const [statusFilter, setStatusFilter] = useState('active');

  const statusColumnFilterFn = useCallback((row, columnId, filterValue) => {
    if (filterValue === 'all') {
      return true;
    }

    const status = row.original.status.toLowerCase();
    return status.includes((filterValue as string).toLowerCase());
  }, []);

  
  const globalFilterFn = useCallback((row, columnId, filterValue) => {
    if (filterValue === 'all') {
      return true;
    }

    const value = row.getValue(columnId) as string;
    return (
      value?.toLowerCase().includes((filterValue as string).toLowerCase()) ||
      row.original.status.toLowerCase().includes((filterValue as string).toLowerCase())
    );
  }, []);

  return {
    filter,
    setFilter,
    searchTerm,
    setSearchTerm,
    globalFilterFn,
    statusFilter,
    setStatusFilter,
    statusColumnFilterFn,
  };
};