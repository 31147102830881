import React, { useCallback, useState } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@/components/ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { MoreHorizontal, Archive, Copy, LayoutGrid, FlaskConical, Clipboard } from 'lucide-react'
import ValuePill from '../../../../ui/src/lib/badges/ValuePill';
import { useArchiveTestSuiteForUserMutation, useArchiveTestSuiteMutation, useDuplicateTestSuiteMutation, useGetTestSuitesQuery, useUpdateTestSuiteMutation } from '@app.raytd.com/store'
import { Test } from '../../../../dist/store/src/lib/assessment/result';
import TestTypePill from '@/components/raytd/test-type-pill'
import classNames from 'classnames'
import Filters from '@/app/features/assets/Filters'
import { MainPage } from '@/app/layout2/AppLayout'
import SubPageLayout from '@/app/features/organisation/SubPageLayout'
import { useNavigate } from 'react-router-dom'
import { compare } from 'natural-orderby'
import { SkeletonLoader } from '@/app/features/assets/Skeleton'
import OrganisationAvatar from '@/components/raytd/organisation-avatar'
import { toast } from 'sonner'
import ArchiveRestoreMenuItem from '@/components/raytd/archive-restore-menu-item'
import { TestSuite } from 'store/src/lib/tests/entity'
import { Badge } from '@/components/ui/badge'
import { text } from 'stream/consumers';
import EmptyState from '@/components/raytd/empty-state'
import { CreateTestSuiteModal } from './v2/CreateTestSuiteModal';

const CategoriesPill = ({ item }) => {

    if (item.categories === 0 && item.subCategories === 0) {
        return null;
    }

    return (
        <div className="
            flex flex-row flex-wrap space-x-1.5 items-center justify-center text-xs bg-zinc-100 px-2 py-1.5 rounded-md h-full"
            title='Test Categories and Subcategories'
        >
            <LayoutGrid className="h-4 w-4 text-zinc-500" />
            <span>{item.categories}</span>
            <span className="px-0.5 opacity-50">|</span>
            <span>{item.subCategories}</span>
        </div>

    )
}

const TestSuitesPage: React.FC = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('')
    const [filter, setFilter] = useState('active');

    const { data: testSuites, isLoading, isFetching } = useGetTestSuitesQuery({})

    console.debug('testSuites', testSuites)

    const filteredTestSuites = testSuites?.filter(suite =>
        (suite.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            suite.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (filter === 'all' || suite.status === filter.toLocaleLowerCase())
    ).sort((a, b) => compare()(a.title, b.title));

    return (

        <>
            <div className="flex flex-row justify-end items-center space-x-4">
                <Filters selected={filter} setFilter={setFilter} setSearchTerm={setSearchTerm} />
                <CreateTestSuiteModal />
            </div>

            <MainPage>
                <SubPageLayout
                    title="Test Suites"
                    subtitle='Create, update, manage and share your test suites.'
                    showSpinner={isFetching}
                >
                    {isLoading ? (
                        <SkeletonLoader />
                    ) : (
                        <TestSuiteTable data={filteredTestSuites} />
                    )}

                </SubPageLayout>
            </MainPage>
        </>
    )
}

const TestSuiteTable = ({ data }) => {

    const navigate = useNavigate();

    const [archiveForUser] = useArchiveTestSuiteForUserMutation();
    const [archive] = useArchiveTestSuiteMutation();
    const [updateTestSuite] = useUpdateTestSuiteMutation();
    const [duplicate, { isLoading: isDuplicating }] = useDuplicateTestSuiteMutation();

    const handleArchiveForUser = useCallback(async (testSuite) => {
        console.log('Archive', testSuite.id);
        const changeToStatus = testSuite.status === 'active' ? 'archived' : 'restored';
        try {
            await archiveForUser(testSuite.id).unwrap();
            toast.success(`Test suite ${changeToStatus} successfully`);
        } catch (error) {
            console.error('Error archiving element group', error);
            toast.error(`Test suite was not ${changeToStatus}`);
        }
    }, []);

    const handleArchive = useCallback(async (testSuite) => {
        console.log('Archive', testSuite.id);
        const changeToStatus = testSuite.status === 'active' ? 'archived' : 'restored';
        try {
            await archive(testSuite.id).unwrap();
            toast.success(`Test suite ${changeToStatus} successfully`);
        } catch (error) {
            console.error('Error archiving test suite', error);
            toast.error(`Test suite was not ${changeToStatus}`);

        }
    }, []);

    const handleRetire = useCallback(async (testSuite) => {
        try {
            await updateTestSuite({
                id: testSuite.id,
                retired: !testSuite.retired
            }).unwrap();

            const text = testSuite.retired ? 'Reinstated' : 'Retired';

            toast.success(`Test suite ${text} successfully`);
        } catch (error) {
            console.error('Error retiring test suite', error);
            toast.error(`Test suite was not ${testSuite.retired ? 'reinstated' : 'retired'}`);
        }
    }, []);


    const handleDuplicate = useCallback(async (elementGroup) => {
        console.log('Duplicate', elementGroup.id);
        try {
            const response = await duplicate(elementGroup).unwrap();
            console.log('Response', response);
            toast.success(`Element group duplicated successfully`);
        } catch (error) {
            console.error('Error duplicating element group', error);
            toast.error(`Element group was not duplicated`);
        }
    }, []);


    const handleClick = (suite) => {
        navigate(`/test-suites/${suite.id}`)
    }

    if (!data) {
        return null;
    }

    if (data.length === 0) {
        return <EmptyState
            title="You have no test suites."
            description="Create your test suite to get started."
            actionLabel='Create Test Suite'
            onAction={() => navigate('/test-suites/new')}
        />;
    }

    console.debug('data', data);

    return (
        <Table>
            <TableBody>
                {data?.map((suite: TestSuite) => (
                    <TableRow key={suite.id} className={classNames({ 'opacity-70': suite.status === 'archived' })}>
                        <TableCell className='w-12'>
                            <div className='flex flex-wrap'>
                                <TestTypePill type={suite.type} inverse={suite.status === 'archived'} />
                            </div>
                        </TableCell>
                        <TableCell>
                            <Button variant='ghost' onClick={() => handleClick(suite)} asChild>
                                <div className="flex items-center space-x-4 cursor-pointer group">
                                    <div>
                                        <div className="font-semibold group-hover:underline">{suite.title}</div>
                                        <div className="text-sm text-gray-500 truncate">{suite.description}</div>
                                    </div>
                                </div>
                            </Button>
                        </TableCell>
                        <TableCell>
                            {suite.retired && (<Badge variant={'retired'}>Retired</Badge>)}

                            <CategoriesPill item={suite} />
                        </TableCell>
                        <TableCell>
                            <ValuePill label="Tests" value={suite.tests} iconOnly icon={<FlaskConical className='text-zinc-500 h-4 w-4' />} />
                        </TableCell>
                        <TableCell>
                            <ValuePill label="Inspections" value={suite.inspections} iconOnly icon={<Clipboard className="text-zinc-500 h-4 w-4" />} />
                        </TableCell>
                        <TableCell>
                            <OrganisationAvatar organisation={suite.organisation} size='sm' />
                        </TableCell>

                        <TableCell>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="ghost" className="h-8 w-8 p-0">
                                        <MoreHorizontal className="h-4 w-4" />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end">

                                    <DropdownMenuItem onClick={() => handleDuplicate(suite.id)}>
                                        <Copy className="mr-2 h-4 w-4" />
                                        Duplicate
                                    </DropdownMenuItem>
                                    
                                    <DropdownMenuSeparator />
                                    
                                    <DropdownMenuItem onClick={() => handleArchiveForUser(suite)}>
                                        <ArchiveRestoreMenuItem status={suite.status} />
                                    </DropdownMenuItem>

                                    <DropdownMenuSeparator />
                                    
                                    <DropdownMenuItem onClick={() => handleArchive(suite)}>
                                        <ArchiveRestoreMenuItem
                                            status={suite.is_globally_archived ? 'archived' : 'active'}
                                            archiveText='Archive (Everyone)'
                                            restoreText='Restore (Everyone)'
                                        />
                                    </DropdownMenuItem>

                                    <DropdownMenuItem
                                        onClick={() => handleRetire(suite)}>
                                        <Archive className="mr-2 h-4 w-4" />
                                        {suite.retired ? 'Reinstate' : 'Retire'}
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default TestSuitesPage;