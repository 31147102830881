'use client'

import * as React from 'react'
import { motion } from 'framer-motion'
import { Button } from "@/components/ui/button"

interface RoleSelectorProps {
  value: string
  onChange: (value: string) => void
  name?: string
  disabled?: boolean
}

export const RoleSelector = React.forwardRef<HTMLDivElement, RoleSelectorProps>(
  ({ value, onChange, name, disabled = false, ...props }, ref) => {
    const options = ['Admin', 'Member', 'Guest']

    const [activeIndex, setActiveIndex] = React.useState(() => {
      const index = options.findIndex((option) => option.toLowerCase() === value.toLocaleLowerCase())
      return index === -1 ? 0 : index
    })

    React.useEffect(() => {
      const newIndex = options.findIndex((option) => option.toLowerCase() === value.toLocaleLowerCase())
      if (newIndex !== -1 && newIndex !== activeIndex) {
        setActiveIndex(newIndex)
      }
    }, [value, options, activeIndex])

    const containerRef = React.useRef<HTMLDivElement>(null)
    const buttonRefs = React.useRef<(HTMLButtonElement | null)[]>([])

    const [highlighterDimensions, setHighlighterDimensions] = React.useState({
      width: 0,
      x: 0
    })

    const updateHighlighter = React.useCallback(() => {
      const selectedButton = buttonRefs.current[activeIndex]
      const container = containerRef.current

      if (selectedButton && container) {
        const containerRect = container.getBoundingClientRect()
        const selectedRect = selectedButton.getBoundingClientRect()

        setHighlighterDimensions({
          width: selectedRect.width,
          x: selectedRect.left - containerRect.left
        })
      }
    }, [activeIndex])

    React.useEffect(() => {
      updateHighlighter()
      window.addEventListener('resize', updateHighlighter)
      return () => window.removeEventListener('resize', updateHighlighter)
    }, [updateHighlighter])

    const handleChange = React.useCallback(
      (index: number) => {
        setActiveIndex(index)
        onChange(options[index].toLocaleLowerCase())
      },
      [options, onChange]
    )

    React.useImperativeHandle(ref, () => containerRef.current as HTMLDivElement, [])

    return (
      <div>
      <div
        ref={containerRef}
        role="radiogroup"
        aria-label="Role options"
        className="relative inline-flex bg-secondary rounded-md p-0 space-x-3"
        {...props}
      >

        {options.map((option, index) => (
          <Button
            key={option}
            ref={(el) => (buttonRefs.current[index] = el)}
            type="button"
            variant="ghost"
            size="sm"
            className={`
              relative z-20 flex-1 px-4 py-2 text-center transition-colors duration-100
              ${index === activeIndex 
                ? 'text-primary-foreground bg-primary hover:text-primary-foreground hover:bg-primary' 
                : 'text-muted-foreground hover:text-primary-foreground hover:bg-primary/20'}
            `}
            onClick={() => handleChange(index)}
            disabled={disabled}
            aria-checked={index === activeIndex}
            role="radio"
          >
            {option}
          </Button>
        ))}
      </div>
      </div>
    )
  }
)

RoleSelector.displayName = 'RoleSelector'

export default RoleSelector