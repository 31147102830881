"use client"

import { useCallback, useEffect, useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { ChevronLeft } from "lucide-react"
import { useCreateReportBlockMutation, useGetReportBlockQuery, useUpdateReportBlockMutation } from "@app.raytd.com/store"
import { ReportBlock } from '../../../../store/src/lib/services/types';
import { useNavigate, useParams } from "react-router-dom"
import { useAuth } from "@/app/_components"
import { useUnsavedChanges } from "@/hooks/useUnsavedChanges"
import APIError from "store/src/lib/api/apiError"
import { toast } from "sonner"
import { useNav } from "@/app/features/profile/NavContext"
import BusyButton from "@/components/raytd/busy-button"

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  description: z.string().min(1, "Description is required"),
  permittedUse: z.array(z.string()).refine((value) => value.length > 0, {
    message: "You must select at least one permitted use",
  }),
})

const permittedUseOptions = [
  { id: "compliance", label: "Compliance Test Suites" },
  { id: "condition", label: "Condition Assessment Test Suites" },
  { id: "generic", label: "General Test Suites" },
  { id: "custom", label: "Custom Result Test Suites" },
]

export default function ReportBlockDetails() {

  const { blockId } = useParams<{ blockId: string }>();
  const { data: block, isLoading, error } = useGetReportBlockQuery(blockId);

  return (
    <div className="max-w-xl">
      <ReportBlockForm block={block} />
    </div>
  )
}

export const ReportBlockForm: React.FC<{ block: Partial<ReportBlock>}> = ({ block }) => {

  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);
  const { user } = useAuth();

  const [updateBlock, { isLoading: isUpdating }] = useUpdateReportBlockMutation();
  const [createBlock, { isLoading: isCreating }] = useCreateReportBlockMutation();

  const isBusy = isUpdating || isCreating;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      description: "",
      permittedUse: ["compliance", "condition", "generic", "custom"],
    },
    disabled: formDisabled
  });

  const { formState } = form;
  const { isDirty } = formState;

  console.info('formState', {isDirty, formState});

  const { AlertDialogComponent } = useUnsavedChanges(isDirty, form.reset, formDisabled);

  const onSubmit = useCallback(async (values: z.infer<typeof formSchema>) => {
    try {
      console.log('submitting new block', {block, values});

      const { ...data } = values;

      if (block && block?.id !== null) {
        
        await updateBlock({
          id: block.id,
          ...data
        }).unwrap();

      } else {
        const payload = await createBlock({
          organisation_id: user.company?.id,
          ...data
        }).unwrap();

        console.debug('create new block payload', payload);
        setFormDisabled(true);
        setTimeout(() => navigate(`/report-blocks/${payload.id}`), 10);

      }


    } catch (err) {
      console.error(err as APIError);
      if (err?.status === 403) {
        form.setError("name", {
          type: "manual",
          message: "You are not authorised to update this asset",
        });
        toast.error("You dont' have permission to update this asset");
      }
    }

  }, [createBlock, updateBlock, block, form]);

  const { setNavButtons } = useNav();

  useEffect(() => {
    setNavButtons([
      <BusyButton
        onClick={form.handleSubmit(onSubmit)}
        disabled={!isDirty}
        isBusy={isBusy}
        busyText="Saving..."
      >
        Save
      </BusyButton>

    ])

    return () => {
      setNavButtons([])
    }
  }, [setNavButtons, block, form.handleSubmit, onSubmit, isDirty, isBusy]);


  useEffect(() => {

    if (block) {
      console.info('block reset', block); 
      form.reset(block);

      const isAdmin = true; // Implement role logic here
      setFormDisabled(!isAdmin);

    }
  }, [block, form.reset])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name*</FormLabel>
              <FormControl>
                <Input placeholder="Report Block Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description*</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Report Block Description"
                  className="resize-none"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="permittedUse"
          render={() => (
            <FormItem>
              <div className="mb-4">
                <FormLabel className="text-base">Permitted Use</FormLabel>
                <FormDescription>
                  Select the permitted uses for this report block.
                </FormDescription>
              </div>
              <div className="space-y-2">
                {permittedUseOptions.map((option) => (
                  <FormField
                    key={option.id}
                    control={form.control}
                    name="permittedUse"
                    render={({ field }) => {
                      return (
                        <FormItem
                          key={option.id}
                          className="flex flex-row items-start space-x-3 space-y-0"
                        >
                          <FormControl>
                            <Checkbox
                              checked={field.value?.includes(option.id)}
                              onCheckedChange={(checked) => {
                                return checked
                                  ? field.onChange([...field.value, option.id])
                                  : field.onChange(
                                    field.value?.filter(
                                      (value) => value !== option.id
                                    )
                                  )
                              }}
                            />
                          </FormControl>
                          <FormLabel className="font-normal">
                            {option.label}
                          </FormLabel>
                        </FormItem>
                      )
                    }}
                  />
                ))}
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
      <AlertDialogComponent />
    </Form>
  )
}