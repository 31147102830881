import { ColumnDef } from "@tanstack/react-table"
import { Button } from "@/components/ui/button"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import RolePill from "@/components/ui/role-pill"
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu"
import { TeamMember } from "@/app/features/organisation/types"
import { EllipsisVertical } from "lucide-react"
import { RoleTypes } from '../../organisation/types';
import { CompanyEntity } from "@app.raytd.com/store"

interface ColumnActions {
  onChangeRole: (member: TeamMember, newRole: string) => void;
  onRemoveMember: (member: TeamMember) => void;
  onDeleteMember: (member: TeamMember) => void;
  userRole?: RoleTypes;
  userId: number;
}

export const createColumns = ({
  userId,
  userRole = 'admin',
  onChangeRole,
  onRemoveMember,
  onDeleteMember
}: ColumnActions): ColumnDef<TeamMember & {organisations:CompanyEntity[]}>[] => [
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ row }) => (
        <div className="flex items-center space-x-4">
          <Avatar>
            <AvatarImage src={row.original.avatar} alt={row.original.name} />
            <AvatarFallback>{row.original.first_name?.[0]}</AvatarFallback>
          </Avatar>
          <div className="font-semibold">{row.original.first_name} {row.original.last_name}</div>
        </div>
      ),
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <EllipsisVertical className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
           
            <DropdownMenuItem
              onClick={() => onRemoveMember(row.original)}
            >
              Leave Organisation
            </DropdownMenuItem>

            <DropdownMenuItem
              onClick={() => onDeleteMember(row.original)}
              disabled={userId === row.original.id}
            >
              Delete User
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ]