import { zodResolver } from "@hookform/resolvers/zod"
import { debounce, isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from "react"
import { Controller, useForm } from 'react-hook-form'
import * as z from "zod"

import SegmentedControl from "@/components/raytd/segmented-control"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Switch } from "@/components/ui/switch"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useSubmitWithSaving } from '@app.raytd.com/ui'
import React from 'react'
import { CustomField } from "store/src/lib/tests/entity"
import { ListEditor } from './DataEditorField'
import { DataRecord, fieldType, FieldTypeDetails } from './types';

interface ExampleUIProps {
    fieldType: FieldTypeDetails
}

const ExampleUI = ({ fieldType }: ExampleUIProps) => {

    if (!fieldType.examples) {
        return null;
    }

    return (

        <Tabs className="w-full border rounded-lg px-4 py-2" defaultValue="0">
            <TabsList className="bg-white">

                {
                    fieldType.examples.map((example, index) => (
                        <TabsTrigger className='' key={index} value={index.toString()}>{example.label}</TabsTrigger>
                    ))
                }
            </TabsList>
            {
                fieldType.examples.map((example, index) => (
                    <TabsContent key={index} value={index.toString()}>
                        <img src={example.image} alt={example.label} />
                    </TabsContent>
                ))
            }
        </Tabs>
    );

}


type CustomFieldEditorProps = {
    customField: CustomField
    initialValues: CustomField
    onSave: (customField: Partial<CustomField>) => Promise<boolean>
    onSuccessfulSave?: (data: CustomField) => void
    onDirtyChange?: (isDirty: boolean) => void
    onChange?: (data: CustomField) => void
    onRemove?: (field_id: string) => void
    onDiscard?: (field_id: string) => void
    onValidationChange?: (isValid: boolean) => void
    onErrorsChange?: (errors: Record<string, any>) => void
}

const formSchema = z.object({
    label: z.string()
        .min(1, "Name is required")
        .max(50, "Name must be less than 50 characters"),
    description: z.string()
        .min(1, "Description is required")
        .max(200, "Description must be less than 200 characters"),
    info: z.string().min(1, "Information popup text is required"),
    type: z.enum(['text', 'textarea', 'number', 'scrollable_number', 'select', 'date', 'switch', 'checkbox', 'radio']),
    placeholder: z.string().nullable().optional(),
    validations: z.string().nullable(),
    decimal_places: z.number().nullable().optional(),
    allow_add: z.boolean().nullable().optional(),
    selection_type: z.enum(['single', 'multiple']).nullable().optional(),
    data: z.array(z.object({ id: z.string(), displayName: z.string() })).nullable().optional(),
}).refine((data) => {

    const isListType = data.type === 'select';
    const canHaveMissingData = data.type === 'select' && data.allow_add;

    if (isListType && canHaveMissingData) {
        return true;
    }

    if (fieldType[data.type]?.hasData && data.type !== 'textarea') {
        return data.data && data.data.length > 0;
    }

    return true;
}, {
    message: "List must have at least one item",
    path: ["data"],
}).refine((data) => {
    if (data.type === 'select') {
        return data.selection_type ?? false;
    }
    return true;
}, {
    message: "Selection type is required for list fields",
    path: ['selection_type']
})

export const CustomFieldEditor = ({
    customField,
    initialValues,
    onSave,
    onSuccessfulSave,
    onDirtyChange,
    onChange,
    onValidationChange,
    onErrorsChange,
    onRemove,
    onDiscard
}: CustomFieldEditorProps) => {

    const form = useForm<CustomField>({
        resolver: zodResolver(formSchema),
        defaultValues: initialValues,
        mode: 'all',
        reValidateMode: 'onChange',
    });
    const { control, handleSubmit, reset, watch, trigger, formState: { errors, isDirty, isValid } } = form;

    const [initialState, setInitialState] = useState<CustomField>(initialValues)
    const [changes, setChanges] = useState<CustomField>(initialValues)
    const [showDiscardModal, setShowDiscardModal] = useState(false)
    const watchedValues = watch();

    useEffect(() => {
        console.info('initialValues updated', initialValues)
    }, [initialValues])

    useEffect(() => {
        onValidationChange?.(isValid);
        onErrorsChange?.(errors);
    }, [isValid, errors, onValidationChange, onErrorsChange]);

    useEffect(() => {
        if (customField === undefined) {
            return
        }
        console.debug('resetting form', customField)
        reset(customField, { keepDefaultValues: true })
        trigger();
    }, [])

    const onSubmit = useCallback(async (data: CustomField): Promise<boolean> => {
        if (data.field_name === '') {
            data.field_name = data.label.toLowerCase().replace(/ /g, '_')
        }

        return onSave(data).then((result) => {
            if (result) {
                onSuccessfulSave?.(data)
                reset(data)
                return true
            }
        }).catch((error) => {
            return false
        })
    }, [onSave, onSuccessfulSave, reset])

    const { handleSubmit: handleSave, saving, error } = useSubmitWithSaving(onSubmit)

    const handleDiscard = useCallback(() => {
        console.debug('Discarding changes', initialState)

        //const isNewField = initialState.field_name.includes('new_field');
        const isNewField = !!!initialState;

        if (isNewField) {
            onRemove?.(customField.field_id);
        } else {
            reset(initialState, { keepDefaultValues: true });
            onDiscard?.(customField.field_id);
            trigger();
        }


        setShowDiscardModal(false)
    }, [initialState, customField.field_id, onRemove, reset]);

    const _handleOrderChange = useCallback((data: DataRecord[]) => {
        handleSubmit(onSubmit)()
    }, [onSubmit, handleSubmit]);

    const debouncedOnChange = useCallback(
        debounce((data: CustomField) => {
            setChanges(data);
            onChange?.(data);
            trigger();
        }, 300), // 300ms delay
        [onChange, trigger]
    );

    useEffect(() => {
        setInitialState(initialValues)
    }, [])

    useEffect(() => {
        console.debug('isDirty', isDirty);
        onDirtyChange?.(isDirty)
    }, [isDirty, onDirtyChange]);

    useEffect(() => {
        const comparison = { ...watchedValues }
        delete comparison['id'];
        delete comparison['active'];
        delete comparison['required'];

        if (!isEqual(comparison, changes)) {
            debouncedOnChange?.(comparison)
        }

    }, [watchedValues, debouncedOnChange, changes])

    useEffect(() => {
        return () => {
            debouncedOnChange.cancel();
        };
    }, [debouncedOnChange]);

    const isFormChanged = useMemo(() => {
        //const isNewField = initialState.field_name.includes('new_field');
        const isNewField = !!!initialState;
        return isNewField || JSON.stringify(initialState) !== JSON.stringify(watchedValues);
    }, [initialState, watchedValues]);

    const fieldTypeDetails = fieldType[customField.type]

    useEffect(() => {
        console.debug('errors', { errors, isValid });
    }, [errors, isValid])

    return (
        <>
            <Form {...form}>
                <form onSubmit={handleSubmit(handleSave)}>
                    <Card>
                        <CardHeader>
                            <CardTitle>{fieldTypeDetails.title}</CardTitle>
                            <CardDescription>{fieldTypeDetails.information}</CardDescription>
                        </CardHeader>
                        <CardContent className="flex flex-row space-x-6">
                            <div className="flex flex-col space-y-6 flex-1">

                                <div className="space-y-2">
                                    <Label htmlFor="label">Field Name</Label>
                                    <Controller
                                        name="label"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Input
                                                id="label"
                                                placeholder="Name"
                                                {...field}
                                            />
                                        )}
                                    />
                                    {errors.label && <p className="text-sm text-red-500">{errors.label.message}</p>}
                                </div>

                                <div className="space-y-2">
                                    <FormField
                                        name="description"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Description</FormLabel>
                                                <FormControl>
                                                    <Input
                                                        id="description"
                                                        placeholder="Description"
                                                        {...field}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                </div>

                                <div className="space-y-2">
                                    <FormField
                                        control={control}
                                        name="info"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Information Popup</FormLabel>
                                                <FormControl>
                                                    <Input
                                                        id="info"
                                                        placeholder="Information Popup"
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                </div>

                                {fieldTypeDetails.placeholder && (
                                    <div className="space-y-2">
                                        <Label htmlFor="placeholder">Placeholder</Label>
                                        <Controller
                                            name="placeholder"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Input
                                                    id="placeholder"
                                                    placeholder="Enter field placeholder"
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {errors.placeholder && <p className="text-sm text-red-500">{errors.placeholder.message}</p>}
                                    </div>
                                )}

                                {customField.type === 'number' && (
                                    <div className="space-y-2">
                                        <Label htmlFor="decimal_places">Maximum Decimal Places Permitted</Label>
                                        <Controller
                                            name="decimal_places"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Select
                                                    onValueChange={(value) => field.onChange(parseInt(value, 10))}
                                                    value={field.value?.toString() || '0'}
                                                >
                                                    <SelectTrigger>
                                                        <SelectValue placeholder="Select decimal places" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                                                            <SelectItem key={i} value={i.toString()}>{i.toString()}</SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            )}
                                        />
                                        {errors.decimal_places && <p className="text-sm text-red-500">{errors.decimal_places.message}</p>}
                                    </div>
                                )}

                                {(customField.type === 'select' || customField.type === 'textarea') && (

                                    <div className="flex items-center space-x-2">
                                        <Controller
                                            name="allow_add"
                                            control={control}
                                            render={({ field }) => (
                                                <Switch
                                                    id="allow_add"
                                                    checked={field.value}
                                                    onCheckedChange={field.onChange}
                                                />
                                            )}
                                        />
                                        <Label htmlFor="allow_add">{
                                            customField.type === 'select'
                                                ? 'Allow users to add items'
                                                : 'Allow add Fast Fill on mobile application'
                                        }
                                        </Label>
                                    </div>

                                )}
                                {(customField.type === 'select') && (

                                    <div className="space-y-2">
                                        <Label htmlFor="selection_type">Selections Allowed</Label>
                                        <Controller
                                            name="selection_type"
                                            control={control}
                                            rules={{ required: customField.type === 'select' }}
                                            render={({ field }) => (
                                                <SegmentedControl
                                                    value={field.value}
                                                    onValueChange={field.onChange}
                                                    options={[
                                                        { value: "single", label: "One" },
                                                        { value: "multiple", label: "Multiple" }
                                                    ]}
                                                />
                                            )}

                                        />
                                        {errors.selection_type && <p className="text-sm text-red-500">{errors.selection_type.message}</p>}
                                    </div>
                                )}

                                {fieldTypeDetails.hasData && (
                                    <div className="space-y-2">
                                        <Label htmlFor="data">{customField.type === 'textarea' ? 'Quick Fills' : 'List Options'}</Label>

                                        <div className="p-1 max-w-md">
                                            <Controller
                                                name="data"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => {
                                                    return (
                                                        <ListEditor
                                                            value={field.value}
                                                            multiline={customField.type === 'textarea'}
                                                            addItemText={customField.type === 'textarea' ? 'Add Quick Fill' : 'Add Item'}
                                                            onChange={field.onChange}
                                                            onBlur={field.onBlur}
                                                        />
                                                    )
                                                }}
                                            />
                                            {errors.data && <p className="text-sm text-red-500">{errors.data.message?.toString()}</p>}

                                        </div>

                                    </div>
                                )}

                            </div>
                            <div className="w-96">
                                <ExampleUI fieldType={fieldTypeDetails} />
                            </div>

                        </CardContent>
                        <CardContent className="flex justify-end">
                            <Button variant="outline" disabled={!isFormChanged} onClick={() => setShowDiscardModal(true)}>
                                Discard
                            </Button>
                        </CardContent>
                    </Card>
                </form>
            </Form>
            <Dialog open={showDiscardModal} onOpenChange={setShowDiscardModal}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Discard Changes</DialogTitle>
                        <DialogDescription>
                            Are you sure you want to discard changes and revert to the original field configuration?
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setShowDiscardModal(false)}>Cancel</Button>
                        <Button variant="default" onClick={handleDiscard}>Discard</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default React.memo(CustomFieldEditor);