import AddIndividualCollaboratorDialog from "@/app/features/assets/AddIndividualCollaboratorDialog"
import SubPageLayout from "@/app/features/organisation/SubPageLayout"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import RolePill from "@/components/ui/role-pill"
import { Separator } from "@/components/ui/separator"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { useChangeUserRoleMutation, useGetAssetQuery, useRemoveCollaboratorMutation, UsersEntity } from "@app.raytd.com/store"
import { MoreHorizontal, Search, SearchIcon } from "lucide-react"
import React, { useCallback, useEffect, useState } from "react"
import { useConfirmationActionDialog } from "@/components/raytd/use-confirmation-action-dialog"
import { useNav } from "@/app/features/profile/NavContext"
import SearchInput from "@/components/raytd/search-input"
import { RoleTypes } from "@/app/features/organisation/types"
import { isRoleGreaterThanOrEqual, isRoleLessThan } from "@/app/utils/roles"
import { toast } from "sonner"

export const OrganisationsTable = ({ organisations, searchTerm }) => {
  const filteredOrganisations = organisations.filter(org =>
    org.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableCell colSpan={3} className="font-bold text-base text-zinc-600">Organisations</TableCell>
            <TableCell className="text-right">
              {/* <Button variant="link">
                <Plus className="mr-2 h-4 w-4" /> Add Organisation Collaborator
              </Button> */}
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredOrganisations.map((org) => (
            <TableRow key={org.id}>
              <TableCell className="flex items-center space-x-4">
                <Avatar className="h-10 w-10">
                  <AvatarImage src={org.logo} alt={org.name} className="object-cover" />
                  <AvatarFallback>{org.name[0]}</AvatarFallback>
                </Avatar>
                <span className="font-medium">{org.name}</span>
              </TableCell>
              <TableCell>

              </TableCell>
              <TableCell className="text-right"><RolePill role={org.role} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

const IndividualsTable = ({ asset, individuals, searchTerm, setBusy }) => {

  const [updateUserAssetRole, { isLoading }] = useChangeUserRoleMutation();

  const filteredIndividuals = individuals.filter(individual =>
    individual.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const [removeCollaborator] = useRemoveCollaboratorMutation();

  const dialogDescription = useCallback((item: any) => `Are you sure you want to remove ${item?.name} as a collaborator on ${asset?.name}?`, [asset]);

  const handleRemove = useCallback(async (individual) => {
    await removeCollaborator({ assetId: asset.id, userId: individual.id });
  }, [asset, removeCollaborator]);

  const {
    isProcessing,
    openConfirmationDialog,
    ConfirmationDialog
  } = useConfirmationActionDialog({
    onConfirm: async (item) => {
      await handleRemove(item);
    },
    onSuccess: () => {
    },
    onError: (error) => {
      // Handle error
    },
    successMessage: "Action completed successfully",
    dialogTitle: "Remove Collaborator",
    dialogDescription: dialogDescription,
  });

  const confirmRemove = (individual) => {
    //removeCollaborator({assetId: asset.id, userId: individual.id });
    openConfirmationDialog(individual);
  }

  const handleChangeRole = async (individual, newRole) => {
    console.log(`Change role from ${individual.role} to ${newRole}`);
    //assetId, role
    try {
      const response = await updateUserAssetRole({ assetId: asset.id, user_id: individual.id, role: newRole }).unwrap();
      console.log("Update role response", response);
      toast.success("User role updated successfully");
    } catch (err) {
      console.error("Error updating user role", err);
      toast.error("Error updating user role");
    }
  }

  return (
    <div>

      <Table className="w-full overflow-auto">

        <TableHeader>
          <TableRow>
            <TableHead colSpan={1} className="font-bold text-base text-zinc-600">Individuals</TableHead>
            <TableHead colSpan={1} className="text-right w-16">
              {
                isProcessing ? (
                  <span className="text-muted-foreground">Processing...</span>
                ) : (
                  <AddIndividualCollaboratorDialog asset={asset} />

                )
              }
            </TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>

          {filteredIndividuals.length === 0 && (
            <TableRow>
              <TableCell colSpan={4} className="text-left">No individuals collaborators.</TableCell>
            </TableRow>
          )}

          {filteredIndividuals.map((individual) => (
            <TableRow key={individual.id}>
              <TableCell className="flex items-center space-x-4" colSpan={1}>
                <Avatar className="h-10 w-10">
                  <AvatarImage src={individual.avatar} alt={individual.name} className="object-cover" />
                  <AvatarFallback>{individual.name[0]}</AvatarFallback>
                </Avatar>
                <span className="font-medium">{individual.name}</span>
              </TableCell>

              <TableCell className="text-right">
                <RolePill role={individual.is_pending ? 'pending' : individual.role} />
                <ActionMenu 
                  individual={individual} 
                  userRole={'admin'} 
                  onChangeRole={handleChangeRole} 
                  onRemove={() => confirmRemove(individual)} 
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmationDialog />
    </div>
  )
}

type ActionMenuProps = {
  individual: UsersEntity;
  userRole: 'admin' | 'member' | 'guest';
  onRemove: () => void;
  onChangeRole: (individual: any, role: string) => void;
}

const ActionMenu: React.FC<ActionMenuProps> = ({ individual, userRole, onRemove, onChangeRole }) => {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">

        {
          individual.role !== 'pending' &&
          isRoleGreaterThanOrEqual(userRole, 'member') && (
            <>
              <DropdownMenuItem disabled={individual.role === "admin" || isRoleLessThan(userRole, 'admin')}
                onClick={() => onChangeRole(individual, "admin")}>
                Change to Owner
              </DropdownMenuItem>
              <DropdownMenuItem disabled={individual.role === "member" || isRoleLessThan(userRole, 'member')}
                onClick={() => onChangeRole(individual, "member")}>
                Change to Member
              </DropdownMenuItem>
              <DropdownMenuItem disabled={individual.role === "guest" || isRoleLessThan(userRole, 'member')}
                onClick={() => onChangeRole(individual, "guest")}>
                Change to Guest
              </DropdownMenuItem>
              <DropdownMenuSeparator />
            </>

          )}

        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={onRemove}>
          Remove
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const AssetCollaboratorsPage: React.FC<{ assetId: string }> = ({ assetId }) => {
  const [searchTerm, setSearchTerm] = useState("")

  const { data: asset, isLoading } = useGetAssetQuery(assetId);

  console.info('asset', asset);

  const individuals = asset?.collaborators || [];
  const organisations = asset?.organisation ? [asset.organisation] : [];

  const { busy, setIsBusy, setNavButtons } = useNav();

  useEffect(() => {
    setNavButtons([
      <SearchInput key="search" searchTerm={searchTerm} setSearchTerm={setSearchTerm} />,
    ])
    return () => {
      setNavButtons([]);
    }
  }, [setNavButtons, searchTerm, setSearchTerm]);

  return (
    <SubPageLayout title="Collaborators" showLoading={isLoading || busy}>

      {/* <div className="mb-6">
        <div className="relative">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search collaborators..."
            className="pl-8"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div> */}

      <div className="space-y-8">
        <OrganisationsTable organisations={organisations} searchTerm={searchTerm} />
        <Separator />
        <IndividualsTable asset={asset} individuals={individuals} searchTerm={searchTerm} setBusy={setIsBusy} />
      </div>
    </SubPageLayout>
  )
}

export default AssetCollaboratorsPage;