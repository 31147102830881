// src/hooks/useAutocompleteSuggestions.ts

import { useState, useEffect } from 'react';
import { getAutocompleteSuggestions } from './autocomplete';

export function useAutocompleteSuggestions(input: string) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (input.length > 2) {
        setIsLoading(true);
        try {
          const result = await getAutocompleteSuggestions(input);
          if (result.error) {
            setError(result.error);
            setData(null);
          } else {
            setData(result.data);
            setError(null);
          }
        } catch (err) {
          setError(err instanceof Error ? err.message : String(err));
          setData(null);
        } finally {
          setIsLoading(false);
        }
      } else {
        setData(null);
        setError(null);
      }
    };

    fetchSuggestions();
  }, [input]);

  useEffect(() => {
    console.debug('data:', data);
  }, [data]);

  return { data, isLoading, error };
}
