import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils"

export interface ValuePillProps extends VariantProps<typeof valuePillVariants> {
    value: number
    label: string
    icon?: React.ReactNode
    onClick?: () => void
}

const valuePillVariants = cva(
    "flex items-center justify-center rounded-md px-2 py-1.5 text-xs",
    {
        variants: {
            variant: {
                default: "bg-zinc-100 text-zinc-950",
                dark: "bg-zinc-500 text-white",
                inverted: "bg-white text-zinc-500 border border-zinc-300",
            },
            iconOnly: {
                true: "h-8",
                false: "",
            },
        },
        defaultVariants: {
            variant: "default",
            iconOnly: false,
        },
    }
)

export interface ValuePillProps extends VariantProps<typeof valuePillVariants> {
    value: number
    label: string
    icon?: React.ReactNode
    onClick?: () => void
}

export function ValuePill({
    value,
    label,
    variant,
    icon,
    iconOnly = false,
    onClick,
}: ValuePillProps) {
    return (
        <button
            className={cn(valuePillVariants({ variant, iconOnly }))}
            onClick={onClick}
            type="button"
            aria-label={`${value} ${label}`}
        >
            {!iconOnly && (
                <span className={cn("truncate", icon && "hidden lg:inline")}>
                    <span className="font-semibold mr-1">{value}</span>
                    {label}
                </span>
            )}
            {icon && (
                <span
                    className={cn(
                        "flex items-center",
                        !iconOnly && "lg:hidden"
                    )}
                >
                    {icon}
                    <span className="font-semibold ml-1">{value}</span>
                </span>
            )}
        </button>
    )
}