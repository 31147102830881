import { RatingScale } from './../entity';
import { api } from './../../api/fetch';

export const fetchRatingScale = async (id: number) => {
    const response = await api.get('/api/v1/rating-scales/' + id);
    return response.body as RatingScale;
}

export const fetchRatingScales = async () => {
    const response = await api.get('/api/v1/rating-scales');
    return response.body as RatingScale[];
}