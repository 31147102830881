import React, { useRef, useCallback, useEffect, useState, forwardRef } from "react";
import { FormMessages } from "@/components/form-messages";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import { Input } from "@/components/ui/input";
import { useDebounce } from "@/hooks/use-debounce";
import { Cross, CrossIcon, Delete, Loader2, MapPinPlusIcon, Pencil, XIcon } from "lucide-react";
import AddressDialog from "./address-dialog";
import { Command as CommandPrimitive } from "cmdk";
import { useAutocompleteSuggestions } from './useAutocompleteSuggestions';
import { usePlaceDetails } from './usePlacesAPI';
import { Pill } from "@/components/raytd/pill";
import AddressLine from "@/app/features/assets/AddressLine";
import { Address, formatAddress } from "@app.raytd.com/store";
import { convertAddressTypeToAddress } from "@/app/features/assets/components/address";

export interface AddressType {
  address1: string;
  address2?: string;
  formattedAddress?: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
  lat?: number;
  lng?: number;
}

interface AddressAutoCompleteProps {
  address: AddressType;
  setAddress: (address: AddressType) => void;
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  dialogTitle: string;
  showInlineError?: boolean;
  placeholder?: string;
}

const AddressAutoComplete = forwardRef<HTMLInputElement, AddressAutoCompleteProps>(
  ({
    address,
    setAddress,
    dialogTitle,
    showInlineError = true,
    searchInput,
    setSearchInput,
    placeholder,
  }, ref) => {
    const [selectedPlaceId, setSelectedPlaceId] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const handleClear = useCallback((e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedPlaceId("");
      setAddress({
        address1: "",
        address2: "",
        formattedAddress: "",
        city: "",
        region: "",
        postalCode: "",
        country: "",
        lat: 0,
        lng: 0,
      });
    }, [setAddress]);

    const { data, isLoading, error } = usePlaceDetails(selectedPlaceId);

    const adrAddress = data?.adrAddress;

    const prevAddressRef = useRef<AddressType | null>(null);

    useEffect(() => {
        if (data?.address && data.address !== prevAddressRef.current) {
            console.info('Updating address from data', { data, address2: data?.address, address });
            setAddress(data.address as AddressType);
            prevAddressRef.current = data.address;
        }
    }, [data]);

    const handleStartEditing = useCallback(() => {
      setIsEditing(true);
      if (address?.formattedAddress !== "") {
        setIsOpen(true);
      }
    }, [address]);

    useEffect(() => {
      if (isEditing && !isOpen && address.formattedAddress === "") {
        if (ref && 'current' in ref && ref.current) {
          ref.current.focus();
        }
      }
    }, [ref, isEditing, address, isOpen]);

    useEffect(() => {
      if (!isOpen) {
        setIsEditing(false);
      }
    }, [isOpen]);

    if (!isEditing) {
      return (
        <div onClick={handleStartEditing}>
          <Pill>
            <AddressLine address={convertAddressTypeToAddress(address)} emptyText="Select Address" />
            <span onClick={handleClear} className="hover:text-zinc-900 text-zinc-400 hover:font-medium">
              <XIcon className="w-4 h-4" />
            </span>
          </Pill>
        </div>
      );
    }

    return (
      <>
        <AddressAutoCompleteInput
          ref={ref}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          selectedPlaceId={selectedPlaceId}
          setSelectedPlaceId={setSelectedPlaceId}
          setIsOpenDialog={setIsOpen}
          showInlineError={showInlineError}
          placeholder={placeholder}
        />
        <AddressDialog
          isLoading={isLoading}
          dialogTitle={dialogTitle}
          adrAddress={adrAddress}
          address={address}
          setAddress={setAddress}
          open={isOpen}
          setOpen={setIsOpen}
        />
      </>
    );
  }
);

interface CommonProps {
  selectedPlaceId: string;
  setSelectedPlaceId: (placeId: string) => void;
  setIsOpenDialog: (isOpen: boolean) => void;
  showInlineError?: boolean;
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  placeholder?: string;
}

const AddressAutoCompleteInput = forwardRef<HTMLInputElement, CommonProps>(
  ({
    setSelectedPlaceId,
    selectedPlaceId,
    setIsOpenDialog,
    showInlineError,
    searchInput,
    setSearchInput,
    placeholder,
  }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => setIsOpen(true), []);
    const close = useCallback(() => setIsOpen(false), []);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Escape") {
        close();
      }
    };

    const debouncedSearchInput = useDebounce(searchInput, 500);

    const { data, isLoading, error: autocompleteError } = useAutocompleteSuggestions(debouncedSearchInput);

    const predictions = data?.length > 0 ? data : [];

    return (
      <Command
        shouldFilter={false}
        onKeyDown={handleKeyDown}
        className="overflow-visible"
      >
        <Input
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onFocus={open}
          ref={ref}
          onBlur={close}
          placeholder={placeholder || "Enter address"}
          className="w-full rounded-md"
        />
        {searchInput !== "" && !isOpen && !selectedPlaceId && showInlineError && (
          <FormMessages
            type="error"
            className="pt-1 text-xs"
            messages={["Enter a valid address"]}
          />
        )}

        {isOpen && (
          <div className="relative animate-in fade-in-0 zoom-in-95 h-auto">
            <CommandList>
              <div className="absolute top-1.5 z-50 w-full">
                <CommandGroup className="relative h-auto z-50 min-w-[8rem] overflow-hidden rounded-md border shadow-md bg-background">
                  {isLoading ? (
                    <div className="h-28 flex items-center justify-center">
                      <Loader2 className="size-6 animate-spin" />
                    </div>
                  ) : (
                    <>
                      <CommandPrimitive.Item
                        className="flex select-text flex-row cursor-pointer gap-1 h-max p-2 px-3 mb-2 rounded-md aria-selected:bg-accent aria-selected:text-accent-foreground hover:bg-accent hover:text-accent-foreground items-center justify-start"
                        onSelect={() => {
                          setSearchInput("");
                          setSelectedPlaceId("");
                          setIsOpenDialog(true);
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        <MapPinPlusIcon className="h-4 w-4" /><span className="font-semibold text-sm">Add Manual Address</span>
                      </CommandPrimitive.Item>
                      <CommandSeparator />
                      {predictions.map(
                        (prediction: {
                          placePrediction: {
                            placeId: string;
                            place: string;
                            text: { text: string };
                          };
                        }) => (
                          <CommandPrimitive.Item
                            value={prediction.placePrediction.text.text}
                            onSelect={() => {
                              setSearchInput("");
                              setSelectedPlaceId(
                                prediction.placePrediction.place,
                              );
                              setIsOpenDialog(true);
                            }}
                            className="flex select-text flex-col cursor-pointer gap-0.5 h-max p-2 px-3 rounded-md aria-selected:bg-accent aria-selected:text-accent-foreground hover:bg-accent hover:text-accent-foreground items-start"
                            key={prediction.placePrediction.placeId}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {prediction.placePrediction.text.text}
                          </CommandPrimitive.Item>
                        ),
                      )}
                    </>
                  )}

                  <CommandEmpty>
                    {!isLoading && predictions.length === 0 && (
                      <div className="py-4 flex items-center justify-center">
                        {searchInput === ""
                          ? "Please enter an address"
                          : "No address found"}
                      </div>
                    )}
                  </CommandEmpty>
                </CommandGroup>
              </div>
            </CommandList>
          </div>
        )}
      </Command>
    );
  }
);

AddressAutoComplete.displayName = 'AddressAutoComplete';
AddressAutoCompleteInput.displayName = 'AddressAutoCompleteInput';

export default AddressAutoComplete;