import {
  EntityState,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { ElementClassification } from './types';
import { ElementClassificationRename } from './types/element';
import {
  fetchElementClassifications as fetchElementClassificationsAPI,
  addElementClassification as addElementClassificationAPI,
  updateElementClassification as updateElementClassificationAPI,
  deleteElementClassification as deleteElementClassificationAPI,
  renameElementClassification as renameElementClassificationAPI,
} from './api';

export const ELEMENT_CLASSIFICATION_FEATURE_KEY = 'elementClassifications';

const elementClassificationsAdapter =
  createEntityAdapter<ElementClassification>();

export interface ElementClassificationsState
  extends EntityState<ElementClassification, number> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  savingStatus: 'not saved' | 'saving' | 'saved' | 'error';
  error?: string | null;
}

export const initialElementClassificationsState: ElementClassificationsState =
  elementClassificationsAdapter.getInitialState({
    loadingStatus: 'not loaded',
    savingStatus: 'not saved',
    error: null,
  });

export const fetchElementClassifications = createAsyncThunk<
  ElementClassification[],
  number
>(
  `${ELEMENT_CLASSIFICATION_FEATURE_KEY}/fetchElementClassifications`,
  async (treeId: number, thunkAPI) => {
    try {
      const response = await fetchElementClassificationsAPI(treeId);
      return response.body as ElementClassification[];
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const saveElementClassification = createAsyncThunk<
  ElementClassification,
  ElementClassification
>(
  `${ELEMENT_CLASSIFICATION_FEATURE_KEY}/saveElementClassification`,
  async (elementClassification: ElementClassification, thunkAPI) => {
    try {
      if (elementClassification.id) {
        const response = await updateElementClassificationAPI(
          elementClassification
        );
        return response.body as ElementClassification;
      } else {
        const response = await addElementClassificationAPI(
          elementClassification
        );
        return response.body as ElementClassification;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const renameElementClassification = createAsyncThunk<
  ElementClassification[],
  ElementClassificationRename
>(
  `${ELEMENT_CLASSIFICATION_FEATURE_KEY}/renameElementClassification`,
  async (elementClassification: ElementClassificationRename, thunkAPI) => {
    try {
      
      const response = await renameElementClassificationAPI(
        elementClassification
      );

      return response.body.elements as ElementClassification[];
      
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const deleteElementClassification = createAsyncThunk<
  ElementClassification,
  ElementClassification
>(
  `${ELEMENT_CLASSIFICATION_FEATURE_KEY}/deleteElementClassification`,
  async (elementClassification: ElementClassification, thunkAPI) => {
    try {
      await deleteElementClassificationAPI(elementClassification);
      return elementClassification;
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const elementClassificationsSlice = createSlice({
  name: ELEMENT_CLASSIFICATION_FEATURE_KEY,
  initialState: initialElementClassificationsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchElementClassifications.pending,
        (state: ElementClassificationsState) => {
          state.loadingStatus = 'loading';
        }
      )
      .addCase(
        fetchElementClassifications.fulfilled,
        (
          state: ElementClassificationsState,
          action: PayloadAction<ElementClassification[]>
        ) => {
          elementClassificationsAdapter.setAll(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(
        fetchElementClassifications.rejected,
        (state: ElementClassificationsState, action) => {
          state.loadingStatus = 'error';
          state.error = action.error.message;
        }
      )
      .addCase(
        saveElementClassification.pending,
        (state: ElementClassificationsState) => {
          state.savingStatus = 'saving';
        }
      )
      .addCase(
        saveElementClassification.fulfilled,
        (
          state: ElementClassificationsState,
          action: PayloadAction<ElementClassification>
        ) => {
          elementClassificationsAdapter.upsertOne(state, action.payload);
          state.savingStatus = 'saved';
        }
      )
      .addCase(
        renameElementClassification.pending,
        (state: ElementClassificationsState) => {
          state.savingStatus = 'saving';
        }
      )
      .addCase(
        renameElementClassification.fulfilled,
        (
          state: ElementClassificationsState,
          action: PayloadAction<ElementClassification[]>
        ) => {
          elementClassificationsAdapter.upsertMany(state, action.payload);
          state.savingStatus = 'saved';
        }
      )
      .addCase(
        saveElementClassification.rejected,
        (state: ElementClassificationsState, action) => {
          state.savingStatus = 'error';
          state.error = action.error.message;
        }
      )
      .addCase(
        deleteElementClassification.fulfilled,
        (
          state: ElementClassificationsState,
          action: PayloadAction<ElementClassification>
        ) => {
          elementClassificationsAdapter.removeOne(state, action.payload.id);
        }
      )
      .addCase(
        deleteElementClassification.rejected,
        (state: ElementClassificationsState, action) => {
          state.savingStatus = 'error';
          state.error = action.error.message;
        }
      );
  },
});

export const elementClassificationsReducer =
  elementClassificationsSlice.reducer;
export const elementClassificationsActions = {
  ...elementClassificationsSlice.actions,
};

const { selectAll, selectEntities, selectById } =
  elementClassificationsAdapter.getSelectors();

export const getElementClassificationsState = (rootState: {
  [ELEMENT_CLASSIFICATION_FEATURE_KEY]: ElementClassificationsState;
}): ElementClassificationsState => {
  return rootState[ELEMENT_CLASSIFICATION_FEATURE_KEY];
};

export const selectAllElementClassifications = createSelector(
  getElementClassificationsState,
  selectAll
);

export const selectElementClassificationEntities = createSelector(
  getElementClassificationsState,
  selectEntities
);

export const selectElementClassificationById = (
  elementClassificationId: number
) =>
  createSelector(getElementClassificationsState, (state) => {
    return state.entities[elementClassificationId];
  });

export const selectElementClassificationsByTreeId = createSelector(
  selectAllElementClassifications,
  (_, treeId: number) => treeId,
  (elementClassifications: ElementClassification[], treeId: number) =>
    elementClassifications.filter(
      (elementClassification) =>
        elementClassification.classification_tree_id === treeId
    )
);

export const selectElementClassificationLoadingStatus = createSelector(
  getElementClassificationsState,
  (state: ElementClassificationsState) => state.loadingStatus
);

export const selectElementClassificationSavingStatus = createSelector(
  getElementClassificationsState,
  (state: ElementClassificationsState) => state.savingStatus
);
