import React, { useState } from 'react';
import Downshift from 'downshift';
import { CompanyEntity, api } from '@app.raytd.com/store';
import { XCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';

export const searchAPI = async (inputValue: string) => {
    const response = await api.get(`api/v1/organisations/search/?query=${encodeURIComponent(inputValue)}`);
    return response;
}

type OrganisationProps = {
    api: (inputValue: string) => any;
    value: any;
    disabled: boolean;
    onChange: (item: any) => void;
    onClear: () => void;
}

export const AutocompleteOrganisation =
    React.forwardRef<HTMLInputElement, OrganisationProps>((
        { api, value, disabled, onChange, onClear }: OrganisationProps, ref) => {
        const [organisations, setOrganisations] = useState<CompanyEntity[]>([]);

        const fetchOrganisations = async (inputValue: string) => {
            try {
                // Only proceed if the input length is at least 3 characters
                if (inputValue.length >= 3) {
                    const response = await api(inputValue);
                    console.log(response);
                    setOrganisations(response.body);
                }
            } catch (error) {
                console.error('Error fetching organisations:', error);
                // Handle the error as per your requirements
                // For instance, you might want to clear the existing organisations
                // or you might want to show an error message.
                setOrganisations([]);
            }
        };

        const clear = () => {
            setOrganisations([]);
            onClear();
        }

        return (
            <Downshift
                onInputValueChange={(inputValue) => {
                    fetchOrganisations(inputValue);
                }}
                initialInputValue={value?.title}
                onChange={onChange}
                itemToString={(item) => (item ? item.title : '')}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                    selectedItem,
                    selectItem
                }) => (
                    <div className="relative">
                        <input
                            {...getInputProps({
                                ref: ref,
                                name: 'organisation',
                                disabled: disabled,
                                placeholder: disabled ? '' : 'Search for an organisation',
                                onKeyDown: (event) => {
                                    if (event.key === 'Escape') {
                                        selectItem(null);
                                        clear();
                                    }
                                    if (event.key === 'Tab' && isOpen && highlightedIndex !== null) {
                                        selectItem(organisations[highlightedIndex]);
                                        event.preventDefault();
                                    }
                                },
                                className: `w-full border border-gray-300 rounded-md shadow-sm ${disabled ? 'bg-gray-200 text-gray-400' : 'bg-white text-black'} rounded-b-none`,

                            },)}

                        />
                        {inputValue && (
                            <button
                                onClick={() => {
                                    selectItem(null);
                                    clear();
                                }}
                                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                            >
                                <XMarkIcon className='h-6 w-6 text-gray-500' />
                            </button>
                        )}
                        {isOpen && inputValue && (
                            <ul {...getMenuProps()}
                                role="listbox"
                                className="dropdown-menu absolute z-10 w-full bg-gray-50 shadow-sm max-h-60 overflow-y-auto border border-gray-300 rounded-md rounded-t-none"
                            >
                                {isOpen &&
                                    (organisations.length > 0 ?
                                        organisations.map((item, index) => (
                                            <li
                                                {...getItemProps({
                                                    key: item.id,
                                                    index,
                                                    item,
                                                    className: `dropdown-item cursor-pointer 
                                                ${highlightedIndex === index ? 'bg-gray-100' : 'bg-white'} 
                                                ${selectedItem === item ? 'font-semibold' : 'font-normal'}
                                                 p-2 hover:bg-gray-100`
                                                })}
                                            >
                                                {item.name}
                                            </li>
                                        ))
                                        :
                                        <li className="dropdown-item cursor-default p-2">
                                            No results found
                                        </li>
                                    )
                                }
                            </ul>
                        )}
                    </div>
                )}
            </Downshift>
        );
    });

export default AutocompleteOrganisation;