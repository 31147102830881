import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { api } from '@app.raytd.com/store';
import { useNavigate } from 'react-router-dom';

type RequestStatus = 'idle' | 'pending' | 'success' | 'error';

export function ForgotPasswordPage() {
    const navigate = useNavigate();
    const [requestStatus, setRequestStatus] = useState<RequestStatus>('idle');
    const [showMessage, setShowMessage] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();

    //reset to idle on unmount
    useEffect(() => {
        return () => {
            setShowMessage(false);
            setRequestStatus('idle');
        };
    }, []);

    const onSubmit = async (data: any) => {
        setShowMessage(false);

        if (requestStatus === 'success') {
            navigate('/login');
            return;
        }

        if (requestStatus === 'pending') {
            return;
        }

        setRequestStatus('pending');

        try {
            // Simulate API request to reset password
            console.log('Requesting password reset for:', data.email);

            const response = await api.post(`/api/v1/forgot-password`, {
                email: data.email
            });

            console.log('Response:', response);
            setShowMessage(true);
            setRequestStatus('success');
        } catch (error) {
            setRequestStatus('error');
        }
    };

    return (

        <Card className="w-96 border-none shadow-none">
            <CardHeader className="mb-4">
                <CardTitle className="text-center text-2xl text-zinc-800">Reset your password</CardTitle>
            </CardHeader>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-8">
                        <Label htmlFor="email" className="sr-only">Email address</Label>
                        <Input
                            id="email"
                            type="email"
                            {...register('email', { required: true })}
                            className="mt-1"
                            autoComplete="email"
                            placeholder="Email address"
                            aria-invalid={errors.email ? 'true' : 'false'}
                            hidden={requestStatus === 'success'}
                            readOnly={requestStatus === 'success'}
                        />
                        {errors.email && <p className="text-red-600 text-sm mt-1">Email is required</p>}
                    </div>

                    <Button type="submit" className="w-full" disabled={requestStatus === 'pending'}>
                        {requestStatus === 'pending' && 'Processing...'}
                        {requestStatus === 'success' && 'Return to Login'}
                        {requestStatus === 'error' && 'Try again'}
                        {requestStatus === 'idle' && 'Reset password'}
                    </Button>

                    {showMessage && (
                        <div className="mt-3 text-sm text-zinc-500 text-center">
                            Password reset instructions have been sent to the nominated email address.
                        </div>
                    )}

                </form>
            </CardContent>
        </Card>

    );
}

export default ForgotPasswordPage;
