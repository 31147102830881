import { Card, CardContent } from "@/components/ui/card";
import React, { Component, ReactNode } from "react";


interface CustomFieldErrorBoundaryProps {
    children: ReactNode;
  }
  
  interface CustomFieldErrorBoundaryState {
    hasError: boolean;
  }
  

  class CustomFieldErrorBoundary extends Component<CustomFieldErrorBoundaryProps, CustomFieldErrorBoundaryState> {
    state = { hasError: false };
  
    static getDerivedStateFromError(error: Error) {
      return { hasError: true };
    }
  
    render() {
      if (this.state.hasError) {
        return (
          <Card>
            <CardContent>
              <div className="text-red-500">
                Something went wrong loading this field. Please try refreshing.
              </div>
            </CardContent>
          </Card>
        );
      }
  
      return this.props.children;
    }
  }

  export default CustomFieldErrorBoundary;