import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import type { AddressType } from ".";
import { Address, formatAddress } from "@app.raytd.com/store";
import { convertAddressTypeToAddress } from "@/app/features/assets/components/address";
import CountryDropdown from "@/components/raytd/country-dropdown";

interface AddressDialogProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	address: AddressType;
	setAddress: (address: AddressType) => void;
	adrAddress: string;
	dialogTitle: string;
	isLoading: boolean;
}

interface AddressFields {
	address1?: string;
	city?: string;
	region?: string;
	postalCode?: string;
}

function createAddressSchema(address: AddressFields) {
	let schema: Record<string, z.ZodTypeAny> = {};

	if (address.address1 !== "") {
		schema.address1 = z.string().min(1, { message: "Address line 1 is required" });
	}

	if (address.city !== "") {
		schema.city = z.string().min(1, { message: "Suburb is required" });
	}

	if (address.region !== "") {
		schema.region = z.string().min(1, { message: "State is required" });
	}

	if (address.postalCode !== "") {
		schema.postalCode = z.string().min(1, { message: "Postal code is required" });
	}

	return z.object(schema);
}

export default function AddressDialog({
	children,
	dialogTitle,
	open,
	setOpen,
	address,
	setAddress,
	adrAddress,
	isLoading,
}: React.PropsWithChildren<AddressDialogProps>) {

	const addressSchema = z.object({
		address1: z.string().min(1, { message: "Address line 1 is required" }),
		city: z.string().min(1, { message: "Suburb is required" }),
		region: z.string().min(1, { message: "State is required" }),
		postalCode: z.string().min(1, { message: "Postal code is required" }),
		country: z.string().min(1, { message: "Country is required" }),
	});

	const form = useForm<z.infer<typeof addressSchema>>({
		resolver: zodResolver(addressSchema),
		defaultValues: {
			address1: '',
			city: '',
			region: '',
			postalCode: '',
			country: 'Australia',
		},
	});

	function updateAndFormatAddress(
		addressString: string,
		addressComponents: {
			"street-address": string;
			locality: string;
			region: string;
			"postal-code": string;
		},
	) {
		let updatedAddressString = addressString;

		Object.entries(addressComponents).forEach(([key, value]) => {
			const regex = new RegExp(`(<span class="${key}">)[^<]*(</span>)`, "g");
			updatedAddressString = updatedAddressString?.replace(regex, `$1${value}$2`);
		});

		updatedAddressString = updatedAddressString.replace(/<\/?span[^>]*>/g, "");

		updatedAddressString = updatedAddressString
			.replace(/,\s*,/g, ",")
			.trim()
			.replace(/\s\s+/g, " ")
			.replace(/,\s*$/, "");

		return updatedAddressString;
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		form.handleSubmit(onSubmit)(e);
	};

	function onSubmit(values: z.infer<typeof addressSchema>) {
		console.log('AddressDialog onSubmit', { values });

		if (
			values.postalCode !== address.postalCode ||
			values.address1 !== address.address1 ||
			values.city !== address.city ||
			values.region !== address.region
		) {
			console.info('AddressDialog onSubmit setAddress', { values });
			setAddress({
				address1: values.address1,
				city: values.city,
				region: values.region,
				postalCode: values.postalCode,
				country: values.country,
				lat: 0,
				lng: 0,
				//@ts-ignore
				formattedAddress: formatAddress(convertAddressTypeToAddress({
					...values,
					country: values.country
				}))
			});
		}
		setOpen(false);
	}

	useEffect(() => {
		if (open) {
			console.log('AddressDialog useEffect', { address, open });
			form.reset({
				address1: address.address1 || '',
				city: address.city || '',
				region: address.region || '',
				postalCode: address.postalCode || '',
				country: address.country || 'Australia',
			});
		}
	}, [address, open, form.reset]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent disableOutsideClick onMouseDown={e => e.stopPropagation()} onPointerDownOutside={(e) => e.preventDefault()}>
				<DialogHeader>
					<DialogTitle>{dialogTitle}</DialogTitle>
				</DialogHeader>

				{isLoading ? (
					<div className="h-52 flex items-center justify-center">
						<Loader2 className="size-6 animate-spin" />
					</div>
				) : (
					<Form {...form}>
						<form onSubmit={handleSubmit} className="space-y-4 py-7">
							<FormField
								control={form.control}
								name="address1"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Address line 1</FormLabel>
										<FormControl>
											<Input
												{...field}

												placeholder="Address line 1"
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>

							<div className="flex gap-4">
								<FormField
									control={form.control}
									name="city"
									render={({ field }) => (
										<FormItem className="flex-1">
											<FormLabel>Suburb</FormLabel>
											<FormControl>
												<Input
													{...field}

													placeholder="City"
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="region"
									render={({ field }) => (
										<FormItem className="flex-1">
											<FormLabel>State</FormLabel>
											<FormControl>
												<Input
													{...field}

													placeholder="Region"
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>

							<div className="flex gap-4">
								<FormField
									control={form.control}
									name="postalCode"
									render={({ field }) => (
										<FormItem className="flex-1">
											<FormLabel>Postal Code</FormLabel>
											<FormControl>
												<Input
													{...field}

													placeholder="Postal Code"
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								{/* <FormItem className="flex-1">
									<FormLabel>Country</FormLabel>
									<Input
										value={address?.country}
										disabled
										placeholder="Country"
									/>
								</FormItem> */}

								<FormField
									control={form.control}
									name="country"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Country</FormLabel>
											<FormControl>
												<CountryDropdown
													onSelect={(country) => {
														field.onChange(country.value)
													}}
													defaultValue={field.value}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>

							<DialogFooter>
								<Button
									type="button"
									onClick={() => setOpen(false)}
									variant="outline"
								>
									Cancel
								</Button>
								<Button type="submit" onClick={e => e.stopPropagation()}>Save</Button>
							</DialogFooter>
						</form>
					</Form>
				)}
			</DialogContent>
		</Dialog>
	);
}