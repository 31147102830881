import SubPageLayout from "@/app/features/organisation/SubPageLayout"
import { useNav } from "@/app/features/profile/NavContext"
import SearchInput from "@/components/raytd/search-input"
import { useGetInspectionQuery } from "@app.raytd.com/store"
import React, { useEffect, useState } from "react"
import { OrganisationsTable } from '../assets/AssetCollaboratorPage'


const InspectionsCollaborators: React.FC<{ id: string }> = ({ id }) => {
    const [searchTerm, setSearchTerm] = useState("")
  
    const { data: inspection, isLoading } = useGetInspectionQuery(id);
  
    console.info('asset', inspection);
  
    const organisations = inspection?.asset?.organisation ? [inspection?.asset?.organisation] : [];

    console.info('organisations', organisations);   
  
    const { busy, setIsBusy, setNavButtons } = useNav();
  
    useEffect(() => {
      setNavButtons([
        <SearchInput key="search" searchTerm={searchTerm} setSearchTerm={setSearchTerm} />,
      ])
      return () => {
        setNavButtons([]);
      }
    }, [setNavButtons, searchTerm, setSearchTerm]);
  
    return (
      <SubPageLayout title="Collaborators" showLoading={isLoading || busy}>
  
        <div className="space-y-8">
          <OrganisationsTable organisations={organisations} searchTerm={searchTerm} />
        </div>
      </SubPageLayout>
    )
  }
  
  export default InspectionsCollaborators;