import { schema } from 'normalizr';
import { Building, Floor, FloorLocation } from '../asset/types';
import { AssetEntity } from '../asset/types/asset';

const roomSchema = new schema.Entity<FloorLocation>('rooms');

const levelSchema = new schema.Entity<Floor>('levels', {
  rooms: [roomSchema]
});

const buildingSchema = new schema.Entity<Building>('buildings', {
  levels: [levelSchema],
});

const assetSchema = new schema.Entity<AssetEntity>('assets', {
  buildings: [buildingSchema],
});


export { levelSchema, buildingSchema, assetSchema };