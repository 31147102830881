import TestSpecificationForm from "@/app/features/testsuites/specifications/TestSpecificationForm"
import ExpandButton from "@/components/raytd/expand-button"
import FastFillCountPill from "@/components/raytd/fast-fill-count-pill"
import ListButton from "@/components/raytd/list-button"
import { Pill } from "@/components/raytd/pill"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible"
import { Input } from "@/components/ui/input"
import { cn } from "@/lib/utils"
import { TestSpecificationsEntity } from "@app.raytd.com/store"
import { CornerDownRight } from "lucide-react"
import { useCallback, useMemo, useState } from "react"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'; // Shadcn dropdown components
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { Button } from "@/components/ui/button"
import { useTestSpecifications } from "@/app/features/testsuites/specifications/Context"
import { AlertDialog } from '@/components/ui/alert-dialog';
import { useAlertDialog } from "@/hooks/useAlertDialog"
import { is } from 'date-fns/locale';
import ModifiedPill from "@/components/raytd/modified-pill"

type TestProps = {
    test: TestSpecificationsEntity
}

/*
export type TestSpecificationsEntity = {
  id: number;
  test_suite_id: number;
  label: string;
  category: string;
  subcategory: string;
  description: string;
  observations: Observations[];
  recommendations: Recommendation[];
  ratingScale: number;
  observations_required: boolean;
  recommendations_required: boolean;
};
*/


export const TestActionDropdown = (
    {
        test,
        onDelete
    }: {
        test: TestSpecificationsEntity;
        onDelete: (id: number) => void;
    }

) => {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="outline" className="h-8 w-8 p-0 bg-white">
                    <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuItem className="text-red-600" onClick={() => onDelete(test.id)}>Delete</DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

export function Test({ test }: TestProps) {
    // console.debug('Test', test);

    const [isExpanded, setIsExpanded] = useState(false);
    const { onDelete, onUpdate, onDirtyChange, dirtyForms } = useTestSpecifications();

    const isDirty = useMemo(() => dirtyForms.has(`test-${test.id.toString()}`), [dirtyForms, test.id]);

    const { AlertDialogComponent, showAlertDialog } = useAlertDialog();

    const handleToggle = useCallback(() => {

        if (isExpanded && dirtyForms.has(`test-${test.id.toString()}`)) {
            // If the form is dirty, we don't want to close the collapsible
            console.debug('is dirty');

            showAlertDialog({
                title: 'Unsaved Changes',
                description:
                    'You have unsaved changes. Do you wish to discard?',
                confirmText: 'Discard',
                cancelText: 'Keep Editing',
            }).then((confirmed) => {
                if (confirmed) {
                    setIsExpanded(!isExpanded);
                } else {
                    return;
                }
            });

            return;
        }

        setIsExpanded(!isExpanded);
    }, [isExpanded, setIsExpanded, dirtyForms, showAlertDialog, test.id]);

    const handleDelete = useCallback((id: number) => {
        onDelete(id);
    }, [onDelete]);

    const handleUpdate = useCallback(async (data) => {
        try {
            await onUpdate({
                ...test,
                ...data
            });
            setIsExpanded(false);
        } catch (error) {
            console.error(error);
        }
    }, [onUpdate, test]);

    return (
        <Collapsible open={isExpanded} onOpenChange={handleToggle} className={cn({ "bg-zinc-100 ml-2": isExpanded })}>
            <div className="mb-2 flex items-center space-x-4">
                <div className="ml-6"><CornerDownRight className="h-4 w-4" /></div>
                <Pill className="flex-shrink-0">{test.label}</Pill>
                <div
                    className="flex-shrink mr-2 max-w-sm text-zinc-600 border-zinc-300 truncate overflow-hidden text-sm border px-2 py-1.5 rounded-md"
                >
                    {test.description ?? ''}
                </div>
                <div className="flex-1" />
                <ModifiedPill isDirty={isDirty} />
                <FastFillCountPill item={{
                    observations_count: test.observations?.length,
                    recommendations_count: test.recommendations?.length
                }} />
                <TestActionDropdown test={test} onDelete={handleDelete} />
                <CollapsibleTrigger asChild>
                    <ExpandButton variant="item" expanded={isExpanded} />
                </CollapsibleTrigger>
            </div>
            <CollapsibleContent className="px-2 pb-2">

                <TestSpecificationForm
                    specification={test}
                    onSubmit={handleUpdate}
                    onDiscard={() => { }}
                    formId={`test-${test.id.toString()}`}
                    onDirtyChange={onDirtyChange}
                />

            </CollapsibleContent>
            <AlertDialogComponent />

        </Collapsible>
    )
}