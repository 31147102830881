import * as React from "react"
import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { ScrollArea } from "@/components/ui/scroll-area"
import { cn } from "@/lib/utils"
import { useFormContext, Controller } from "react-hook-form"
import { ChevronRight, Clipboard, FileStack } from 'lucide-react'
import ImageWithFallback from "@/components/raytd/image-with-fallback"
import AddressLine from "@/app/features/assets/AddressLine"
import OrganisationAvatar from "@/components/raytd/organisation-avatar"
import { ValuePill } from "@/components/raytd/value-pill"
import SpacesPill from "@/components/raytd/asset-spaces-pill"
import { selectAllAssets, useAppSelector, useGetAssetsQuery } from "@app.raytd.com/store"
import { FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Skeleton } from "@/components/ui/skeleton"
import LoadingState from "@/components/raytd/loading-state"


interface Asset {
    id: string
    name: string
    imageUrl: string
    address: string
    numberOfAssessments: number
    numberOfReports: number
    spaces: number
    organisation: {
        name: string
        logoUrl: string
    }
}

interface AssetSelectorProps {
    name: string
    label?: string
    disabled?: boolean
}

export function AssetSelector({ name, label = "Asset", disabled = false }: AssetSelectorProps) {
    const [open, setOpen] = React.useState(false)
    const [search, setSearch] = React.useState("")
    const { isLoading } = useGetAssetsQuery(undefined)
    const assets = useAppSelector(selectAllAssets);
    const form = useFormContext()

    const filteredAssets = React.useMemo(() => {
        return assets.filter((asset) =>
            asset.name.toLowerCase().includes(search.toLowerCase())
        )
    }, [assets, search])

    const selectedAsset = React.useMemo(() => {
        const selectedId = form.watch(name)
        return assets.find(asset => asset.id === selectedId)
    }, [assets, form, name]);

    const handleClick = React.useCallback(() => {
        if (disabled) return;
        setOpen(true)
    }, [disabled]);

    return (
        <FormField
            name={name}
            control={form.control}
            render={({ field }) => (
                <div className="space-y-2">
                    <div className="flex justify-between items-center">
                        <FormLabel>{label}</FormLabel>
                    </div>

                    {selectedAsset && !isLoading && (
                        <div className={cn(
                            "flex items-center space-x-4 p-4 border rounded-md",
                            { "cursor-pointer hover:bg-accent hover:text-accent-foreground": !disabled },
                            { "bg-zinc-200": disabled }
                        )}
                            onClick={handleClick}
                        >
                            <ImageWithFallback
                                src={selectedAsset.imageUrl}
                                alt={selectedAsset.name}
                                className="h-16 w-16 rounded-lg object-cover"
                                fallbackSrc='/no_building.svg'
                            />
                            <div>
                                <p className="font-semibold">{selectedAsset.name}</p>
                                <AddressLine address={selectedAsset.address} />
                            </div>
                        </div>
                    )}

                    {
                        isLoading && (
                            <div className="h-20 flex flex-row w-full gap-4">
                                <Skeleton className="h-16 w-16" />
                                <Skeleton className="h-16 w-full" />
                            </div>
                        )

                    }

                    {(!selectedAsset && !disabled && !isLoading) && (
                        <Button
                            type="button"
                            variant="outline"
                            size="sm"
                            className="text-zinc-600 bg-zinc-100 border-zinc-200"
                            onClick={() => setOpen(true)}
                            disabled={disabled}
                        >
                           Select <ChevronRight className="w-4 h-4 text-zinc-600" />
                        </Button>
                    )}

                    <FormMessage />

                    <Dialog open={open} onOpenChange={setOpen}>
                        <DialogContent className="max-w-3xl">
                            <DialogHeader>
                                <DialogTitle>Assets</DialogTitle>
                            </DialogHeader>
                            <div className="space-y-4 py-4">
                                <Input
                                    placeholder="Search assets..."
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="w-full"
                                />
                                <ScrollArea className="h-[400px] rounded-md border p-4">
                                    {isLoading ? (
                                        <div className="text-center">Loading assets...</div>
                                    ) : (
                                        <div className="space-y-4">
                                            {filteredAssets.map((asset, index) => (
                                                <div
                                                    key={asset.id}
                                                    className={cn(
                                                        "flex items-center space-x-4 rounded-md cursor-pointer transition-colors",
                                                        "hover:bg-accent hover:text-accent-foreground",
                                                        field.value === asset.id && "bg-accent text-accent-foreground",
                                                        "border-b border-zinc-200", // Add bottom border
                                                        index === filteredAssets.length - 1 && "border-b-0" // Remove border from the last div
                                                    )}
                                                    onClick={() => {
                                                        field.onChange(asset.id)
                                                        setOpen(false)
                                                    }}
                                                >
                                                    <ImageWithFallback
                                                        src={asset.imageUrl}
                                                        alt={asset.name}
                                                        className="h-16 w-16 rounded-lg object-cover"
                                                        fallbackSrc='/no_building.svg'
                                                    />
                                                    <div className="flex-grow">
                                                        <p className="font-semibold">{asset.name}</p>
                                                        <AddressLine address={asset.address} />
                                                        <div className="flex space-x-2 mt-2">
                                                            {/* <SpacesPill asset={asset} /> */}
                                                            <ValuePill label="Inspections" value={asset.numberOfAssessments} iconOnly icon={<Clipboard className="text-zinc-500 h-4 w-4" />} />
                                                            <ValuePill label="Reports" value={asset.numberOfReports} iconOnly icon={<FileStack className="text-zinc-500 w-4 h-4" />} />
                                                        </div>
                                                    </div>
                                                    <OrganisationAvatar organisation={asset.organisation} size='sm' />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </ScrollArea>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        />
    )
}

