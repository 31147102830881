import { AssetAPI } from './api';
import {
  EntityState,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { Floor } from './types';

export const LEVELS_FEATURE_KEY = 'levels';

const levelsAdapter = createEntityAdapter<Floor>();

export interface LevelsState extends EntityState<Floor, number> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  error?: string | null;
}

export const initialLevelsState: LevelsState = levelsAdapter.getInitialState({
  loadingStatus: 'not loaded',
  error: null,
});

export const fetchLevels = createAsyncThunk<Floor[], number>(
  'asset/fetchLevels',
  async (buildingId: number, thunkAPI) => {
    console.info('fetch levels', buildingId);
    try {
      const response = await AssetAPI.fetchLevels(buildingId);
      console.info('fetch levels response', response.body);
      return response.body.data as Floor[];
    } catch (error) {
      console.info('fetch levels error', error);
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const updateLevelOrder = createAsyncThunk<void, number[]>(
  'asset/updateLevelOrder',
  async (ids: number[], thunkAPI) => {
    console.info('update level order', ids);
    try {
      const response = await AssetAPI.updateLevelOrder(ids);
      console.info('update level order response', response.body);
      return response.body;
    } catch (error) {
      console.info('update level order error', error);
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const levelSlice = createSlice({
  name: LEVELS_FEATURE_KEY,
  initialState: initialLevelsState,
  reducers: {
    add: levelsAdapter.addOne,
    remove: levelsAdapter.removeOne,
    updateLevel(state, action) {
      levelsAdapter.upsertOne(state, action.payload);
    },
    updateLevels(state, action) {
      console.info('levels received', action.payload);
      levelsAdapter.upsertMany(state, action.payload);
      state.loadingStatus = 'loaded';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLevels.pending, (state: LevelsState) => {
        state.loadingStatus = 'loading';
      })
      .addCase(
        fetchLevels.fulfilled,
        (state: LevelsState, action: PayloadAction<Floor[]>) => {
          //levelsAdapter.setAll(state, action.payload);
          levelsAdapter.upsertMany(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(fetchLevels.rejected, (state: LevelsState, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const levelsReducer = levelSlice.reducer;
export const levelsActions = {
  ...levelSlice.actions,
};

const { selectAll, selectEntities, selectById } = levelsAdapter.getSelectors();

export const getLevelState = (rootState: {
  [LEVELS_FEATURE_KEY]: LevelsState;
}): LevelsState => {
  return rootState[LEVELS_FEATURE_KEY];
};

export const selectAllLevels = createSelector(getLevelState, selectAll);

export const selectLevelEntities = createSelector(
  getLevelState,
  selectEntities
);

export const selectLevelLoadingStatus = createSelector(
  getLevelState,
  (state: LevelsState) => state.loadingStatus
);

export const selectLevelById = (levelId: number) =>
  createSelector(getLevelState, (state) => {
    return state.entities[levelId];
  });

export const selectLevelsByAssetId = createSelector(
  selectAllLevels, // selector that selects all levels from the state
  //(state: LevelsState, assetId: number) => assetId, // selector that selects the assetId from the props
  (_, assetId: number) => assetId, // selector that selects the assetId from the props
  (levels: Floor[], assetId: number) =>
    levels.filter((level) => level.asset_id === assetId)
);
