// uiSlice.js
import { createSlice } from '@reduxjs/toolkit';


export interface UIState {
  pageTitle: string;
  breadcrumbs: string[];
  activeElements: { [key: string]: string };
}

export const initialUIState: UIState = {
  pageTitle: null,
  breadcrumbs: [],
  activeElements: {},
};

export const UISTATE_FEATURE_KEY = 'ui'

export const uiSlice = createSlice({
  name: UISTATE_FEATURE_KEY,
  initialState: initialUIState,
  reducers: {
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
    setActiveElement: (state, action) => {
      state.activeElements[action.payload.id] = action.payload.value;
      console.info('ui', state.activeElements);
    }
  },
});

export const { setPageTitle, setBreadcrumbs, setActiveElement } = uiSlice.actions;

export const selectUiState = (state) => state.ui;

export default uiSlice.reducer;