import { useGetTestSuiteQuery, useUpdateTestSuiteMutation } from "@app.raytd.com/store";
import RatingToolSelector from "./v2/RatingToolSelector";
import SubPageLayout from "../organisation/SubPageLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import LoadingState from "@/components/raytd/loading-state";
import { useNav } from "@/app/features/profile/NavContext";
import BusyButton from "@/components/raytd/busy-button";

const RatingToolPage = ({
    testSuiteId
}: {
    testSuiteId: string;
}) => {

    const { data: suite, isLoading } = useGetTestSuiteQuery(testSuiteId);
    const [updateTestSuite, { isLoading: isUpdating }] = useUpdateTestSuiteMutation();
    const [selectedRatingScale, setSelectedRatingScale] = useState<number>(undefined);

    const isDirty = useMemo(() => {
        return suite?.ratingScale?.id !== selectedRatingScale;
    }, [suite, selectedRatingScale]);

    const isBusy = isUpdating || isLoading;

    useEffect(() => {
        if (suite) {
            setSelectedRatingScale(suite?.ratingScale?.id);
        }
    }, [suite]);

    const handleUpdate = useCallback(async () => {

        console.debug({ selectedRatingScale });
        await updateTestSuite({
            id: testSuiteId,
            rating_scale_id: selectedRatingScale
        });

    }, [updateTestSuite, testSuiteId, selectedRatingScale]);

    const { setNavButtons } = useNav();

    useEffect(() => {
        setNavButtons(
            <BusyButton
                onClick={handleUpdate}
                disabled={!isDirty}
                isBusy={isBusy}
                busyText="Saving..."
            >
                Save
            </BusyButton>
        );

        return () => setNavButtons(null);
    }, [
        setNavButtons,
        handleUpdate,
        isDirty,
        isBusy
    ]);

    if (isLoading) {
        return <LoadingState />;
    }

    return (
        <SubPageLayout title="Rating Tool">

            <RatingToolSelector
                testSuite={suite}
                onTypeSelect={(type) => setSelectedRatingScale(type)}
            />
        </SubPageLayout>
    );

}

export default RatingToolPage;