import { useAuth } from "@/app/_components";
import { RoleTypes, TeamMember } from "@/app/features/organisation/types";
import ErrorAlert from "@/components/raytd/form-error";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader } from "@/components/ui/alert-dialog";
import { DataTable } from "@/components/ui/data-table";
import { Input } from "@/components/ui/input";
import { CompanyEntity, useGetTeamUsersQuery, useLeaveOrganisationMutation, useUpdateUserRoleMutation } from "@app.raytd.com/store";
import { AlertDialogTitle } from "@radix-ui/react-alert-dialog";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import AddTeamMemberDialog from "./AddTeamMemberDialog";
import SubPageLayout from './SubPageLayout';
import { createColumns } from "./TeamColumns";
import { Badge } from "@/components/ui/badge";

interface TeamPageProps {
    organisation: CompanyEntity; // Define the prop type
}

export const TeamPage: React.FC<TeamPageProps> = ({ organisation }) => {
    const [globalFilter, setGlobalFilter] = useState("")
    const { data: teamMembers, isLoading, isFetching, error } = useGetTeamUsersQuery({});
    const [updateUserRole, { isLoading: isUpdatingRole, isError: isUpdateRoleError, error: updateUserRoleError }] = useUpdateUserRoleMutation();
    const { user, refreshUser } = useAuth();
    const [userToRemove, setUserToRemove] = useState<TeamMember | null>(null);
    const [leaveOrganisation, { isLoading: isLeavingOrganisation, isError: isLeavingOrgError, error: leaveOrgError }] = useLeaveOrganisationMutation();
    const navigate = useNavigate();

    const handleChangeRole = useCallback(async (member: TeamMember, newRole: RoleTypes) => {
        try {
            console.log("Change role", { userId: member.id, organisation_id: organisation.id, role: newRole })
            const response = await updateUserRole({ user_id: member.id, organisation_id: organisation.id, role: newRole });
            console.log("Update role response", response);
            if (response.error) {
                //@ts-ignore
                throw new Error(response.error?.message ?? "Error updating user role");
            }
            toast.success("User role updated successfully");
        } catch (error) {
            console.error("Error updating user role", { error, isUpdateRoleError, updateUserRoleError });
            toast.error("Error updating user role");
        }
    }, []);

    console.info('Team members', organisation, user);

    const handleRemoveMemberConfirmation = useCallback((member: TeamMember) => {

        // Implement your logic here
        setUserToRemove(member);

    }, []);

    const handleRemoveMember = useCallback(async () => {
        console.log(`Remove ${userToRemove.name}`);

        try {
            const response = await leaveOrganisation({ id: organisation.id, user_id: userToRemove.id });
            await refreshUser();
            console.log("Leave organisation response", response);
            toast.success("User removed successfully");

            if (userToRemove.id === user.id) {
                navigate('/');
            }

        } catch (error) {
            console.error("Error removing user", { error, isLeavingOrgError, leaveOrgError });
            toast.error("Error removing user");
        } finally {
            setUserToRemove(null);
        }

    }, [userToRemove]);

    const columns = createColumns({
        userId: user.id,
        userRole: organisation.role,
        onChangeRole: handleChangeRole,
        onRemoveMember: handleRemoveMemberConfirmation,
    });

    if (error) {
        //@ts-ignore
        return <ErrorAlert description={error.message} />
    }

    return (
        <SubPageLayout
            title='Team'
            showSpinner={isFetching || isLoading || isUpdatingRole}
            badge={<Badge>{organisation?.role}</Badge>}
        >
            <div className="space-y-4 flex flex-col">
                <div className="flex justify-between items-end">
                    <Input
                        placeholder="Search team members..."
                        value={globalFilter ?? ""}
                        onChange={(event) => setGlobalFilter(event.target.value)}
                        className="max-w-sm"
                    />
                    <AddTeamMemberDialog organisation={organisation} />
                </div>
                <DataTable
                    columns={columns}
                    showHeaders={false}
                    isLoading={isLoading}
                    data={teamMembers}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    emptyMessage="No team members available"
                    showPagination={true}
                    showPageSize={false}
                    showFirstLastButtons={false}
                    itemsText="users"
                    pageSizeText="Users per page"
                    pageSizeOptions={[50]}
                />
            </div>
            <AlertDialog open={userToRemove !== null} onOpenChange={(open) => !open && setUserToRemove(null)}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Remove Member?</AlertDialogTitle>
                        <AlertDialogDescription>
                            {userToRemove?.id === user.id ? "If you remove yourself from the organisation, you'll no longer have access and will need to be reinvited." : `By removing the member from the team, they will lose access to the organisation.`}
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleRemoveMember}>Confirm</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </SubPageLayout>
    );

};

export default TeamPage;