import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom"
import { Assessment, useGetInspectionsQuery } from "@app.raytd.com/store"
import { TestSuiteType } from '../../../../store/src/lib/tests/entity';
import TestTypePill from "@/components/raytd/test-type-pill"
import { TestCountPills } from "@/components/raytd/test-count-pills"
import { SkeletonLoader } from "@/app/features/assets/Skeleton"
import { ListActionDropdown } from "@/app/features/inspections/ListActionDropdown"
import { useEffect, useState, useMemo } from "react"
import { cn } from "@/lib/utils"
import OrganisationAvatar from "@/components/raytd/organisation-avatar"
import RetiredBadge from "@/components/raytd/retired-badge"

interface InspectionsListProps {
    filter: string
    searchTerm: string
    filters?: {
        assetId?: string
    }
    handleAdd: () => void
}

type InspectionItemProps = {
    inspection: Assessment
    onClick: (inspection: Assessment) => void
}

const Inspection = ({ inspection, onClick }: InspectionItemProps) => {
    const opacity = inspection.status === 'archived' ? 0.5 : 1

    const [isBusy, setIsBusy] = useState(false);

    return (
        <TableRow
            key={inspection.id}
            style={{ opacity }}
            className={cn(
                { 'animate-pulse bg-primary-foreground bg-opacity-10': isBusy },
            )}
        >
            <TableCell className="group cursor-pointer flex flex-row items-center" onClick={() => onClick(inspection)}>
                <div className="mr-2">
                    <OrganisationAvatar organisation={inspection.asset.organisation} size='md' />
                </div>
                <div>
                    <div className="font-medium">{inspection.title}</div>
                    <div className="text-sm text-muted-foreground">{inspection.asset?.title}</div>
                </div>
            </TableCell>

            <TableCell>
                <div className="flex flex-wrap items-center space-x-4 justify-end">
                    <RetiredBadge retired={inspection.retired} />
                    <div className="flex -space-x-2">
                        {inspection.collaborators.map((collaborator) => (
                            <Avatar key={collaborator.id} className="h-8 w-8 border-2 border-background">
                                <AvatarImage src={collaborator.avatar} alt={collaborator.name} />
                                <AvatarFallback>{collaborator.name[0]}</AvatarFallback>
                            </Avatar>
                        ))}
                    </div>

                    <div className="flex flex-wrap items-end space-x-1">
                        {
                            inspection.testSuiteTypes.map((type: TestSuiteType) => (
                                <TestTypePill key={type} type={type} />
                            ))
                        }
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <TestCountPills testCounts={inspection.testCounts} />
            </TableCell>
            <TableCell>
                <ListActionDropdown inspection={inspection} setIsBusy={setIsBusy} />
            </TableCell>
        </TableRow>
    )
}

export const InspectionList = ({
    filter, searchTerm, filters, handleAdd
}: InspectionsListProps) => {
    const navigate = useNavigate();

    // This would be replaced with actual data from your API
    //const inspections: Inspection[] = []
    const { data: inspections, isLoading, isFetching } = useGetInspectionsQuery()

    const groupFilteredInspections = useMemo(() => inspections?.filter((inspection) => {
        const matchesAsset = filters?.assetId ? inspection.asset?.id === parseInt(filters.assetId) : true;
        return matchesAsset
    }), [inspections, filters])

    const filteredInspections = groupFilteredInspections?.filter((inspection) => {
        const matchesInspectionTitle = inspection.title.toLowerCase().includes(searchTerm.toLowerCase())
        const matchesAssetTitle = inspection.asset?.title.toLowerCase().includes(searchTerm.toLowerCase())

        const matchesSearch = matchesInspectionTitle || matchesAssetTitle

        const matchesFilter = filter === 'all' || inspection.status === filter;
        const matchesAsset = filters?.assetId ? inspection.asset?.id === parseInt(filters.assetId) : true;
        return matchesSearch && matchesFilter && matchesAsset
    })

    console.debug('inspections', inspections);

    useEffect(() => {
        if (isFetching) {
            console.log('Fetching inspections')
        }
    }, [isFetching])

    const handleAssessmentClick = (inspection: Assessment) => {
        navigate(`/inspections/${inspection.id}`)
    }

    if (groupFilteredInspections?.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center py-12">
                <h3 className="text-lg font-semibold">You have no inspections</h3>
                <p className="text-muted-foreground mt-2">Create your first inspection to get started.</p>
                <Button onClick={handleAdd} className="mt-4">
                    Create Inspection
                </Button>
            </div>
        )
    }

    if (isLoading) {
        return <SkeletonLoader />
    }

    return (
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHead>Name</TableHead>
                    <TableHead></TableHead>
                    <TableHead className="w-[220px]"></TableHead>
                    <TableHead className="w-[50px]"></TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {filteredInspections?.map((inspection) => (
                    <Inspection key={inspection.id} inspection={inspection} onClick={handleAssessmentClick} />
                ))}
            </TableBody>
        </Table>
    )
}

export default InspectionList;
