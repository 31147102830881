import { TableCell, TableRow } from "../table"
import { FolderOpen } from "lucide-react"

interface EmptyTableProps {
  colSpan: number
  message?: string
}

export function EmptyTableComponent({ colSpan, message = "No results found" }: EmptyTableProps) {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} className="h-24 text-center">
        <div className="flex flex-col items-center justify-center">
          <FolderOpen className="h-8 w-8 text-gray-400 mb-2" />
          <span className="text-gray-500">{message}</span>
        </div>
      </TableCell>
    </TableRow>
  )
}