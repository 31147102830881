import { Loader2 } from "lucide-react"
import { Button, ButtonProps } from "@/components/ui/button"
import React from "react"

export interface BusyButtonProps extends ButtonProps {
  isBusy?: boolean
  busyText?: string
}

const BusyButton = React.forwardRef<HTMLButtonElement, BusyButtonProps>(
  ({ isBusy = false, children, busyText, className, ...props }, ref) => {
    const buttonText = isBusy ? busyText || "Processing..." : children

    return (
      <Button
        disabled={isBusy}
        ref={ref}
        className={`relative ${className}`}
        {...props}
        aria-busy={isBusy}
      >
        {isBusy && (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" aria-hidden="true" />
        )}
        <span className={isBusy ? "opacity-70" : ""}>{buttonText}</span>
      </Button>
    )
  }
)

BusyButton.displayName = "BusyButton"

export default BusyButton