import { UseFormReturn } from "react-hook-form"
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"

interface TestSuiteDetailsFormProps {
    //@ts-expect-error
    form: UseFormReturn<CreateFormValues>
    //@ts-expect-error
  onSubmit: (data: CreateFormValues) => void
}

export default function TestSuiteDetailsForm({ form, onSubmit }: TestSuiteDetailsFormProps) {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name*</FormLabel>
              <FormDescription>Used to identify your test suite on the Raytd web app, mobile app and reports.</FormDescription>
              <FormControl>
                <Input placeholder="Test Suite Name..." {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description*</FormLabel>
              <FormControl>
                <Textarea placeholder="Test Suite Description..." {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  )
}

