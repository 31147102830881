import OfficeTileContent from "@/app/features/organisation/OfficeTileContent"
import { Office } from "@/app/features/organisation/types"
import { Button } from "@/components/ui/button"
import { Command, CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { CommandLoading } from "cmdk"
import { MoreVertical } from "lucide-react"
import { useCallback, useEffect, useState } from "react"

type OfficeSelectorProps = {
    office: number;
    editable?: boolean;
    onSelect?: (office: Office) => void;
    offices: Office[];
    isLoading: boolean;
}

const OfficeSelector = ({ office, editable = true, onSelect, offices, isLoading = false }: OfficeSelectorProps) => {

    const [selectOfficeOpen, setSelectOfficeOpen] = useState(false)
    const [selectedOffice, setSelectedOffice] = useState<Office | undefined>(undefined);

    useEffect(() => {
        console.info('OfficeSelector useEffect', { office, offices });
        setSelectedOffice(offices?.find((o) => o.id === office));
    }, [office, offices]);

    const handleSelect = useCallback((office: Office) => {
        console.info('handleSelect', office);
        setSelectedOffice(office);
        onSelect?.(office);
        setSelectOfficeOpen(false);
    }, [onSelect]);

    const handleOpen = useCallback(() => {
        setSelectOfficeOpen(true);
    }, []);

    useEffect(() => {
		if (selectOfficeOpen) {
			// Pushing the change to the end of the call stack
			const timer = setTimeout(() => {
				document.body.style.pointerEvents = "";
			}, 0);

			return () => clearTimeout(timer);
		} else {
			document.body.style.pointerEvents = "auto";
		}
	}, [selectOfficeOpen]);

    return (
        <div className="flex items-center justify-between p-4 bg-gray-100 rounded-lg">
            <OfficeTileContent office={selectedOffice} />

            {editable && (
                <>
                    <DropdownMenu modal={false}>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="icon">
                                <MoreVertical className="h-4 w-4" />
                                <span className="sr-only">Open menu</span>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className="w-[200px]">
                            <DropdownMenuItem onSelect={handleOpen}>
                                Change Office
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                    <CommandDialog open={selectOfficeOpen} onOpenChange={setSelectOfficeOpen} >
                        <Command>
                            <CommandInput placeholder="Search Offices..." />
                            <CommandList>
                                {isLoading ? (
                                    <CommandLoading>Loading offices...</CommandLoading>
                                ) : (
                                    <>
                                        <CommandEmpty>No results found.</CommandEmpty>
                                        <CommandGroup heading="Offices">
                                            {offices.map((office) => (
                                                <CommandItem key={office.id} onSelect={() => handleSelect(office)} className="space-y-2">
                                                    <OfficeTileContent office={office} />
                                                </CommandItem>
                                            ))}
                                        </CommandGroup>
                                    </>
                                )}
                            </CommandList>
                        </Command>
                    </CommandDialog>

                </>
            )}

        </div>


    )
}


export default OfficeSelector;