import { Outlet } from 'react-router-dom';

export function AuthLayout() {
  return (
    <div className="flex h-screen w-full flex-1">
      <div className="flex flex-col items-center justify-center w-1/2 bg-black">
        {/* <h1 className="text-white text-6xl font-bold">raytd<span className="text-blue-500">.</span></h1> */}
        <img src="/raytd_logo_dark.png" alt="raytd." className="w-1/2" />
      </div>
      <div className="flex items-center justify-center w-1/2 bg-white">
        <Outlet />
      </div>
    </div>
  );
}

export default AuthLayout;
