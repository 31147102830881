import React from 'react';
import { Archive, ArchiveRestore } from 'lucide-react'; // Adjust the import path as necessary

interface ArchiveRestoreMenuItemProps {
    status: 'active' | 'archived';
    archiveText?: string;
    restoreText?: string;
}

const ArchiveRestoreMenuItem: React.FC<ArchiveRestoreMenuItemProps> = ({ 
    status ,
    archiveText = 'Archive',
    restoreText = 'Restore'
}) => {

    if (status === 'active') {
        return (
            <span className="flex flex-row gap-2">
                <Archive className="h-4 w-4" />
                {archiveText}
            </span>
        );
    }   

    return (
        <span className="flex flex-row gap-2">
            <ArchiveRestore className="h-4 w-4" />
            {restoreText}
        </span>
    );

};

export default ArchiveRestoreMenuItem;