import SearchInput, { SearchInputWithButton } from '@/components/raytd/search-input';
import classNames from 'classnames';
import React from 'react';

type FiltersProps = {
  selected: string;
  setFilter: (filter: string) => void;
  setSearchTerm: (term: string) => void;
};

const FilterButton = ({ label, onClick, active }) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "text-sm px-2 py-1.5 rounded-lg",
        {
          "bg-white font-semibold text-zinc-900": active,
          "bg-transparent text-muted-foreground": !active,
        })}
      style={{ minWidth: '50px' }}
    >
      {label}
    </button>
  );
}

const Filters: React.FC<FiltersProps> = ({ selected, setFilter, setSearchTerm }) => {

  return (
    <div className="flex justify-between items-center flex-1">
      <div className="flex space-x-2 bg-muted text-muted-foreground py-1 px-1 rounded-lg">
        <FilterButton label="All" onClick={() => setFilter('all')} active={selected === 'all'} />
        <FilterButton label="Current" onClick={() => setFilter('active')} active={selected === 'active'} />
        <FilterButton label="Archived" onClick={() => setFilter('archived')} active={selected === 'archived'} />
      </div>

      <SearchInputWithButton onSearch={setSearchTerm} placeholder="Search..." />
    </div>
  );
};

export default Filters;