import SubPageLayout from "@/app/features/organisation/SubPageLayout";
import { useNav } from "@/app/features/profile/NavContext";
import BusyButton from "@/components/raytd/busy-button";
import EmptyState from "@/components/raytd/empty-state";
import ExpandButton from "@/components/raytd/expand-button";
import FastFillCountPill from "@/components/raytd/fast-fill-count-pill";
import { FastFillEditor, FastFillItem } from "@/components/raytd/fast-fill-editor";
import FastFillViewer from "@/components/raytd/fast-fill-viewer";
import NestedListLoadingState from "@/components/raytd/nested-list-loading-state";
import OptionToggle from "@/components/raytd/option-toggle";
import { Pill } from "@/components/raytd/pill";
import { SearchInputDebounced } from "@/components/raytd/search-input";
import { Button } from "@/components/ui/button";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { TooltipProvider } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { useCreateClassificationItemMutation, useGetElementTreeQuery, useGetObservationsQuery, useGetRecommendationsQuery, useHandleObservationsMutation, useHandleRecommendationsMutation, useUpdateClassificationItemMutation } from "@app.raytd.com/store";
import { ReloadIcon } from "@radix-ui/react-icons";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { debounce } from 'lodash';
import { Plus } from "lucide-react";
import { compare } from 'natural-orderby';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "sonner";
import { ClassificationItem } from "store/src/lib/services/types";



const ElementEditorPage = ({ treeId }) => {

    const { data, error, isLoading } = useGetElementTreeQuery(parseInt(treeId));

    // if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error occurred</div>;
    //if (!data) return <div>No data available</div>;

    let nestedData = [];

    if (data) {
       nestedData = convertToNestedStructure(data);
    }

    return (
        <SubPageLayout title='Elements'>
            {
                isLoading ? (
                    <NestedListLoadingState />
                ) : (
                    <TooltipProvider>
                        <Spaces assetId={treeId} data={nestedData} />
                    </TooltipProvider>
                )
            }

        </SubPageLayout>
    )
}

function convertToNestedStructure(items: ClassificationItem[]) {

    function addLevel(items: ClassificationItem[], level = 0): ClassificationItem[] {
        return items.map(item => ({
            ...item,
            level,
            children: item.children ? addLevel(item.children, level + 1) : []
        }));
    }

    const itemsWithLevels = addLevel(items);

    function sortChildren(items: ClassificationItem[]) {
        // items.sort((a, b) => a.name.localeCompare(b.name));
        items.sort((a, b) => compare()(a.name, b.name));
        items.forEach(item => {
            if (item.children && item.children.length > 0) {
                sortChildren(item.children);
            }
        });
    }

    sortChildren(itemsWithLevels);

    function mapToTreeStructure(items: ClassificationItem[], parentId: string = '') {
        return items.map(item => ({
            // id: parentId ? `${parentId}-${item.id}` : item.id.toString(),
            id: item.id.toString(),
            parentId: parentId,
            original: item,
            title: item.name,
            isOpen: item.level < 3, // Adjust this condition as needed
            children: item.children && item.children.length > 0
                ? mapToTreeStructure(item.children, item.id.toString())
                : undefined,
        }));
    }

    return mapToTreeStructure(itemsWithLevels);
}


function searchNestedStructure(items: any[], searchTerm: string) {

    if (searchTerm.trim() === '') {
        return items;
    }

    const searchTermLower = searchTerm.toLowerCase();

    function search(items: any[]): any[] {
        let results = [];

        for (const item of items) {
            const match = item.title.toLowerCase().includes(searchTermLower);
            const childResults = item.children ? search(item.children) : [];
            const actualChildren = item.children?.length || 0;

            if (match || childResults.length > 0) {
                results.push({
                    ...item,
                    children: childResults,
                    actualChildren,
                });
            }
        }

        return results;
    }

    return search(items);

}

interface ListItem {
    id: string
    parentId?: number;
    original: any;
    title: string
    children?: ListItem[]
    actualChildren?: number;
    isOpen: boolean;
    recommendations_count?: number;
    observations_count?: number;
}

interface CountPillProps {
    count: number | null;
    countPillDescription: string;
}

const CountPill: React.FC<CountPillProps> = ({ count, countPillDescription }) => {
    if (count === null) return null;
    return (
        <Pill
            variant="disabled"
            size="sm"
            className="ml-2 flex-1"
        >
            {`${count} ${countPillDescription}`}
        </Pill>
    );
};

const ExpandedPillContent: React.FC<{ item: any; expanded: boolean; }> = ({ item, expanded }) => {
    if (!expanded) return null;
    return (
        <div className="grid grid-cols-2 gap-6 ml-2 py-4 px-4">
            <FastFillViewer title="Observations" items={item?.observations} />
            <FastFillViewer title="Recommendations" items={item?.observations} />
        </div>
    )
}

type ElementItemProps = {
    title: string
    isPlaceholder: boolean
    depth: number
    onClick?: () => void;
    childrenCount?: number;
    item: any;
}

const ElementItemPill: React.FC<ElementItemProps> = ({
    title,
    isPlaceholder,
    depth,
    onClick,
    childrenCount,
    item
}) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const nextType = elementTypeDescription(depth + 1);
    const countPillDescription = nextType ? pluralize(nextType, childrenCount) : 'undefined';

    return (

        <div className={classNames("w-full", { 'bg-zinc-100': isExpanded })}>
            <div className="flex items-center w-full py-1 gap-2">
                <div className={
                    cn(
                        {
                            'ml-0': depth === 0,
                            'ml-2': depth > 0,
                        }
                    )
                } />
                <Pill
                    variant={!isPlaceholder ? "enabled" : "disabled"}
                    bold={depth === 0}
                    className="cursor-pointer"
                    onClick={onClick ?? (() => { setIsExpanded(!isExpanded) })}
                >
                    {isPlaceholder && <Plus className="h-4 w-4 mr-2" />}
                    {title}
                </Pill>
                <div className="flex-shrink-0">
                    <CountPill count={childrenCount ?? null} countPillDescription={countPillDescription} />
                </div>
                <div className="flex-1" />
                <FastFillCountPill item={item} onClick={onClick} />
                <ExpandButton
                    onClick={() => setIsExpanded(!isExpanded)}
                    expanded={isExpanded}
                    variant="section"
                />
            </div>
            <ExpandedPillContent item={item} expanded={isExpanded} />
        </div>
    )
}

type EditModeProps = {
    classificationItemId: number;
    initialTitle: string
    onSubmit: (value: string, observations: FastFillItem[], recommendations: FastFillItem[]) => Promise<void>
    onCancel: () => void
    isExistingNode: boolean;
    isBusy?: boolean;
}

const EditMode: React.FC<EditModeProps> = ({
    classificationItemId,
    initialTitle,
    onSubmit,
    onCancel,
    isExistingNode,
    isBusy = false
}) => {
    const [itemTitle, setItemTitle] = useState(initialTitle);
    const [observations, setObservations] = useState<FastFillItem[]>([]);
    const [recommendations, setRecommendations] = useState<FastFillItem[]>([]);

    const [isDirty, setIsDirty] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const { data: initialRecommendations, isLoading: recommendationsLoading } = useGetRecommendationsQuery({ classificationItem: classificationItemId });
    const { data: initialObservations, isLoading: observationsLoading } = useGetObservationsQuery({ classificationItem: classificationItemId });

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, []);

    useEffect(() => {
        if (initialObservations) {
            console.debug('initialObservations', initialObservations);
            setObservations(initialObservations);
        }
    }, [initialObservations]);

    useEffect(() => {
        if (initialRecommendations) {
            setRecommendations(initialRecommendations);
        }
    }, [initialRecommendations]);

    useEffect(() => {
        const titleChanged = itemTitle !== initialTitle
        const observationsChanged = JSON.stringify(observations) !== JSON.stringify(initialObservations)
        const recommendationsChanged = JSON.stringify(recommendations) !== JSON.stringify(initialRecommendations)
        setIsDirty(titleChanged || observationsChanged || recommendationsChanged)
    }, [itemTitle, observations, recommendations, initialTitle, initialObservations, initialRecommendations])

    const handleSubmit = async () => {
        if (!itemTitle.trim()) return;

        if (itemTitle === initialTitle) {
            itemTitle == '';
        }

        await onSubmit(itemTitle.trim(), observations, recommendations)
    }

    return (
        <div className="w-full">
            <div className="flex flex-col space-y-2">
                <div className="w-6" />
                <div>
                    <Label className="min-w-fit pr-6 block">Element Name*</Label>
                </div>
                <div className="space-x-2 flex flex-row w-full items-center">
                    <Input
                        type="text"
                        ref={inputRef}
                        value={itemTitle}
                        onChange={(e) => setItemTitle(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                        placeholder="Start typing..."
                        className="flex-grow mr-2"
                    />
                    <Button onClick={onCancel} variant="outline" size="sm">
                        {isDirty ? `Discard` : `Cancel`}
                    </Button>
                    <BusyButton
                        isBusy={isBusy}
                        onClick={handleSubmit}
                        size="sm"
                        disabled={!isDirty || !itemTitle.trim()}
                    >
                        {isExistingNode ? 'Update' : 'Add'}
                    </BusyButton>
                </div>
            </div>
            <div className="w-full h-[300px] py-4 flex flex-row space-x-6">
                <div className={classNames("w-1/2", { "animate-pulse": observationsLoading })}>
                    <FastFillEditor
                        title="Observations"
                        itemName="Observation"
                        initialItems={observations}
                        onChange={setObservations}
                    />
                </div>
                <div className={classNames("w-1/2", { "animate-pulse": recommendationsLoading })}>
                    <FastFillEditor
                        title="Recommendations"
                        itemName="Recommendation"
                        initialItems={recommendations}
                        onChange={setRecommendations}
                    />
                </div>
            </div>
        </div>
    )
}

interface AddNewLeafProps {
    title?: string
    initialValue?: string
    onSubmit: (value: string, observations: FastFillItem[], recommendations: FastFillItem[]) => Promise<void>
    depth: number
    id?: string;
    childrenCount?: number;
    item?: any;
}

const AddNewLeaf = ({
    title = 'Add new item',
    initialValue = '',
    onSubmit,
    depth,
    id,
    childrenCount = null,
    item
}: AddNewLeafProps) => {
    const [isEditingActive, setIsEditing] = useState(false)
    const [busy, setBusy] = useState(false)
    const componentRef = useRef<HTMLDivElement>(null)
    const isExistingNode = id !== undefined

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
                setIsEditing(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleSubmit = useCallback(async (value: string, observations: FastFillItem[], recommendations: FastFillItem[]) => {
        setBusy(true);
        await onSubmit(value, observations, recommendations);
        setBusy(false);
        setIsEditing(false);
    }, [setBusy, onSubmit, setIsEditing]);

    // if (busy) {
    //     return (
    //         <div className="flex items-center">
    //             <div className="w-6" />
    //             <ReloadIcon className="h-4 w-4 animate-spin mr-2" />
    //             <Label className="mr-2">{isEditing ? 'Updating...' : 'Adding...'}</Label>
    //         </div>
    //     )
    // }

    return (
        <div ref={componentRef} className="flex flex-shrink-0">
            {isEditingActive ? (
                <EditMode
                    classificationItemId={parseInt(id)}
                    initialTitle={initialValue}
                    onSubmit={handleSubmit}
                    onCancel={() => setIsEditing(false)}
                    isExistingNode={isExistingNode}
                    isBusy={busy}
                />
            ) : (
                <ElementItemPill
                    title={title}
                    isPlaceholder={!isExistingNode}
                    depth={depth}
                    onClick={() => setIsEditing(true)}
                    childrenCount={childrenCount}
                    item={item}
                />
            )}
        </div>
    )
}

function elementTypeDescription(depth: number) {
    const types = ["Category", "Sub-Category", "Type", "Sub-Type"];
    return types[depth];
}

type NestedListItemProps = {
    item: ListItem
    depth?: number
    onAddChild: (parentId: number, title: string, depth: number) => Promise<void>
    onEditItem: (parentId: number, title: string, depth: number, original: any, observations: FastFillItem[], recommendations: FastFillItem[]) => Promise<void>
    onToggle: (id: string) => void
    isEditingEnabled: boolean
    maxDepth?: number
    isNewlyAdded?: boolean
    newlyAddedId?: string | null
}

const NestedListItem: React.FC<NestedListItemProps> = ({
    item,
    depth = 0,
    onAddChild,
    onEditItem,
    onToggle,
    isEditingEnabled,
    maxDepth = 3,
    isNewlyAdded = false,
    newlyAddedId

}) => {

    const isMaxDepth = depth >= maxDepth;
    const isEditingAllowed = !isMaxDepth && isEditingEnabled;
    const childrenCount = !isMaxDepth ? item.actualChildren ?? (item.children ? item.children.length : 0) : null;
    const childrenType = elementTypeDescription(depth + 1);
    const parentId = item.parentId;

    const itemRef = useRef<HTMLDivElement>(null);

    const handleSubmit = useCallback(async (title: string, observations: FastFillItem[], recommendations: FastFillItem[]) => {
        await onEditItem(parentId, title, depth, item.original, observations, recommendations);
    }, [onEditItem, parentId, depth, item.original]);

    useEffect(() => {
        if (isNewlyAdded && itemRef.current) {
            itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [isNewlyAdded])

    // console.debug(item.title, parentId, { item });

    const isExpandable = (!isEditingAllowed && item.children && item.children.length > 0) || (isEditingAllowed && !isMaxDepth);

    return (

        <Collapsible open={item.isOpen} onOpenChange={() => onToggle(item.id)}>
            <motion.div
                ref={itemRef}
                className="flex items-center border-b border-zinc-200 min-h-[48px] w-full"
                initial={isNewlyAdded ? { backgroundColor: "#eeeeee" } : { backgroundColor: '#eeeeee' }}
                animate={{ backgroundColor: "#FFFFFF" }}
                transition={{ duration: 1 }}
            >
                {isExpandable ? (
                    <CollapsibleTrigger asChild>
                        <ExpandButton variant="item" expanded={item.isOpen} className="ml-1" />
                    </CollapsibleTrigger>
                ) : (
                    <div className="w-9" />
                )}

                {/* <span className="ml-2 py-2">{item.title}</span> */}
                {/* <span className="ml-2 py-2"><Pill variant="enabled" bold={depth === 0}>{item.title}</Pill></span> */}
                {/* <span className="ml-10 text-xs text-muted-foreground">{count}</span> */}

                {isEditingEnabled ? (
                    /** Edit Existing Item */

                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                        className="w-full"
                    >
                        <AddNewLeaf
                            title={item.title}
                            initialValue={item.title}
                            onSubmit={handleSubmit}
                            depth={depth}
                            childrenCount={childrenCount}
                            id={item.id}
                            item={item}
                        />
                    </motion.div>
                ) : (
                    <ElementItemPill
                        title={item.title}
                        isPlaceholder={false}
                        depth={depth}
                        //  onClick={() => onToggle(item.id)}
                        childrenCount={childrenCount}
                        item={item}
                    />
                )}

            </motion.div>
            <CollapsibleContent>
            
                <div className={`ml-8 mt-1 border-l`}>
                    {item.children && item.children.map((child) => (
                        <NestedListItem
                            key={`${item.id}-${child.id}`}
                            item={child}
                            depth={depth + 1}
                            onAddChild={onAddChild}
                            onEditItem={onEditItem}
                            onToggle={onToggle}
                            isEditingEnabled={isEditingEnabled}
                            isNewlyAdded={child.id === newlyAddedId}
                            newlyAddedId={newlyAddedId}
                        />
                    ))}
                    {isEditingAllowed && (
                        <div className={`ml-6`}>
                            {/* /** Adding a new Item */}
                            <AddNewLeaf
                                title={`Add ${childrenType}`}
                                onSubmit={(title) => onAddChild(parseInt(item.id), title, depth + 1)}
                                depth={depth + 1}
                            />
                        </div>
                    )
                    }
                </div>
            </CollapsibleContent>
        </Collapsible>
    )
}

type ExpandOrCollapse = 'expand' | 'collapse';

const Spaces = ({ assetId, data }) => {
    const [listData, setListData] = useState<ListItem[]>([])
    const [isEditMode, setIsEditMode] = useState(false);
    const [expandedState, setExpandedState] = useState<Record<string, boolean>>({});
    const [isExpanded, setIsExpanded] = useState<ExpandOrCollapse>('collapse');
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);

    const { setNavButtons } = useNav();

    //  const [archiveAsset, { isLoading: isArchiving, isSuccess: isArchiveSuccess, isError: isArchiveError, error: archiveError }] = useArchiveAssetMutation();

    const [createItem, { isLoading: isAddingItem, isSuccess: isCreateSuccess, isError: isCreationError }] = useCreateClassificationItemMutation();
    const [updateItem, { isLoading: isUpdatingItem, isSuccess: isUpdateSuccess, isError: isUpdateError }] = useUpdateClassificationItemMutation();
    const [updateRecommendations, { isLoading: isUpdatingRecommendations, isSuccess: isUpdateRecommendationsSuccess, isError: isUpdateRecommendationsError }] = useHandleRecommendationsMutation();
    const [updateObservations, { isLoading: isUpdatingObservations, isSuccess: isUpdateObservationsSuccess, isError: isUpdateObservationsError }] = useHandleObservationsMutation();

    const [newlyAddedItemId, setNewlyAddedItemId] = useState<string | null>(null)

    const memoizedSearchNestedStructure = useMemo(() => {
        return (items: any[], term: string) => searchNestedStructure(items, term);
    }, []);

    // Debounce the search handler
    const handleSearch = useCallback(
        (term: string) => {
            setIsSearching(true);
            console.info('Updating search results.', { term, listData });
            if (term.trim() === '') {
                setSearchResults(listData);
                setIsFiltered(false);
            } else {
                const results = memoizedSearchNestedStructure(listData, term);
                setSearchResults(results);
                setIsFiltered(true);
            }
            setIsSearching(false);
        }, [listData, memoizedSearchNestedStructure]);

    const [searchTerm, setSearchTerm] = useState('');


    const debouncedSearchTerm = useCallback(
        debounce(handleSearch, 300, { leading: false, trailing: true }),
        [handleSearch]
    );

    useEffect(() => {
        console.info('searchTerm effect', searchTerm);
        if (searchTerm.trim() !== '') {
            setIsExpanded('expand');
        }
        debouncedSearchTerm(searchTerm);
        // Cleanup function to cancel debounce on unmount
        return () => {
            debouncedSearchTerm.cancel();
        };
    }, [searchTerm, debouncedSearchTerm]);

    useEffect(() => {
        console.info('reinitializing list data');
        // Initialize the list data with isOpen set to true for all items
        const initializeListData = (items: ListItem[]): ListItem[] => {
            return items.map(item => ({
                ...item,
                isOpen: expandedState[item.id] ?? isExpanded === 'expand',
                observations_count: item.observations_count ?? item.original?.observations_count,
                recommendations_count: item.observations_count ?? item.original?.recommendations_count,
                children: item.children ? initializeListData(item.children) : undefined
            }))
        }

        setListData(initializeListData(data))
    }, [assetId]);

    useEffect(() => {

        setListData(prev => {
            return prev.map(item => {
                return {
                    ...item,
                    isOpen: expandedState[item.id] ?? isExpanded === 'expand',
                    children: item.children ? item.children.map(child => {
                        return {
                            ...child,
                            isOpen: expandedState[child.id] ?? isExpanded === 'expand',
                            children: child.children ? child.children.map(grandChild => {
                                return {
                                    ...grandChild,
                                    isOpen: expandedState[grandChild.id] ?? isExpanded === 'expand',
                                }
                            }) : undefined
                        }
                    }) : undefined
                }
            })
        })

    }, [expandedState, isExpanded]);

    useEffect(() => {
        setNavButtons([
            <SearchInputDebounced key="search" setSearchTerm={setSearchTerm} searchTerm={searchTerm} />,
        ])
        return () => {
            setNavButtons([]);
        }
    }, [setNavButtons, setSearchTerm, searchTerm]);


    const handleEditItem = useCallback(async (parentId: number, title: string, depth: number, original: any, observations: FastFillItem[], recommendations: FastFillItem[]) => {
        console.debug('Editing', { parentId, title, depth, original, observations, recommendations });

        console.info('original id', original.id);

        try {
            if (title) {

                const response = await updateItem({
                    classificationItem: original.id,
                    name: title
                }).unwrap();
                console.debug('response', response);
            }


            if (recommendations.length > 0) {
                // Update recommendations
                console.debug('Updating recommendations', { parentId, title, depth, original, observations, recommendations });



                await updateRecommendations({
                    classificationItem: original.id,
                    recommendations: recommendations.map((item) => ({ ...item, content: item.delete ? null : item.content.trim() }))
                }).unwrap();

            }

            if (observations.length > 0) {
                // Update observations
                console.debug('Updating observations', { parentId, title, depth, original, observations, recommendations });

                await updateObservations({
                    classificationItem: original.id,
                    observations: observations.map((item) => ({ ...item, content: item.delete ? null : item.content.trim() }))
                }).unwrap();

            }

            const editItemInList = (items: ListItem[]): ListItem[] => {

                return items.map((item) => {
                    if (item.id === original.id.toString()) {
                        return {
                            ...item,
                            title,
                            observations_count: observations.length,
                            recommendations_count: recommendations.length
                        }
                    }
                    if (item.children) {
                        return {
                            ...item,
                            children: editItemInList(item.children),
                        }
                    }
                    return item
                })
            }

            toast.success('Item updated successfully');

            const updatedItems = editItemInList(listData);
            console.debug('updatedItems', { updatedItems, listData });

            setListData(updatedItems);
        } catch (e) {
            console.error('Error editing item', e);
            toast.error('Error editing item');
        }

    }, [updateItem, updateRecommendations, updateObservations, listData]);


    const handleAddChild = useCallback(async (parentId: number, title: string, depth: number) => {
        try {
            //const type = (depth === 0 ? "building" : depth === 1 ? "floor" : "room");
            const type = ['category', 'sub_category', 'type', 'sub_type'][depth];
            console.debug('Adding new', { assetId, parentId, title, depth, type });

            const response = await createItem({
                classification_tree_id: assetId,
                parent_id: parentId === 0 ? null : parentId,
                name: title,
                type
            }).unwrap();

            const newId = response.id;
            console.debug('response', response);
            setNewlyAddedItemId(newId);
            setTimeout(() => setNewlyAddedItemId(null), 2000);

            toast.success('Item added successfully');

            function addChildToItem(items: ListItem[]): ListItem[] {
                return items.map((item) => {
                    if (item.id === parentId.toString()) {
                        return {
                            ...item,
                            original: { id: newId },
                            children: [...(item.children || []), {
                                id: newId,
                                original: { id: newId },
                                title,
                                isOpen: true
                            }],
                        };
                    }
                    if (item.children) {
                        return {
                            ...item,
                            children: addChildToItem(item.children),
                        };
                    }
                    return item;
                });
            };

            setListData(prevListData => addChildToItem(prevListData));

        } catch (error) {
            console.error('Error adding child', error);
            toast.error('Error adding child');
        }

    }, [assetId, createItem]);

    const handleAddNewRootNode = useCallback((title) => {
        handleAddChild(0, title, 0);
    }, [handleAddChild]);

    const toggleItem = useCallback((id: string) => {
        console.debug('toggle', id, !expandedState[id]);

        setExpandedState((prevState) => ({
            ...prevState,
            [id]: prevState[id] !== undefined ? !prevState[id] : isExpanded === 'expand' ? false : true,
        }));
    }, [expandedState, isExpanded]);

    const expandAll = useCallback(() => {
        setExpandedState({});
        setIsExpanded('expand');
    }, []);

    const collapseAll = useCallback(() => {
        console.info('collapsing');
        setExpandedState({});
        setIsExpanded('collapse');
    }, []);

    const [isListStabilized, setIsListStabilized] = useState(false);

    useEffect(() => {
        if (listData && listData.length > 0) {
            setIsListStabilized(true);
            handleSearch('');
        }
    }, [listData]);


    useEffect(() => {
        if (!isListStabilized) return;
        if (isExpanded === 'expand') {
            expandAll()
        } else {
            collapseAll()
        }
    }, [isExpanded]);

    return (
        <div className="w-full mx-auto p-4 bg-white rounded-lg shadow">
            <div className="flex justify-end items-center mb-4">
                <div className="space-x-2">
                    <OptionToggle options={[
                        { description: 'Expand All', value: 'expand' as ExpandOrCollapse },
                        { description: 'Collapse All', value: 'collapse' as ExpandOrCollapse }]}
                        value={isExpanded} onChange={(value) => value === 'expand' ? expandAll() : collapseAll()} />
                    <OptionToggle options={[{ description: 'View', value: 'view' }, { description: 'Edit', value: 'edit' }]} value={isEditMode ? 'edit' : 'view'} onChange={(value) => setIsEditMode(value === 'edit')} />
                </div>
            </div>

            {isSearching ? (
                <div className="flex items-center justify-center h-48">
                    <ReloadIcon className="h-8 w-8 animate-spin mr-2" />
                    <Label>Searching...</Label>
                </div>
            ) : (
                <>
                    <AnimatePresence>
                        {searchResults.map((item) => (
                            <motion.div
                                key={item.id}
                                initial={{ opacity: 0.5, y: 0 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 0 }}
                                transition={{ duration: 0.1 }}
                                className=""
                            >
                                <NestedListItem
                                    item={item}
                                    onAddChild={handleAddChild}
                                    onEditItem={handleEditItem}
                                    onToggle={toggleItem}
                                    isEditingEnabled={isEditMode}
                                    newlyAddedId={newlyAddedItemId}
                                />
                            </motion.div>
                        ))}
                    </AnimatePresence>

                    {
                        isFiltered && searchResults.length === 0 && (
                            <div className="flex flex-col space-y-6 items-center justify-center h-24">
                                <Label>No elements found</Label>
                            </div>
                        )
                    }

                    {isEditMode && !isFiltered && searchResults.length !== 0 && (
                        <div className="ml-6">
                            <AddNewLeaf
                                title={`Add Element`}
                                onSubmit={(title) => handleAddChild(0, title, 0)}
                                depth={0}
                            />
                        </div>
                    )}

                    {!isFiltered && searchResults.length === 0 && (
                        <EmptyState
                            title="No elements"
                            description="Create your first element to get started."
                            customComponent={
                                <div className="pl-10 relative">
                                    <AddNewLeaf
                                        title={`Add Element`}
                                        onSubmit={(title) => handleAddChild(0, title, 0)}
                                        depth={0}
                                    />
                                </div>
                            }
                        />
                    )}
                </>
            )}

        </div>
    )
}

export default ElementEditorPage;