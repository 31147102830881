import {
  EntityState,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RatingScale } from './entity';
import TestAPI from './api';

export const RATINGSCALES_FEATURE_KEY = 'ratingScales';

const ratingScaleAdapter = createEntityAdapter<RatingScale>();

export interface RatingScalesState extends EntityState<RatingScale, number> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  error?: string | null;
}

export const initialRatingScalesState: RatingScalesState =
  ratingScaleAdapter.getInitialState({
    loadingStatus: 'not loaded',
    error: null,
  });

export const fetchRatingScaleById = createAsyncThunk(
  `${RATINGSCALES_FEATURE_KEY}/fetchRatingScaleById`,
  async (id: number, thunkAPI) => {
    console.info('fetch rating scale', id);
    const response = await TestAPI.fetchRatingScale(id);
    return response;
  }
);

export const fetchRatingScales = createAsyncThunk(
  `${RATINGSCALES_FEATURE_KEY}/fetchRatingScales`,
  async (_, thunkAPI) => {
    console.info('fetch rating scales');
    const response = await TestAPI.fetchRatingScales();
    return response;
  }
);

export const ratingScaleSlice = createSlice({
  name: RATINGSCALES_FEATURE_KEY,
  initialState: initialRatingScalesState,
  reducers: {
    add: ratingScaleAdapter.addOne,
    remove: ratingScaleAdapter.removeOne,
    updateRatingScale(state, action: PayloadAction<RatingScale>) {
      ratingScaleAdapter.upsertOne(state, action.payload);
    },
    updateRatingScales(state, action: PayloadAction<RatingScale[]>) {
      ratingScaleAdapter.upsertMany(state, action.payload);
      state.loadingStatus = 'loaded';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRatingScaleById.pending, (state: RatingScalesState) => {
        state.loadingStatus = 'loading';
      })
      .addCase(
        fetchRatingScaleById.fulfilled,
        (state: RatingScalesState, action: PayloadAction<RatingScale>) => {
          ratingScaleAdapter.upsertOne(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(
        fetchRatingScaleById.rejected,
        (state: RatingScalesState, action) => {
          state.loadingStatus = 'error';
          state.error = action.error.message;
        }
      )
      .addCase(
        fetchRatingScales.fulfilled,
        (state: RatingScalesState, action: PayloadAction<RatingScale[]>) => {
          ratingScaleAdapter.upsertMany(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(
        fetchRatingScales.rejected,
        (state: RatingScalesState, action) => {
          state.loadingStatus = 'error';
          state.error = action.error.message;
        }
      )
      .addDefaultCase((state: RatingScalesState) => {
        state.loadingStatus = 'not loaded';
      })
      ;
  },
});

export const ratingScalesReducer = ratingScaleSlice.reducer;

export const ratingScalesActions = {
  ...ratingScaleSlice.actions,
};

const { selectAll, selectEntities, selectById } =
  ratingScaleAdapter.getSelectors();

export const getRatingScaleState = (rootState: {
  [RATINGSCALES_FEATURE_KEY]: RatingScalesState;
}): RatingScalesState => {
  return rootState[RATINGSCALES_FEATURE_KEY];
};

export const selectAllRatingScales = createSelector(
  getRatingScaleState,
  selectAll
);

export const selectRatingScaleEntities = createSelector(
  getRatingScaleState,
  selectEntities
);

export const selectRatingScales = (state: any) =>
  state[RATINGSCALES_FEATURE_KEY];

export const selectRatingScaleLoadingStatus = createSelector(
  getRatingScaleState,
  (state: RatingScalesState) => state.loadingStatus
);

export const selectRatingScaleById = createSelector(
  [selectAllRatingScales, (_, id: number) => id],
  (ratingScales, id) => {
    return ratingScales.find((ratingScale) => ratingScale.id === id);
  }
);

// export const selectBuildingsByAssetId = createSelector(
//   [selectAllBuildings, (_, assetId: number) => assetId],
//   (buildings, assetId) => buildings.filter(building => building.asset_id === assetId)
// );
