// Import statements
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector, EntityState } from '@reduxjs/toolkit';
import { FloorLocation } from './types/floorLocation';
import { AssetAPI } from './api'; // Assume AssetAPI has methods for rooms
import { RootState } from '../root';

export type Room = FloorLocation;

export const ROOMS_FEATURE_KEY = 'rooms';

const roomsAdapter = createEntityAdapter<Room>();

export interface RoomsState extends EntityState<Room, number> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  error?: string | null;
}

export const initialRoomsState: RoomsState = roomsAdapter.getInitialState({
  loadingStatus: 'not loaded',
  error: null,
});

export const createRoom = createAsyncThunk<Partial<FloorLocation>, Partial<FloorLocation>>(
  `${ROOMS_FEATURE_KEY}/createRoom`,
  async (room: Partial<FloorLocation>, thunkAPI) => {
    try {
      console.info('create room', room);
      const response = await AssetAPI.addRoom(room);
      console.info('asset response', response.body);
      return response.body as FloorLocation;
    } catch (error) {
      console.info('Create room error', error);
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

// Assuming your API has a method to fetch rooms, e.g., fetchRoomsByLevel(levelId)
export const fetchRooms = createAsyncThunk<Room[], number>(
  `${ROOMS_FEATURE_KEY}/fetchRooms`,
  async (levelId: number, { rejectWithValue }) => {
    try {
      const response = await AssetAPI.fetchRoomsByFloorId(levelId);
      return response.body;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const fetchRoomsByLevelId = createAsyncThunk<Room[], number>(
 `${ROOMS_FEATURE_KEY}/fetchRoomsByLevelId`,
  async (levelId: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await AssetAPI.fetchRoomsByFloorId(levelId);
      dispatch(roomsActions.updateRooms(response.body));
      return response.body as FloorLocation[];
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const roomsSlice = createSlice({
  name: ROOMS_FEATURE_KEY,
  initialState: initialRoomsState,
  reducers: {
    addRoom: roomsAdapter.addOne,
    removeRoom: roomsAdapter.removeOne,
    updateRoom: roomsAdapter.updateOne,
    updateRooms(state, action) {
        console.info('rooms received', action.payload);
        roomsAdapter.upsertMany(state, action.payload);
        state.loadingStatus = 'loaded';
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRooms.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchRooms.fulfilled, (state, action) => {
        roomsAdapter.upsertMany(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchRooms.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error as string;
      });
  },
});

// Export reducers and actions
export const roomsReducer = roomsSlice.reducer;
export const roomsActions = roomsSlice.actions;

// Entity selectors
const { selectAll, selectEntities } = roomsAdapter.getSelectors();

// Custom selectors
export const getRoomsState = (state: RootState): RoomsState => state[ROOMS_FEATURE_KEY];

export const selectAllRooms = createSelector(getRoomsState, selectAll);

export const selectRoomEntities = createSelector(
  getRoomsState,
  selectEntities
);

export const selectRoomLoadingStatus = createSelector(
  getRoomsState,
  (state: RoomsState) => state.loadingStatus
);

export const selectRoomById = (roomId: number) =>
  createSelector(getRoomsState, (state) => {
    return state.entities[roomId];
  });

// export const selectLevelsByAssetId = (assetId: number) =>
//   createSelector(selectAllLevels, (levels) => {
//     const asset = levels.find((l) => l.asset_id === assetId);
//     return asset?.levels ?? [];
//   });

export const selectRoomsByAssetId = createSelector(
    selectAllRooms, // selector that selects all levels from the state
  //(state: LevelsState, assetId: number) => assetId, // selector that selects the assetId from the props
  (_, assetId: number) => assetId, // selector that selects the assetId from the props
  (rooms: Room[], assetId) => rooms.filter(room => room.asset_id === assetId)
);
