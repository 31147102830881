import {
  RootState,
  UsersEntity,
  alertsActions,
  api,
  fetchUsers,
  selectUserById,
  updateUser,
  useAppDispatch,
} from '@app.raytd.com/store';
import { CameraIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Input, Card, Layout, Cell, Button, Heading, FileUpload, ImageViewer, Box, FloatingNotification, SkeletonGroup, SkeletonLine, Text, FormField, Loader, Avatar, Tabs, Badge, EmptyState } from '@wix/design-system';
import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// eslint-disable-next-line @nx/enforce-module-boundaries
import ConfirmationDialog, { useConfirmationDialog } from '../../../../src/app/features/test/ConfirmationDialog';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UserListPage } from '@app.raytd.com/ui';
import { TabProvider, TabContainer, TabPage } from '../tabs';

type ProfileContextValue = {
  user: UsersEntity,
};

export const ProfileContext = React.createContext<ProfileContextValue>({
  user: {} as UsersEntity,
});

const tabs = {
  1: { title: 'Profile', slug: 'settings' },
  2: { title: 'Organisations', slug: 'organisations' },
  3: { title: 'Team', slug: 'team' },
  4: { title: 'Subscriptions', slug: 'subscriptions' }
}

export const ProfilePage = (props: ProfileProps) => {

  const { id } = useParams<{ id: string }>();
  const user = useSelector(selectUserById(props.id ? props.id : parseInt(id ? id : '0')));

  const { isVisible, showDialog, confirm, cancel } = useConfirmationDialog();
  const [pageIsDirty, setPageIsDirty] = useState<boolean>(false);

  const profileContextValues = useMemo(
    () => ({
      user,
    }),
    [user]
  );


  return (
    <ProfileContext.Provider value={profileContextValues}>
      <TabProvider tabs={tabs} path='/profile' defaultTabId={1}>

          {/* <Box direction="vertical" gap="small"> */}
        <TabContainer tabs={tabs} pageIsDirty={pageIsDirty}>
            <TabPage id={1} title="Profile"><ProfileSettingsPage user={user} /></TabPage>
            <TabPage id={2} title="Profile"><EmptyState title="Organisations" subtitle="Add an organisation to your team to collaborate with your colleagues." /></TabPage>
            <TabPage id={3} title="Profile"> <UserListPage /></TabPage>
            <TabPage id={4} title="Profile"><EmptyState title="Subscriptions" subtitle="Add a subscription to your team to collaborate with your colleagues." /></TabPage>
        </TabContainer>
          {/* </Box> */}
      </TabProvider>
      <ConfirmationDialog isOpen={isVisible} onConfirm={confirm} onCancel={cancel} />
    </ProfileContext.Provider>

  )

}

/* eslint-disable-next-line */
export type ProfileProps = {
  id?: number;
};

type Inputs = {
  name: string;
  email: string;
  mobile_number: string;
};

interface ProfileSettingsPageProps {
  user: UsersEntity;
}

export function ProfileSettingsPage({ user }: ProfileSettingsPageProps) {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [updateProfileStatus, setUpdateProfileStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined);
  const [imageUploadStatus, setImageUploadStatus] = React.useState('idle');

  const loadingStatus = useSelector(
    (state: RootState) => state.users.loadingStatus
  );

  useEffect(() => {
    if (loadingStatus === 'not loaded') {
      dispatch(fetchUsers());
    }
  }, [dispatch, loadingStatus]);

  React.useEffect(() => {
    if (user) {
      setImageUrl(user.avatar);
    }
  }, [user]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: '',
      email: '',
      mobile_number: ''
    },
    values: user,
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    dispatch(alertsActions.clear());
    setErrorMessage('');

    try {
      if (!user) {
        console.info('user not found');
        return;
      }

      const updatedUser: UsersEntity = {
        ...user,
        name: data.name,
        email: data.email,
      };

      const response = await dispatch(updateUser(updatedUser)).unwrap();

      dispatch(
        alertsActions.success({
          message: 'Profile saved',
          showAfterRedirect: true,
          timeout: 2000,
        })
      );
      navigate(-1);

      return data;
    } catch (err: any) {
      console.error('error', err);
      setErrorMessage(err?.message ?? 'Unknown error');
    } finally {
      setUpdateProfileStatus('idle');
    }
  };


  const handleAvatarUpload = async (fileList: FileList) => {
    setErrorMessage('');
    setImageUploadStatus('loading');
    const file = fileList[0];

    if (!file) {
      console.error('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('profile_image', file);

    try {
      const response = await api.post(`api/v1/users/${user.id}/avatar`, formData);

      if (!response.ok) {
        setErrorMessage('There was an error updating the image');
        throw new Error('Network response was not ok');
      }

      const result = await response.body;
      console.log('Upload success:', result);
      setImageUrl(result.url);
      setImageUploadStatus('idle');
      // Handle success, e.g., showing a success message, updating UI, etc.
    } catch (error) {
      console.error('Upload failed:', error);
      setImageUploadStatus('error');
      // Handle error, e.g., showing an error message
    }
  };

  if (loadingStatus !== 'loaded') {
    return <Card>
      <Card.Content size="large">
        <SkeletonGroup>
          <Box direction='vertical' gap='12px'>

            <Box width={'450px'}><SkeletonLine /></Box>
            <Box width={'450px'}><SkeletonLine /></Box>
            <SkeletonLine />
            <SkeletonLine />
          </Box>
        </SkeletonGroup>
      </Card.Content>
    </Card>;
  }


  if (!user) {
    return <div>User not found</div>;
  }

  return (

    <Card>
      <Card.Header
        title={`${user?.name}`}
      />
      {errorMessage && (
        <FloatingNotification
          type="destructive"
          fullWidth
          prefixIcon={<ExclamationTriangleIcon />}
          text={<Text>{errorMessage}</Text>}
        />
      )}
      <Card.Divider />
      <Card.Content size="medium">
        <form>
          <Layout>
            <Cell span={6}>
              <Layout>

                <Cell span={6}>
                  <Box direction="vertical" gap="12px">
                    <FormField label="Name">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            ref={ref}
                            onBlur={onBlur}
                          />
                        )}
                      />
                    </FormField>

                    <FormField label="Email">
                      <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Input
                            type="email"
                            value={value}
                            onChange={onChange}
                            ref={ref}
                            onBlur={onBlur}
                          />
                        )}
                      />
                    </FormField>

                    <FormField label="Mobile Phone">
                      <Controller
                        name="mobile_number"
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Input
                            placeholder="0400 1234 5678"
                            pattern='[0-9]{4} [0-9]{4} [0-9]{4}'
                            value={value}
                            onChange={onChange}
                            ref={ref}
                            onBlur={onBlur}
                          />
                        )}
                      />
                    </FormField>
                  </Box>
                </Cell>

              </Layout>
            </Cell>

            <Cell span={6}>
              <Box direction="vertical" gap="small" flex={1} height={'100%'} marginTop={10}>
                <FileUpload
                  accept=".jpeg,.gif,.png,.jpg"
                  onChange={handleAvatarUpload}
                >
                  {({ openFileUploadDialog }) => (
                    imageUploadStatus === 'loading' ? <Loader text="Uploading..." /> :
                      // <ImageViewer
                      //   onAddImage={openFileUploadDialog}
                      //   onUpdateImage={openFileUploadDialog}
                      //   imageUrl={imageUrl} />
                      <Avatar
                        name={user.name}
                        size="size90"
                        imgProps={{ src: imageUrl }}
                        indication={<CameraIcon width={12} height={12} />}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        /** @ts-ignore */
                        onIndicationClick={() => { openFileUploadDialog(1) }}

                      />
                  )}
                </FileUpload>


              </Box>
            </Cell>
            <Cell span={12}>
              <Card.Divider />
            </Cell>

            <Cell>

              <Heading size="small">Organisations</Heading>
              <Card.Divider />
              {
                [user.company].map((company) => (<Cell>{company.title}</Cell>))
              }
            </Cell>

            <Cell span={12}>

              <Card.Divider />
              <Heading size="small">Update Password</Heading>
              <Card.Divider />
              <FormField label="Current Password">
                <Input type="password" />
              </FormField>
              <FormField label="New Password">
                <Input type="password" />
              </FormField>
              <FormField label="Confirm New Password">
                <Input type="password" />
              </FormField>


              <div className="mb-6 flex w-full" />
              <Button
                priority="primary"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Save Profile
              </Button>
              <span className="mr-4" />
              <Button onClick={() => navigate(-1)}>Cancel</Button>
            </Cell>
          </Layout>
        </form>

      </Card.Content>
    </Card>

  );
}

export default ProfilePage;
