import { assetSchema } from '../../schemas';
import { AssetEntity, AssetAPI, Building, Floor, Room } from '..';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { updateEntities } from '../updateEntitiesAction';
export const fetchAsset = createAsyncThunk<AssetEntity[]>(
    'asset/fetchAssets',
    async (_, thunkAPI) => {
      console.info('fetching assets');
  
      const response = await AssetAPI.fetchUserAssets();
      console.info('asset response', response.body);
      const normalizedData = normalize(response.body.data, [assetSchema]);
      console.info('asset normalized data', normalizedData);
  
      thunkAPI.dispatch(updateEntities(normalizedData.entities));
  
      return Object.values(normalizedData.entities['assets']);
    }
  );
  
  
  //assetActions.get
  
  export const getAsset = createAsyncThunk<AssetEntity, number>(
    'asset/getAsset',
    async (id: number, thunkAPI) => {
      console.info('get asset', id);
  
      try {
        const response = await AssetAPI.get(id);
        console.info('asset response', response.body);
  
        const asset = {
          ...response.body,
        };
  
        const normalizedData = normalize(asset, assetSchema);
        console.info('asset normalized data', normalizedData);
    
        thunkAPI.dispatch(updateEntities(normalizedData.entities));
  
        return asset as AssetEntity;
      } catch (err) {
        console.info('get asset error', err);
        return thunkAPI.rejectWithValue(err);
      }
    }
  );
  
  
  //create thunk for adding new asset
  export const addAsset = createAsyncThunk<AssetEntity, AssetEntity>(
    'asset/addAsset',
    async (asset: AssetEntity, thunkAPI) => {
      const result = await thunkAPI.dispatch(createAsset(asset));
      console.info('result', result);
      if (createAsset.fulfilled.match(result)) {
        await thunkAPI.dispatch(fetchAsset());
        return result.payload;
      }
  
      return thunkAPI.rejectWithValue(result.payload);
    }
  );
  
  export const addBuilding = createAsyncThunk<Building, Building>(
    'asset/addBuilding',
    async (building: Building, thunkAPI) => {
      const result = await thunkAPI.dispatch(createBuilding(building));
      console.info('result', result);
      if (createBuilding.fulfilled.match(result)) {
        await thunkAPI.dispatch(fetchAsset());
        return result.payload as Building;
      }
  
      return thunkAPI.rejectWithValue(result.payload);
    }
  );

  export const updateBuilding = createAsyncThunk<Building, Building>(
    'asset/updateBuilding',
    async (building: Building, thunkAPI) => {
      console.info('update building', building);
  
      const response = await AssetAPI.updateBuilding(building);
      console.info('asset response', response.body);
      return response.body as Building;
    }
  );
  
  export const createBuilding = createAsyncThunk<Building, Building>(
    'asset/createBuilding',
    async (building: Building, thunkAPI) => {
      console.info('create building', building);
  
      const response = await AssetAPI.addBuilding(building);
      console.info('asset response', response.body);
      return response.body as Building;
    }
  );
  
  export const createFloor = createAsyncThunk<Partial<Floor>, Partial<Floor>>(
    'asset/createFloor',
    async (floor: Partial<Floor>, thunkAPI) => {
      try {
        console.info('create floor', floor);
        const response = await AssetAPI.addFloor(floor);
        console.info('asset response', response.body);
        return response.body as Floor;
      } catch (error) {
        console.info('Create floor error', error);
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  );

  export const updateFloor = createAsyncThunk<Partial<Floor>, Partial<Floor>>(
    'asset/updateFloor',
    async (floor: Partial<Floor>, thunkAPI) => {
      console.info('update floor', floor);
  
      const response = await AssetAPI.updateFloor(floor);
      console.info('asset response', response.body);
      return response.body as Floor;
    }
  );
  
  const createAsset = createAsyncThunk<AssetEntity, AssetEntity>(
    'asset/createAsset',
    async (asset: any, thunkAPI) => {
      console.info('create asset', asset);
  
      const response = await AssetAPI.add(asset);
      console.info('asset response', response.body);
      return response.body as AssetEntity;
    }
  );

  export const updateRoom = createAsyncThunk<Partial<Room>, Partial<Room>>(
    'asset/updateRoom',
    async (room: Partial<Room>, thunkAPI) => {
      console.info('update room', room);

      const response = await AssetAPI.updateRoom(room);
      console.info('asset room response', response.body);
      return response.body as Room;
    }
  );

  export const updateAssetStatus = createAsyncThunk<AssetEntity, { id: number; status: string }>(
    'asset/updateAssetStatus',
    async ({ id, status }, thunkAPI) => {
      console.info('update asset status', id, status);
  
      const response = await AssetAPI.updateStatus(id, status);
      console.info('asset response', response.body);
      return response.body?.asset as AssetEntity;
    }
  );