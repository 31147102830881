import HeaderLogo from '@/app/layout2/HeaderLogo';
import { Button } from '@/components/ui/button';
import { AlertTriangleIcon } from 'lucide-react';
import React, { Component, ErrorInfo, PropsWithChildren, ReactNode } from 'react';

interface ErrorBoundaryState {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}


class ErrorBoundary extends Component<PropsWithChildren, ErrorBoundaryState> {
    constructor(props: {children}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // You can also log the error to an error reporting service
        console.error("Uncaught error:", error, errorInfo);
        this.setState({ error, errorInfo });
    }

    handleReload = () => {
        window.location.reload();
    };

    render(): ReactNode {
        if (this.state.hasError) {
            return (
                <div className="min-h-screen flex flex-col justify-center items-center bg-gray-50">
                    <div className="text-center space-y-4">
                        {/* Error Icon */}
                        <div className="flex flex-col justify-center items-center text-zinc-500 space-y-6">
                            <AlertTriangleIcon className="h-12 w-12" />
                            <HeaderLogo />
                        </div>

                        {/* Error Title */}
                        <h1 className="text-2xl font-bold text-zinc-800">
                            Oops, something went wrong.
                        </h1>

                        {/* Error Description */}
                        <p className="text-zinc-600">
                            An unexpected error has occurred. Please try again or return to the homepage.
                        </p>

                          {/* Show error details in development mode */}
                          {process.env.NODE_ENV === 'development' && this.state.error && this.state.errorInfo && (
                            <div className="text-left text-red-600">
                                <h2 className="text-xl font-bold">Error Details:</h2>
                                <pre>{this.state.error.toString()}</pre>
                                <pre>{this.state.errorInfo.componentStack}</pre>
                            </div>
                        )}

                        {/* Action Buttons */}
                        <div className="flex space-x-4 justify-center">
                            <Button variant="outline" onClick={this.handleReload}>
                                Retry
                            </Button>
                            <Button variant="link" onClick={() => (window.location.href = '/')}>
                                Go to Homepage
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;