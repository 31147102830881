import { useState } from 'react'
import { ChevronRight, ChevronDown } from 'lucide-react'
import { SubCategory } from './SubCategory'
import { TestSpecificationsEntity } from '@app.raytd.com/store'
import { Pill } from '@/components/raytd/pill'
import { ValuePill } from '@/components/raytd/value-pill'

type CategoryProps = {
  category: string
  subCategories: Record<string, TestSpecificationsEntity[]>
}

export function Category({ category, subCategories }: CategoryProps) {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <div className="mb-4">
      <div
        className="flex items-center cursor-pointer space-x-2 border-y py-2"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <ChevronDown className="w-4 h-4 mr-2" /> : <ChevronRight className="w-4 h-4 mr-2" />}
        <Pill bold>{category}</Pill>
        <ValuePill value={Object.keys(subCategories).length} label={"Sub Categories"} variant='inverted' />
      </div>
      {isExpanded && (
        <div className="ml-6 mt-2">
          {Object.entries(subCategories).map(([subCategory, tests]) => (
            <SubCategory
              key={subCategory}
              subCategory={subCategory}
              tests={tests}
            />
          ))}
        </div>
      )}
    </div>
  )
}