import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import AddressInput from "@/app/features/assets/components/address";
import BusyButton from "@/components/raytd/busy-button";
import ErrorAlert from "@/components/raytd/form-error";
import { DialogDescription } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { useCreateOfficeMutation, useUpdateOfficeMutation } from "@app.raytd.com/store";
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { toast } from 'sonner';
import * as z from "zod";

interface OfficeDialogProps {
  office?: OfficeFormValues;
  onSuccess?: () => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const addressSchema = z.object({
  street: z.string().min(1, { message: "Address line 1 is required" }),
  suburb: z.string().min(1, { message: "Suburb is required" }),
  state: z.string().optional(),
  postcode: z.string().min(1, { message: "Postal code is required" }),
  country: z.string().min(1, { message: "Country is required" }),
})

const officeSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(2, { message: "Name must be at least 2 characters." }),
  // address: z.string().min(5, { message: "Address must be at least 5 characters." }),
  address: addressSchema.optional(),
  phone: z.string().min(10, { message: "Phone must be at least 10 characters." }),
  email: z.string().email({ message: "Invalid email address." }),
})

type OfficeFormValues = z.infer<typeof officeSchema>

export const OfficeDialog: React.FC<OfficeDialogProps> = ({ open, onOpenChange, office, onSuccess }) => {
  const [createOffice] = useCreateOfficeMutation()
  const [updateOffice] = useUpdateOfficeMutation()

  const isEditing = !!office

  const form = useForm<OfficeFormValues>({
    resolver: zodResolver(officeSchema),
    defaultValues: office || {
      id: null,
      name: "",
      address: {},
      phone: "",
      email: "",
    },
  });

  useEffect(() => {
    if (office) {
      form.reset(office)
    } else {
      form.reset({
        name: "",
        address: {},
        phone: "",
        email: "",
      })
    }
  }, [office, form]);

  const onSubmit = useCallback(async (values: OfficeFormValues) => {
    try {
      if (isEditing) {
        await updateOffice({ id: office.id, ...values }).unwrap()
        toast.success("Office updated")
      } else {
        await createOffice(values).unwrap()
        toast.success("Office added")
      }
      form.reset()
      onOpenChange(false)
      onSuccess?.()
    } catch (error) {
      console.error(error)
      toast.error(isEditing ? "Failed to update office" : "Failed to add office")
    }
  }, [form, createOffice, updateOffice, isEditing, office, onSuccess])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]" disableOutsideClick>
        <DialogHeader>
          <DialogTitle>{isEditing ? 'Edit Office' : 'Add Office'}</DialogTitle>
          <DialogDescription>
            {isEditing ? 'Edit the details of your office here.' : 'Enter the details of your new office here.'}
          </DialogDescription>
        </DialogHeader>
        {form.formState.errors.root && (
          <ErrorAlert
            title={isEditing ? 'Error updating office' : 'Error adding office'}
            description={form.formState.errors.root.message || 'An unexpected error occurred'}
          />
        )}
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="e.g. Main Office" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <AddressInput {...field}
                      value={{
                        street: field.value?.street ?? '',
                        suburb: field.value?.suburb ?? '',
                        state: field.value?.state ?? '',
                        postcode: field.value?.postcode ?? '',
                        country: field.value?.country ?? '',
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone</FormLabel>
                  <FormControl>
                    <Input placeholder="+61 711 456 327" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="admin@yourdomain.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button type="button" variant="ghost" onClick={() => onOpenChange(false)}>
                Cancel
              </Button>
              <BusyButton type="submit" isBusy={form.formState.isSubmitting}>
                {isEditing ? 'Update' : 'Add'}
              </BusyButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}