import { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"

import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { Form } from "@/components/ui/form"
import BusyButton from "@/components/raytd/busy-button"
import TestSuiteDetailsForm from "./CreateTestSuiteForm"
import { TestSuiteTypePicker } from "./TestSuiteTypePicker"
import { useCreateTestSuiteMutation } from "@app.raytd.com/store"
import { c } from "node_modules/@udecode/plate-list/dist/BaseListPlugin-B0eGlA5x"
import { useAlertDialog } from "@/hooks/useAlertDialog"
import { PlusIcon } from "lucide-react"

const createSchema = z.object({
    name: z.string().min(1, "Name is required"),
    description: z.string().min(1, "Description is required"),
})

const selectTypeSchema = z.object({
    type: z.enum(["compliance", "condition", "generic", "numerical"], {
        required_error: "You need to select a test suite type",
    }),
})

type CreateFormValues = z.infer<typeof createSchema>
type SelectTypeFormValues = z.infer<typeof selectTypeSchema>

export function CreateTestSuiteModal() {
    const [step, setStep] = useState<"create" | "selectType">("create")
    const [createData, setCreateData] = useState<CreateFormValues | null>(null)
    const [open, setOpen] = useState(false)

    const { AlertDialogComponent, showAlertDialog } = useAlertDialog();

    const [createTestSuite, { isLoading: isCreating }] = useCreateTestSuiteMutation()

    const navigate = useNavigate()

    const createForm = useForm<CreateFormValues>({
        resolver: zodResolver(createSchema),
    })

    const selectTypeForm = useForm<SelectTypeFormValues>({
        resolver: zodResolver(selectTypeSchema),
    })

    const handleCancel = useCallback(() => {
        setOpen(false)
        setStep("create")
        createForm.reset()
        selectTypeForm.reset()
    }, [createForm, selectTypeForm])

    const handleOpenChange = useCallback((newOpen: boolean) => {
        console.debug('handleOpenChange', { newOpen, isDirty: createForm.formState.isDirty || selectTypeForm.formState.isDirty });
        if (!newOpen && (createForm.formState.isDirty || selectTypeForm.formState.isDirty)) {
            console.log('Form is dirty');

            showAlertDialog({
                title: 'Unsaved Changes',
                description:
                    'You have unsaved changes. Are you sure you want to leave?',
                confirmText: 'Leave',
                cancelText: 'Stay',
            }).then((confirmed) => {
                if (confirmed) {
                    handleConfirmClose();
                } else {
                    // do nothing;
                }
            });

        } else {
            setOpen(newOpen)
            if (!newOpen) {
                handleCancel()
            }
        }
    }, [createForm.formState.isDirty, selectTypeForm.formState.isDirty, showAlertDialog]);


    const onSubmitCreate = useCallback((data: CreateFormValues) => {
        setStep("selectType")
        setCreateData(data)
    }, [])

    const onSubmitSelectType = async (data: SelectTypeFormValues) => {
        try {
            const response = await createTestSuite({
                title: createData!.name,
                description: createData!.description,
                type: data.type,
            }).unwrap()

            toast.success('Test suite created successfully')
            setOpen(false)
            console.debug('response', response);
            navigate(`/test-suites/${response.id}/fields`)
        } catch (error) {
            console.error('Error creating test suite', error)
            toast.error('Failed to create test suite')
        }
    }

    const handleNext = useCallback(() => {
        if (step === "create") {
            createForm.handleSubmit(onSubmitCreate)()
        } else {
            selectTypeForm.handleSubmit(onSubmitSelectType)()
        }
    }, [step, createForm, selectTypeForm, onSubmitCreate, onSubmitSelectType])

    const handleConfirmClose = () => {
        setOpen(false)
        handleCancel()
    }

    const handleRequestClose = useCallback(() => {
        handleOpenChange(false);
    }, [handleOpenChange]);

    return (
        <Dialog open={open} onOpenChange={handleOpenChange}>
            <DialogTrigger asChild>
                {/* <Button variant="default">Create Test Suite</Button> */}
                <Button className="" variant="default">+ Create Test Suite</Button>
            </DialogTrigger>
            <DialogContent className="w-full max-w-5xl h-[90vh] flex flex-col p-0 bg-white">
                <DialogHeader className="p-6 pb-0">
                    <DialogTitle>{step === "create" ? "New Test Suite" : "Select Type"}</DialogTitle>
                </DialogHeader>
                <div className="flex-grow overflow-auto p-6 pt-0">
                    {step === "create" && (
                        <TestSuiteDetailsForm
                            form={createForm}
                            onSubmit={onSubmitCreate}
                        />
                    )}
                    {step === "selectType" && (
                        <Form {...selectTypeForm}>
                            <form onSubmit={selectTypeForm.handleSubmit(onSubmitSelectType)} className="space-y-6">
                                <TestSuiteTypePicker control={selectTypeForm.control} name="type" />
                                {selectTypeForm.formState.errors.type && (
                                    <p className="text-sm text-destructive">{selectTypeForm.formState.errors.type.message}</p>
                                )}
                            </form>
                        </Form>
                    )}
                </div>
                <div className="p-6 border-t">
                    <div className="flex justify-between">
                        <Button variant="outline" onClick={handleRequestClose}>
                            Cancel
                        </Button>
                        <BusyButton isBusy={isCreating} onClick={handleNext}>
                            {step === "create" ? "Next" : "Create"}
                        </BusyButton>
                    </div>
                </div>
                <AlertDialogComponent />
            </DialogContent>
        </Dialog>
    )
}

