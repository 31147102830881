import { useState, useCallback } from 'react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"

interface AlertDialogProps {
  title: string
  description: string
  confirmText: string
  cancelText: string
}

export function useAlertDialog() {
  const [isOpen, setIsOpen] = useState(false)
  const [resolvePromise, setResolvePromise] = useState<((value: boolean) => void) | null>(null)
  const [dialogProps, setDialogProps] = useState<AlertDialogProps | null>(null)

  const showAlertDialog = useCallback((props: AlertDialogProps) => {
    setDialogProps(props)
    setIsOpen(true)
    return new Promise<boolean>((resolve) => {
      setResolvePromise(() => resolve)
    })
  }, [])

  const handleConfirm = useCallback(() => {
    setIsOpen(false)
    resolvePromise?.(true)
  }, [resolvePromise])

  const handleCancel = useCallback(() => {
    setIsOpen(false)
    resolvePromise?.(false)
  }, [resolvePromise])

  const AlertDialogComponent = useCallback(() => {
    if (!dialogProps) return null

    return (
      <AlertDialog open={isOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{dialogProps.title}</AlertDialogTitle>
            <AlertDialogDescription>{dialogProps.description}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancel}>{dialogProps.cancelText}</AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirm}>{dialogProps.confirmText}</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }, [isOpen, dialogProps, handleCancel, handleConfirm])

  return { showAlertDialog, AlertDialogComponent }
}