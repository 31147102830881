import SubPageLayout from "@/app/features/organisation/SubPageLayout";
import OfficeSelector from "@/app/features/profile/OfficeSelector";
import BusyButton from "@/components/raytd/busy-button";
import { ImageUpload } from "@/components/raytd/image-upload";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useFileUpload } from "@/hooks/use-file-upload";
import { useUnsavedChanges } from "@/hooks/useUnsavedChanges";
import { useGetLoggedInUserQuery, useGetOfficesQuery, useUpdateUserProfileMutation } from "@app.raytd.com/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import APIError from "store/src/lib/api/apiError";
import { z } from "zod";
import { LinkedInInput } from './LinkedIn';
import { useNav } from './NavContext';
import { PasswordResetPopup } from './PasswordReset';
import Qualifications from './Qualifications';


const profileFormSchema = z.object({
    first_name: z.string().min(1, "First name is required"),
    last_name: z.string().min(1, "Last name is required"),
    phone: z.string().optional(),
    email: z.string().email("Invalid email address"),
    //organisation: z.string().optional(),
    position_title: z.string().optional(),
    office_id: z.union([z.number(), z.null()]).optional(),
    linkedin: z.union([z.string().url("Invalid URL"), z.literal(''), z.null()]).optional(),
    photo: z.union([z.null(), z.instanceof(File)]).optional(),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

export const ProfileForm = () => {

    const { data: profile, isLoading, isFetching, isError: loadingError } = useGetLoggedInUserQuery({});
    const [updateProfile, { isLoading: isUpdating, isError: isUpdateError, isSuccess: isUpdateSuccessful }] = useUpdateUserProfileMutation();
    const { upload: uploadPhoto, isLoading: isImageUploading, isError: isImageError, isSuccess: isImageSuccess, error: imageError, reset } = useFileUpload('/api/v1/users/:user/avatar', 'profile_image');


    const { data: offices, isLoading: officesLoading, isFetching: officesFetching } = useGetOfficesQuery({});

    console.debug({ profile, isLoading, isFetching });

    const form = useForm<ProfileFormValues>({
        resolver: zodResolver(profileFormSchema),
        defaultValues: {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            position_title: '',
            linkedin: '',
            office_id: null,
            photo: null
        },
    });


    const [retainedImage, setRetainedImage] = useState<string | null>(null);

    const isBusy = isUpdating || isImageUploading;

    const { isDirty } = form.formState;

    const { AlertDialogComponent } = useUnsavedChanges(isDirty, form.reset);

    const onSubmit = useCallback(async (values: z.infer<typeof profileFormSchema>) => {
        try {
            const { photo, ...data } = values;

            const promises = [];

            if (photo instanceof File) {
                const uploadPhotoPromise = uploadPhoto(photo, { user: profile.id?.toString() });
                promises.push(uploadPhotoPromise);
            }

            const updateProfilePromise = updateProfile({ ...data }).unwrap();
            promises.push(updateProfilePromise);

            const [photoResponse, payload] = await Promise.all(promises);

            if (photoResponse) {
                console.debug('photo', photoResponse);
                setRetainedImage(photoResponse.url);
            }

            toast.success("Profile updated");
            console.error([payload, photoResponse]);

        } catch (err) {
            console.error(err as APIError);
            toast.error("Failed to update profile");
        }
    }, [updateProfile, form]);


    const { setNavButtons } = useNav();

    useEffect(() => {
        setNavButtons(
            <>
                <PasswordResetPopup />
                <BusyButton
                    onClick={form.handleSubmit(onSubmit)}
                    disabled={!isDirty}
                    isBusy={isBusy}
                    busyText="Saving..."
                >
                    Save
                </BusyButton>
            </>
        );

        // Clean up function to reset buttons when component unmounts
        return () => setNavButtons(null);
    }, [setNavButtons,
        setNavButtons,
        form.handleSubmit,
        onSubmit,
        isDirty,
        isBusy
    ]);

    useEffect(() => {
        if (profile) {
            console.info('resetting form with profile data:', profile);
            form.reset({
                first_name: profile?.first_name,
                last_name: profile?.last_name,
                phone: profile?.phone,
                email: profile?.email,
                position_title: profile?.position_title,
                linkedin: profile?.linkedin,
                office_id: profile?.office?.id ?? null,
                photo: null
            });
        }
    }, [profile, form.reset]);

    useEffect(() => {
        console.info('errors', form.formState.errors, isDirty);
    }, [form.formState.errors, isDirty]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <SubPageLayout title="Profile">
            <AlertDialogComponent />
            <Form {...form}  >
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="grid gap-6 grid-cols-3 mb-4">
                        <div className="grid gap-6 grid-cols-2 mb-4 col-span-2">
                            <div className="col-span-2 grid grid-cols-2 gap-4">
                                <FormField
                                    control={form.control}
                                    name="first_name"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>First Name*</FormLabel>
                                            <FormControl>
                                                <Input {...field} placeholder="First Name" required />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="last_name"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Last Name*</FormLabel>
                                            <FormControl>
                                                <Input {...field} placeholder="Last Name" required />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <FormField
                                control={form.control}
                                name="phone"
                                render={({ field }) => (
                                    <FormItem className="col-span-2">
                                        <FormLabel>Phone</FormLabel>
                                        <FormControl>
                                            <Input {...field} placeholder="Phone" />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem className="col-span-2">
                                        <FormLabel>Email</FormLabel>
                                        <FormControl>
                                            <Input {...field} placeholder="Email" readOnly className="bg-muted" />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                            <FormItem className="col-span-2">
                                <FormLabel>Organisation</FormLabel>
                                <FormControl>
                                    <Input name="company" value={profile?.company?.name} placeholder="Organisation" readOnly className="bg-muted cursor-not-allowed" />
                                </FormControl>
                            </FormItem>
                            <FormField
                                control={form.control}
                                name="position_title"
                                render={({ field }) => (
                                    <FormItem className="col-span-2">
                                        <FormLabel>Position Title</FormLabel>
                                        <FormControl>
                                            <Input {...field} placeholder="Position Title" />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div>
                            <FormField
                                control={form.control}
                                name="photo"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Photo</FormLabel>
                                        <FormControl>

                                            <ImageUpload
                                                onChange={(file) => field.onChange(file)}
                                                onBlur={field.onBlur}
                                                value={field.value}
                                                name={field.name}
                                                error={form.formState.errors.photo?.message}
                                                initialImage={retainedImage ?? profile?.avatar}
                                                shape="circle"
                                                isLoading={isImageUploading}
                                            />

                                        </FormControl>

                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                    </div>


                    <div className="flex flex-col gap-4">
                        <Qualifications />

                        <div>

                            <h3 className="text-sm font-medium mb-2">Office</h3>
                            <FormField
                                control={form.control}
                                name="office_id"
                                render={({ field }) => (
                                    <FormControl>

                                        <OfficeSelector
                                            office={field.value}
                                            editable={true}
                                            offices={offices}
                                            isLoading={officesLoading || officesFetching}
                                            onSelect={(office) => {
                                                console.info('selected office', office);
                                                field.onChange(office.id);
                                            }}

                                        />
                                    </FormControl>
                                )}
                            />


                        </div>

                        <div>

                            <FormField
                                control={form.control}
                                name="linkedin"
                                render={({ field }) => (
                                    <LinkedInInput
                                        label="LinkedIn Profile"
                                        description="Your LinkedIn profile URL (optional)"
                                        placeholder="username"
                                        {...field}
                                        value={field.value || ""}
                                        onChange={(value) => field.onChange(value || null)}
                                    />
                                )}
                            />
                        </div>

                    </div>
                </form>
            </Form>
        </SubPageLayout>
    );
};
