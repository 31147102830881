import { Pill } from "@/components/raytd/pill";

const ModifiedPill = ({ isDirty }) => {
    if (!isDirty) {
        return null;
    }

    return <Pill variant="outline" size="sm">Modified</Pill>;
}

export default ModifiedPill;