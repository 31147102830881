import SubPageLayout from '@/app/features/organisation/SubPageLayout';
import { MainPage } from '@/app/layout2/AppLayout';
import EmptyState from '@/components/raytd/empty-state';
import { Button } from '@/components/ui/button';
import { selectAllAssets, useAppSelector, useGetAssetsQuery } from '@app.raytd.com/store';
import { useState } from 'react';
import { AssetList } from './AssetList'; // List of asset rows
import Filters from './Filters'; // Filter tabs and search functionality
import { useNavigate } from 'react-router-dom';

const AssetPage = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState('active');
  const [searchTerm, setSearchTerm] = useState('');

  // const {data:assets, isLoading, isFetching, isError, error } = useGetAssetsQuery({filter, searchTerm});
  const { isLoading, isFetching, isError, error, refetch } = useGetAssetsQuery(undefined);
  const assets = useAppSelector(selectAllAssets);

  console.info({ error, isError, isLoading, isFetching });

  //  console.debug({assets, isLoading, isError, error});

  const assetsData = assets || [];

  // Filter assets based on search and current filter
  const filteredAssets = assetsData.filter((asset) => {
    const matchesSearch = asset.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = filter === 'all' || asset.status === filter.toLowerCase();
    return matchesSearch && matchesFilter;
  });

  const handleNewAsset = () => {
    navigate('/assets/new');
  }

  return (
    <>

      <div className="flex flex-row justify-end items-center space-x-4">
        <Filters selected={filter} setFilter={setFilter} setSearchTerm={setSearchTerm} />
        <Button className="" variant="default" onClick={handleNewAsset}>+ Create Asset</Button>
      </div>

      <MainPage>
        <SubPageLayout
          title="Assets"
          subtitle='Create, update, manage and share your assets.'
          showSpinner={isFetching}
        >
          {isError ? (
            <EmptyState
              title="Failed to load assets"
              description="An error occurred while loading assets. Please try again."
              actionLabel="Retry"
              onAction={() => refetch()} />
          ) : (assetsData.length === 0 && !isLoading) ? (
            <EmptyState
              title="You have no assets"
              description="Create your first asset to get started."
              actionLabel="Create Asset"
              onAction={handleNewAsset}
            />
          ) : (
            <AssetList
              assets={filteredAssets}
              isLoading={isLoading}
            />
          )}
        </SubPageLayout>
      </MainPage>
    </>
  );

};

export default AssetPage;