import { api } from '../../api/fetch';
import { ElementClassification } from '../types';
import { ElementClassificationRename } from '../types/element';

export const fetchElementClassifications = async (treeId: number) => {
    return await api.get(`api/v1/classification-trees/tree/${treeId}`);
};

export const addElementClassification = async (elementClassification: Partial<ElementClassification>) => {
    return await api.post(`api/v1/element-classifications`, elementClassification);
};

export const updateElementClassification = async (elementClassification: Partial<ElementClassification>) => {
    return await api.put(`api/v1/element-classifications/${elementClassification.id}`, elementClassification);
};

export const deleteElementClassification = async (elementClassification: ElementClassification) => {
    return await api.delete(`api/v1/element-classifications/${elementClassification.id}`);
};

export const renameElementClassification = async (elementClassification: ElementClassificationRename) => {
    return await api.put(`api/v1/classification-trees/${elementClassification.classification_tree_id}/rename`, elementClassification);
}


export default {
    fetchElementClassifications,
    addElementClassification,
    updateElementClassification,
    deleteElementClassification,
    renameElementClassification
}