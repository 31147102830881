import React from 'react';
import MobileNavigation from './MobileNavigation';
import SearchBar from './SearchBar';
import UserMenu from './UserMenu';
import BreadcrumbNavigation from './BreadcrumbNavigation';
import HeaderLogo from './HeaderLogo';

const Header: React.FC = () => {
  return (
    <header className="flex h-14 items-center gap-4 border-none lg:h-[60px] justify-between px-1">
      <MobileNavigation />
      {/* <SearchBar /> */}
      <BreadcrumbNavigation />
      <HeaderLogo/> 
    </header>
  );
};

export default Header;
