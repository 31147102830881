import { CompanyEntity } from "@app.raytd.com/store";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import classNames from 'classnames';

type Props = {
    organisation: CompanyEntity;
    showName?: boolean;
    className?: string;
    size? : 'sm' | 'md' | 'lg';
}

const OrganisationAvatar: React.FC<Props> = ({ organisation, showName = false, className = '', size='md' }) => {
    return (
        <div className={classNames("flex flex-row space-x-4 items-center", className)}>
            <Avatar className={classNames({'w-8 h-8' : size === 'sm', 'w-12 h-12': size === 'md', 'w-16 h-16': size === 'lg'})} >
                <AvatarImage src={organisation?.logo_url} alt={organisation?.name} />
                <AvatarFallback>{organisation?.name[0]}</AvatarFallback>
            </Avatar>
            {showName && (<div className='font-semibold'>{organisation?.name}</div>)}
        </div>
    );
}

export default OrganisationAvatar;