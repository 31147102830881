import { CompanyEntity } from '../company.slice';
import { api } from '../../api/fetch';

export const requestToJoinOrganisation = async (data: {
  email?: string;
  organisation_id?: number;
}) => {
  return await api.post('/api/v1/requests/organisations/join', data);
};

export default requestToJoinOrganisation;
