import { ReportBlockForm } from "@/app/features/blocks/Details";
import SubPageLayout from "@/app/features/organisation/SubPageLayout"
import PageWithTopBarNavigation from "@/app/features/profile/PageWithTopBarNavigation";
import { TopNavBar } from "@/app/features/profile/TopNavBar";
import { MainPage } from "@/app/layout2/AppLayout";
import { SquareChevronLeft } from "lucide-react";
import { NavLink } from "react-router-dom";

const NewReportBlockPage = () => {

    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    <NavLink to="/report-blocks"><SquareChevronLeft className="h-6 w-6 text-zinc-500" /></NavLink>
                </TopNavBar>
                <div className="mb-3" />
                <SubPageLayout title='Create Report Block'>
                    <div className="max-w-xl">

                        <ReportBlockForm
                            // title='Create Element Group'
                            block={{
                                id: null,
                                name: '',
                                description: ''
                            }} />
                    </div>
                </SubPageLayout>

            </PageWithTopBarNavigation>
        </MainPage>
    );

}

export default NewReportBlockPage;