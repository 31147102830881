import * as React from "react"
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table"
import { ArrowUpDown, ChevronDown, ChevronUp, Loader2 } from "lucide-react"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"

import { PaginationToolbar } from "@/components/ui/pagination-toolbar"
import { EmptyTableComponent } from "@/components/ui/table/empty-table"

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[] | undefined
  globalFilter: string
  setGlobalFilter: (value: string) => void
  showHeaders?: boolean
  isLoading?: boolean
  emptyMessage?: string
  // Pagination props
  showPagination?: boolean
  showPageSize?: boolean
  showFirstLastButtons?: boolean
  itemsText?: string
  pageSizeText?: string
  pageSizeOptions?: number[]
}

export function DataTable<TData, TValue>({
  columns,
  data = [],
  globalFilter,
  setGlobalFilter,
  showHeaders = true,
  isLoading = false,
  emptyMessage = "No data available",
  showPagination = true,
  showPageSize = true,
  showFirstLastButtons = true,
  itemsText = "items",
  pageSizeText = "Items per page",
  pageSizeOptions = [10, 20, 30, 40, 50],
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [rowSelection, setRowSelection] = React.useState({})

  const table = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      sorting,
      globalFilter,
      rowSelection,
    },
  })

  if (isLoading) {
    return (
      <div className="rounded-md border">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                <Loader2 className="h-6 w-6 animate-spin mx-auto" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )
  }

  if (!data || data.length === 0) {
    return (
      <div className="rounded-md border">
        <Table>
          <TableBody>
            <EmptyTableComponent colSpan={columns.length} message={emptyMessage} />
          </TableBody>
        </Table>
      </div>
    )
  }

  return (
    <div>
      <div className="rounded-md border">
        <Table>
          {showHeaders && (
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : (
                        <div
                          className={header.column.getCanSort() ? "cursor-pointer select-none flex items-center" : ""}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getCanSort() && (
                            <span className="ml-2">
                              {header.column.getIsSorted() === "asc" ? (
                                <ChevronUp className="h-4 w-4" />
                              ) : header.column.getIsSorted() === "desc" ? (
                                <ChevronDown className="h-4 w-4" />
                              ) : (
                                <ArrowUpDown className="h-4 w-4" />
                              )}
                            </span>
                          )}
                        </div>
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
          )}
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {showPagination && (
        <PaginationToolbar
          currentPage={table.getState().pagination.pageIndex + 1}
          totalPages={table.getPageCount()}
          pageSize={table.getState().pagination.pageSize}
          onPageChange={(page) => table.setPageIndex(page - 1)}
          onPageSizeChange={(newPageSize) => table.setPageSize(newPageSize)}
          totalItems={table.getFilteredRowModel().rows.length}
          selectedItems={Object.keys(rowSelection).length}
          showPageSize={showPageSize}
          showFirstLastButtons={showFirstLastButtons}
          itemsText={itemsText}
          pageSizeText={pageSizeText}
          pageSizeOptions={pageSizeOptions}
        />
      )}
    </div>
  )
}