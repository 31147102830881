import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    ColumnDef,
    ColumnMeta,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { Archive, ArchiveRestore, Copy, MoreHorizontal } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@/components/ui/table'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import DataTablePagination from '@/app/features/assets/TablePagination'
import { MainPage } from '@/app/layout2/AppLayout'
import SubPageLayout from '@/app/features/organisation/SubPageLayout'
import Filters from '@/app/features/assets/Filters'
import { useTableStatusFilter } from '@/hooks/use-table-status-filter'
import { ClassificationTree, useArchiveElementTreeForUserMutation, useArchiveElementTreeMutation, useDuplicateClassificationTreeMutation, useGetElementGroupsQuery } from '@app.raytd.com/store'
import { SkeletonLoader } from '@/app/features/assets/Skeleton'
import OrganisationAvatar from '@/components/raytd/organisation-avatar'
import EmptyState from '@/components/raytd/empty-state'
import { toast } from 'sonner'
import ArchiveRestoreMenuItem from '@/components/raytd/archive-restore-menu-item'
import classNames from 'classnames'
import { useAuth } from '@/app/_components'

interface ExtendedColumnMeta extends ColumnMeta<ClassificationTree, unknown> {
    cellClassName?: string;
}

interface ElementGroup {
    id: string
    name: string
    description: string
    imageUrl: string
    status: 'active' | 'archived'
}

export default function ElementGroups() {
    const navigate = useNavigate()

    const {user} = useAuth();

    const { data: elementGroups, isLoading, isError } = useGetElementGroupsQuery(user.company.id);
    const [archiveGroupForUser] = useArchiveElementTreeForUserMutation();
    const [archiveGroup] = useArchiveElementTreeMutation();
    const [duplicateGroup] = useDuplicateClassificationTreeMutation();

    const { filter, setFilter, statusFilter, setStatusFilter, statusColumnFilterFn, searchTerm, setSearchTerm, globalFilterFn } = useTableStatusFilter();

    const [columnFilters, setColumnFilters] = useState([]);

    const handleItemClick = (elementGroup) => {
        navigate(`/elements/${elementGroup.id}`);
    }

    useEffect(() => {
        console.info('Status filter changed:', statusFilter);
        setColumnFilters([{ id: 'status', value: statusFilter }]);
    }, [statusFilter]);

    const handleArchiveForUser = useCallback(async (elementGroup) => {
        console.log('Archive', elementGroup.id);
        const changeToStatus = elementGroup.status === 'active' ? 'archived' : 'restored';
        try {
            await archiveGroupForUser(elementGroup.id).unwrap();
            toast.success(`Element group ${changeToStatus} successfully`);
        } catch (error) {
            console.error('Error archiving element group', error);
            toast.error(`Element was not ${changeToStatus}`);
        }
    }, []);

    const handleArchive = useCallback(async (elementGroup) => {
        console.log('Archive', elementGroup.id);
        const changeToStatus = elementGroup.status === 'active' ? 'archived' : 'restored';
        try {
            await archiveGroup(elementGroup.id).unwrap();
            toast.success(`Element group ${changeToStatus} successfully`);
        } catch (error) {
            console.error('Error archiving element group', error);
            toast.error(`Element was not ${changeToStatus}`);

        }
    }, []);

    const handleDuplicate = useCallback(async (elementGroup) => {
        console.log('Duplicate', elementGroup.id);
        try {
            const response = await duplicateGroup(elementGroup).unwrap();
            console.log('Response', response);
            toast.success(`Element group duplicated successfully`);
        } catch (error) {
            console.error('Error duplicating element group', error);
            toast.error(`Element group was not duplicated`);
        }
    }, []);

    const columns: ColumnDef<ClassificationTree>[] = [
        {
            accessorKey: 'avatar',
            size: 50,
            meta: {
                cellClassName: 'w-10',
            },
            cell: ({ row }) => (
                <OrganisationAvatar organisation={row.original.organisation} size='md' />
            ),
        },
        {
            accessorKey: 'name',
            header: 'Element Groups',
            cell: ({ row }) => (
                <Button variant="ghost" onClick={() => handleItemClick(row.original)} className="px-4">
                    <div className="flex flex-col items-start">
                        <div className="font-semibold">{row.original.name}</div>
                        <div className="font-normal">
                            {row.original.description}
                        </div>
                    </div>
                </Button>
            ),
        },
        {
            accessorKey: 'status',
            filterFn: statusColumnFilterFn,
        },
        {
            id: 'actions',
            meta: {
                cellClassName: 'w-10',
            },
            cell: ({ row }) => (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={() => handleDuplicate(row.original.id)}>
                            <Copy className="mr-2 h-4 w-4" />
                            Duplicate
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => handleArchiveForUser(row.original)}>
                            <ArchiveRestoreMenuItem status={row.original.status} />
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => handleArchive(row.original)}>
                            <ArchiveRestoreMenuItem
                                status={row.original.is_globally_archived ? 'archived' : 'active'}
                                archiveText='Archive (Everyone)'
                                restoreText='Restore (Everyone)'
                            />
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            ),
        },
    ];

    //@ts-ignore
    const table = useReactTable({
        data: elementGroups,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onColumnFiltersChange: setColumnFilters,
        state: {
            columnFilters,
            //@eslint-disable-next-line
            globalFilter: searchTerm,
        },
        onGlobalFilterChange: setSearchTerm,
        globalFilterFn: (row, columnId, filterValue) => {
            return row.original.name.toLowerCase().includes(filterValue.toLowerCase());
        },
        //globalFilterFn,
        initialState: {
            columnVisibility: {
                status: false,
            },
        },
    });

    const isFetching = false;

    const handleCreate = () => {
        navigate('/elements/new');
    }

    return (
        <>
            <div className="flex flex-row justify-end items-center space-x-4">
                <Filters selected={statusFilter} setFilter={setStatusFilter} setSearchTerm={setSearchTerm} />
                <Button className="" variant="default" onClick={handleCreate}>+ Create Element Group</Button>
            </div>
            <MainPage>
                <SubPageLayout
                    title="Element Groups"
                    subtitle='Create, update, manage and share your element groups.'
                    showSpinner={isFetching}
                >

                    <div className="space-y-4">

                        <div className="rounded-md border">
                            <Table>
                                <TableBody>
                                    <ElementGroupTableBody isLoading={isLoading} data={elementGroups} table={table} columns={columns} />
                                </TableBody>
                            </Table>
                        </div>
                        {!isLoading && (
                            <DataTablePagination table={table} itemsText='element groups' />
                        )}
                    </div>
                </SubPageLayout>
            </MainPage>
        </>
    )
}

const ElementGroupTableBody = ({ data, table, columns, isLoading }) => {

    if (isLoading) {
        return <SkeletonLoader />;
    }

    if (!data) {
        return null;
    }

    if (data.length === 0) {
        return <EmptyState
            title="You have no element groups."
            description="Create your first element group to get started."
        />;
    }

    if (table.getFilteredRowModel().rows?.length === 0) {
        return <NoSearchResults columnsLength={columns.length} text="No element groups found." />
    }

    const rows = table.getRowModel().rows;
    return <TableRows rows={rows} />;
};

const TableRows = ({ rows }) => (
    <>
        {rows.map((row) => (
            <TableRow
                key={row.id} data-state={row.getIsSelected() && 'selected'}
                className={classNames({ 'opacity-60': row.original.status === 'archived' })}
            >
                {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}
                        className={(cell.column.columnDef.meta as ExtendedColumnMeta)?.cellClassName}
                        style={{
                            maxWidth: cell.column.columnDef.size,
                            alignContent: cell.column.columnDef.align,
                        }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                ))}
            </TableRow>
        ))}
    </>
);


const NoSearchResults = ({ columnsLength, text }) => (
    <TableRow>
        <TableCell colSpan={columnsLength} className="h-24 text-center">
            No element groups found.
        </TableCell>
    </TableRow>
);