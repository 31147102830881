import { useRef, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Header } from './header';

import { UserSignup, UsersEntity, alertsActions, api, authActions, signupUser, useAppDispatch, useCreateUserMutation } from '@app.raytd.com/store';
import ErrorBox from './error';

import { history } from '@app.raytd.com/common';

import { Button } from '@/components/ui/button';
import CardTitle from './CardTitle';
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import BusyButton from '@/components/raytd/busy-button';


const Step1Schema = z.object({
  first_name: z.string().min(2, { message: 'Name must be at least 2 characters' }),
  last_name: z.string().min(2, { message: 'Name must be at least 2 characters' }),
  email: z.string().email({ message: 'Please enter a valid email address' }),
});

const Step2Schema = z.object({
  password: z.string().min(8, { message: 'Password must have at least 8 characters' }),
  password_confirmation: z.string(),
});

const SignupSchema = Step1Schema.merge(Step2Schema).refine(
  (data) => data.password === data.password_confirmation,
  {
    message: 'Your passwords do not match',
    path: ['password_confirmation'],
  }
);

// Type for the form data
type SignupFormData = z.infer<typeof SignupSchema>;
type Step1Data = z.infer<typeof Step1Schema>;
type Step2Data = z.infer<typeof Step2Schema>;

// Define the three steps of the form
const Step1 = ({ register, errors }: any) => (
  <>
    <div className="flex space-x-4 mb-4">
      <div className="flex-1">
        <Label htmlFor="first_name" className="sr-only">First Name</Label>
        <Input
          id="first_name"
          type="text"
          placeholder="First Name"
          {...register('first_name', { required: true })}
        />
        {errors.firstName && <p className="text-red-600 text-sm">First name is required</p>}
      </div>
      <div className="flex-1">
        <Label htmlFor="last_name" className="sr-only">Last Name</Label>
        <Input
          id="last_name"
          type="text"
          placeholder="Last Name"
          {...register('last_name', { required: true })}
        />
        {errors.lastName && <p className="text-red-600 text-sm">Last name is required</p>}
      </div>
    </div>
    <div className="mb-4">
      <Label htmlFor="email" className="sr-only">Email address</Label>
      <Input
        id="email"
        type="email"
        placeholder="Email address"
        {...register('email', { required: true })}
      />
      {errors.email && <p className="text-red-600 text-sm">Email is required</p>}
    </div>
  </>
);

const Step1Form = ({ onComplete, prefill }: { onComplete: (data: Step1Data) => void, prefill: any }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<Step1Data>({
    resolver: zodResolver(Step1Schema),
    defaultValues: {
      first_name: prefill?.user?.first_name || '',
      last_name: prefill?.user?.last_name || '',
      email: prefill?.user?.email || '',
    },
    mode: 'onBlur'
  });

  const onSubmit = (data: Step1Data) => {
    onComplete(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Step1 register={register} errors={errors} />
      <Button type="submit" className="w-full">Next</Button>
    </form>
  );
}

const Step2 = ({ register, errors, watch }: any) => {

  const password = watch('password');


  return (
    <>
      <div className="mb-4">
        <Label htmlFor="password" className="sr-only">Password</Label>
        <Input
          id="password"
          type="password"
          placeholder="Password"
          autoComplete='new-apssword'
          {...register('password', { required: true })}
        />
        {errors.password && <p className="text-red-600 text-sm">Password is required</p>}
      </div>
      <div className="mb-4">
        <Label htmlFor="password_confirmation" className="sr-only">Confirm Password</Label>
        <Input
          id="password_confirmation"
          type="password"        autoComplete='new-apssword'

          placeholder="Confirm Password"
          {...register('password_confirmation', {
            required: true,
            validate: (value) => value === password || 'Passwords do not match', // Validate that the confirmation matches the password
          })}
        />
        {errors.password_confirmation && <p className="text-red-600 text-sm">Confirmation is required</p>}
      </div>
    </>
  )
};

const Step3 = ({ register, errors }: any) => (
  <>
    <div className="mb-4">
      <Label htmlFor="searchOrg" className="sr-only">Search by name</Label>
      <Input
        id="searchOrg"
        type="text"
        placeholder="Search by name"
        {...register('searchOrg')}
      />
    </div>
    <div className=" ml-2 mb-4 text-sm">OR</div>
    <div className="mb-4">
      <Label htmlFor="searchUser" className="sr-only">Search by user</Label>
      <Input
        id="searchUser"
        type="text"
        placeholder="Search by user"
        {...register('searchUser')}
      />
    </div>
  </>
);



type CreateUserResponse = {
  body: {
    user: UsersEntity;
    token: string;
  }
}

// Main Signup component that handles the steps
export function Signup() {
  
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const prefill = location.state?.invite || {};
  const [step, setStep] = useState(location.state?.invite?.token ? 2 : 1);
  const [isBusy, setBusy] = useState(false);
  const [step1Data, setStep1Data] = useState<Step1Data | null>(null);


  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: zodResolver(SignupSchema),
    defaultValues: {
      first_name: prefill?.user?.first_name || '',
      last_name: prefill?.user?.last_name || '',
      email: prefill?.user?.email || '',
      password: '',
      password_confirmation: '',
    },
    mode: 'onBlur'  
  });

  const onStep1Complete = (data: Step1Data) => {
    setStep1Data(data);
    setStep(2);
  };

  const onSubmit = async (data: any) => {
    if (step < 3) {

      if (step === 2) {
        // Validate password and confirm password match
        // and attempt to create a user.

        console.info('passwords match', data.password === data.password_confirmation);

        if (data.password !== data.password_confirmation) {
          return;
        }

        api.post('/api/v1/signup/invite', {
          token: prefill.token,
          password: data.password,
          password_confirmation: data.password_confirmation,
          email: data.email,
        }).then((response: CreateUserResponse) => {

          console.info('User created', response);
          dispatch(authActions.loginUser({ user: response?.body?.user, token: response?.body?.token }));
          // User created successfully, now you might want to refresh the user profile
          dispatch(authActions.getUser()).unwrap().then((user) => {
            console.info('Logged User', user);
            navigate('/profile');
          }).catch((error) => {
            console.error('Failed to get user', error);

          }).finally(() => {
            setBusy(false);
          });

          // user password is set.
          //setStep(step + 1); // Go to the next step
        }).catch((error) => {
          console.error('Failed to create user', error);
        });
        return;
      }

      
    } else {
      // Submit final form data here
      console.log('Final form data', data);
    }
  };

  return (
    <Card className="w-96 border-none shadow-none">
      <CardHeader className="mb-4">
        {step === 1 && <CardTitle className="text-center text-2xl">Create your free account</CardTitle>}
        {step === 2 && <CardTitle className="text-center text-2xl">Choose your password</CardTitle>}
        {step === 3 && <CardTitle className="text-center text-2xl">Find your organisation</CardTitle>}
      </CardHeader>
      <CardContent>
      {step === 1 ? (
          <Step1Form onComplete={onStep1Complete} prefill={prefill} />
        ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* {step === 1 && <Step1 register={register} errors={errors} />} */}
          {step === 2 && <Step2 register={register} errors={errors} watch={watch} />}
          {step === 3 && <Step3 register={register} errors={errors} />}

          <BusyButton type="submit" className="w-full" isBusy={isBusy} >
            {step === 3 ? 'Join' : 'Next'}
          </BusyButton>
        </form>
        )}
      </CardContent>
      {step === 3 && (
        <CardFooter className="flex justify-center mt-4">
          <Button variant="ghost" className="w-full">
            Skip
          </Button>
        </CardFooter>
      )}
    </Card>
  );
}

export default Signup;


export type SignupProps = {
  /**
   *
   *
   * */
};

type Inputs = {
  //role: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_repeat: string;
};


export function SignUpPage(props: SignupProps) {
  const dispatch = useAppDispatch();

  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const [createUser, { isLoading:isUserCreating, isError:isUserCreationError, error:creationError }] = useCreateUserMutation();

  const canSave = addRequestStatus === 'idle';

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(SignupSchema),
    defaultValues: {
      first_name: '',
      last_name:  '',
      email: '',
      password: '',
      password_repeat: '',
    } 
  });

  const onSubmit = async (data: z.infer<typeof SignupSchema>) => {
    dispatch(alertsActions.clear());
    setErrorMessage('');

    if (!canSave) {
      console.info('Submission in process');
      return;
    }
    try {
      setAddRequestStatus('pending');

      // const response = await dispatch(
      //   signupUser({
      //     name: data.name,
      //     email: data.email,
      //     password: data.password,
      //     password_confirmation
      //   } as UserSignup)
      // ).unwrap();

      history.navigate('/login');
      dispatch(
        alertsActions.success({
          message: 'Registration successful!',
          showAfterRedirect: true,
        })
      );
    } catch (err: any) {
      console.error('Failed to save the post: ', err);
      setErrorMessage(
        //'An unexpected error occured during sign up, please try again.'
        err
      );

    } finally {
      setAddRequestStatus('idle');
    }
  };

  const password = useRef({});
  password.current = watch('password', '');

  return (
    <Card className="w-96 border-none shadow-none">
      <CardHeader className="mb-4">
        <CardTitle className="text-center text-2xl">Create your free account</CardTitle>
        <p className="text-center text-sm text-zinc-500">Limitless 30-day trial. No credit card required.</p>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex space-x-4 mb-4">
            <div className="flex-1">
              <Label htmlFor="firstName" className="sr-only">First Name</Label>
              <Input
                id="firstName"
                type="text"
                {...register('first_name', { required: true })}
                className="mt-1"
                placeholder="First Name"
                aria-invalid={errors.first_name ? 'true' : 'false'}
              />
              {errors.first_name && <p className="text-red-600 text-sm mt-1">First name is required</p>}
            </div>
            <div className="flex-1">
              <Label htmlFor="lastName" className="sr-only">Last Name</Label>
              <Input
                id="lastName"
                type="text"
                {...register('last_name', { required: true })}
                className="mt-1"
                placeholder="Last Name"
                aria-invalid={errors.last_name ? 'true' : 'false'}
              />
              {errors.last_name && <p className="text-red-600 text-sm mt-1">Last name is required</p>}
            </div>
          </div>

          <div className="mb-4">
            <Label htmlFor="email" className="sr-only">Email address</Label>
            <Input
              id="email"
              type="email"
              {...register('email', { required: true })}
              className="mt-1"
              autoComplete="email"
              placeholder="Email address"
              aria-invalid={errors.email ? 'true' : 'false'}
            />
            {errors.email && <p className="text-red-600 text-sm mt-1">Email is required</p>}
          </div>

          <Button type="submit" className="w-full" disabled={addRequestStatus === 'pending'}>
            {addRequestStatus === 'pending' ? 'Creating...' : 'Create'}
          </Button>

          <div className=" flex justify-end text-sm text-zinc-500 mt-2">
            Already have an account?&nbsp;
            <Link to="/login" className="font-semibold text-black hover:text-zinc-800">
              Log in
            </Link>
          </div>
        </form>
      </CardContent>
    </Card>
  )

}
