import ListButton from '@/components/raytd/list-button';
import { cn } from '@/lib/utils';
import React from 'react';

interface ExpandButtonProps {
    expanded?: boolean;
    className?: string;
    variant: 'item' | 'section';
    onClick?: () => void;
}

const ExpandButton: React.FC<ExpandButtonProps> = ({ expanded = false, variant='section', className, onClick }) => {
    return (
        <ListButton
            variant={expanded ? (variant === 'item' ? 'expand-down' : 'expand-item-down') : (variant === 'item' ? 'expand-right' : 'expand-item-right')}
            className={cn(className)}
            onClick={onClick}
        />
    );
};

export default ExpandButton;