import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { XCircle } from "lucide-react"

interface ErrorAlertProps {
  title?: string;
  description: string;
}

export default function ErrorAlert({ title = "Error", description }: ErrorAlertProps = { description: "An error occurred. Please try again." }) {
  return (
    <Alert variant="destructive" className="border-red-500 bg-red-50 dark:bg-red-900 dark:bg-opacity-10 mb-4">
      <XCircle className="h-5 w-5 text-red-600 dark:text-red-400" />
      <AlertTitle className="text-red-800 dark:text-red-200">{title}</AlertTitle>
      <AlertDescription className="text-red-700 dark:text-red-300">
        {description}
      </AlertDescription>
    </Alert>
  )
}