import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import TestAPI from './api';
import { CustomField, TestSuite } from './entity';

export const TESTSUITES_FEATURE_KEY = 'testSuites';

/*
 * Update these interfaces according to your requirements.
 */
type TestsuitesEntity = TestSuite;

export interface TestsuitesState extends EntityState<TestsuitesEntity, number> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  savingStatus: 'idle' | 'saving' | 'saved' | 'error';
  error?: string | null;
}

export const testsuitesAdapter = createEntityAdapter<TestsuitesEntity>();

export const initialTestsuitesState: TestsuitesState =
  testsuitesAdapter.getInitialState({
    loadingStatus: 'not loaded',
    savingStatus: 'idle',
    error: null,
  });

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchTestsuites())
 * }, [dispatch]);
 * ```
 */
export const fetchTestsuites = createAsyncThunk<TestSuite[]>(
  'testsuites/fetchStatus',
  async (_, thunkAPI) => {
    /**
     * Replace this with your custom fetch call.
     * For example, `return myApi.getTestsuitess()`;
     * Right now we just return an empty array.
     */
    const response = await TestAPI.fetchTestSuites();
    return response.body.data as TestSuite[];
  }
);

export const saveTestSuite = createAsyncThunk<TestSuite, TestSuite>(
  'testsuites/saveTestSuite',
  async (testSuite: TestSuite, thunkAPI) => {
    let response;
    if (testSuite.id) {
      response = await TestAPI.updateTestSuite(testSuite);
    } else {
      response = await TestAPI.saveTestSuite(testSuite);
    }

    console.info('saveTestSuite response', response, response.ok);
    if (response.ok) {
      return response.body as TestSuite;
    }

    return thunkAPI.rejectWithValue(response.body);
  }
);

export const duplicateTestSuite = createAsyncThunk<TestSuite, TestSuite>(
  'testsuites/duplicateTestSuite',
  async (testSuite: TestSuite, thunkAPI) => {
    if (!testSuite.id) {
      return thunkAPI.rejectWithValue('Test Suite ID is required');
    }

    const response = await TestAPI.duplicateTestSuite(testSuite);
    console.info('duplicateTestSuite response', response, response.ok);
    if (response.ok) {
      return response.body as TestSuite;
    }

    return thunkAPI.rejectWithValue(response.body);
  }
);

export const archiveTestSuite = createAsyncThunk<TestSuite, TestSuite>(
  'testsuites/archiveTestSuite',
  async (testSuite: TestSuite, thunkAPI) => {
    if (!testSuite.id) {
      return thunkAPI.rejectWithValue('Test Suite ID is required');
    }

    const response = await TestAPI.archiveTestSuite(testSuite);
    console.info('archiveTestSuite response', response, response.ok);
    if (response.ok) {
      return response.body as TestSuite;
    }

    return thunkAPI.rejectWithValue(response.body);
  }
);

export const deleteTestSuite = createAsyncThunk<number, TestSuite>(
  'testsuites/deleteTestSuite',
  async (testSuite: TestSuite, thunkAPI) => {
    if (!testSuite.id) {
      return thunkAPI.rejectWithValue('Test Suite ID is required');
    }

    const response = await TestAPI.deleteTestSuite(testSuite);
    console.info('deleteTestSuite response', response, response.ok);
    if (response.ok) {
      return testSuite.id;
    }

    return thunkAPI.rejectWithValue(response.body);
  }
);

type CustomFieldSaveArgs = {
  testSuiteId: number;
  customField: CustomField;
};

export const saveTestSuiteFields = createAsyncThunk<
  CustomField[],
  { testSuiteId: number; fields: Partial<CustomField>[] }
>(
  'testsuites/saveTestSuiteFields',
  async ({ testSuiteId, fields }, thunkAPI) => {
      const response = await TestAPI.saveTestSuiteFields(testSuiteId, fields);

    console.info('saveTestSuiteFields response', response, response.ok);
    if (response.ok) {
      return response.body.fields as CustomField[];
    }

    return thunkAPI.rejectWithValue(response.body);
  }
);

export const saveCustomField = createAsyncThunk<
  CustomField[],
  CustomFieldSaveArgs
>(
  'testsuites/saveCustomField',
  async ({ testSuiteId, customField }: CustomFieldSaveArgs, thunkAPI) => {
    let response;
    console.info('saveCustomField', testSuiteId, customField);
    if (customField.field_id) {
      response = await TestAPI.updateCustomField(testSuiteId, customField);
    } else {
      response = await TestAPI.saveCustomField(testSuiteId, customField);
    }

    console.info('saveCustomField response', response, response.ok);
    if (response.ok) {
      return response.body as CustomField[];
    }

    return thunkAPI.rejectWithValue(response.body);
  }
);

export const deleteCustomField = createAsyncThunk<
  boolean,
  CustomFieldSaveArgs
>(
  'testsuites/deleteCustomField',
  async ({ testSuiteId, customField }: CustomFieldSaveArgs, thunkAPI) => {
    let response;
    console.info('deleteCustomField', testSuiteId, customField);
    if (customField.field_id) {
      response = await TestAPI.deleteCustomField(testSuiteId, customField);
    }

    console.info('deleteCustomField response', response, response.ok);
    if (response.ok) {
      return true;
    }

    return thunkAPI.rejectWithValue(response.body);
  }
);

export const testsuitesSlice = createSlice({
  name: TESTSUITES_FEATURE_KEY,
  initialState: initialTestsuitesState,
  reducers: {
    add: testsuitesAdapter.addOne,
    remove: testsuitesAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestsuites.pending, (state: TestsuitesState) => {
        state.loadingStatus = 'loading';
      })
      .addCase(
        fetchTestsuites.fulfilled,
        (state: TestsuitesState, action: PayloadAction<TestsuitesEntity[]>) => {
          testsuitesAdapter.setAll(state, action.payload);
          state.loadingStatus = 'loaded';
        }
      )
      .addCase(fetchTestsuites.rejected, (state: TestsuitesState, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(saveTestSuite.pending, (state) => {
        state.savingStatus = 'saving';
      })
      .addCase(saveTestSuite.fulfilled, (state, action) => {
        testsuitesAdapter.upsertOne(state, action.payload);
        state.savingStatus = 'saved';
      })
      .addCase(saveTestSuite.rejected, (state, action) => {
        state.savingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(archiveTestSuite.pending, (state) => {
        state.savingStatus = 'saving';
      })
      .addCase(archiveTestSuite.fulfilled, (state, action) => {
        testsuitesAdapter.upsertOne(state, action.payload);
        state.savingStatus = 'saved';
      })
      .addCase(archiveTestSuite.rejected, (state, action) => {
        state.savingStatus = 'error';
        state.error = action.error.message;
      })
      .addCase(deleteTestSuite.pending, (state) => {
        state.savingStatus = 'saving';
      })
      .addCase(deleteTestSuite.fulfilled, (state, action) => {
        testsuitesAdapter.removeOne(state, action.payload);
        state.savingStatus = 'saved';
      })
      .addCase(deleteTestSuite.rejected, (state, action) => {
        state.savingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const testsuitesReducer = testsuitesSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(testsuitesActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const testsuitesActions = testsuitesSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
import { TestSuite } from './entity';
 *
 * // ...
 *
 * const entities = useSelector(selectAllTestsuites);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = testsuitesAdapter.getSelectors();

export const getTestsuitesState = (rootState: {
  [TESTSUITES_FEATURE_KEY]: TestsuitesState;
}): TestsuitesState => rootState[TESTSUITES_FEATURE_KEY];

export const selectAllTestsuites = createSelector(
  getTestsuitesState,
  selectAll
);

export const selectTestsuitesEntities = createSelector(
  getTestsuitesState,
  selectEntities
);

export const selectTestSuiteLoadingStatus = createSelector(
  getTestsuitesState,
  (state) => state.loadingStatus
);

export const selectTestSuiteSavingStatus = createSelector(
  getTestsuitesState,
  (state) => state.savingStatus
);

export const selectTestSuiteById = createSelector(
  [selectAllTestsuites, (_, testSuiteId: number) => testSuiteId],
  (suites, testSuiteId) =>
    suites.find((suite: TestSuite) => suite.id === testSuiteId)
);
