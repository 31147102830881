// export type Address = {
//   streetNumber: string; // e.g., "1600"
//   street: string; // e.g., "Amphitheatre Parkway"
//   city: string; // e.g., "Mountain View"
//   state: string; // e.g., "CA"
//   postcode: string; // e.g., "94043"
//   country: string; // e.g., "United States"
//   formattedAddress: string; // e.g., "1600 Amphitheatre Parkway, Mountain View, CA 94043, United States"
//   latitude?: number; // e.g., 37.4224764
//   longitude?: number; // e.g., -122.0842499
// };

export type Address = {
  street: string;
  city?: string;
  suburb: string;
  postcode: string;
  state: string;
  country: string;
};

export type Addressable = Address & {
  addressable_id: string;
  id: number;
};

export function formatAddress(
  address: Address,
  excludeCountry = false,
  separator = ', '
): string {
  // Customize the format below as needed
  // const city = address.suburb ? address.suburb : address.city;
  //return `${address.street}, ${city}, ${address.state} ${address.postcode}, ${address.country}`;

  const addressParts = [
    address.street ?? '',
    address.city ?? '',
    address.state ?? '',
    address.postcode ?? '',
    excludeCountry ? '' : address.country ?? '',
  ].filter(Boolean); // Remove any undefined or empty strings

  // Remove duplicate entries (e.g., if suburb and city are the same)
  const uniqueAddressParts = Array.from(new Set(addressParts));

  const formattedAddress = uniqueAddressParts.join(separator);

  return formattedAddress;
}
