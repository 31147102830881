import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { api } from '@app.raytd.com/store';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorAlert from '@/components/raytd/form-error';

type RequestStatus = 'idle' | 'pending' | 'success' | 'error';

export function ResetPasswordPage() {
    const navigate = useNavigate();
    const [requestStatus, setRequestStatus] = useState<RequestStatus>('idle');
    const [error, setError ] = useState<string | null>(null);

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    //reset to idle on unmount
    useEffect(() => {
        return () => {
            setRequestStatus('idle');
            setError(null);
        };
    }, []);

    const onSubmit = async (data: any) => {
        setError(null);

        if (requestStatus === 'success') {
            navigate('/login');
            return;
        }

        if (requestStatus === 'pending') {
            return;
        }

        setRequestStatus('pending');

        try {
            // Simulate API request to reset password
            console.log('Requesting password reset for:', data.email);


            const response = await api.put(`/api/v1/user/password/reset`, {
                token,
                email,
                password: data.password,
                password_confirmation: data.password_confirmation
            });

            console.log('Response:', response);
            setRequestStatus('success');
        } catch (error) {
            console.debug('Error:', {error}, error?.message ?? 'No message');
            setRequestStatus('error');
            setError(error?.message ?? 'An unexpected error occurred');
        }
    };


    const password = watch('password');

    return (

        <Card className="w-96 border-none shadow-none">
            <CardHeader className="mb-4">
                <CardTitle className="text-center text-2xl text-zinc-800">Reset your password</CardTitle>
            </CardHeader>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {requestStatus === 'error' && (
                        <ErrorAlert title="Error" description={error} />
                    )}
                    <div className="mb-4" hidden={requestStatus === 'success'}>
                        <Label htmlFor="password" className="sr-only">Password</Label>
                        <Input
                            id="password"
                            type="password"
                            placeholder="Password"
                            autoComplete='new-apssword'
                            {...register('password', { required: true })}
                        />
                        {errors.password && <p className="text-red-600 text-sm">Password is required</p>}
                    </div>
                    <div className="mb-4" hidden={requestStatus === 'success'}>
                        <Label htmlFor="password_confirmation" className="sr-only">Confirm Password</Label>
                        <Input
                            id="password_confirmation"
                            type="password" autoComplete='new-apssword'

                            placeholder="Confirm Password"
                            {...register('password_confirmation', {
                                required: true,
                                validate: (value) => value === password || 'Passwords do not match', // Validate that the confirmation matches the password
                            })}
                        />
                        {errors.password_confirmation && <p className="text-red-600 text-sm">Confirmation is required</p>}
                    </div>

                    {requestStatus === 'success' && (
                        <div className="mb-3 text-sm text-zinc-500 text-center">
                            Your password has been reset successfully.
                        </div>
                    )}

                    <Button type="submit" className="w-full" disabled={requestStatus === 'pending'}>
                        {requestStatus === 'pending' && 'Processing...'}
                        {requestStatus === 'success' && 'Return to Login'}
                        {requestStatus === 'error' && 'Try again'}
                        {requestStatus === 'idle' && 'Reset password'}
                    </Button>



                </form>
            </CardContent>
        </Card>

    );
}

export default ResetPasswordPage;
