"use client"

import * as React from "react"
import { ChevronRight, ChevronDown, X } from 'lucide-react'
import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { ScrollArea } from "@/components/ui/scroll-area"
import { cn } from "@/lib/utils"
import { Badge } from "@/components/ui/badge"
import { useFormContext, Controller } from "react-hook-form"
import { useGetElementGroupsQuery } from "@app.raytd.com/store"
import { useAuth } from "@/app/_components"
import { Skeleton } from "@/components/ui/skeleton"

interface ElementTree {
    id: string
    name: string
    type: string
}

interface ElementTreeSelectorProps {
    name: string
    label?: string
}

export function ElementTreeSelector({ name, label = "Element Groups" }: ElementTreeSelectorProps) {
    const [open, setOpen] = React.useState(false)
    const [search, setSearch] = React.useState("")
    //const { data: elementTrees = [] } = useElementtree()
    const { user } = useAuth();
    const { data: elementTrees = [], isLoading, isError } = useGetElementGroupsQuery(user.company.id);
    const form = useFormContext()

    console.debug({ elementTrees });

    const filteredGroups = React.useMemo(() => {
        return elementTrees?.filter((suite) =>
            suite.name?.toLowerCase().includes(search?.toLowerCase())
        )
    }, [elementTrees, search])

    return (
        <Controller
            name={name}
            control={form.control}
            defaultValue={[]}
            render={({ field }) => (
                <div className="space-y-2">
                    <div className="flex justify-between items-center">
                        <div className="text-sm font-medium">{label}</div>
                        <Button
                            type="button"
                            variant="link"
                            size="default"
                            onClick={() => setOpen(true)}
                        >
                            + Add Element Group
                        </Button>
                    </div>

                    {
                        isLoading && (
                            <div className="h-20 flex flex-col w-full gap-4">
                                <Skeleton className="h-8 w-24" />
                                <Skeleton className="h-8 w-40" />
                                <Skeleton className="h-8 w-32" />
                            </div>
                        )

                    }

                    {!isLoading && (

                        <div className="flex flex-col items-start gap-2 bg-zinc-100 p-2 rounded-lg">
                            {field.value.map((id: string) => {
                                const group = elementTrees.find((s) => s.id === id)
                                if (!group) return null;

                                return (
                                    <Badge
                                        key={group.id}
                                        variant="secondary"
                                        className={cn(
                                            "px-3 py-1 text-sm",
                                            "bg-zinc-300"


                                        )}
                                    >
                                        {group.name}
                                        <button
                                            type="button"
                                            className="ml-2 hover:text-foreground/80"
                                            onClick={() => {
                                                field.onChange(field.value.filter((i: string) => i !== group.id))
                                            }}
                                        >
                                            <X className="h-3 w-3" />
                                        </button>
                                    </Badge>
                                )
                            })}
                            {
                                field.value.length === 0 && (
                                    <span className="text-muted-foreground text-sm">No element groups assigned</span>
                                )
                            }
                        </div>

                    )}

                    <Dialog open={open} onOpenChange={setOpen}>
                        <DialogContent className="max-w-2xl">
                            <DialogHeader>
                                <DialogTitle>Select Element Groups</DialogTitle>
                            </DialogHeader>
                            <div className="space-y-4 py-4">
                                <Input
                                    placeholder="Search element groups..."
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="w-full"
                                />
                                <ScrollArea className="h-[300px] rounded-md border p-4">
                                    <div className="space-y-2 flex items-start flex-col">
                                        {filteredGroups?.map((suite) => (
                                            <Badge
                                                key={suite.id}
                                                className={cn(
                                                    "flex items-center justify-between rounded-lg p-2 cursor-pointer transition-colors",
                                                    "bg-zinc-300 text-zinc-950",

                                                    field.value.includes(suite.id) && "opacity-50"
                                                )}
                                                onClick={() => {
                                                    if (!field.value.includes(suite.id)) {
                                                        field.onChange([...field.value, suite.id])
                                                    }
                                                }}
                                            >
                                                <span>{suite.name}</span>
                                                {field.value.includes(suite.id) && (
                                                    <X className="h-4 w-4" />
                                                )}
                                            </Badge>
                                        ))}
                                    </div>
                                </ScrollArea>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        />
    )
}

export default ElementTreeSelector