import { useNav } from '@/app/features/profile/NavContext'
import BusyButton from '@/components/raytd/busy-button'
import TestTypePill from '@/components/raytd/test-type-pill'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { useUnsavedChanges } from '@/hooks/useUnsavedChanges'
import { useGetTestSuiteQuery, useUpdateTestSuiteFieldsMutation } from '@app.raytd.com/store'
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { cloneDeep, isEqual } from 'lodash'
import { GlobeIcon } from 'lucide-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CustomField, CustomFieldType, DefaultFieldProperties, TestSuiteType } from 'store/src/lib/tests/entity'
import { v4 as uuidv4 } from 'uuid'
import { locationFields as defaultLocationFields, testSuiteTypeSpecificFields } from '../../test/defaultFieldConfiguration'
import ActiveSelect from './ActiveSelect'
import { CustomFieldDropdown } from './CustomFieldDropdown'
import HandleTableCell from './HandleTableCell'
import SortableCustomField from './SortableCustomField'
import { toast } from 'sonner'

const updateFields = (fields: DefaultFieldProperties[], settings: any) => {
  return fields.map((field) => {
    const updatedField = settings.find((f: any) => f.field_name === field.field_name);
    if (updatedField) {
      return {
        field_id: field.field_name,
        ...field,
        required: updatedField.required,
        active: updatedField.active,
      };
    }
    return field;
  });
};

const getDirtyFields = (currentFields: any[], initialFields: any[]) => {
  // return currentFields.reduce((acc, field, index) => {
  //   if (!isEqual(field, initialFields[index])) {
  //     acc.push(field.field_id);
  //   }
  //   return acc;
  // }, [] as string[]);
  const dirtyFields = new Set<string>();

  // Check for modified or new fields
  currentFields.forEach((field, index) => {
    if (!isEqual(field, initialFields[index])) {
      dirtyFields.add(field.field_id);
    }
  });

  // Check for deleted fields
  initialFields.forEach((field) => {
    if (!currentFields.some(currentField => currentField.field_id === field.field_id)) {
      dirtyFields.add(field.field_id);
    }
  });

  return Array.from(dirtyFields);
};

export default function TestSuiteFields({ testSuiteId }: { testSuiteId: string }) {
  const [customFields, setCustomFields] = useState<CustomField[]>([])
  const [standardFieldsState, setStandardFieldsState] = useState([])
  const [testSuiteType, setTestSuiteType] = useState<TestSuiteType | undefined>(undefined);

  const [initialCustomFields, setInitialCustomFields] = useState<CustomField[] | undefined>([]);
  const [initialStandardFields, setInitialStandardFields] = useState<DefaultFieldProperties[] | undefined>([]);

  const [customFieldsAreDirty, setCustomFieldsAreDirty] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const fieldRefs = useRef<Record<string, HTMLTableCellElement | null>>({});

  const [updateFieldSetttings, { isLoading: isUpdatingFields }] = useUpdateTestSuiteFieldsMutation();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { data: testSuite } = useGetTestSuiteQuery(testSuiteId);

  const [fieldValidations, setFieldValidations] = useState<Record<string, boolean>>({});

  const isAllFieldsValid = useCallback(() => {
    return Object.values(fieldValidations).every(isValid => isValid);
  }, [fieldValidations]);

  const handleFieldValidationChange = useCallback((fieldId: string, isValid: boolean) => {
    setFieldValidations(prev => ({
      ...prev,
      [fieldId]: isValid
    }));
  }, []);

  useEffect(() => {
    console.debug('field validations', fieldValidations);
  }, [fieldValidations]);

  const reset = useCallback(() => {
    setCustomFields(cloneDeep(initialCustomFields));
    setStandardFieldsState(cloneDeep(initialStandardFields));
  }, [initialCustomFields, initialStandardFields]);


  const handleSave = useCallback(async () => {

    if (!isAllFieldsValid()) {
      toast.error('Please ensure all fields are valid');
      console.debug('Invalid fields', fieldValidations);
      const firstInvalidFieldId = Object.keys(fieldValidations).find(fieldId => !fieldValidations[fieldId]);
      if (firstInvalidFieldId && fieldRefs.current[firstInvalidFieldId]) {
        fieldRefs.current[firstInvalidFieldId]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }

    try {
      await updateFieldSetttings({
        id: testSuiteId,
        settings: standardFieldsState,
        custom_fields: customFields
      }).unwrap();
      toast.success('Test suite fields saved');

      //setInitialCustomFields(cloneDeep(customFields));
      reset();

    } catch {
      console.error('Failed to save test suite fields');
      toast.error('Failed to save test suite fields');
    }

  }, [testSuiteId, standardFieldsState, customFields, fieldValidations, updateFieldSetttings, isAllFieldsValid, reset]);

  const { setNavButtons } = useNav();

  useEffect(() => {
    setNavButtons([
      <BusyButton
        onClick={handleSave}
        disabled={!unsavedChanges}
        isBusy={saving}
        busyText="Saving..."
      >
        Save
      </BusyButton>

    ])

    return () => {
      setNavButtons([])
    }
  }, [setNavButtons, handleSave, unsavedChanges, saving]);


  const { AlertDialogComponent } = useUnsavedChanges(unsavedChanges, reset, false);

  useEffect(() => {
    // Load test suite;

    if (!testSuite) {
      return;
    }

    console.debug('Reloading test suite');

    const testSuiteType = testSuite?.type.toLowerCase() as TestSuiteType;
    setTestSuiteType(testSuiteType);
    setCustomFields(testSuite?.custom_fields ?? []);

    const testSuiteSpecificFields = testSuiteTypeSpecificFields[testSuiteType];

    const updatedTestSuiteSpecificFields = updateFields(testSuiteSpecificFields, testSuite?.settings);
    const updatedLocationDefaultFields = updateFields([...defaultLocationFields], testSuite?.settings);

    const standardFields = updatedTestSuiteSpecificFields.concat(updatedLocationDefaultFields);

    setStandardFieldsState(standardFields);
    setInitialStandardFields(cloneDeep(standardFields));
    setInitialCustomFields(testSuite?.custom_fields ? cloneDeep(testSuite?.custom_fields) : []);

    console.log('test suite ', { testSuite, testSuiteType });
  }, [testSuite]);

  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>();

  //whenever a field is updated, check if the fields are dirty and keep the dirty state in a state variable
  useEffect(() => {

    //const customFieldsAreDirty = isEqual(customFields, initialCustomFields);
    //const standardFieldsAreDirty = isEqual(standardFieldsState, initialStandardFields);
    const dirtyCustomFields = getDirtyFields(customFields, initialCustomFields);

    const dirtyStandardFields = getDirtyFields(standardFieldsState, initialStandardFields);
    const isDirty = dirtyCustomFields.length > 0 || dirtyStandardFields.length > 0;

    console.debug({
      customFieldsAreDirty,
      dirtyCustomFields,
      dirtyStandardFields,
      isDirty
    })

    setUnsavedChanges(isDirty);
    setCustomFieldsAreDirty(dirtyCustomFields.length > 0);

    const dirtyFields = [...dirtyCustomFields, ...dirtyStandardFields].reduce((acc, fieldId) => {
      acc[fieldId] = true;
      return acc;
    }, {} as Record<string, boolean>);

    setDirtyFields(dirtyFields);

  }, [customFields, standardFieldsState]);

  useEffect(() => {
    console.log('dirtyFields', dirtyFields);
  }, [dirtyFields]);

  useEffect(() => {
    console.debug('standard fields updated', standardFieldsState);
  }, [standardFieldsState]);

  const addCustomField = (fieldType: CustomFieldType) => {

    const validationRules = {
      text: 'string|max:255',
      textarea: 'string|max:5000',
      number: 'numeric',
      date: 'date',
      select: '',
      checkbox: 'boolean',
      radio: '',
      switch: 'boolean',
      scrollable_number: 'numeric'
    }

    const newField: CustomField = {
      field_id: uuidv4(),
      field_name: `new_field_${customFields.length + 1}`,
      field_order: customFields.length,
      label: '',
      description: '',
      info: '',
      type: fieldType,
      placeholder: '',
      required: validationRules[fieldType] !== 'boolean' ? true : false,
      active: true,
      validations: validationRules[fieldType],
      ghost: true,
      data: []
    }
    setCustomFields([...customFields, newField]);
    setExpandedFields([...expandedFields, newField.field_id]);
  }

  const updateCustomField = useCallback((fieldId: string, updates: Partial<CustomField>) => {
    console.debug('updating custom field', { fieldId, updates });

    setCustomFields(prevFields => {

      return prevFields.map(field =>
        field.field_id === fieldId ? { ...field, ...updates } : field
      );
    });

  }, []);

  const removeFieldValidation = useCallback((fieldId: string) => {
    setFieldValidations(prev => {
      const newValidations = { ...prev };
      delete newValidations[fieldId];
      return newValidations;
    });
  }, []);

  const removeCustomField = useCallback((fieldId: string) => {
    setCustomFields((fields) => fields.filter((field) => field.field_id !== fieldId));
    //remove from fieldValidations array if exists
    removeFieldValidation(fieldId);
  }, []);

  const discardCustomField = useCallback((fieldId: string) => {
    const field = initialCustomFields.find(f => f.field_id === fieldId);
    if (!field) {
      removeCustomField(fieldId);
    } else {
      setCustomFields(fields => fields.map(f => f.field_id === fieldId ? initialCustomFields.find(f => f.field_id === fieldId) : f));
    }
  }, [initialCustomFields, removeCustomField]);

  const duplicateCustomField = useCallback((fieldId: string) => {
    console.info('duplicating field', fieldId);
    const field = customFields.find(f => f.field_id === fieldId);
    if (field) {
      const newField = {
        ...field,
        field_id: uuidv4(),
        field_name: `new_field_${customFields.length + 1}`,
        label: `Copy of ${field.label}`,
        ghost: true
      };
      setCustomFields(fields => [...fields, newField]);
    }
  }, [customFields]);

  const updateStandardField = useCallback((fieldId: string, updates: Partial<DefaultFieldProperties>) => {

    setStandardFieldsState(fields =>
      fields.map(field =>
        field.field_id === fieldId ? { ...field, ...updates } : field
      )
    )
  }, []);

  const [expandedFields, setExpandedFields] = useState<string[]>([]);

  const toggleExpand = (fieldId: string) => {
    setExpandedFields(prevExpandedFields =>
      prevExpandedFields.includes(fieldId)
        ? prevExpandedFields.filter(id => id !== fieldId)
        : [...prevExpandedFields, fieldId]
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setCustomFields((items) => {
        const oldIndex = items.findIndex((item) => item.field_id === active.id);
        const newIndex = items.findIndex((item) => item.field_id === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const fieldHeadings = useMemo(() => {

    //get unique headers from standard fields
    const headers = standardFieldsState.reduce((acc, field) => {
      if (!acc.includes(field.header)) {
        acc.push(field.header);
      }
      return acc;
    }, [] as string[]);

    // return Location Fields and then any others in alphabetical order
    return ['Location Fields', ...headers.filter(header => header !== 'Location Fields').sort()];

  }, [standardFieldsState])

  return (
    <div className="container mx-auto p-4 space-y-4 mb-10">
      <div className='flex flex-row gap-4'>
        <h1 className="text-2xl font-bold">Fields</h1>
        <TestTypePill type={testSuiteType} inverse />
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className=""></TableHead>
            <TableHead className="w-[16px]"></TableHead>
            <TableHead className="w-[200px]">Name</TableHead>
            <TableHead className="w-[calc(100%-500px)]">Description</TableHead>
            <TableHead className="w-[150px]"></TableHead>
            <TableHead className="w-[80px]">Include</TableHead>
            <TableHead className="w-[80px]">Compulsory</TableHead>
            <TableHead className="w-[12px]"></TableHead>
            <TableHead className="w-[12px]"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {fieldHeadings.map((header) => (
            <>
              <TableRow className='h-12'>
                <TableCell colSpan={1}></TableCell>
                <TableCell colSpan={7} className="font-semibold text-zinc-600">{header}</TableCell>
              </TableRow>
              {standardFieldsState.filter(f => f.header === header).map((field) => (
                <TableRow key={field.field_id}>
                  <HandleTableCell></HandleTableCell>
                  <TableCell className="w-[16px]"><GlobeIcon className="s-6 text-zinc-500" /></TableCell>
                  <TableCell className="w-[200px] font-medium">{field.label}</TableCell>
                  <TableCell className="w-[calc(100%-600px)] truncate">{field.description}</TableCell>
                  <TableCell className="w-[150px]">
                  </TableCell>
                  <TableCell className="w-[80px]">

                    <ActiveSelect
                      value={field.active}
                      onValueChange={(value) => updateStandardField(field.field_id, { active: value })}
                    />

                  </TableCell>
                  <TableCell className="w-[80px]">

                    <ActiveSelect
                      value={field.required}
                      onValueChange={(value) => updateStandardField(field.field_id, { required: value })}
                    />

                  </TableCell>
                  <TableCell className="w-[12px]"></TableCell>
                  <TableCell className="w-[12px]"></TableCell>
                </TableRow>
              ))}
            </>
          ))}


          <TableRow className='h-12'>
            <TableCell colSpan={1}></TableCell>
            <TableCell colSpan={2} className="font-semibold text-zinc-600">Custom Fields</TableCell>
            <TableCell colSpan={5}>
              <div className="flex flex-row items-end justify-end">
                <CustomFieldDropdown
                  addCustomField={addCustomField}
                />
              </div>

            </TableCell>
          </TableRow>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={customFields.map(f => f.field_id)}
              strategy={verticalListSortingStrategy}
            >
              {customFields.map((field) => (
                <SortableCustomField
                  key={field.field_id}
                  field={field}
                  initialValue={initialCustomFields?.find(f => f.field_id === field.field_id)}
                  updateCustomField={updateCustomField}
                  removeCustomField={removeCustomField}
                  discardCustomField={discardCustomField}
                  onDuplicateField={duplicateCustomField}
                  onValidationChange={handleFieldValidationChange}
                  toggleExpand={toggleExpand}
                  isExpanded={expandedFields.includes(field.field_id)}
                  isDirty={dirtyFields[field.field_id]}
                  isValid={fieldValidations[field.field_id]}
                  // @ts-expect-error
                  fieldRef={el => (fieldRefs.current[field.field_id] = el)}
                />
              ))}
            </SortableContext>
          </DndContext>
        </TableBody>
      </Table>
      <AlertDialogComponent />
    </div>
  )
}