import { useEffect, useCallback } from 'react';
import { useBlocker } from 'react-router-dom';
import { UseFormWatch, UseFormReset } from 'react-hook-form';
import { useAlertDialog } from './useAlertDialog';

export function useUnsavedChanges<T extends Record<string, any>>(
  isDirty: boolean,
  reset: UseFormReset<T>,
  isDisabled?: boolean
) {
  const { AlertDialogComponent, showAlertDialog } = useAlertDialog();

  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      console.debug('shouldBlock', {currentLocation, nextLocation, isDirty, isDisabled});
      return isDirty && !isDisabled;
    },
    [isDirty, isDisabled]
  );

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      showAlertDialog({
        title: 'Unsaved Changes',
        description:
          'You have unsaved changes. Are you sure you want to leave?',
        confirmText: 'Leave',
        cancelText: 'Stay',
      }).then((confirmed) => {
        if (confirmed) {
          blocker.proceed();
          reset();
        } else {
          blocker.reset();
        }
      });
    }
  }, [blocker, showAlertDialog, reset, isDirty]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty && !isDisabled) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty, isDisabled]);


    return { AlertDialogComponent };    
}
