import AddressInput from "@/app/features/assets/components/address";
import assetTypes from "@/app/features/assets/components/assetTypes";
import NestedAssetPicker from '@/app/features/assets/components/nested-asset-picker';
import { useNav } from "@/app/features/profile/NavContext";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useFileUpload } from "@/hooks/use-file-upload";
import { AssetEntity, useCreateAssetMutation, useGetAssetQuery, useUpdateAssetMutation } from "@app.raytd.com/store";
import { zodResolver } from '@hookform/resolvers/zod';
import classNames from "classnames";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import SubPageLayout from '../organisation/SubPageLayout';
import BusyButton from "@/components/raytd/busy-button";
import { useUnsavedChanges } from "@/hooks/useUnsavedChanges";
import { ImagePlus } from "lucide-react";
import { ImageUpload } from "@/components/raytd/image-upload";
import { toast } from "sonner";
import APIError from "store/src/lib/api/apiError";
import { useAuth } from "@/app/_components";
import { useNavigate } from "react-router-dom";

const AssetDetails = ({ assetId }) => {

    const { data: asset, isLoading, error } = useGetAssetQuery(assetId);

    if (isLoading) {
        return <div>Loading...</div>
    }

    console.debug({ asset });

    return (
        <AssetForm asset={asset} />
    )
}

export default AssetDetails;

const addressSchema = z.object({
    street: z.string().min(1, { message: "Address line 1 is required" }),
    suburb: z.string().min(1, { message: "Suburb is required" }),
    state: z.string().optional(),
    postcode: z.string().min(1, { message: "Postal code is required" }),
    country: z.string().min(1, { message: "Country is required" }),
})

const formSchema = z.object({
    name: z.string().min(2, { message: "Name must be at least 2 characters." }),
    description: z.union([z.null(), z.string()]).optional(),
    address: addressSchema.optional(),
    physical_address: addressSchema.optional(),
    type: z.string(),
    photo: z.union([z.null(), z.instanceof(File)]).optional(),
})

const AssetForm = ({ asset, title = "Details" }: { asset: Partial<AssetEntity>, title?: string }) => {

    const navigate = useNavigate();

    const [formDisabled, setFormDisabled] = useState(false);
    const {user} = useAuth();

    const [retainedImage, setRetainedImage] = useState<string | null>(null);
    const { upload: uploadPhoto, isLoading: isImageUploading, isError: isImageError, isSuccess: isImageSuccess, error: imageError, reset } = useFileUpload('/api/v1/asset/:id/cover', 'cover_image');

    const [updateAsset, { isLoading: isUpdating, error: updateError }] = useUpdateAssetMutation();
    const [createAsset, { isLoading: isCreating, error: createError }] = useCreateAssetMutation();

    const isBusy = isUpdating || isImageUploading || isCreating;

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: "",
            description: "",
            address: {},
            photo: null
        },
        disabled: formDisabled,
    });

    const { formState } = form;
    const { isDirty } = formState;

    const { AlertDialogComponent } = useUnsavedChanges(isDirty, form.reset, formDisabled);

    const onSubmit = useCallback(async (values: z.infer<typeof formSchema>) => {
        try {
            const { photo, address, ...data } = values;
            data.physical_address = address;

            if (asset.id === null) {
                const payload = await createAsset({
                    organisationId: user.company?.id,
                    ...data
                }).unwrap();
                console.debug('create payload', payload);

                if (photo instanceof File) {
                    const photoPayload = await uploadPhoto(photo, { id: payload.asset.id.toString() });
                    console.debug('photo', photoPayload);
                }

                console.debug('asset created', payload.asset.id);
                setFormDisabled(true);
                setTimeout(() => navigate(`/assets/${payload.asset.id}`), 10);

            } else {

                const promises = [];

                if (photo instanceof File) {
                    const uploadPhotoPromise = uploadPhoto(photo, { id: asset.id.toString() });
                    promises.push(uploadPhotoPromise);
                }

                const updateFormPromise = updateAsset({ id: asset.id, ...data }).unwrap();
                promises.push(updateFormPromise);

                const [photoResponse, payload] = await Promise.all(promises);

                if (photoResponse) {
                    console.debug('photo', photoResponse);
                    setRetainedImage(photoResponse.url);
                }

                console.info(payload);
            }
            //form.reset(values);

        } catch (err) {
            console.error(err as APIError);
            if (err?.status === 403) {
                form.setError("name", {
                    type: "manual",
                    message: "You are not authorised to update this asset",
                });
                toast.error("You dont' have permission to update this asset");
            }
        }
    }, [createAsset, updateAsset, uploadPhoto, asset.id, form]);

    const { setNavButtons } = useNav();

    useEffect(() => {
        form.reset({
            ...asset,
            photo: null
        });

        //const isAdmin = asset?.role ?? 'admin' === 'admin';
        const isAdmin = asset?.role === 'admin';
        setFormDisabled(!isAdmin && asset?.id !== null);

    }, [asset, form.reset, setFormDisabled]);

    useEffect(() => {
        setNavButtons(
            <BusyButton
                onClick={form.handleSubmit(onSubmit)}
                disabled={!isDirty || formDisabled}
                isBusy={isBusy}
                busyText="Saving..."
            >
                Save
            </BusyButton>
        );

        // Clean up function to reset buttons when component unmounts
        return () => setNavButtons(null);
    }, [
        setNavButtons,
        form.handleSubmit,
        onSubmit,
        isDirty,
        isBusy

    ]);


    return (
        <SubPageLayout title={title}>
            <Form {...form} >
                <form >
                    <div className="space-y-4">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Asset name..." {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="description"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Description</FormLabel>
                                    <FormControl>
                                        <Textarea placeholder="Asset description..." {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="address"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Address</FormLabel>
                                    <FormControl>
                                        <AddressInput {...field}
                                            value={{
                                                street: field.value?.street ?? '',
                                                suburb: field.value?.suburb ?? '',
                                                state: field.value?.state ?? '',
                                                postcode: field.value?.postcode ?? '',
                                                country: field.value?.country ?? '',
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="type"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Type</FormLabel>
                                    <FormControl>

                                        <NestedAssetPicker
                                            assetTypes={assetTypes}
                                            {...field}
                                        />

                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />


                        <FormField
                            control={form.control}
                            name="photo"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Photo</FormLabel>
                                    <FormControl>

                                        <ImageUpload
                                            onChange={(file) => field.onChange(file)}
                                            onBlur={field.onBlur}
                                            value={field.value}
                                            name={field.name}
                                            error={form.formState.errors.photo?.message}
                                            initialImage={retainedImage ?? asset.imageUrl}
                                            isLoading={isImageUploading}
                                        />

                                    </FormControl>

                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                    </div>
                </form>
            </Form>
            <AlertDialogComponent />

        </SubPageLayout>
    )
}

export { AssetForm }