import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'; // Shadcn table components

export const SkeletonLoader: React.FC = () => {
  const skeletonRows = new Array(5).fill(0); // 5 rows of skeleton

  return (
    <div className="rounded-md border">
      <Table className="table-fixed">
        <TableBody>
          {skeletonRows.map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <div className="h-6 w-12 bg-zinc-200 rounded-md animate-pulse" />
              </TableCell>
              <TableCell>
                <div className="h-6 w-52 bg-zinc-200 rounded-md animate-pulse" />
              </TableCell>
              <TableCell>
                <div className="h-6 w-12 bg-zinc-200 rounded-md animate-pulse" />
              </TableCell>
              <TableCell>
                <div className="h-6 w-12 bg-zinc-200 rounded-md animate-pulse" />
              </TableCell>
              <TableCell>
                <div className="h-6 w-12 bg-zinc-200 rounded-md animate-pulse" />
              </TableCell>
              <TableCell>
                <div className="h-6 w-24 bg-zinc-200 rounded-md animate-pulse" />
              </TableCell>
              <TableCell>
                <div className="h-6 w-12 bg-zinc-200 rounded-md animate-pulse" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
