import {
    Badge,
    Box,
    Card,
    Text,
    TableListItem,
    Heading,
} from "@wix/design-system";
import { TestSuiteType } from "store/src/lib/tests/entity";
import React from "react";

type TestSuiteSelectTileProps = {
    title: string;
    description: string;
    info: string;
    image?: string;
    action: () => void;
    selected?: boolean;

}

const TestSuiteSelectTile = ({ title, description, info, action, selected, image }: TestSuiteSelectTileProps) => {

    const border = selected ? '1px solid #000' : '1px solid #eee';

    return (
        <Box padding="tiny" display="block" flex={1}
            border={border}
        >
            <TableListItem
                // border={true}
                onClick={action}
                horizontalPadding={'tiny'}
                options={[
                    {
                        value: (
                            <Box marginLeft="SP3" direction="vertical" align="left" flex={1}>
                                <Heading size="small" width="48px" height="48px" >{title}</Heading>
                                <Text size="small">{description}</Text>
                                <Text size="small" secondary>{info}</Text>
                                <Box alignContent="center" flex={1} width={"100%"} marginTop={2}>{image && <img src={image} alt={title}  width={200} height={20} />}</Box>
                            </Box>
                        ),
                        width: '80%',
                    },
                    {
                        value: (
                            selected && (<Badge size="medium" skin="standard">Selected</Badge>)
                        ),
                        align: 'right',
                    },
                ]}
            />
        </Box>
    )
}

export const TestSuiteSelection = ({ onChange, value }: { onChange: (testSuiteType: TestSuiteType) => void, value?: TestSuiteType }) => {

    const [selected, setSelected] = React.useState<TestSuiteType | undefined>(value);

    const handleSelect = (testSuiteType: TestSuiteType) => {
        setSelected(testSuiteType);
        onChange(testSuiteType);
    }

    React.useEffect(() => {
        setSelected(value);
    }, [value]);

    return (
        <Card>
            <Card.Content>

                <Box marginLeft="SP3" direction="vertical" align="left" padding="medium">
                    <Heading size="medium">Select a test suite to configure</Heading>
                    <Text size="small">Select a test suite that matches the type of inspection you will be completing. Note: you can use multiple test suites during an inspection.</Text>
                </Box>

                <Box direction="vertical" gap="small">

                    <TestSuiteSelectTile
                        selected={selected === 'compliance'}
                        title="Compliance Inspection"
                        description="Measure the performance of building elements against specific performance criteria using our industry standard rating tool."
                        info="Automatically generates a full quantitative analysis for your reports."
                        image="/public/Compliance_All.png"
                        action={() => handleSelect('compliance')}
                    />
                    <TestSuiteSelectTile
                        selected={selected === 'condition'}
                        title="Condition Assessment Inspection"
                        description="Assess the condition of specific building elements using our industry standard rating tool."
                        info="Automatically generates a full quantitative analysis for your reports."
                        image="/public/Condition_All.png"
                        action={() => handleSelect('condition')}
                    />
                    <TestSuiteSelectTile
                        selected={selected === 'generic'}
                        title="General Inspection"
                        description="Document building elements and record comments without ratings."
                        info=""
                        action={() => handleSelect('generic')}
                    />

                </Box>

            </Card.Content>
        </Card>
    )

}
