import { Control, useController } from 'react-hook-form'
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Pill } from "@/components/raytd/pill"
import { cn } from "@/lib/utils"
import { TestType } from "@/components/raytd/test-type-pill"
import { Circle } from 'lucide-react'
import { useState } from "react"

interface TestSuiteType {
  type: TestType
  title: string
  description: string[]
  types: {
    label: string
    rating: {
      total: number
      value: number
      color: string
    }
    image: string;
    description: string
  }[]
  disabled?: boolean
}

const TEST_SUITE_TYPES: TestSuiteType[] = [
  {
    type: 'compliance',
    title: 'Compliance Test Suite',
    description: [
      'Measure the performance of building elements or spaces against specific performance criteria using our industry standard rating tool.',
      'Automatically generates a full quantitative analysis for your reports.'
    ],
    types: [
      {
        label: 'Type A',
        rating: {
          total: 5,
          value: 5,
          color: 'bg-green-500'
        },
        image: '/reports/ratings/PNG__Compliance-A_FULL.png',
        description: 'The inspected feature is compliant and meets the requirements of the relevant legislation or code.\n\nNo modification is required or necessary.'
      },
      {
        label: 'Type B',
        rating: {
          total: 5,
          value: 4,
          color: 'bg-blue-500'
        },
        image: '/reports/ratings/PNG__Compliance-B_FULL.png',
        description: 'The inspected feature meets most requirements with minor non-compliance issues.'
      },
      {
        label: 'Type C',
        rating: {
          total: 5,
          value: 3,
          color: 'bg-yellow-500'
        },
        image: '/reports/ratings/PNG__Compliance-C_FULL.png',
        description: 'The inspected feature has moderate compliance issues that need attention.'
      }
    ]
  },
  {
    type: 'condition',
    title: 'Condition Assessment Test Suite',
    description: [
      'Assess the condition of specific building elements or spaces using our industry standard rating tool.',
      'Automatically generates a full quantitative analysis for your reports.'
    ],
    types: [
      {
        label: 'Condition',
        rating: {
          total: 5,
          value: 4,
          color: 'bg-blue-500'
        },
        image: '/reports/ratings/PNG__Condition_FULL.png',
        description: 'The inspected feature is in a very good condition, is high quality, meets user expectations, and is serviceable and working.'
      },
    ]
  },
  {
    type: 'generic',
    title: 'General Test Suite',
    description: [
      'Document building elements and record comments without ratings.'
    ],
    types: [
      {
        label: 'General',
        rating: {
          total: 5,
          value: 0,
          color: 'bg-gray-200'
        },
        image: '/reports/ratings/PNG__No_Rating.png',
        description: 'Document observations and recommendations without numerical ratings.'
      }
    ]
  },
  {
    type: 'numerical',
    title: 'Numerical Test Suite',
    description: ['Something about numerical ratings'],
    types: [
      {
        label: 'Custom',
        rating: {
          total: 5,
          value: 1,
          color: 'bg-purple-500'
        },
        image: '/reports/ratings/PNG__Numerical_FULL.png',
        description: 'Create your own numerical rating system.'
      }
    ],
    disabled: true
  }
]

interface TestSuiteTypePickerProps {
  control: Control<any>
  name: string
}

export const TestSuiteTypePicker: React.FC<TestSuiteTypePickerProps> = ({ control, name }) => {
  const { field } = useController({ name, control })

  return (
    <RadioGroup
      onValueChange={field.onChange}
      defaultValue={field.value}
      className="flex gap-4 overflow-x-auto pb-4 w-full snap-x snap-mandatory"
    >
      {TEST_SUITE_TYPES.map((suite) => (
        <SuiteTypeCard
          key={suite.type}
          {...suite}
          selected={field.value === suite.type}
        />
      ))}
    </RadioGroup>
  )
}

interface SuiteTypeCardProps extends TestSuiteType {
  selected: boolean
}

function RatingDisplay({ total, value, color }: { total: number; value: number; color: string }) {
  return (
    <div className="flex gap-1 my-4">
      {Array.from({ length: total }).map((_, i) => (
        <Circle
          key={i}
          className={cn(
            "size-6",
            i < value ? color : "text-gray-200",
            i < value ? "fill-current" : "fill-current"
          )}
        />
      ))}
    </div>
  )
}

function SuiteTypeCard({ type, title, description, types, selected, disabled }: SuiteTypeCardProps) {
  const [selectedType, setSelectedType] = useState(types[0].label)
  const currentType = types.find(t => t.label === selectedType) || types[0]

  return (
    <div className="w-[350px] h-[800px] shrink-0 snap-center">
      <RadioGroupItem
        value={type}
        id={type}
        className="peer sr-only"
        disabled={disabled}
      />
      <Label
        htmlFor={type}
        className="cursor-pointer"
      >
        <Card className={cn(
          "transition-all h-full",
          selected && "border-primary",
          disabled && "opacity-50 cursor-not-allowed"
        )}>
          <CardHeader>
            <CardTitle>{title}</CardTitle>
            <div className="text-sm text-muted-foreground space-y-2">
              {description.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </div>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="border p-6 rounded-md">
                {types.map(type => {
                    return (
                        <>
                        <div>{type.label}</div>
                        <div className="text-sm text-muted-foreground whitespace-pre-line">
                        <img src={type.image} className="my-2" />
                    </div>
                    </>
                        )}
                    )}
                    <div className="text-sm font-normal">
                    {types[0].description}
                    </div>
            </div>

            {selected && (
              <div className="flex flex-wrap justify-end">
                <Pill variant="inverted">Selected</Pill>
              </div>
            )}
          </CardContent>
        </Card>
      </Label>
    </div>
  )
}

