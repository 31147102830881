import { InspectionForm } from "@/app/features/inspections/InspectionDetail";
import SubPageLayout from "@/app/features/organisation/SubPageLayout"
import PageWithTopBarNavigation from "@/app/features/profile/PageWithTopBarNavigation";
import { TopNavBar } from "@/app/features/profile/TopNavBar";
import { MainPage } from "@/app/layout2/AppLayout";
import { SquareChevronLeft } from "lucide-react";
import { NavLink, useLocation, useParams } from "react-router-dom";


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};


const NewInspectionPage = () => {

    const query = useQuery();
    const assetId = query.get('assetId');

    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    <NavLink to={assetId ? `/assets/${assetId}/inspections` : '/inspections'}>
                        <SquareChevronLeft className="h-6 w-6 text-zinc-500" />
                    </NavLink>
                </TopNavBar>

                <SubPageLayout title="New Inspection">

                    <InspectionForm

                        inspection={{
                            id: undefined,
                            title: '',
                            asset: assetId ? { id: parseInt(assetId) } : undefined,
                            testSuiteId: [],
                            elementGroupId: [],
                            status: 'active',
                        }} />
                </SubPageLayout>
            </PageWithTopBarNavigation>
        </MainPage>
    );

}

export default NewInspectionPage;