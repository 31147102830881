import { Loader2 } from "lucide-react"

type LoadingStateProps = {
    message?: string;
}

const LoadingState: React.FC<LoadingStateProps> = () => {
    return (
        <div className="w-full min-h-[500px] flex flex-col items-center justify-center p-6">
            <div className="max-w-md text-center space-y-4">
                <Loader2 className="h-16 w-16 text-zinc-300 animate-spin" />
            </div>
        </div>
    )
}

export default LoadingState;