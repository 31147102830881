import { PlusIcon } from "lucide-react"
import { useCallback, useState } from "react"
import { toast } from "sonner"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { useDeleteOfficeMutation, useGetOfficesQuery } from "@app.raytd.com/store"
import { OfficeDialog } from "./AddOfficeDialog"
import OfficeTile from './OfficeTile'
import { Office } from './types'


const EmptyOffices = () => (
    <div className="p-4 text-center text-gray-500">
        <p>No offices added</p>
    </div>
)

export default function Offices({ disabled = false }) {
    const { data: offices, isLoading, isFetching } = useGetOfficesQuery({});
    const [removeOffice] = useDeleteOfficeMutation();

    const [officeToRemove, setOfficeToRemove] = useState<number | null>(null)

    const [dialogOpen, setDialogOpen] = useState(false);
    const [editingOffice, setEditingOffice] = useState<Office | undefined>(undefined);

    const handleRemove = useCallback(async () => {
        console.info('officeToRemove', officeToRemove);
        try {
            if (!officeToRemove) return;
            await removeOffice(officeToRemove).unwrap()
            toast.success("Office removed")
            return true;
        } catch (error) {
            console.error(error)
            toast.error("Failed to remove office")
            return false;
        } finally {
            setOfficeToRemove(null)
        }
    }, [removeOffice, officeToRemove]);

    const removeOfficeHandler = useCallback((office: Office) => {
        console.info('removeOfficeHandler', office);
        setOfficeToRemove(office.id);
    }, [setOfficeToRemove]);

    const handleEditOffice = useCallback((office: Office) => {
        console.info('handle edit office', office);
        setEditingOffice(office)
        setDialogOpen(true)
    }, [setEditingOffice, setDialogOpen]);

    const handleAddOffice = useCallback(() => {
        setEditingOffice(undefined);
        setDialogOpen(true);
    }, [setEditingOffice, setDialogOpen]);

    const handleDialogClose = () => {
        setDialogOpen(false)
        setEditingOffice(undefined)
    }

    const handleSuccess = () => {
        // Refresh your office list here
        console.log("Office added or updated successfully")
    }

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h3 className="text-lg font-medium">Offices</h3>
                {!disabled && (
                    // <AddOfficeDialog />
                    <Button variant="ghost" onClick={handleAddOffice} ><PlusIcon className="w-4 h-4" />Add Office</Button>
                )}
            </div>
            <Card>
                <div className="space-y-1">
                    {isLoading && <div>Loading...</div>}
                    {!isLoading && offices?.length > 0 ? (
                        offices.map((office) => (
                            <OfficeTile
                                key={office.id}
                                office={office}
                                onRemove={removeOfficeHandler}
                                onEdit={handleEditOffice}
                                editable={!disabled}
                            />
                        ))
                    ) : (
                        <EmptyOffices />
                    )}
                </div>
            </Card>
            <OfficeDialog
                open={dialogOpen}
                onOpenChange={handleDialogClose}
                office={editingOffice}
                onSuccess={handleSuccess}
            />
            <AlertDialog open={!!officeToRemove} onOpenChange={(open) => !open && setOfficeToRemove(null)}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                            This action cannot be undone. This will permanently delete the office.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleRemove}>Remove</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    )
}