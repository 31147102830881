import { Modal, MessageModalLayout, Text } from '@wix/design-system';
import { TestSuite } from 'store/src/lib/tests/entity';
interface TestSuiteDeleteModalProps {
    isOpen: boolean;
    onClose: () => void;
    onDelete: (testSuiteId: number) => Promise<void>;
    onArchive: (testSuiteId: number) => Promise<void>;
    testSuite: TestSuite;
}

export const TestSuiteDeleteModal = (props: TestSuiteDeleteModalProps) => {
    const { isOpen, onClose, onDelete, onArchive, testSuite } = props;

    if (!testSuite) return null;

    const mode = testSuite.total_assessments === 0 && testSuite.status === 'archived' ? 'delete' : 'archive';

    const modeConfig = {
        archive: {
            confirmationMessage: 'This will archive the test suite and all associated tests.',
            primaryButtonText: 'Archive'
        },
        delete: {
            confirmationMessage: 'This will delete the test suite and all associated tests. This cannot be undone.',
            primaryButtonText: 'Delete'
        }
    };

    const handleDelete = async () => {
        if (mode === 'archive') {
            await onArchive(testSuite.id);
        }
        else {
            await onDelete(testSuite.id);
        }

        onClose();
    }

    const { confirmationMessage, primaryButtonText } = modeConfig[mode];

    return (
        <Modal isOpen={isOpen} shouldCloseOnOverlayClick={true} onRequestClose={onClose}>
            <MessageModalLayout
                primaryButtonText={primaryButtonText}
                secondaryButtonText="Cancel"
                onCloseButtonClick={onClose}
                secondaryButtonOnClick={onClose}
                primaryButtonOnClick={handleDelete}
                title={`${mode === 'archive' ? 'Archive' : 'Delete'} ${testSuite.title}`}
                content={
                    <Text>
                        {confirmationMessage}
                    </Text>
                }

            />
        </Modal>
    );

}
